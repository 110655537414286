// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var _map = {"Anonymous":"Anonymous","Authenticated":"Authenticated"};

function authenticationStatusToJs(param) {
  return param;
}

function authenticationStatusFromJs(param) {
  return _map[param];
}

var _map$1 = {"ItemRemoved":"Item Removed","ItemAdded":"Item Added","ItemClicked":"Item Clicked","ItemChanged":"Item Changed","SearchedForItem":"Searched For Item","ItemCreationInitiated":"Item Creation Initiated"};

var _revMap = {"Item Removed":"ItemRemoved","Item Added":"ItemAdded","Item Clicked":"ItemClicked","Item Changed":"ItemChanged","Searched For Item":"SearchedForItem","Item Creation Initiated":"ItemCreationInitiated"};

function propertiesViewPopupActionToJs(param) {
  return _map$1[param];
}

function propertiesViewPopupActionFromJs(param) {
  return _revMap[param];
}

var _map$2 = {"Unknown":"Unknown","WrongPassword":"Wrong Password","UserNotFound":"User Not Found","UserDisabled":"User Disabled","InvalidEmail":"Invalid Email","OperationNotAllowed":"Operation Not Allowed","EmailAlreadyInUse":"Email Already In Use","WeakPassword":"Weak Password","CredentialAlreadyInUse":"Credential Already In Use","RefreshTokenRevoked":"Refresh Token Revoked"};

var _revMap$1 = {"Unknown":"Unknown","Wrong Password":"WrongPassword","User Not Found":"UserNotFound","User Disabled":"UserDisabled","Invalid Email":"InvalidEmail","Operation Not Allowed":"OperationNotAllowed","Email Already In Use":"EmailAlreadyInUse","Weak Password":"WeakPassword","Credential Already In Use":"CredentialAlreadyInUse","Refresh Token Revoked":"RefreshTokenRevoked"};

function authenticationErrorToJs(param) {
  return _map$2[param];
}

function authenticationErrorFromJs(param) {
  return _revMap$1[param];
}

var _map$3 = {"Admin":"Admin","Editor":"Editor","CommentOnly":"Comment Only","Viewer":"Viewer","BillingOnly":"Billing Only","CodegenAccess":"Codegen Access"};

var _revMap$2 = {"Admin":"Admin","Editor":"Editor","Comment Only":"CommentOnly","Viewer":"Viewer","Billing Only":"BillingOnly","Codegen Access":"CodegenAccess"};

function toRoleToJs(param) {
  return _map$3[param];
}

function toRoleFromJs(param) {
  return _revMap$2[param];
}

var _map$4 = {"Editor":"Editor","Admin":"Admin","CommentOnly":"Comment Only","Viewer":"Viewer","BillingOnly":"Billing Only","CodegenAccess":"Codegen Access"};

var _revMap$3 = {"Editor":"Editor","Admin":"Admin","Comment Only":"CommentOnly","Viewer":"Viewer","Billing Only":"BillingOnly","Codegen Access":"CodegenAccess"};

function fromRoleToJs(param) {
  return _map$4[param];
}

function fromRoleFromJs(param) {
  return _revMap$3[param];
}

var _map$5 = {"BranchesOverviewRow":"Branches Overview Row","BranchesPopup":"Branches Popup","BranchesOverviewActivityItem":"Branches Overview Activity Item"};

var _revMap$4 = {"Branches Overview Row":"BranchesOverviewRow","Branches Popup":"BranchesPopup","Branches Overview Activity Item":"BranchesOverviewActivityItem"};

function branchLinkLocationToJs(param) {
  return _map$5[param];
}

function branchLinkLocationFromJs(param) {
  return _revMap$4[param];
}

var _map$6 = {"OldestFirst":"Oldest First","NewestFirst":"Newest First","Alphabetical":"Alphabetical","NoSorting":"No Sorting"};

var _revMap$5 = {"Oldest First":"OldestFirst","Newest First":"NewestFirst","Alphabetical":"Alphabetical","No Sorting":"NoSorting"};

function currentSortingToJs(param) {
  return _map$6[param];
}

function currentSortingFromJs(param) {
  return _revMap$5[param];
}

var _map$7 = {"Goals":"Goals","Events":"Events","SchemaDashboard":"Schema Dashboard","EventPropertyComment":"Event Property Comment","EventProperty":"Event Property","EventComment":"Event Comment","EventFullscreen":"Event Fullscreen","NotFound":"Not Found","PasswordReset":"Password Reset","SourceSettings":"Source Settings","DestinationSettings":"Destination Settings","Event":"Event","Diff":"Diff","Settings":"Settings","Billing":"Billing","Schemas":"Schemas","SignUp":"Sign Up","SignIn":"Sign In","Invite":"Invite","Welcome":"Welcome","Properties":"Properties","Property":"Property","AuthenticateCLI":"Authenticate CLI","AuthenticateCLISuccess":"Authenticate CLI Success","AuthenticateCLIError":"Authenticate CLI Error","Implement":"Implement","EventPropertyGroup":"Event Property Group","PropertyGroup":"Property Group","Metric":"Metric","Onboarding":"Onboarding","AuditLog":"Audit Log","Integrations":"Integrations","Category":"Category","HealthErrors":"Health Errors","HealthCoverage":"Health Coverage","HealthDebugger":"Health Debugger","InspectorEvents":"InspectorEvents","BranchActivity":"Branch Activity","Integration":"Integration","IntegrationComment":"IntegrationComment","ImportUpload":"Import Upload","ImportReview":"Import Review","ImportSuccess":"Import Success","EventTrigger":"Event Trigger","SourceInspectorSetup":"Source Inspector Setup","SourceFunctionsSetup":"Source Functions Setup","SourceEventReference":"Source Event Reference","EventPropertyGroups":"Event Property Groups","BranchImplementation":"Branch Implementation","PublicBranchImplementation":"Public Branch Implementation","BranchImplementationSource":"Branch Implementation Source","InspectorIssues":"Inspector Issues","InspectorSavedViews":"Inspector Saved Views","InspectorSavedView":"Inspector Saved View","InspectorIssue":"Inspector Issue","InspectorIssueComment":"Inspector Issue Comment","Sandbox":"Sandbox","EventVariant":"Event Variant","EventVariantTrigger":"Event Variant Trigger"};

var _revMap$6 = {"Goals":"Goals","Events":"Events","Schema Dashboard":"SchemaDashboard","Event Property Comment":"EventPropertyComment","Event Property":"EventProperty","Event Comment":"EventComment","Event Fullscreen":"EventFullscreen","Not Found":"NotFound","Password Reset":"PasswordReset","Source Settings":"SourceSettings","Destination Settings":"DestinationSettings","Event":"Event","Diff":"Diff","Settings":"Settings","Billing":"Billing","Schemas":"Schemas","Sign Up":"SignUp","Sign In":"SignIn","Invite":"Invite","Welcome":"Welcome","Properties":"Properties","Property":"Property","Authenticate CLI":"AuthenticateCLI","Authenticate CLI Success":"AuthenticateCLISuccess","Authenticate CLI Error":"AuthenticateCLIError","Implement":"Implement","Event Property Group":"EventPropertyGroup","Property Group":"PropertyGroup","Metric":"Metric","Onboarding":"Onboarding","Audit Log":"AuditLog","Integrations":"Integrations","Category":"Category","Health Errors":"HealthErrors","Health Coverage":"HealthCoverage","Health Debugger":"HealthDebugger","InspectorEvents":"InspectorEvents","Branch Activity":"BranchActivity","Integration":"Integration","IntegrationComment":"IntegrationComment","Import Upload":"ImportUpload","Import Review":"ImportReview","Import Success":"ImportSuccess","Event Trigger":"EventTrigger","Source Inspector Setup":"SourceInspectorSetup","Source Functions Setup":"SourceFunctionsSetup","Source Event Reference":"SourceEventReference","Event Property Groups":"EventPropertyGroups","Branch Implementation":"BranchImplementation","Public Branch Implementation":"PublicBranchImplementation","Branch Implementation Source":"BranchImplementationSource","Inspector Issues":"InspectorIssues","Inspector Saved Views":"InspectorSavedViews","Inspector Saved View":"InspectorSavedView","Inspector Issue":"InspectorIssue","Inspector Issue Comment":"InspectorIssueComment","Sandbox":"Sandbox","Event Variant":"EventVariant","Event Variant Trigger":"EventVariantTrigger"};

function toScreenToJs(param) {
  return _map$7[param];
}

function toScreenFromJs(param) {
  return _revMap$6[param];
}

var _map$8 = {"Page":"Page","Alias":"Alias","Revenue":"Revenue","Unidentify":"Unidentify","Identify":"Identify","SkipLogEvent":"Skip Log Event","UpdateGroups":"Update Groups"};

var _revMap$7 = {"Page":"Page","Alias":"Alias","Revenue":"Revenue","Unidentify":"Unidentify","Identify":"Identify","Skip Log Event":"SkipLogEvent","Update Groups":"UpdateGroups"};

function eventTypesSelectedAfterToJs(param) {
  return _map$8[param];
}

function eventTypesSelectedAfterFromJs(param) {
  return _revMap$7[param];
}

var _map$9 = {"Custom":"Custom","MParticle":"mParticle","FirebaseAnalytics":"Firebase Analytics","FacebookAnalytics":"Facebook Analytics","Segment":"Segment","Amplitude":"Amplitude","Mixpanel":"Mixpanel","FullStory":"FullStory","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","Braze":"Braze","ZendeskConnect":"ZendeskConnect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","GoogleAnalytics4":"Google Analytics 4","Freshpaint":"Freshpaint","AdobeAnalytics":"Adobe Analytics","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

var _revMap$8 = {"Custom":"Custom","mParticle":"MParticle","Firebase Analytics":"FirebaseAnalytics","Facebook Analytics":"FacebookAnalytics","Segment":"Segment","Amplitude":"Amplitude","Mixpanel":"Mixpanel","FullStory":"FullStory","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","Braze":"Braze","ZendeskConnect":"ZendeskConnect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","Google Analytics 4":"GoogleAnalytics4","Freshpaint":"Freshpaint","Adobe Analytics":"AdobeAnalytics","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

function dataDestinationToJs(param) {
  return _map$9[param];
}

function dataDestinationFromJs(param) {
  return _revMap$8[param];
}

var _map$10 = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","CommentOnly":"Comment Only","BillingOnly":"Billing Only"};

var _revMap$9 = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","Comment Only":"CommentOnly","Billing Only":"BillingOnly"};

function userRoleToJs(param) {
  return _map$10[param];
}

function userRoleFromJs(param) {
  return _revMap$9[param];
}

var _map$11 = {"CurrentVersion":"Current version","NextVersion":"Next version","OneHourAfterBranchMerge":"One hour after branch merge","_24HoursAfterBranchMerge":"24 hours after branch merge","CustomTimeframe":"Custom timeframe","DontIgnore":"Don't Ignore"};

var _revMap$10 = {"Current version":"CurrentVersion","Next version":"NextVersion","One hour after branch merge":"OneHourAfterBranchMerge","24 hours after branch merge":"_24HoursAfterBranchMerge","Custom timeframe":"CustomTimeframe","Don't Ignore":"DontIgnore"};

function releaseVersionSourceOptionSelectedToJs(param) {
  return _map$11[param];
}

function releaseVersionSourceOptionSelectedFromJs(param) {
  return _revMap$10[param];
}

var _map$12 = {"Free":"Free","Scholarship":"Scholarship","Growth":"Growth","Enterprise":"Enterprise","Starter":"Starter","Team":"Team"};

function workspaceSubscriptionPlanToJs(param) {
  return param;
}

function workspaceSubscriptionPlanFromJs(param) {
  return _map$12[param];
}

var _map$13 = {"OneHourAfterBranchMerge":"One hour after branch merge","_24HoursAfterBranchMerge":"24 hours after branch merge","CustomTimeframe":"Custom timeframe","CurrentVersion":"Current version","NextVersion":"Next version","DontIgnore":"Don't ignore"};

var _revMap$11 = {"One hour after branch merge":"OneHourAfterBranchMerge","24 hours after branch merge":"_24HoursAfterBranchMerge","Custom timeframe":"CustomTimeframe","Current version":"CurrentVersion","Next version":"NextVersion","Don't ignore":"DontIgnore"};

function releaseVersionAppliedOptionsToJs(param) {
  return _map$13[param];
}

function releaseVersionAppliedOptionsFromJs(param) {
  return _revMap$11[param];
}

var _map$14 = {"AlwaysSent":"Always Sent","SometimesSent":"Sometimes Sent","NeverSent":"Never Sent","DependingOnSource":"Depending on Source"};

var _revMap$12 = {"Always Sent":"AlwaysSent","Sometimes Sent":"SometimesSent","Never Sent":"NeverSent","Depending on Source":"DependingOnSource"};

function propertyPresenceTypeAfterToJs(param) {
  return _map$14[param];
}

function propertyPresenceTypeAfterFromJs(param) {
  return _revMap$12[param];
}

var _map$15 = {"Required":"Required","Optional":"Optional"};

function changedToToJs(param) {
  return param;
}

function changedToFromJs(param) {
  return _map$15[param];
}

var _map$16 = {"Header":"Header","None":"None","Sentence":"Sentence","Title":"Title","Pascal":"Pascal","Capital":"Capital","Upper":"Upper","Kebab":"Kebab","Camel":"Camel","Constant":"Constant","Snake":"Snake","Lower":"Lower","Advanced":"Advanced"};

function namingConventionToJs(param) {
  return param;
}

function namingConventionFromJs(param) {
  return _map$16[param];
}

var _map$17 = {"Events":"Events","SignIn":"Sign In","SignUp":"Sign Up","Schemas":"Schemas","Billing":"Billing","Settings":"Settings","Diff":"Diff","Event":"Event","DestinationSettings":"Destination Settings","SourceSettings":"Source Settings","PasswordReset":"Password Reset","NotFound":"Not Found","EventFullscreen":"Event Fullscreen","EventComment":"Event Comment","EventProperty":"Event Property","EventPropertyComment":"Event Property Comment","SchemaDashboard":"Schema Dashboard","AuthenticateCLI":"Authenticate CLI","AuthenticateCLISuccess":"Authenticate CLI Success","AuthenticateCLIError":"Authenticate CLI Error","Implement":"Implement","EventPropertyGroup":"Event Property Group","PropertyGroup":"Property Group","Metric":"Metric","Onboarding":"Onboarding","AuditLog":"Audit Log","Goals":"Goals","Properties":"Properties","Property":"Property","Integrations":"Integrations","Category":"Category","InspectorEvents":"InspectorEvents","BranchActivity":"Branch Activity","Integration":"Integration","IntegrationComment":"IntegrationComment","ImportUpload":"Import Upload","ImportReview":"Import Review","ImportSuccess":"Import Success","EventTrigger":"Event Trigger","SourceInspectorSetup":"Source Inspector Setup","SourceFunctionsSetup":"Source Functions Setup","SourceEventReference":"Source Event Reference","EventPropertyGroups":"Event Property Groups","BranchImplementation":"Branch Implementation","PublicBranchImplementation":"Public Branch Implementation","BranchImplementationSource":"Branch Implementation Source","InspectorIssues":"Inspector Issues","InspectorSavedViews":"Inspector Saved Views","InspectorSavedView":"Inspector Saved View","InspectorIssue":"Inspector Issue","InspectorIssueComment":"Inspector Issue Comment","EventVariant":"Event Variant","EventVariantTrigger":"Event Variant Trigger"};

var _revMap$13 = {"Events":"Events","Sign In":"SignIn","Sign Up":"SignUp","Schemas":"Schemas","Billing":"Billing","Settings":"Settings","Diff":"Diff","Event":"Event","Destination Settings":"DestinationSettings","Source Settings":"SourceSettings","Password Reset":"PasswordReset","Not Found":"NotFound","Event Fullscreen":"EventFullscreen","Event Comment":"EventComment","Event Property":"EventProperty","Event Property Comment":"EventPropertyComment","Schema Dashboard":"SchemaDashboard","Authenticate CLI":"AuthenticateCLI","Authenticate CLI Success":"AuthenticateCLISuccess","Authenticate CLI Error":"AuthenticateCLIError","Implement":"Implement","Event Property Group":"EventPropertyGroup","Property Group":"PropertyGroup","Metric":"Metric","Onboarding":"Onboarding","Audit Log":"AuditLog","Goals":"Goals","Properties":"Properties","Property":"Property","Integrations":"Integrations","Category":"Category","InspectorEvents":"InspectorEvents","Branch Activity":"BranchActivity","Integration":"Integration","IntegrationComment":"IntegrationComment","Import Upload":"ImportUpload","Import Review":"ImportReview","Import Success":"ImportSuccess","Event Trigger":"EventTrigger","Source Inspector Setup":"SourceInspectorSetup","Source Functions Setup":"SourceFunctionsSetup","Source Event Reference":"SourceEventReference","Event Property Groups":"EventPropertyGroups","Branch Implementation":"BranchImplementation","Public Branch Implementation":"PublicBranchImplementation","Branch Implementation Source":"BranchImplementationSource","Inspector Issues":"InspectorIssues","Inspector Saved Views":"InspectorSavedViews","Inspector Saved View":"InspectorSavedView","Inspector Issue":"InspectorIssue","Inspector Issue Comment":"InspectorIssueComment","Event Variant":"EventVariant","Event Variant Trigger":"EventVariantTrigger"};

function pageNameToJs(param) {
  return _map$17[param];
}

function pageNameFromJs(param) {
  return _revMap$13[param];
}

var _map$18 = {"Open":"Open","Close":"Close","ReadDocs":"Read docs","TakeTour":"Take tour"};

var _revMap$14 = {"Open":"Open","Close":"Close","Read docs":"ReadDocs","Take tour":"TakeTour"};

function learnHubInteractionToJs(param) {
  return _map$18[param];
}

function learnHubInteractionFromJs(param) {
  return _revMap$14[param];
}

var _map$19 = {"Skip":"Skip","Complete":"Complete"};

function namePromptInteractionTypeToJs(param) {
  return param;
}

function namePromptInteractionTypeFromJs(param) {
  return _map$19[param];
}

var _map$20 = {"Python":"Python","IOS":"iOS","Android":"Android","Node":"Node","ReactNative":"React Native","Web":"Web","Unity":"Unity","Java":"Java","JsonSchema":"JsonSchema","Expo":"Expo","Ruby":"Ruby","Gtm":"GTM","Php":"PHP","DotNet":"DotNet","MacOS":"MacOS","Other":"Other","Flutter":"Flutter","Go":"Go"};

var _revMap$15 = {"Python":"Python","iOS":"IOS","Android":"Android","Node":"Node","React Native":"ReactNative","Web":"Web","Unity":"Unity","Java":"Java","JsonSchema":"JsonSchema","Expo":"Expo","Ruby":"Ruby","GTM":"Gtm","PHP":"Php","DotNet":"DotNet","MacOS":"MacOS","Other":"Other","Flutter":"Flutter","Go":"Go"};

function developmentPlatformToJs(param) {
  return _map$20[param];
}

function developmentPlatformFromJs(param) {
  return _revMap$15[param];
}

var _map$21 = {"Property":"Property","Event":"Event","PropertyGroup":"Property Group","PropertyNameMapping":"Property Name Mapping","Goal":"Goal","Metric":"Metric","Source":"Source","Destination":"Destination","Branch":"Branch","Integration":"Integration","EventDiff":"Event Diff","PropertyDiff":"Property Diff","MetricDiff":"Metric Diff","CategoryDiff":"Category Diff","SourceDiff":"Source Diff","DestinationDiff":"Destination Diff","GroupType":"Group Type","CodegenSource":"Codegen Source","BranchImplementation":"Branch Implementation","InspectorIssues":"Inspector Issues","InspectorIssue":"Inspector Issue","EventVariant":"Event Variant"};

var _revMap$16 = {"Property":"Property","Event":"Event","Property Group":"PropertyGroup","Property Name Mapping":"PropertyNameMapping","Goal":"Goal","Metric":"Metric","Source":"Source","Destination":"Destination","Branch":"Branch","Integration":"Integration","Event Diff":"EventDiff","Property Diff":"PropertyDiff","Metric Diff":"MetricDiff","Category Diff":"CategoryDiff","Source Diff":"SourceDiff","Destination Diff":"DestinationDiff","Group Type":"GroupType","Codegen Source":"CodegenSource","Branch Implementation":"BranchImplementation","Inspector Issues":"InspectorIssues","Inspector Issue":"InspectorIssue","Event Variant":"EventVariant"};

function itemTypeToJs(param) {
  return _map$21[param];
}

function itemTypeFromJs(param) {
  return _revMap$16[param];
}

var _map$22 = {"EventSelected":"Event Selected","EventDeselected":"Event Deselected","PropertySelected":"Property Selected","PropertyDeselected":"Property Deselected","SourceSelected":"Source Selected","SourceDeselected":"Source Deselected","PropertyTypeChanged":"Property Type Changed","PropertyPresenceChanged":"Property Presence Changed","UserPropertiesExcluded":"User Properties Excluded","UserPropertiesIncluded":"User Properties Included","SystemPropertiesExcluded":"System Properties Excluded","SystemPropertiesIncluded":"System Properties Included"};

var _revMap$17 = {"Event Selected":"EventSelected","Event Deselected":"EventDeselected","Property Selected":"PropertySelected","Property Deselected":"PropertyDeselected","Source Selected":"SourceSelected","Source Deselected":"SourceDeselected","Property Type Changed":"PropertyTypeChanged","Property Presence Changed":"PropertyPresenceChanged","User Properties Excluded":"UserPropertiesExcluded","User Properties Included":"UserPropertiesIncluded","System Properties Excluded":"SystemPropertiesExcluded","System Properties Included":"SystemPropertiesIncluded"};

function importChangeToJs(param) {
  return _map$22[param];
}

function importChangeFromJs(param) {
  return _revMap$17[param];
}

var _map$23 = {"SystemProperty":"System Property","UserProperty":"User Property","EventProperty":"Event Property","GroupProperty":"Group Property"};

var _revMap$18 = {"System Property":"SystemProperty","User Property":"UserProperty","Event Property":"EventProperty","Group Property":"GroupProperty"};

function propertyTypeToJs(param) {
  return _map$23[param];
}

function propertyTypeFromJs(param) {
  return _revMap$18[param];
}

var _map$24 = {"Python":"Python","IOS":"iOS","Android":"Android","Node":"Node","ReactNative":"React Native","Web":"Web","Unity":"Unity","Java":"Java","JsonSchema":"JsonSchema","Expo":"Expo","Ruby":"Ruby","Gtm":"GTM","Php":"PHP","DotNet":"DotNet","MacOS":"MacOS","Other":"Other","Flutter":"Flutter","Go":"Go"};

var _revMap$19 = {"Python":"Python","iOS":"IOS","Android":"Android","Node":"Node","React Native":"ReactNative","Web":"Web","Unity":"Unity","Java":"Java","JsonSchema":"JsonSchema","Expo":"Expo","Ruby":"Ruby","GTM":"Gtm","PHP":"Php","DotNet":"DotNet","MacOS":"MacOS","Other":"Other","Flutter":"Flutter","Go":"Go"};

function fromDevelopmentPlatformToJs(param) {
  return _map$24[param];
}

function fromDevelopmentPlatformFromJs(param) {
  return _revMap$19[param];
}

var _map$25 = {"NoDiff":"No Diff","Unified":"Unified","Split":"Split"};

var _revMap$20 = {"No Diff":"NoDiff","Unified":"Unified","Split":"Split"};

function diffTypeToJs(param) {
  return _map$25[param];
}

function diffTypeFromJs(param) {
  return _revMap$20[param];
}

var _map$26 = {"Amplitude":"Amplitude","Mixpanel":"Mixpanel","Segment":"Segment","FacebookAnalytics":"Facebook Analytics","FirebaseAnalytics":"Firebase Analytics","MParticle":"mParticle","Custom":"Custom","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","FullStory":"FullStory","Braze":"Braze","ZendeskConnect":"ZendeskConnect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","GoogleAnalytics4":"GoogleAnalytics4","Freshpaint":"Freshpaint","AdobeAnalytics":"AdobeAnalytics","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

var _revMap$21 = {"Amplitude":"Amplitude","Mixpanel":"Mixpanel","Segment":"Segment","Facebook Analytics":"FacebookAnalytics","Firebase Analytics":"FirebaseAnalytics","mParticle":"MParticle","Custom":"Custom","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","FullStory":"FullStory","Braze":"Braze","ZendeskConnect":"ZendeskConnect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","GoogleAnalytics4":"GoogleAnalytics4","Freshpaint":"Freshpaint","AdobeAnalytics":"AdobeAnalytics","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

function onboardingDestinationsToJs(param) {
  return _map$26[param];
}

function onboardingDestinationsFromJs(param) {
  return _revMap$21[param];
}

var _map$27 = {"ImportAndGetFeedbackOnYourTrackingPlan":"Import and get feedback on your tracking plan","OpenABranchAndMakeYourFirstChanges":"Open a branch and make your first changes","GrabDetailedImplementationDetails":"Grab detailed implementation details","ConfigureAPublishingIntegration":"Configure a publishing integration","CreateATriggerForAnEvent":"Create a trigger for an event","CreateMoreThanOnePublishingIntegration":"Create more than one publishing integration","TryApprovalWorkflows":"Try approval workflows","CommentOnYourTrackingPlan":"Comment on your tracking plan","ObserveYourDataFurtherBackInTime":"Observe your data further back in time","ConfigureYourAuditToYourLiking":"Configure your audit to your liking","GettingStartedAddYourFirstEvent":"Getting Started: Add your first event","GettingStartedSetUpInspector":"Getting Started: Set up Inspector","GettingStartedAddEventsFromInspector":"Getting Started: Add events from Inspector","GettingStartedSetUpAlerts":"Getting Started: Set up Alerts","GettingStartedTriageAnIssue":"Getting Started: Triage an issue","GettingStartedMergeABranch":"Getting Started: Merge a branch"};

var _revMap$22 = {"Import and get feedback on your tracking plan":"ImportAndGetFeedbackOnYourTrackingPlan","Open a branch and make your first changes":"OpenABranchAndMakeYourFirstChanges","Grab detailed implementation details":"GrabDetailedImplementationDetails","Configure a publishing integration":"ConfigureAPublishingIntegration","Create a trigger for an event":"CreateATriggerForAnEvent","Create more than one publishing integration":"CreateMoreThanOnePublishingIntegration","Try approval workflows":"TryApprovalWorkflows","Comment on your tracking plan":"CommentOnYourTrackingPlan","Observe your data further back in time":"ObserveYourDataFurtherBackInTime","Configure your audit to your liking":"ConfigureYourAuditToYourLiking","Getting Started: Add your first event":"GettingStartedAddYourFirstEvent","Getting Started: Set up Inspector":"GettingStartedSetUpInspector","Getting Started: Add events from Inspector":"GettingStartedAddEventsFromInspector","Getting Started: Set up Alerts":"GettingStartedSetUpAlerts","Getting Started: Triage an issue":"GettingStartedTriageAnIssue","Getting Started: Merge a branch":"GettingStartedMergeABranch"};

function checklistStepNameToJs(param) {
  return _map$27[param];
}

function checklistStepNameFromJs(param) {
  return _revMap$22[param];
}

var _map$28 = {"PropertyInconsistentWithTrackingPlan":"Property inconsistent with tracking plan","UnexpectedProperty":"Unexpected property","UnexpectedEvent":"Unexpected event","InconsistentPropertyValueType":"Inconsistent property value type","PropertySometimesMissing":"Property sometimes missing","PropertyNotFoundInTrackingPlan":"Property not found in tracking plan","MissingExpectedProperty":"Missing expected property","EventNotFoundInTrackingPlan":"Event not found in tracking plan","InconsistentPropertyValueTypeAcrossSources":"Inconsistent property value type across sources"};

var _revMap$23 = {"Property inconsistent with tracking plan":"PropertyInconsistentWithTrackingPlan","Unexpected property":"UnexpectedProperty","Unexpected event":"UnexpectedEvent","Inconsistent property value type":"InconsistentPropertyValueType","Property sometimes missing":"PropertySometimesMissing","Property not found in tracking plan":"PropertyNotFoundInTrackingPlan","Missing expected property":"MissingExpectedProperty","Event not found in tracking plan":"EventNotFoundInTrackingPlan","Inconsistent property value type across sources":"InconsistentPropertyValueTypeAcrossSources"};

function inspectorIssueTypeToJs(param) {
  return _map$28[param];
}

function inspectorIssueTypeFromJs(param) {
  return _revMap$23[param];
}

var _map$29 = {"ItemRemoved":"Item Removed","ItemAdded":"Item Added","ItemClicked":"Item Clicked","ItemChanged":"Item Changed","SearchedForItem":"Searched For Item","ItemCreationInitiated":"Item Creation Initiated"};

var _revMap$24 = {"Item Removed":"ItemRemoved","Item Added":"ItemAdded","Item Clicked":"ItemClicked","Item Changed":"ItemChanged","Searched For Item":"SearchedForItem","Item Creation Initiated":"ItemCreationInitiated"};

function eventsViewPopupActionToJs(param) {
  return _map$29[param];
}

function eventsViewPopupActionFromJs(param) {
  return _revMap$24[param];
}

var _map$30 = {"PublicShareLinkCreated":"Public share link created","PublicShareLinkCopied":"Public share link copied","PublicShareLinkDeleted":"Public share link deleted"};

var _revMap$25 = {"Public share link created":"PublicShareLinkCreated","Public share link copied":"PublicShareLinkCopied","Public share link deleted":"PublicShareLinkDeleted"};

function branchImplementationShareModalInteractionTypeToJs(param) {
  return _map$30[param];
}

function branchImplementationShareModalInteractionTypeFromJs(param) {
  return _revMap$25[param];
}

var _map$31 = {"PropertyTypeSegmentation":"Property Type Segmentation","IncreaseColumnWidth":"Increase Column Width","DecreaseColumnWidth":"Decrease Column Width"};

var _revMap$26 = {"Property Type Segmentation":"PropertyTypeSegmentation","Increase Column Width":"IncreaseColumnWidth","Decrease Column Width":"DecreaseColumnWidth"};

function propertiesViewConfiguredActionToJs(param) {
  return _map$31[param];
}

function propertiesViewConfiguredActionFromJs(param) {
  return _revMap$26[param];
}

var _map$32 = {"Alphabetical":"Alphabetical","NewestFirst":"Newest First","OldestFirst":"Oldest First","NoSorting":"No Sorting"};

var _revMap$27 = {"Alphabetical":"Alphabetical","Newest First":"NewestFirst","Oldest First":"OldestFirst","No Sorting":"NoSorting"};

function sortAfterToJs(param) {
  return _map$32[param];
}

function sortAfterFromJs(param) {
  return _revMap$27[param];
}

var _map$33 = {"ActivityLog":"Activity Log","BranchReview":"Branch Review","CommandPalette":"Command Palette"};

var _revMap$28 = {"Activity Log":"ActivityLog","Branch Review":"BranchReview","Command Palette":"CommandPalette"};

function unarchiveLocationToJs(param) {
  return _map$33[param];
}

function unarchiveLocationFromJs(param) {
  return _revMap$28[param];
}

var _map$34 = {"NoConflictingProperties":"No conflicting properties","UniqueEventNames":"Unique event names","ConsistentEventNameCasing":"Consistent event name casing","PreferredEventNameCasing":"Preferred event name casing","UniquePropertyNames":"Unique property names","ConsistentPropertyNameCasing":"Consistent property name casing","PreferredPropertyNameCasing":"Preferred property name casing","AllPropertiesHaveTypes":"All properties have types","AllEventsHaveDescriptions":"All events have descriptions","AllPropertiesHaveDescriptions":"All properties have descriptions","ForceEventCasing":"Force event casing","ForcePropertyCasing":"Force property casing","PreferredEventNameCasingOtherThanInherit":"Preferred event name casing other than inherit","PreferredPropertyNameCasingOtherThanInherit":"Preferred property name casing other than inherit"};

var _revMap$29 = {"No conflicting properties":"NoConflictingProperties","Unique event names":"UniqueEventNames","Consistent event name casing":"ConsistentEventNameCasing","Preferred event name casing":"PreferredEventNameCasing","Unique property names":"UniquePropertyNames","Consistent property name casing":"ConsistentPropertyNameCasing","Preferred property name casing":"PreferredPropertyNameCasing","All properties have types":"AllPropertiesHaveTypes","All events have descriptions":"AllEventsHaveDescriptions","All properties have descriptions":"AllPropertiesHaveDescriptions","Force event casing":"ForceEventCasing","Force property casing":"ForcePropertyCasing","Preferred event name casing other than inherit":"PreferredEventNameCasingOtherThanInherit","Preferred property name casing other than inherit":"PreferredPropertyNameCasingOtherThanInherit"};

function workspaceIssueReportConfigsOnToJs(param) {
  return _map$34[param];
}

function workspaceIssueReportConfigsOnFromJs(param) {
  return _revMap$29[param];
}

var _map$35 = {"Alphabetical":"Alphabetical","NewestFirst":"Newest First","OldestFirst":"Oldest First","NoSorting":"No Sorting"};

var _revMap$30 = {"Alphabetical":"Alphabetical","Newest First":"NewestFirst","Oldest First":"OldestFirst","No Sorting":"NoSorting"};

function sortBeforeToJs(param) {
  return _map$35[param];
}

function sortBeforeFromJs(param) {
  return _revMap$30[param];
}

var _map$36 = {"Page":"Page","Alias":"Alias","Revenue":"Revenue","Unidentify":"Unidentify","Identify":"Identify","SkipLogEvent":"Skip Log Event","UpdateGroups":"Update Groups"};

var _revMap$31 = {"Page":"Page","Alias":"Alias","Revenue":"Revenue","Unidentify":"Unidentify","Identify":"Identify","Skip Log Event":"SkipLogEvent","Update Groups":"UpdateGroups"};

function eventTypesSelectedBeforeToJs(param) {
  return _map$36[param];
}

function eventTypesSelectedBeforeFromJs(param) {
  return _revMap$31[param];
}

var _map$37 = {"Free":"free","Freev2":"freev2","Scholarship":"scholarship","Starter":"starter","Growth":"growth","Growthv2":"growthv2","Enterprise":"enterprise","Enterprisev2":"enterprisev2","Teamv2":"teamv2","Freev3":"freev3","Teamv3":"teamv3"};

var _revMap$32 = {"free":"Free","freev2":"Freev2","scholarship":"Scholarship","starter":"Starter","growth":"Growth","growthv2":"Growthv2","enterprise":"Enterprise","enterprisev2":"Enterprisev2","teamv2":"Teamv2","freev3":"Freev3","teamv3":"Teamv3"};

function workspaceTrialPlanSlugToJs(param) {
  return _map$37[param];
}

function workspaceTrialPlanSlugFromJs(param) {
  return _revMap$32[param];
}

var _map$38 = {"DataScience":"Data science","SoftwareEngineering":"Software engineering","ProductManagement":"Product management","Marketing":"Marketing","Other":"Other","ProductAnalytics":"Product analytics","DataAnalyticsEngineering":"Data/analytics engineering"};

var _revMap$33 = {"Data science":"DataScience","Software engineering":"SoftwareEngineering","Product management":"ProductManagement","Marketing":"Marketing","Other":"Other","Product analytics":"ProductAnalytics","Data/analytics engineering":"DataAnalyticsEngineering"};

function userOnboardingJobFunctionToJs(param) {
  return _map$38[param];
}

function userOnboardingJobFunctionFromJs(param) {
  return _revMap$33[param];
}

var _map$39 = {"AvoManaged":"Avo Managed","DestinationInterface":"Destination Interface"};

var _revMap$34 = {"Avo Managed":"AvoManaged","Destination Interface":"DestinationInterface"};

function destinationModeToJs(param) {
  return _map$39[param];
}

function destinationModeFromJs(param) {
  return _revMap$34[param];
}

var _map$40 = {"ProfilePic":"Profile Pic","UserName":"User Name"};

var _revMap$35 = {"Profile Pic":"ProfilePic","User Name":"UserName"};

function profileUpdateToJs(param) {
  return _map$40[param];
}

function profileUpdateFromJs(param) {
  return _revMap$35[param];
}

var _map$41 = {"Enable":"Enable","Disable":"Disable"};

function pathUpdateToJs(param) {
  return param;
}

function pathUpdateFromJs(param) {
  return _map$41[param];
}

var _map$42 = {"ListChecked":"List Checked","ListNotChecked":"List not Checked"};

var _revMap$36 = {"List Checked":"ListChecked","List not Checked":"ListNotChecked"};

function propertyListToggleAfterToJs(param) {
  return _map$42[param];
}

function propertyListToggleAfterFromJs(param) {
  return _revMap$36[param];
}

var _map$43 = {"Event":"Event","Property":"Property","Metric":"Metric","PropertyGroup":"Property Group","BranchActivity":"Branch Activity","Category":"Category","BranchDiff":"Branch Diff","Integration":"Integration","InspectorIssue":"Inspector Issue"};

var _revMap$37 = {"Event":"Event","Property":"Property","Metric":"Metric","Property Group":"PropertyGroup","Branch Activity":"BranchActivity","Category":"Category","Branch Diff":"BranchDiff","Integration":"Integration","Inspector Issue":"InspectorIssue"};

function commentLocationToJs(param) {
  return _map$43[param];
}

function commentLocationFromJs(param) {
  return _revMap$37[param];
}

var _map$44 = {"Bool":"bool","Float":"float","Int":"int","String":"string","Object":"object","Any":"any","Long":"long"};

var _revMap$38 = {"bool":"Bool","float":"Float","int":"Int","string":"String","object":"Object","any":"Any","long":"Long"};

function to_ToJs(param) {
  return _map$44[param];
}

function to_FromJs(param) {
  return _revMap$38[param];
}

var _map$45 = {"AddCreditCardNumber":"Add Credit Card Number","AddCreditCardExpiration":"Add Credit Card Expiration","AddCreditCardCVC":"Add Credit Card CVC","ClickAddCoupon":"Click Add Coupon","ApplyCoupon":"Apply Coupon","ClickCompleteUpgrade":"Click Complete Upgrade","ToggleTimeUnit":"Toggle Time Unit","ConfigureEditors":"Configure Editors","ConfigureInspectorVolume":"Configure Inspector Volume","ConfigureActiveImplementers":"Configure Active Implementers","ContinueToPaymentDetails":"Continue To Payment Details","BackToPlanBuilder":"Back To Plan Builder"};

var _revMap$39 = {"Add Credit Card Number":"AddCreditCardNumber","Add Credit Card Expiration":"AddCreditCardExpiration","Add Credit Card CVC":"AddCreditCardCVC","Click Add Coupon":"ClickAddCoupon","Apply Coupon":"ApplyCoupon","Click Complete Upgrade":"ClickCompleteUpgrade","Toggle Time Unit":"ToggleTimeUnit","Configure Editors":"ConfigureEditors","Configure Inspector Volume":"ConfigureInspectorVolume","Configure Active Implementers":"ConfigureActiveImplementers","Continue To Payment Details":"ContinueToPaymentDetails","Back To Plan Builder":"BackToPlanBuilder"};

function subscriptionUpgradeModalInteractionToJs(param) {
  return _map$45[param];
}

function subscriptionUpgradeModalInteractionFromJs(param) {
  return _revMap$39[param];
}

var _map$46 = {"Self":"Self","OtherMember":"Other Member"};

var _revMap$40 = {"Self":"Self","Other Member":"OtherMember"};

function collaboratorAdderRelationshipToJs(param) {
  return _map$46[param];
}

function collaboratorAdderRelationshipFromJs(param) {
  return _revMap$40[param];
}

var _map$47 = {"Existing":"Existing","New":"New"};

function propertyStateToJs(param) {
  return param;
}

function propertyStateFromJs(param) {
  return _map$47[param];
}

var _map$48 = {"Banner":"Banner","Tooltip":"Tooltip"};

function overlayItemTypeToJs(param) {
  return param;
}

function overlayItemTypeFromJs(param) {
  return _map$48[param];
}

var _map$49 = {"Team":"Team","Custom":"Custom","Premium":"Premium","Free":"Free","Scholarship":"Scholarship","Growth":"Growth","Enterprise":"Enterprise","Starter":"Starter"};

function subscriptionPlanToJs(param) {
  return param;
}

function subscriptionPlanFromJs(param) {
  return _map$49[param];
}

var _map$50 = {"SelectTag":"Select Tag","DeselectTag":"Deselect Tag","SelectSource":"Select Source","DeselectSource":"Deselect Source","SelectDestination":"Select Destination","DeselectDestination":"Deselect Destination","ClearSources":"Clear Sources","ClearDestinations":"Clear Destinations","ClearTags":"Clear Tags","Reset":"Reset","SelectPropertyGroup":"Select Property Group","DeselectPropertyGroup":"Deselect Property Group","ClearPropertyGroups":"Clear Property Groups","SelectProperty":"Select Property","DeselectProperty":"Deselect Property","ClearProperties":"Clear Properties","SelectAction":"Select Action","DeselectAction":"Deselect Action","ClearActions":"Clear Actions","SelectCategory":"Select Category","DeselectCategory":"Deselect Category","ClearCategories":"Clear Categories"};

var _revMap$41 = {"Select Tag":"SelectTag","Deselect Tag":"DeselectTag","Select Source":"SelectSource","Deselect Source":"DeselectSource","Select Destination":"SelectDestination","Deselect Destination":"DeselectDestination","Clear Sources":"ClearSources","Clear Destinations":"ClearDestinations","Clear Tags":"ClearTags","Reset":"Reset","Select Property Group":"SelectPropertyGroup","Deselect Property Group":"DeselectPropertyGroup","Clear Property Groups":"ClearPropertyGroups","Select Property":"SelectProperty","Deselect Property":"DeselectProperty","Clear Properties":"ClearProperties","Select Action":"SelectAction","Deselect Action":"DeselectAction","Clear Actions":"ClearActions","Select Category":"SelectCategory","Deselect Category":"DeselectCategory","Clear Categories":"ClearCategories"};

function filterActionToJs(param) {
  return _map$50[param];
}

function filterActionFromJs(param) {
  return _revMap$41[param];
}

var _map$51 = {"Sdk":"SDK","SegmentStream":"SegmentStream","ScriptTag":"Script Tag","RudderStackStream":"RudderStackStream"};

var _revMap$42 = {"SDK":"Sdk","SegmentStream":"SegmentStream","Script Tag":"ScriptTag","RudderStackStream":"RudderStackStream"};

function inspectorIntegrationTypeToJs(param) {
  return _map$51[param];
}

function inspectorIntegrationTypeFromJs(param) {
  return _revMap$42[param];
}

var _map$52 = {"Property":"Property","Event":"Event"};

function describedObjectToJs(param) {
  return param;
}

function describedObjectFromJs(param) {
  return _map$52[param];
}

var _map$53 = {"CloudFunctions":"Cloud Functions","Web":"Web","LandingPage":"Landing Page","Cli":"Cli","WebDebugger":"Web Debugger","AndroidDebugger":"Android Debugger","IosDebugger":"Ios Debugger","ReactNativeDebuggerIos":"React Native Debugger (ios)","ReactNativeDebuggerAndroid":"React Native Debugger (android)","Docs":"Docs","PublishingWorker":"Publishing Worker"};

var _revMap$43 = {"Cloud Functions":"CloudFunctions","Web":"Web","Landing Page":"LandingPage","Cli":"Cli","Web Debugger":"WebDebugger","Android Debugger":"AndroidDebugger","Ios Debugger":"IosDebugger","React Native Debugger (ios)":"ReactNativeDebuggerIos","React Native Debugger (android)":"ReactNativeDebuggerAndroid","Docs":"Docs","Publishing Worker":"PublishingWorker"};

function clientToJs(param) {
  return _map$53[param];
}

function clientFromJs(param) {
  return _revMap$43[param];
}

var _map$54 = {"ClickLinkToDocs":"Click link to docs","ClickCTAToStartAChat":"Click CTA to start a chat","Dismiss":"Dismiss"};

var _revMap$44 = {"Click link to docs":"ClickLinkToDocs","Click CTA to start a chat":"ClickCTAToStartAChat","Dismiss":"Dismiss"};

function tooltipInteractionTypeToJs(param) {
  return _map$54[param];
}

function tooltipInteractionTypeFromJs(param) {
  return _revMap$44[param];
}

var _map$55 = {"ClickCTA":"Click CTA","ClickLinkToDocs":"Click link to docs","ClickBack":"Click back","PlayVideo":"Play Video"};

var _revMap$45 = {"Click CTA":"ClickCTA","Click link to docs":"ClickLinkToDocs","Click back":"ClickBack","Play Video":"PlayVideo"};

function checklistStepDetailsInteractionTypeToJs(param) {
  return _map$55[param];
}

function checklistStepDetailsInteractionTypeFromJs(param) {
  return _revMap$45[param];
}

var _map$56 = {"AlwaysSent":"Always Sent","SometimesSent":"Sometimes Sent","NeverSent":"Never Sent","NoOverride":"No Override"};

var _revMap$46 = {"Always Sent":"AlwaysSent","Sometimes Sent":"SometimesSent","Never Sent":"NeverSent","No Override":"NoOverride"};

function eventVariantPropertyPresenceOverrideTypeBeforeToJs(param) {
  return _map$56[param];
}

function eventVariantPropertyPresenceOverrideTypeBeforeFromJs(param) {
  return _revMap$46[param];
}

var _map$57 = {"Monthly":"Monthly","Annual":"Annual"};

function subscriptionTimeUnitToJs(param) {
  return param;
}

function subscriptionTimeUnitFromJs(param) {
  return _map$57[param];
}

var _map$58 = {"Sandbox":"Sandbox","Regular":"Regular"};

function schemaTypeToJs(param) {
  return param;
}

function schemaTypeFromJs(param) {
  return _map$58[param];
}

var _map$59 = {"ChecklistOverview":"Checklist Overview","ChecklistStepDetails":"Checklist Step Details","Navigation":"Navigation"};

var _revMap$47 = {"Checklist Overview":"ChecklistOverview","Checklist Step Details":"ChecklistStepDetails","Navigation":"Navigation"};

function checklistCloseLocationToJs(param) {
  return _map$59[param];
}

function checklistCloseLocationFromJs(param) {
  return _revMap$47[param];
}

var _map$60 = {"JobFunctionSelected":"Job Function Selected","JobFunctionCustomised":"Job Function Customised","Dismissed":"Dismissed"};

var _revMap$48 = {"Job Function Selected":"JobFunctionSelected","Job Function Customised":"JobFunctionCustomised","Dismissed":"Dismissed"};

function jobFunctionPromptInteractionTypeToJs(param) {
  return _map$60[param];
}

function jobFunctionPromptInteractionTypeFromJs(param) {
  return _revMap$48[param];
}

var _map$61 = {"Free":"free","Freev2":"freev2","Scholarship":"scholarship","Starter":"starter","Growth":"growth","Growthv2":"growthv2","Enterprise":"enterprise","Enterprisev2":"enterprisev2","Teamv2":"teamv2","Freev3":"freev3","Teamv3":"teamv3"};

var _revMap$49 = {"free":"Free","freev2":"Freev2","scholarship":"Scholarship","starter":"Starter","growth":"Growth","growthv2":"Growthv2","enterprise":"Enterprise","enterprisev2":"Enterprisev2","teamv2":"Teamv2","freev3":"Freev3","teamv3":"Teamv3"};

function workspaceSubscriptionPlanSlugToJs(param) {
  return _map$61[param];
}

function workspaceSubscriptionPlanSlugFromJs(param) {
  return _revMap$49[param];
}

var _map$62 = {"Source":"Source","Version":"Version","EventName":"Event name","FirstSeen":"First seen","LastSeen":"Last seen","EventVolume":"Event volume","IssueVolume":"Issue volume","IssuePercentage":"Issue percentage","PropertyName":"Property Name","IssueType":"Issue Type","Category":"Category","Tag":"Tag","Release":"Release"};

var _revMap$50 = {"Source":"Source","Version":"Version","Event name":"EventName","First seen":"FirstSeen","Last seen":"LastSeen","Event volume":"EventVolume","Issue volume":"IssueVolume","Issue percentage":"IssuePercentage","Property Name":"PropertyName","Issue Type":"IssueType","Category":"Category","Tag":"Tag","Release":"Release"};

function inspectorIssuesConfigureItemToJs(param) {
  return _map$62[param];
}

function inspectorIssuesConfigureItemFromJs(param) {
  return _revMap$50[param];
}

var _map$63 = {"EventName":"Event Name","EventVolume":"Event Volume","Issues":"Issues","SourceEventVolume":"Source Event Volume","SourceIssues":"Source Issues"};

var _revMap$51 = {"Event Name":"EventName","Event Volume":"EventVolume","Issues":"Issues","Source Event Volume":"SourceEventVolume","Source Issues":"SourceIssues"};

function inspectorColumnOrderedByToJs(param) {
  return _map$63[param];
}

function inspectorColumnOrderedByFromJs(param) {
  return _revMap$51[param];
}

var _map$64 = {"Created":"Created","Published":"Published"};

function integrationStageToJs(param) {
  return param;
}

function integrationStageFromJs(param) {
  return _map$64[param];
}

var _map$65 = {"Open":"Open","Closed":"Closed"};

function learnHubStateToJs(param) {
  return param;
}

function learnHubStateFromJs(param) {
  return _map$65[param];
}

var _map$66 = {"AddEMinusmail":"Add E-mail","DefineSource":"Define Source","DefineDestination":"Define Destination","SelectRole":"Select Role","DismissExistingWorkspacePrompt":"Dismiss Existing Workspace Prompt","ClickCreateWorkspaceOnExistingWorkspacePrompt":"Click Create Workspace on Existing Workspace Prompt","DefineContribution":"Define Contribution"};

var _revMap$52 = {"Add E-mail":"AddEMinusmail","Define Source":"DefineSource","Define Destination":"DefineDestination","Select Role":"SelectRole","Dismiss Existing Workspace Prompt":"DismissExistingWorkspacePrompt","Click Create Workspace on Existing Workspace Prompt":"ClickCreateWorkspaceOnExistingWorkspacePrompt","Define Contribution":"DefineContribution"};

function onboardingActionTypeToJs(param) {
  return _map$66[param];
}

function onboardingActionTypeFromJs(param) {
  return _revMap$52[param];
}

var _map$67 = {"InspectorAddEventConflictingEventFound":"Inspector Add Event Conflicting Event Found","InspectorAddPropertyConflictingPropertyFound":"Inspector Add Property Conflicting Property Found"};

var _revMap$53 = {"Inspector Add Event Conflicting Event Found":"InspectorAddEventConflictingEventFound","Inspector Add Property Conflicting Property Found":"InspectorAddPropertyConflictingPropertyFound"};

function warningTypeToJs(param) {
  return _map$67[param];
}

function warningTypeFromJs(param) {
  return _revMap$53[param];
}

var _map$68 = {"Goal":"Goal","Metric":"Metric","Event":"Event","Property":"Property","PropertyGroup":"Property Group","Integration":"Integration"};

var _revMap$54 = {"Goal":"Goal","Metric":"Metric","Event":"Event","Property":"Property","Property Group":"PropertyGroup","Integration":"Integration"};

function exampleTypeToJs(param) {
  return _map$68[param];
}

function exampleTypeFromJs(param) {
  return _revMap$54[param];
}

var _map$69 = {"Proceed":"Proceed","Dismiss":"Dismiss"};

function addSeatPromptInteractionToJs(param) {
  return param;
}

function addSeatPromptInteractionFromJs(param) {
  return _map$69[param];
}

var _map$70 = {"Development":"Development","Staging":"Staging","Production":"Production"};

function inspectorEnvironmentToJs(param) {
  return param;
}

function inspectorEnvironmentFromJs(param) {
  return _map$70[param];
}

var _map$71 = {"AddPropertyGroup":"Add Property Group","RemovePropertyGroup":"Remove Property Group","AddSource":"Add Source","RemoveSource":"Remove Source","AddDestinationToSource":"Add Destination to Source","RemoveDestinationFromSource":"Remove Destination from Source","AddRule":"Add Rule","RemoveRule":"Remove Rule","UpdateRule":"Update Rule","AddSources":"Add Sources","ToggleImplementSourceWithAvo":"Toggle Implement Source with Avo","AddAssociateUserWithGroup":"Add Associate User with Group","RemoveAssociateUserWithGroup":"Remove Associate User with Group","AddAssociateEventWithGroup":"Add Associate Event with Group","RemoveAssociateEventWithGroup":"Remove Associate Event with Group","AddEventTrigger":"Add Event Trigger","RemoveEventTrigger":"Remove Event Trigger"};

var _revMap$55 = {"Add Property Group":"AddPropertyGroup","Remove Property Group":"RemovePropertyGroup","Add Source":"AddSource","Remove Source":"RemoveSource","Add Destination to Source":"AddDestinationToSource","Remove Destination from Source":"RemoveDestinationFromSource","Add Rule":"AddRule","Remove Rule":"RemoveRule","Update Rule":"UpdateRule","Add Sources":"AddSources","Toggle Implement Source with Avo":"ToggleImplementSourceWithAvo","Add Associate User with Group":"AddAssociateUserWithGroup","Remove Associate User with Group":"RemoveAssociateUserWithGroup","Add Associate Event with Group":"AddAssociateEventWithGroup","Remove Associate Event with Group":"RemoveAssociateEventWithGroup","Add Event Trigger":"AddEventTrigger","Remove Event Trigger":"RemoveEventTrigger"};

function eventActionToJs(param) {
  return _map$71[param];
}

function eventActionFromJs(param) {
  return _revMap$55[param];
}

var _map$72 = {"Default":"Default","GoogleSSO":"Google SSO","SamlSso":"SAML SSO"};

var _revMap$56 = {"Default":"Default","Google SSO":"GoogleSSO","SAML SSO":"SamlSso"};

function authenticationConfigToJs(param) {
  return _map$72[param];
}

function authenticationConfigFromJs(param) {
  return _revMap$56[param];
}

var _map$73 = {"Cancel":"Cancel","Confirm":"Confirm"};

function warningPromptInteractionToJs(param) {
  return param;
}

function warningPromptInteractionFromJs(param) {
  return _map$73[param];
}

var _map$74 = {"ImportAndGetFeedbackOnYourTrackingPlan":"Import and get feedback on your tracking plan","OpenABranchAndMakeYourFirstChanges":"Open a branch and make your first changes","GrabDetailedImplementationDetails":"Grab detailed implementation details","ConfigureAPublishingIntegration":"Configure a publishing integration","CreateATriggerForAnEvent":"Create a trigger for an event","CreateMoreThanOnePublishingIntegration":"Create more than one publishing integration","TryApprovalWorkflows":"Try approval workflows","CommentOnYourTrackingPlan":"Comment on your tracking plan","ObserveYourDataFurtherBackInTime":"Observe your data further back in time","ConfigureYourAuditToYourLiking":"Configure your audit to your liking","GettingStartedAddYourFirstEvent":"Getting Started: Add your first event","GettingStartedSetUpInspector":"Getting Started: Set up Inspector","GettingStartedAddEventsFromInspector":"Getting Started: Add events from Inspector","GettingStartedSetUpAlerts":"Getting Started: Set up Alerts","GettingStartedTriageAnIssue":"Getting Started: Triage an issue","GettingStartedMergeABranch":"Getting Started: Merge a branch"};

var _revMap$57 = {"Import and get feedback on your tracking plan":"ImportAndGetFeedbackOnYourTrackingPlan","Open a branch and make your first changes":"OpenABranchAndMakeYourFirstChanges","Grab detailed implementation details":"GrabDetailedImplementationDetails","Configure a publishing integration":"ConfigureAPublishingIntegration","Create a trigger for an event":"CreateATriggerForAnEvent","Create more than one publishing integration":"CreateMoreThanOnePublishingIntegration","Try approval workflows":"TryApprovalWorkflows","Comment on your tracking plan":"CommentOnYourTrackingPlan","Observe your data further back in time":"ObserveYourDataFurtherBackInTime","Configure your audit to your liking":"ConfigureYourAuditToYourLiking","Getting Started: Add your first event":"GettingStartedAddYourFirstEvent","Getting Started: Set up Inspector":"GettingStartedSetUpInspector","Getting Started: Add events from Inspector":"GettingStartedAddEventsFromInspector","Getting Started: Set up Alerts":"GettingStartedSetUpAlerts","Getting Started: Triage an issue":"GettingStartedTriageAnIssue","Getting Started: Merge a branch":"GettingStartedMergeABranch"};

function checklistStepsLeftToJs(param) {
  return _map$74[param];
}

function checklistStepsLeftFromJs(param) {
  return _revMap$57[param];
}

var _map$75 = {"ExecutiveVPCMinussuite":"Executive (VP / C-suite)","HeadOfTeam":"Head of team","PeopleManager":"People manager","PlayerMinuscoach":"Player-coach","IndividualContributor":"Individual contributor","ContractorFreelancer":"Contractor / Freelancer"};

var _revMap$58 = {"Executive (VP / C-suite)":"ExecutiveVPCMinussuite","Head of team":"HeadOfTeam","People manager":"PeopleManager","Player-coach":"PlayerMinuscoach","Individual contributor":"IndividualContributor","Contractor / Freelancer":"ContractorFreelancer"};

function companyRoleToJs(param) {
  return _map$75[param];
}

function companyRoleFromJs(param) {
  return _revMap$58[param];
}

var _map$76 = {"StayOnMaster":"Stay on master","GoToBranch":"Go to branch","Dismiss":"Dismiss"};

var _revMap$59 = {"Stay on master":"StayOnMaster","Go to branch":"GoToBranch","Dismiss":"Dismiss"};

function branchPromptInteractionToJs(param) {
  return _map$76[param];
}

function branchPromptInteractionFromJs(param) {
  return _revMap$59[param];
}

var _map$77 = {"ActionPerformed":"Action Performed","PathDeeper":"Path Deeper"};

var _revMap$60 = {"Action Performed":"ActionPerformed","Path Deeper":"PathDeeper"};

function cmdPaletteSelectionImpactToJs(param) {
  return _map$77[param];
}

function cmdPaletteSelectionImpactFromJs(param) {
  return _revMap$60[param];
}

var _map$78 = {"LatestRelease":"Latest Release","NextRelease":"Next Release","SpecificRelease":"Specific Release","OneHour":"One Hour","_24Hours":"24 Hours"};

var _revMap$61 = {"Latest Release":"LatestRelease","Next Release":"NextRelease","Specific Release":"SpecificRelease","One Hour":"OneHour","24 Hours":"_24Hours"};

function inspectorIssueResolveInToJs(param) {
  return _map$78[param];
}

function inspectorIssueResolveInFromJs(param) {
  return _revMap$61[param];
}

var _map$79 = {"NewSecretKeyGenerated":"New Secret Key Generated","NewSecretKeyGenerationFailed":"New Secret Key Generation Failed"};

var _revMap$62 = {"New Secret Key Generated":"NewSecretKeyGenerated","New Secret Key Generation Failed":"NewSecretKeyGenerationFailed"};

function webhookSecretModalInteractionTypeToJs(param) {
  return _map$79[param];
}

function webhookSecretModalInteractionTypeFromJs(param) {
  return _revMap$62[param];
}

var _map$80 = {"AvoCLIDocsLinkClicked":"Avo CLI docs link clicked","CLIStringCopied":"CLI string copied","WorkspaceLinkCopied":"Workspace link copied","PublicShareDropdownOpened":"Public share dropdown opened","PublicShareDropdownClosed":"Public share dropdown closed"};

var _revMap$63 = {"Avo CLI docs link clicked":"AvoCLIDocsLinkClicked","CLI string copied":"CLIStringCopied","Workspace link copied":"WorkspaceLinkCopied","Public share dropdown opened":"PublicShareDropdownOpened","Public share dropdown closed":"PublicShareDropdownClosed"};

function branchImplementationInteractionTypeToJs(param) {
  return _map$80[param];
}

function branchImplementationInteractionTypeFromJs(param) {
  return _revMap$63[param];
}

var _map$81 = {"Free":"Free","Team":"Team","Trial":"Trial"};

function workspaceBillingStatusToJs(param) {
  return param;
}

function workspaceBillingStatusFromJs(param) {
  return _map$81[param];
}

var _map$82 = {"ClickAddEventToTrackingPlan":"Click add event to tracking plan","ClickAddPropertyToTrackingPlan":"Click add property to tracking plan","ClickViewEventInTrackingPlan":"Click view event in tracking plan","ClickViewPropertyInTrackingPlan":"Click view property in tracking plan","ExpandIssueType":"Expand issue type","CopyEventIssuesLink":"Copy event issues link"};

var _revMap$64 = {"Click add event to tracking plan":"ClickAddEventToTrackingPlan","Click add property to tracking plan":"ClickAddPropertyToTrackingPlan","Click view event in tracking plan":"ClickViewEventInTrackingPlan","Click view property in tracking plan":"ClickViewPropertyInTrackingPlan","Expand issue type":"ExpandIssueType","Copy event issues link":"CopyEventIssuesLink"};

function inspectorEventSidebarInteractionTypeToJs(param) {
  return _map$82[param];
}

function inspectorEventSidebarInteractionTypeFromJs(param) {
  return _revMap$64[param];
}

var _map$83 = {"Created":"Created","Initiated":"Initiated","Removed":"Removed","Updated":"Updated"};

function regexInteractionTypeToJs(param) {
  return param;
}

function regexInteractionTypeFromJs(param) {
  return _map$83[param];
}

var _map$84 = {"Open":"Open","Merged":"Merged","Deleted":"Deleted","ReadyForReview":"ReadyForReview","Approved":"Approved","ChangesRequested":"ChangesRequested"};

function branchGroupBranchStatusToJs(param) {
  return param;
}

function branchGroupBranchStatusFromJs(param) {
  return _map$84[param];
}

var _map$85 = {"Admin":"Admin","Editor":"Editor","None":"None"};

function minimumApprovalRoleRequiredToJs(param) {
  return param;
}

function minimumApprovalRoleRequiredFromJs(param) {
  return _map$85[param];
}

var _map$86 = {"Expand":"Expand","Collapse":"Collapse","Copy":"Copy","ChangeFormat":"Change Format"};

var _revMap$65 = {"Expand":"Expand","Collapse":"Collapse","Copy":"Copy","Change Format":"ChangeFormat"};

function implementationInstructionInteractionToJs(param) {
  return _map$86[param];
}

function implementationInstructionInteractionFromJs(param) {
  return _revMap$65[param];
}

var _map$87 = {"Name":"Name","Description":"Description","ValueType":"Value Type","Presence":"Presence","ValueConstraint":"Value Constraint","NameMapping":"Name Mapping","ListToggle":"List Toggle"};

var _revMap$66 = {"Name":"Name","Description":"Description","Value Type":"ValueType","Presence":"Presence","Value Constraint":"ValueConstraint","Name Mapping":"NameMapping","List Toggle":"ListToggle"};

function propertyComponentToJs(param) {
  return _map$87[param];
}

function propertyComponentFromJs(param) {
  return _revMap$66[param];
}

var _map$88 = {"Popup":"Popup","Banner":"Banner"};

function upgradePromptTypeToJs(param) {
  return param;
}

function upgradePromptTypeFromJs(param) {
  return _map$88[param];
}

var _map$89 = {"Free":"Free","Scholarship":"Scholarship","Growth":"Growth","Enterprise":"Enterprise","Starter":"Starter","Team":"Team"};

function schemaSubscriptionPlanToJs(param) {
  return param;
}

function schemaSubscriptionPlanFromJs(param) {
  return _map$89[param];
}

var _map$90 = {"SeedFundingAnnouncement2020":"Seed funding announcement 2020","AvoForPMsProductHuntMarch2021":"AvoForPMsProductHuntMarch2021","DataQualityWebinarMarch92022":"Data Quality Webinar March 9 2022","UpgradeYourTrackingPlanToProLevelMarch152022":"Upgrade your tracking plan to pro level March 15 2022","GoogleAnalytics4WebinarApril202022":"Google Analytics 4 Webinar April 20 2022","InnovationWeekMay172022":"Innovation Week May 17 2022","GlobalNamespaceInfo":"Global Namespace Info","DataQualityWebinarOctober102023":"Data Quality Webinar October 10 2023","ProductHuntLaunchFebruary2024":"Product Hunt Launch February 2024","TimoWebinarJune202024":"Timo Webinar June 20 2024"};

var _revMap$67 = {"Seed funding announcement 2020":"SeedFundingAnnouncement2020","AvoForPMsProductHuntMarch2021":"AvoForPMsProductHuntMarch2021","Data Quality Webinar March 9 2022":"DataQualityWebinarMarch92022","Upgrade your tracking plan to pro level March 15 2022":"UpgradeYourTrackingPlanToProLevelMarch152022","Google Analytics 4 Webinar April 20 2022":"GoogleAnalytics4WebinarApril202022","Innovation Week May 17 2022":"InnovationWeekMay172022","Global Namespace Info":"GlobalNamespaceInfo","Data Quality Webinar October 10 2023":"DataQualityWebinarOctober102023","Product Hunt Launch February 2024":"ProductHuntLaunchFebruary2024","Timo Webinar June 20 2024":"TimoWebinarJune202024"};

function overlayItemNameToJs(param) {
  return _map$90[param];
}

function overlayItemNameFromJs(param) {
  return _revMap$67[param];
}

var _map$91 = {"OpenBranches":"Open Branches","ClosedBranches":"Closed Branches"};

var _revMap$68 = {"Open Branches":"OpenBranches","Closed Branches":"ClosedBranches"};

function branchViewConfigureToStateToJs(param) {
  return _map$91[param];
}

function branchViewConfigureToStateFromJs(param) {
  return _revMap$68[param];
}

var _map$92 = {"MergeWithoutApproval":"Merge Without Approval","None":"None"};

var _revMap$69 = {"Merge Without Approval":"MergeWithoutApproval","None":"None"};

function branchMergePermissionsBypassedToJs(param) {
  return _map$92[param];
}

function branchMergePermissionsBypassedFromJs(param) {
  return _revMap$69[param];
}

var _map$93 = {"Checked":"Checked","Unchecked":"Unchecked"};

function saveAsDefaultStatusToJs(param) {
  return param;
}

function saveAsDefaultStatusFromJs(param) {
  return _map$93[param];
}

var _map$94 = {"Branch":"Branch"};

function collaboratorTypeToJs(param) {
  return param;
}

function collaboratorTypeFromJs(param) {
  return _map$94[param];
}

var _map$95 = {"CopyCode":"Copy Code","DownloadCode":"Download Code","ExpandCodeSnippet":"Expand Code Snippet"};

var _revMap$70 = {"Copy Code":"CopyCode","Download Code":"DownloadCode","Expand Code Snippet":"ExpandCodeSnippet"};

function inspectorIntegrationInteractionTypeToJs(param) {
  return _map$95[param];
}

function inspectorIntegrationInteractionTypeFromJs(param) {
  return _revMap$70[param];
}

var _map$96 = {"RemoveAllExamples":"Remove All Examples","RemoveCurrentExample":"Remove Current Example","KeepCurrentExample":"Keep Current Example"};

var _revMap$71 = {"Remove All Examples":"RemoveAllExamples","Remove Current Example":"RemoveCurrentExample","Keep Current Example":"KeepCurrentExample"};

function exampleInteractionToJs(param) {
  return _map$96[param];
}

function exampleInteractionFromJs(param) {
  return _revMap$71[param];
}

var _map$97 = {"EventName":"Event Name","EventVolume":"Event Volume","Issues":"Issues","SourceEventVolume":"Source Event Volume","SourceIssues":"Source Issues"};

var _revMap$72 = {"Event Name":"EventName","Event Volume":"EventVolume","Issues":"Issues","Source Event Volume":"SourceEventVolume","Source Issues":"SourceIssues"};

function inspectorColumnSelectedToJs(param) {
  return _map$97[param];
}

function inspectorColumnSelectedFromJs(param) {
  return _revMap$72[param];
}

var _map$98 = {"IntegrationType":"Integration Type","IntegrationName":"Integration Name","DownstreamProjectName":"Downstream Project Name","DownstreamUsername":"Downstream Username","DownstreamSecretToken":"Downstream Secret/Token","EventsFilter":"Events Filter","DownstreamProjectID":"Downstream Project ID","DownstreamURL":"Downstream URL","AutoPublish":"Auto Publish","PayloadFormat":"Payload Format","DownstreamRegion":"Downstream Region","CategoriesAsTags":"Categories as Tags","APIType":"API Type"};

var _revMap$73 = {"Integration Type":"IntegrationType","Integration Name":"IntegrationName","Downstream Project Name":"DownstreamProjectName","Downstream Username":"DownstreamUsername","Downstream Secret/Token":"DownstreamSecretToken","Events Filter":"EventsFilter","Downstream Project ID":"DownstreamProjectID","Downstream URL":"DownstreamURL","Auto Publish":"AutoPublish","Payload Format":"PayloadFormat","Downstream Region":"DownstreamRegion","Categories as Tags":"CategoriesAsTags","API Type":"APIType"};

function integrationItemTypeToJs(param) {
  return _map$98[param];
}

function integrationItemTypeFromJs(param) {
  return _revMap$73[param];
}

var _map$99 = {"CmdKRoot":"Cmd K Root","Search":"Search","Create":"Create","Branches":"Branches","Unarchive":"Unarchive"};

var _revMap$74 = {"Cmd K Root":"CmdKRoot","Search":"Search","Create":"Create","Branches":"Branches","Unarchive":"Unarchive"};

function cmdPaletteInitialPathToJs(param) {
  return _map$99[param];
}

function cmdPaletteInitialPathFromJs(param) {
  return _revMap$74[param];
}

var _map$100 = {"NewBranch":"New Branch","NewEvent":"New Event","NewProperty":"New Property","NewMetric":"New Metric"};

var _revMap$75 = {"New Branch":"NewBranch","New Event":"NewEvent","New Property":"NewProperty","New Metric":"NewMetric"};

function dotNewDomainRedirectToJs(param) {
  return _map$100[param];
}

function dotNewDomainRedirectFromJs(param) {
  return _revMap$75[param];
}

var _map$101 = {"NoConflictingProperties":"No conflicting properties","UniqueEventNames":"Unique event names","ConsistentEventNameCasing":"Consistent event name casing","UniquePropertyNames":"Unique property names","ConsistentPropertyNameCasing":"Consistent property name casing","AllPropertiesHaveTypes":"All properties have types","AllEventsHaveDescriptions":"All events have descriptions","AllPropertiesHaveDescriptions":"All properties have descriptions","ForcePropertyCasing":"Force Property casing","PreferredEventNameCasingOtherThenInherit":"Preferred Event Name Casing Other then Inherit","PreferredPropertyNameCasingOtherThenInherit":"Preferred Property Name Casing Other then Inherit","ForceEventCasing":"Force event casing","ForcePropertyCasing":"Force property casing","PreferredEventNameCasingOtherThanInherit":"Preferred event name casing other than inherit","PreferredPropertyNameCasingOtherThanInherit":"Preferred property name casing other than inherit"};

var _revMap$76 = {"No conflicting properties":"NoConflictingProperties","Unique event names":"UniqueEventNames","Consistent event name casing":"ConsistentEventNameCasing","Unique property names":"UniquePropertyNames","Consistent property name casing":"ConsistentPropertyNameCasing","All properties have types":"AllPropertiesHaveTypes","All events have descriptions":"AllEventsHaveDescriptions","All properties have descriptions":"AllPropertiesHaveDescriptions","Force Property casing":"ForcePropertyCasing","Preferred Event Name Casing Other then Inherit":"PreferredEventNameCasingOtherThenInherit","Preferred Property Name Casing Other then Inherit":"PreferredPropertyNameCasingOtherThenInherit","Force event casing":"ForceEventCasing","Force property casing":"ForcePropertyCasing","Preferred event name casing other than inherit":"PreferredEventNameCasingOtherThanInherit","Preferred property name casing other than inherit":"PreferredPropertyNameCasingOtherThanInherit"};

function issueReportConfigsOnToJs(param) {
  return _map$101[param];
}

function issueReportConfigsOnFromJs(param) {
  return _revMap$76[param];
}

var _map$102 = {"CopyShareLink":"Copy share link","SaveViewAs":"Save view as","Save":"Save","Duplicate":"Duplicate","Rename":"Rename"};

var _revMap$77 = {"Copy share link":"CopyShareLink","Save view as":"SaveViewAs","Save":"Save","Duplicate":"Duplicate","Rename":"Rename"};

function inspectorSavedViewInteractionTypeToJs(param) {
  return _map$102[param];
}

function inspectorSavedViewInteractionTypeFromJs(param) {
  return _revMap$77[param];
}

var _map$103 = {"EventsReceived":"Events Received"};

var _revMap$78 = {"Events Received":"EventsReceived"};

function workspaceInspectorInstallStatusToJs(param) {
  return _map$103[param];
}

function workspaceInspectorInstallStatusFromJs(param) {
  return _revMap$78[param];
}

var _map$104 = {"AvoManaged":"Avo Managed","DestinationInterface":"Destination Interface"};

var _revMap$79 = {"Avo Managed":"AvoManaged","Destination Interface":"DestinationInterface"};

function fromDestinationModeToJs(param) {
  return _map$104[param];
}

function fromDestinationModeFromJs(param) {
  return _revMap$79[param];
}

var _map$105 = {"Rename":"Rename","Create":"Create"};

function namingActionTypeToJs(param) {
  return param;
}

function namingActionTypeFromJs(param) {
  return _map$105[param];
}

var _map$106 = {"Admin":"Admin","Editor":"Editor","None":"None"};

function workspaceMinimumApprovalRoleRequiredToJs(param) {
  return param;
}

function workspaceMinimumApprovalRoleRequiredFromJs(param) {
  return _map$106[param];
}

var _map$107 = {"Google":"Google","Email":"Email","Sso":"SSO","Cli":"CLI"};

var _revMap$80 = {"Google":"Google","Email":"Email","SSO":"Sso","CLI":"Cli"};

function authenticationMethodToJs(param) {
  return _map$107[param];
}

function authenticationMethodFromJs(param) {
  return _revMap$80[param];
}

var _map$108 = {"OneHourAfterBranchMerge":"One hour after branch merge","CustomTimeframe":"Custom timeframe","CurrentVersion":"Current version","NextVersion":"Next version","DontIgnore":"Don't ignore","_24HoursAfterBranchMerge":"24 hours after branch merge"};

var _revMap$81 = {"One hour after branch merge":"OneHourAfterBranchMerge","Custom timeframe":"CustomTimeframe","Current version":"CurrentVersion","Next version":"NextVersion","Don't ignore":"DontIgnore","24 hours after branch merge":"_24HoursAfterBranchMerge"};

function releaseVersionConfiguredOptionsToJs(param) {
  return _map$108[param];
}

function releaseVersionConfiguredOptionsFromJs(param) {
  return _revMap$81[param];
}

var _map$109 = {"OpenIssueItem":"Open Issue Item","FixIssue":"Fix Issue","FixAllIssues":"Fix All Issues"};

var _revMap$82 = {"Open Issue Item":"OpenIssueItem","Fix Issue":"FixIssue","Fix All Issues":"FixAllIssues"};

function auditIssueInteractionTypeToJs(param) {
  return _map$109[param];
}

function auditIssueInteractionTypeFromJs(param) {
  return _revMap$82[param];
}

var _map$110 = {"ConsistentCasing":"Consistent Casing","SimilarToExisting":"Similar to Existing","AlreadyExists":"Already Exists","EmptyName":"Empty Name","Intelligence":"Intelligence"};

var _revMap$83 = {"Consistent Casing":"ConsistentCasing","Similar to Existing":"SimilarToExisting","Already Exists":"AlreadyExists","Empty Name":"EmptyName","Intelligence":"Intelligence"};

function feedbackDetailsToJs(param) {
  return _map$110[param];
}

function feedbackDetailsFromJs(param) {
  return _revMap$83[param];
}

var _map$111 = {"ValidateAvoEventFunction":"Validate Avo Event Function","IosSwiftUseAmplitude5":"Ios Swift Use Amplitude 5"};

var _revMap$84 = {"Validate Avo Event Function":"ValidateAvoEventFunction","Ios Swift Use Amplitude 5":"IosSwiftUseAmplitude5"};

function sourceConfigToJs(param) {
  return _map$111[param];
}

function sourceConfigFromJs(param) {
  return _revMap$84[param];
}

var _map$112 = {"Inherited":"Inherited","SentenceCase":"Sentence Case","TitleCase":"Title Case","PascalCase":"Pascal Case","HeaderCase":"Header Case","CapitalCase":"Capital Case","UpperCase":"Upper Case","KebabCase":"Kebab Case","CamelCase":"Camel Case","ConstantCase":"Constant Case","SnakeCase":"Snake Case","LowerCase":"Lower Case","Advanced":"Advanced"};

var _revMap$85 = {"Inherited":"Inherited","Sentence Case":"SentenceCase","Title Case":"TitleCase","Pascal Case":"PascalCase","Header Case":"HeaderCase","Capital Case":"CapitalCase","Upper Case":"UpperCase","Kebab Case":"KebabCase","Camel Case":"CamelCase","Constant Case":"ConstantCase","Snake Case":"SnakeCase","Lower Case":"LowerCase","Advanced":"Advanced"};

function workspaceIssueReportEventCasingToJs(param) {
  return _map$112[param];
}

function workspaceIssueReportEventCasingFromJs(param) {
  return _revMap$85[param];
}

var _map$113 = {"SavedView":"Saved view","AvoCreated":"Avo created"};

var _revMap$86 = {"Saved view":"SavedView","Avo created":"AvoCreated"};

function inspectorIssueViewTypeToJs(param) {
  return _map$113[param];
}

function inspectorIssueViewTypeFromJs(param) {
  return _revMap$86[param];
}

var _map$114 = {"Workbench":"Workbench","Catalog":"Catalog"};

function eventLocationToJs(param) {
  return param;
}

function eventLocationFromJs(param) {
  return _map$114[param];
}

var _map$115 = {"MetricAdded":"Metric Added","NameUpdated":"Name Updated","MetricRemoved":"Metric Removed","DescriptionUpdated":"Description Updated","EventAdded":"Event Added","EventRemoved":"Event Removed"};

var _revMap$87 = {"Metric Added":"MetricAdded","Name Updated":"NameUpdated","Metric Removed":"MetricRemoved","Description Updated":"DescriptionUpdated","Event Added":"EventAdded","Event Removed":"EventRemoved"};

function categoryUpdateActionToJs(param) {
  return _map$115[param];
}

function categoryUpdateActionFromJs(param) {
  return _revMap$87[param];
}

var _map$116 = {"EventDetails":"Event Details","PropertyDetails":"Property Details","EventsView":"Events View","PropertiesView":"Properties View","MetricDetails":"Metric Details","PropertyGroupDetails":"Property Group Details","NewQueryParameter":"New Query Parameter","CommandPalette":"Command Palette","InspectorSidebar":"Inspector Sidebar","InspectorTable":"Inspector Table","InspectorIssueDetails":"Inspector Issue Details"};

var _revMap$88 = {"Event Details":"EventDetails","Property Details":"PropertyDetails","Events View":"EventsView","Properties View":"PropertiesView","Metric Details":"MetricDetails","Property Group Details":"PropertyGroupDetails","New Query Parameter":"NewQueryParameter","Command Palette":"CommandPalette","Inspector Sidebar":"InspectorSidebar","Inspector Table":"InspectorTable","Inspector Issue Details":"InspectorIssueDetails"};

function propertyOriginToJs(param) {
  return _map$116[param];
}

function propertyOriginFromJs(param) {
  return _revMap$88[param];
}

var _map$117 = {"AmplitudeGovern":"Amplitude Govern","MixpanelLexicon":"Mixpanel Lexicon","SegmentProtocols":"Segment Protocols","Webhook":"Webhook","RudderstackTrackingPlans":"Rudderstack Tracking Plans","MParticleDataMaster":"mParticle Data Master","SnowplowDataStructures":"Snowplow Data Structures"};

var _revMap$89 = {"Amplitude Govern":"AmplitudeGovern","Mixpanel Lexicon":"MixpanelLexicon","Segment Protocols":"SegmentProtocols","Webhook":"Webhook","Rudderstack Tracking Plans":"RudderstackTrackingPlans","mParticle Data Master":"MParticleDataMaster","Snowplow Data Structures":"SnowplowDataStructures"};

function integrationTypesPublishedToJs(param) {
  return _map$117[param];
}

function integrationTypesPublishedFromJs(param) {
  return _revMap$89[param];
}

var _map$118 = {"IssueVolume":"Issue Volume","EventVolume":"Event Volume","IssuePercentage":"Issue Percentage","FirstSeen":"First Seen","LastSeen":"Last Seen","IssueType":"Issue Type","EventName":"Event Name","PropertyName":"Property Name"};

var _revMap$90 = {"Issue Volume":"IssueVolume","Event Volume":"EventVolume","Issue Percentage":"IssuePercentage","First Seen":"FirstSeen","Last Seen":"LastSeen","Issue Type":"IssueType","Event Name":"EventName","Property Name":"PropertyName"};

function inspectorIssuesColumnOrderedByToJs(param) {
  return _map$118[param];
}

function inspectorIssuesColumnOrderedByFromJs(param) {
  return _revMap$90[param];
}

var _map$119 = {"SearchStringEntered":"Search string entered","Opened":"Opened","Closed":"Closed"};

var _revMap$91 = {"Search string entered":"SearchStringEntered","Opened":"Opened","Closed":"Closed"};

function sheetFiltersInteractionTypeToJs(param) {
  return _map$119[param];
}

function sheetFiltersInteractionTypeFromJs(param) {
  return _revMap$91[param];
}

var _map$120 = {"NameMapping":"Name Mapping","NameInLocalWorkspace":"Name In Local Workspace"};

var _revMap$92 = {"Name Mapping":"NameMapping","Name In Local Workspace":"NameInLocalWorkspace"};

function ruleTypeToJs(param) {
  return _map$120[param];
}

function ruleTypeFromJs(param) {
  return _revMap$92[param];
}

var _map$121 = {"Unavailable":"Unavailable","Case":"Case","Advanced":"Advanced"};

function eventNamingConventionsAvailabilityToJs(param) {
  return param;
}

function eventNamingConventionsAvailabilityFromJs(param) {
  return _map$121[param];
}

var _map$122 = {"ImplementAnalytics":"Implement analytics","DetectDataAnomalies":"Detect data anomalies","SetProductMinusrelatedGoalsAndMetrics":"Set product-related goals and metrics","Other":"Other","DefineEventDataStructures":"Define event data structures","ReviewEventDataStructures":"Review event data structures"};

var _revMap$93 = {"Implement analytics":"ImplementAnalytics","Detect data anomalies":"DetectDataAnomalies","Set product-related goals and metrics":"SetProductMinusrelatedGoalsAndMetrics","Other":"Other","Define event data structures":"DefineEventDataStructures","Review event data structures":"ReviewEventDataStructures"};

function onboardingTrackingContributionsToJs(param) {
  return _map$122[param];
}

function onboardingTrackingContributionsFromJs(param) {
  return _revMap$93[param];
}

var _map$123 = {"AddProperty":"Add Property","RemoveProperty":"Remove Property","UpdateName":"Update Name","UpdateDescription":"Update Description"};

var _revMap$94 = {"Add Property":"AddProperty","Remove Property":"RemoveProperty","Update Name":"UpdateName","Update Description":"UpdateDescription"};

function propertyGroupActionToJs(param) {
  return _map$123[param];
}

function propertyGroupActionFromJs(param) {
  return _revMap$94[param];
}

var _map$124 = {"Announcement":"Announcement","ProductInfo":"Product Info"};

var _revMap$95 = {"Announcement":"Announcement","Product Info":"ProductInfo"};

function overlayItemPurposeToJs(param) {
  return _map$124[param];
}

function overlayItemPurposeFromJs(param) {
  return _revMap$95[param];
}

var _map$125 = {"Json":"Json","Csv":"Csv","AvoJson":"Avo Json"};

var _revMap$96 = {"Json":"Json","Csv":"Csv","Avo Json":"AvoJson"};

function exportFormatToJs(param) {
  return _map$125[param];
}

function exportFormatFromJs(param) {
  return _revMap$96[param];
}

var _map$126 = {"Basic":"Basic","PredefinedFramework":"Predefined Framework","PredefinedFrameworkWithPredefinedValues":"Predefined Framework with Predefined Values","CustomFramework":"Custom Framework"};

var _revMap$97 = {"Basic":"Basic","Predefined Framework":"PredefinedFramework","Predefined Framework with Predefined Values":"PredefinedFrameworkWithPredefinedValues","Custom Framework":"CustomFramework"};

function workspaceIssueReportEventNamingConventionToJs(param) {
  return _map$126[param];
}

function workspaceIssueReportEventNamingConventionFromJs(param) {
  return _revMap$97[param];
}

var _map$127 = {"ExecutiveVPCMinussuite":"Executive (VP / C-suite)","HeadOfTeam":"Head of team","PeopleManager":"People manager","PlayerMinuscoach":"Player-coach","IndividualContributor":"Individual contributor","ContractorFreelancer":"Contractor / Freelancer"};

var _revMap$98 = {"Executive (VP / C-suite)":"ExecutiveVPCMinussuite","Head of team":"HeadOfTeam","People manager":"PeopleManager","Player-coach":"PlayerMinuscoach","Individual contributor":"IndividualContributor","Contractor / Freelancer":"ContractorFreelancer"};

function onboardingCompanyRoleToJs(param) {
  return _map$127[param];
}

function onboardingCompanyRoleFromJs(param) {
  return _revMap$98[param];
}

var _map$128 = {"ProductAnalytics":"Product analytics","DataScience":"Data science","SoftwareEngineering":"Software engineering","ProductManagement":"Product management","Marketing":"Marketing","Other":"Other","DataAnalyticsEngineering":"Data/analytics engineering"};

var _revMap$99 = {"Product analytics":"ProductAnalytics","Data science":"DataScience","Software engineering":"SoftwareEngineering","Product management":"ProductManagement","Marketing":"Marketing","Other":"Other","Data/analytics engineering":"DataAnalyticsEngineering"};

function onboardingJobFunctionToJs(param) {
  return _map$128[param];
}

function onboardingJobFunctionFromJs(param) {
  return _revMap$99[param];
}

var _map$129 = {"Free":"free","Freev2":"freev2","Scholarship":"scholarship","Starter":"starter","Growth":"growth","Growthv2":"growthv2","Enterprise":"enterprise","Enterprisev2":"enterprisev2","Teamv2":"teamv2","Freev3":"freev3","Teamv3":"teamv3"};

var _revMap$100 = {"free":"Free","freev2":"Freev2","scholarship":"Scholarship","starter":"Starter","growth":"Growth","growthv2":"Growthv2","enterprise":"Enterprise","enterprisev2":"Enterprisev2","teamv2":"Teamv2","freev3":"Freev3","teamv3":"Teamv3"};

function schemaSubscriptionPlanSlugToJs(param) {
  return _map$129[param];
}

function schemaSubscriptionPlanSlugFromJs(param) {
  return _revMap$100[param];
}

var _map$130 = {"StringMatches":"String Matches","NumericalMin":"Numerical Min","NumericalMax":"Numerical Max"};

var _revMap$101 = {"String Matches":"StringMatches","Numerical Min":"NumericalMin","Numerical Max":"NumericalMax"};

function propertyValueConstraintTypeToJs(param) {
  return _map$130[param];
}

function propertyValueConstraintTypeFromJs(param) {
  return _revMap$101[param];
}

var _map$131 = {"ReadyForReview":"ReadyForReview","Approved":"Approved","ChangesRequested":"ChangesRequested","Open":"Open"};

function branchStatusBeforeToJs(param) {
  return param;
}

function branchStatusBeforeFromJs(param) {
  return _map$131[param];
}

var _map$132 = {"None":"None","Keystroke":"Keystroke"};

function interactionTypeToJs(param) {
  return param;
}

function interactionTypeFromJs(param) {
  return _map$132[param];
}

var _map$133 = {"ValidateAvoEventFunction":"Validate Avo Event Function","IosSwiftUseAmplitude5":"Ios Swift Use Amplitude 5"};

var _revMap$102 = {"Validate Avo Event Function":"ValidateAvoEventFunction","Ios Swift Use Amplitude 5":"IosSwiftUseAmplitude5"};

function fromSourceConfigToJs(param) {
  return _map$133[param];
}

function fromSourceConfigFromJs(param) {
  return _revMap$102[param];
}

var _map$134 = {"Ascending":"Ascending","Decending":"Decending"};

function inspectorIssuesOrderingToJs(param) {
  return param;
}

function inspectorIssuesOrderingFromJs(param) {
  return _map$134[param];
}

var _map$135 = {"Last1Hour":"Last 1 hour","Last24Hours":"Last 24 hours","Last7Days":"Last 7 days","Last30Days":"Last 30 days"};

var _revMap$103 = {"Last 1 hour":"Last1Hour","Last 24 hours":"Last24Hours","Last 7 days":"Last7Days","Last 30 days":"Last30Days"};

function inspectorTimeWindowToJs(param) {
  return _map$135[param];
}

function inspectorTimeWindowFromJs(param) {
  return _revMap$103[param];
}

var _map$136 = {"Finished":"Finished","ToDo":"To do"};

var _revMap$104 = {"Finished":"Finished","To do":"ToDo"};

function checklistStepStatusToJs(param) {
  return _map$136[param];
}

function checklistStepStatusFromJs(param) {
  return _revMap$104[param];
}

var _map$137 = {"CmdPalette":"Cmd Palette","OnboardingChecklist":"Onboarding Checklist","SidebarSettingsCog":"Sidebar Settings Cog","TrackingPlanEventsEmptyState":"Tracking Plan Events Empty State","InspectorIssuesHeader":"Inspector Issues Header","InspectorIssueDetails":"Inspector Issue Details","DirectLink":"Direct Link","InspectorEventsTableRow":"Inspector Events Table Row","InspectorEventsHeader":"Inspector Events Header","InspectorEventsSidebar":"Inspector Events Sidebar"};

var _revMap$105 = {"Cmd Palette":"CmdPalette","Onboarding Checklist":"OnboardingChecklist","Sidebar Settings Cog":"SidebarSettingsCog","Tracking Plan Events Empty State":"TrackingPlanEventsEmptyState","Inspector Issues Header":"InspectorIssuesHeader","Inspector Issue Details":"InspectorIssueDetails","Direct Link":"DirectLink","Inspector Events Table Row":"InspectorEventsTableRow","Inspector Events Header":"InspectorEventsHeader","Inspector Events Sidebar":"InspectorEventsSidebar"};

function importStartedLocationToJs(param) {
  return _map$137[param];
}

function importStartedLocationFromJs(param) {
  return _revMap$105[param];
}

var _map$138 = {"Unknown":"Unknown","Functions":"Functions","InspectorProduction":"Inspector Production","InspectorStaging":"Inspector Staging","InspectorDevelopment":"Inspector Development"};

var _revMap$106 = {"Unknown":"Unknown","Functions":"Functions","Inspector Production":"InspectorProduction","Inspector Staging":"InspectorStaging","Inspector Development":"InspectorDevelopment"};

function highlightedImplementationStatusTypeToJs(param) {
  return _map$138[param];
}

function highlightedImplementationStatusTypeFromJs(param) {
  return _revMap$106[param];
}

var _map$139 = {"LandingPage":"Landing Page","InMinusapp":"In-app"};

var _revMap$107 = {"Landing Page":"LandingPage","In-app":"InMinusapp"};

function bannerLocationToJs(param) {
  return _map$139[param];
}

function bannerLocationFromJs(param) {
  return _revMap$107[param];
}

var _map$140 = {"ReadyForReview":"Ready for Review","BranchMerge":"Branch Merge"};

var _revMap$108 = {"Ready for Review":"ReadyForReview","Branch Merge":"BranchMerge"};

function issueReportConfigureBranchEnforcementStatusToJs(param) {
  return _map$140[param];
}

function issueReportConfigureBranchEnforcementStatusFromJs(param) {
  return _revMap$108[param];
}

var _map$141 = {"Unresolved":"Unresolved","Ignored":"Ignored","Resolved":"Resolved"};

function inspectorIssuesStatusViewToJs(param) {
  return param;
}

function inspectorIssuesStatusViewFromJs(param) {
  return _map$141[param];
}

var _map$142 = {"Comment":"Comment","AddCollaborator":"Add Collaborator","NewBranch":"New Branch","SetBranchStatusToDraft":"Set Branch Status To Draft","SetBranchStatusToReadyForReview":"Set Branch Status To Ready For Review","SetBranchStatusToApproved":"Set Branch Status To Approved","SetBranchStatusToChangesRequested":"Set Branch Status To Changes Requested"};

var _revMap$109 = {"Comment":"Comment","Add Collaborator":"AddCollaborator","New Branch":"NewBranch","Set Branch Status To Draft":"SetBranchStatusToDraft","Set Branch Status To Ready For Review":"SetBranchStatusToReadyForReview","Set Branch Status To Approved":"SetBranchStatusToApproved","Set Branch Status To Changes Requested":"SetBranchStatusToChangesRequested"};

function collaboratorAdderActionToJs(param) {
  return _map$142[param];
}

function collaboratorAdderActionFromJs(param) {
  return _revMap$109[param];
}

var _map$143 = {"Description":"Description","AddProperty":"Add property","RemoveProperty":"Remove property","ClearPropertyOverrides":"Clear property overrides","UpdatePropertyPinnedValue":"Update property pinned value","RemovePropertyPinnedValue":"Remove property pinned value","AddEventTrigger":"Add event trigger","RemoveEventTrigger":"Remove event trigger","ChangePresenceOverride":"Change presence override","ClearSourceOverrides":"Clear source overrides","RemoveSource":"Remove source","EditPropertyAllowedValues":"Edit property allowed values"};

var _revMap$110 = {"Description":"Description","Add property":"AddProperty","Remove property":"RemoveProperty","Clear property overrides":"ClearPropertyOverrides","Update property pinned value":"UpdatePropertyPinnedValue","Remove property pinned value":"RemovePropertyPinnedValue","Add event trigger":"AddEventTrigger","Remove event trigger":"RemoveEventTrigger","Change presence override":"ChangePresenceOverride","Clear source overrides":"ClearSourceOverrides","Remove source":"RemoveSource","Edit property allowed values":"EditPropertyAllowedValues"};

function eventVariantUpdateTypeToJs(param) {
  return _map$143[param];
}

function eventVariantUpdateTypeFromJs(param) {
  return _revMap$110[param];
}

var _map$144 = {"Collapse":"Collapse","Expand":"Expand","CopyButtonClick":"Copy Button Click"};

var _revMap$111 = {"Collapse":"Collapse","Expand":"Expand","Copy Button Click":"CopyButtonClick"};

function codeSnippetInteractionToJs(param) {
  return _map$144[param];
}

function codeSnippetInteractionFromJs(param) {
  return _revMap$111[param];
}

var _map$145 = {"SourceDeleted":"Source Deleted","SourceCreated":"Source Created","LibraryDestination":"Library Destination","LibraryName":"Library Name","ProgrammingLanguage":"Programming Language","DevelopmentPlatform":"Development Platform","SourceName":"Source Name","DestinationAdded":"Destination Added","DestinationRemoved":"Destination Removed","SourceConfig":"Source Config","DestinationModeUpdated":"Destination Mode Updated"};

var _revMap$112 = {"Source Deleted":"SourceDeleted","Source Created":"SourceCreated","Library Destination":"LibraryDestination","Library Name":"LibraryName","Programming Language":"ProgrammingLanguage","Development Platform":"DevelopmentPlatform","Source Name":"SourceName","Destination Added":"DestinationAdded","Destination Removed":"DestinationRemoved","Source Config":"SourceConfig","Destination Mode Updated":"DestinationModeUpdated"};

function sourceChangeToJs(param) {
  return _map$145[param];
}

function sourceChangeFromJs(param) {
  return _revMap$112[param];
}

var _map$146 = {"WorkspaceNotifications":"Workspace Notifications","InspectorNotifications":"Inspector Notifications","None":"None"};

var _revMap$113 = {"Workspace Notifications":"WorkspaceNotifications","Inspector Notifications":"InspectorNotifications","None":"None"};

function workspaceSlackIntegrationsEnabledToJs(param) {
  return _map$146[param];
}

function workspaceSlackIntegrationsEnabledFromJs(param) {
  return _revMap$113[param];
}

var _map$147 = {"AlwaysSent":"Always Sent","SometimesSent":"Sometimes Sent","NeverSent":"Never Sent","NoOverride":"No Override"};

var _revMap$114 = {"Always Sent":"AlwaysSent","Sometimes Sent":"SometimesSent","Never Sent":"NeverSent","No Override":"NoOverride"};

function eventVariantPropertyPresenceOverrideTypeAfterToJs(param) {
  return _map$147[param];
}

function eventVariantPropertyPresenceOverrideTypeAfterFromJs(param) {
  return _revMap$114[param];
}

var _map$148 = {"Inherited":"Inherited","SentenceCase":"Sentence Case","TitleCase":"Title Case","PascalCase":"Pascal Case","HeaderCase":"Header Case","CapitalCase":"Capital Case","UpperCase":"Upper Case","KebabCase":"Kebab Case","CamelCase":"Camel Case","ConstantCase":"Constant Case","SnakeCase":"Snake Case","LowerCase":"Lower Case"};

var _revMap$115 = {"Inherited":"Inherited","Sentence Case":"SentenceCase","Title Case":"TitleCase","Pascal Case":"PascalCase","Header Case":"HeaderCase","Capital Case":"CapitalCase","Upper Case":"UpperCase","Kebab Case":"KebabCase","Camel Case":"CamelCase","Constant Case":"ConstantCase","Snake Case":"SnakeCase","Lower Case":"LowerCase"};

function issueReportPropertyCasingToJs(param) {
  return _map$148[param];
}

function issueReportPropertyCasingFromJs(param) {
  return _revMap$115[param];
}

var _map$149 = {"UpdateDescription":"Update description","AddSource":"Add source","RemoveSource":"Remove source","AddImage":"Add image","RemoveImage":"Remove image"};

var _revMap$116 = {"Update description":"UpdateDescription","Add source":"AddSource","Remove source":"RemoveSource","Add image":"AddImage","Remove image":"RemoveImage"};

function triggerConfigureActionToJs(param) {
  return _map$149[param];
}

function triggerConfigureActionFromJs(param) {
  return _revMap$116[param];
}

var _map$150 = {"None":"None","Matches":"Matches","Min":"Min","Max":"Max","Shape":"Shape","NestedProperty":"NestedProperty","Regex":"Regex"};

function toConstraintTypeToJs(param) {
  return param;
}

function toConstraintTypeFromJs(param) {
  return _map$150[param];
}

var _map$151 = {"EventDetails":"Event Details","PropertyDetails":"Property Details","EventsView":"Events View","PropertiesView":"Properties View","MetricDetails":"Metric Details","PropertyGroupDetails":"Property Group Details","NewQueryParameter":"New Query Parameter","CommandPalette":"Command Palette","InspectorSidebar":"Inspector Sidebar","InspectorTable":"Inspector Table","InspectorIssueDetails":"Inspector Issue Details"};

var _revMap$117 = {"Event Details":"EventDetails","Property Details":"PropertyDetails","Events View":"EventsView","Properties View":"PropertiesView","Metric Details":"MetricDetails","Property Group Details":"PropertyGroupDetails","New Query Parameter":"NewQueryParameter","Command Palette":"CommandPalette","Inspector Sidebar":"InspectorSidebar","Inspector Table":"InspectorTable","Inspector Issue Details":"InspectorIssueDetails"};

function propertyLocationToJs(param) {
  return _map$151[param];
}

function propertyLocationFromJs(param) {
  return _revMap$117[param];
}

var _map$152 = {"OpenDocs":"Open Docs","Setup":"Setup"};

var _revMap$118 = {"Open Docs":"OpenDocs","Setup":"Setup"};

function emptyStateInteractionToJs(param) {
  return _map$152[param];
}

function emptyStateInteractionFromJs(param) {
  return _revMap$118[param];
}

var _map$153 = {"IncludeUserPropsWithEventProps":"Include User Props With Event Props","ProductionApiKey":"Production Api Key","DevelopmentApiKey":"Development Api Key","DestinationDeleted":"Destination Deleted","DestinationCreated":"Destination Created","AnalyticsTool":"Analytics Tool","DestinationName":"Destination Name","StagingApiKey":"Staging Api Key","DisabledByDefault":"Disabled by Default"};

var _revMap$119 = {"Include User Props With Event Props":"IncludeUserPropsWithEventProps","Production Api Key":"ProductionApiKey","Development Api Key":"DevelopmentApiKey","Destination Deleted":"DestinationDeleted","Destination Created":"DestinationCreated","Analytics Tool":"AnalyticsTool","Destination Name":"DestinationName","Staging Api Key":"StagingApiKey","Disabled by Default":"DisabledByDefault"};

function destinationChangeToJs(param) {
  return _map$153[param];
}

function destinationChangeFromJs(param) {
  return _revMap$119[param];
}

var _map$154 = {"Description":"Description","Image":"Image","Sources":"Sources"};

function triggerConfigureItemToJs(param) {
  return param;
}

function triggerConfigureItemFromJs(param) {
  return _map$154[param];
}

var _map$155 = {"DiffTypeChanged":"Diff type changed","SourceSwitched":"Source switched","ImplementationMethodFilterChanged":"Implementation method filter changed"};

var _revMap$120 = {"Diff type changed":"DiffTypeChanged","Source switched":"SourceSwitched","Implementation method filter changed":"ImplementationMethodFilterChanged"};

function branchImplementationConfiguredActionToJs(param) {
  return _map$155[param];
}

function branchImplementationConfiguredActionFromJs(param) {
  return _revMap$120[param];
}

var _map$156 = {"Create":"Create","Remove":"Remove","Add":"Add"};

function tagActionToJs(param) {
  return param;
}

function tagActionFromJs(param) {
  return _map$156[param];
}

var _map$157 = {"Team":"Team","Trial":"Trial","Free":"Free"};

function schemaBillingStatusToJs(param) {
  return param;
}

function schemaBillingStatusFromJs(param) {
  return _map$157[param];
}

var _map$158 = {"Goals":"Goals","Events":"Events","SchemaDashboard":"Schema Dashboard","EventFullscreen":"Event Fullscreen","EventPropertyComment":"Event Property Comment","EventProperty":"Event Property","EventComment":"Event Comment","NotFound":"Not Found","PasswordReset":"Password Reset","SourceSettings":"Source Settings","DestinationSettings":"Destination Settings","Event":"Event","Diff":"Diff","Settings":"Settings","Billing":"Billing","Schemas":"Schemas","SignUp":"Sign Up","SignIn":"Sign In","Invite":"Invite","Welcome":"Welcome","Properties":"Properties","Property":"Property","AuthenticateCLI":"Authenticate CLI","AuthenticateCLISuccess":"Authenticate CLI Success","AuthenticateCLIError":"Authenticate CLI Error","Implement":"Implement","EventPropertyGroup":"Event Property Group","PropertyGroup":"Property Group","Metric":"Metric","Onboarding":"Onboarding","AuditLog":"Audit Log","Integrations":"Integrations","Category":"Category","HealthErrors":"Health Errors","HealthCoverage":"Health Coverage","HealthDebugger":"Health Debugger","InspectorEvents":"InspectorEvents","BranchActivity":"Branch Activity","Integration":"Integration","IntegrationComment":"IntegrationComment","ImportUpload":"Import Upload","ImportReview":"Import Review","ImportSuccess":"Import Success","EventTrigger":"Event Trigger","SourceInspectorSetup":"Source Inspector Setup","SourceFunctionsSetup":"Source Functions Setup","SourceEventReference":"Source Event Reference","EventPropertyGroups":"Event Property Groups","BranchImplementation":"Branch Implementation","PublicBranchImplementation":"Public Branch Implementation","BranchImplementationSource":"Branch Implementation Source","InspectorIssues":"Inspector Issues","InspectorSavedViews":"Inspector Saved Views","InspectorSavedView":"Inspector Saved View","InspectorIssue":"Inspector Issue","InspectorIssueComment":"Inspector Issue Comment","Sandbox":"Sandbox","EventVariant":"Event Variant","EventVariantTrigger":"Event Variant Trigger"};

var _revMap$121 = {"Goals":"Goals","Events":"Events","Schema Dashboard":"SchemaDashboard","Event Fullscreen":"EventFullscreen","Event Property Comment":"EventPropertyComment","Event Property":"EventProperty","Event Comment":"EventComment","Not Found":"NotFound","Password Reset":"PasswordReset","Source Settings":"SourceSettings","Destination Settings":"DestinationSettings","Event":"Event","Diff":"Diff","Settings":"Settings","Billing":"Billing","Schemas":"Schemas","Sign Up":"SignUp","Sign In":"SignIn","Invite":"Invite","Welcome":"Welcome","Properties":"Properties","Property":"Property","Authenticate CLI":"AuthenticateCLI","Authenticate CLI Success":"AuthenticateCLISuccess","Authenticate CLI Error":"AuthenticateCLIError","Implement":"Implement","Event Property Group":"EventPropertyGroup","Property Group":"PropertyGroup","Metric":"Metric","Onboarding":"Onboarding","Audit Log":"AuditLog","Integrations":"Integrations","Category":"Category","Health Errors":"HealthErrors","Health Coverage":"HealthCoverage","Health Debugger":"HealthDebugger","InspectorEvents":"InspectorEvents","Branch Activity":"BranchActivity","Integration":"Integration","IntegrationComment":"IntegrationComment","Import Upload":"ImportUpload","Import Review":"ImportReview","Import Success":"ImportSuccess","Event Trigger":"EventTrigger","Source Inspector Setup":"SourceInspectorSetup","Source Functions Setup":"SourceFunctionsSetup","Source Event Reference":"SourceEventReference","Event Property Groups":"EventPropertyGroups","Branch Implementation":"BranchImplementation","Public Branch Implementation":"PublicBranchImplementation","Branch Implementation Source":"BranchImplementationSource","Inspector Issues":"InspectorIssues","Inspector Saved Views":"InspectorSavedViews","Inspector Saved View":"InspectorSavedView","Inspector Issue":"InspectorIssue","Inspector Issue Comment":"InspectorIssueComment","Sandbox":"Sandbox","Event Variant":"EventVariant","Event Variant Trigger":"EventVariantTrigger"};

function fromScreenToJs(param) {
  return _map$158[param];
}

function fromScreenFromJs(param) {
  return _revMap$121[param];
}

var _map$159 = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","CommentOnly":"Comment Only","BillingOnly":"Billing Only","CodegenAccess":"Codegen Access"};

var _revMap$122 = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","Comment Only":"CommentOnly","Billing Only":"BillingOnly","Codegen Access":"CodegenAccess"};

function roleToJs(param) {
  return _map$159[param];
}

function roleFromJs(param) {
  return _revMap$122[param];
}

var _map$160 = {"JsonSchema":"Json Schema","SnowplowSchemas":"Snowplow Schemas","AvoJson":"Avo Json"};

var _revMap$123 = {"Json Schema":"JsonSchema","Snowplow Schemas":"SnowplowSchemas","Avo Json":"AvoJson"};

function integrationPayloadFormatToJs(param) {
  return _map$160[param];
}

function integrationPayloadFormatFromJs(param) {
  return _revMap$123[param];
}

var _map$161 = {"ProtectedMainBranch":"Protected Main Branch","WorkspaceSlackNotifications":"Workspace Slack Notifications","RequireAdminApproval":"Require Admin Approval","NumberOfRequiredApprovals":"Number of Required Approvals","InspectorSlackNotifications":"Inspector Slack Notifications","ResetTrackingPlan":"Reset Tracking Plan","ForceBranchAudit":"Force Branch Audit","RequireBranchAuditPass":"Require Branch Audit Pass"};

var _revMap$124 = {"Protected Main Branch":"ProtectedMainBranch","Workspace Slack Notifications":"WorkspaceSlackNotifications","Require Admin Approval":"RequireAdminApproval","Number of Required Approvals":"NumberOfRequiredApprovals","Inspector Slack Notifications":"InspectorSlackNotifications","Reset Tracking Plan":"ResetTrackingPlan","Force Branch Audit":"ForceBranchAudit","Require Branch Audit Pass":"RequireBranchAuditPass"};

function settingNameToJs(param) {
  return _map$161[param];
}

function settingNameFromJs(param) {
  return _revMap$124[param];
}

var _map$162 = {"Matches":"Matches","Min":"Min","Max":"Max"};

function constraintTypeToJs(param) {
  return param;
}

function constraintTypeFromJs(param) {
  return _map$162[param];
}

var _map$163 = {"All":"All","User":"User","Event":"Event","System":"System","Group":"Group"};

function propertiesViewSegmentationBeforeToJs(param) {
  return param;
}

function propertiesViewSegmentationBeforeFromJs(param) {
  return _map$163[param];
}

var _map$164 = {"Custom":"Custom","MParticle":"mParticle","FirebaseAnalytics":"Firebase Analytics","FacebookAnalytics":"Facebook Analytics","Mixpanel":"Mixpanel","Segment":"Segment","Amplitude":"Amplitude","FullStory":"FullStory","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","Braze":"Braze","ZendeskConnect":"ZendeskConnect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","GoogleAnalytics4":"GoogleAnalytics4","Freshpaint":"Freshpaint","AdobeAnalytics":"AdobeAnalytics","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

var _revMap$125 = {"Custom":"Custom","mParticle":"MParticle","Firebase Analytics":"FirebaseAnalytics","Facebook Analytics":"FacebookAnalytics","Mixpanel":"Mixpanel","Segment":"Segment","Amplitude":"Amplitude","FullStory":"FullStory","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","Braze":"Braze","ZendeskConnect":"ZendeskConnect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","GoogleAnalytics4":"GoogleAnalytics4","Freshpaint":"Freshpaint","AdobeAnalytics":"AdobeAnalytics","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

function fromDataDestinationToJs(param) {
  return _map$164[param];
}

function fromDataDestinationFromJs(param) {
  return _revMap$125[param];
}

var _map$165 = {"Python":"Python","Swift":"Swift","Java":"Java","Reason":"Reason","JavaScript":"JavaScript","None":"None","Kotlin":"Kotlin","CSharp":"C#","TypeScript":"TypeScript","ObjectiveMinusC":"Objective-C","JsonSchema":"Json Schema","Ruby":"Ruby","Php":"PHP","Python3":"Python3","Dart":"Dart","Go":"Go"};

var _revMap$126 = {"Python":"Python","Swift":"Swift","Java":"Java","Reason":"Reason","JavaScript":"JavaScript","None":"None","Kotlin":"Kotlin","C#":"CSharp","TypeScript":"TypeScript","Objective-C":"ObjectiveMinusC","Json Schema":"JsonSchema","Ruby":"Ruby","PHP":"Php","Python3":"Python3","Dart":"Dart","Go":"Go"};

function fromProgrammingLanguageToJs(param) {
  return _map$165[param];
}

function fromProgrammingLanguageFromJs(param) {
  return _revMap$126[param];
}

var _map$166 = {"Add":"Add","Remove":"Remove","EnableSource":"Enable Source","DisableSource":"Disable Source","AllowOnEvent":"Allow on Event","DisallowOnEvent":"Disallow on Event","AllowOnEventSource":"Allow on Event Source","DisallowOnEventSource":"Disallow on Event Source","AllowOnEventVariant":"Allow on Event Variant","DisallowOnEventVariant":"Disallow on Event Variant"};

var _revMap$127 = {"Add":"Add","Remove":"Remove","Enable Source":"EnableSource","Disable Source":"DisableSource","Allow on Event":"AllowOnEvent","Disallow on Event":"DisallowOnEvent","Allow on Event Source":"AllowOnEventSource","Disallow on Event Source":"DisallowOnEventSource","Allow on Event Variant":"AllowOnEventVariant","Disallow on Event Variant":"DisallowOnEventVariant"};

function matchesActionToJs(param) {
  return _map$166[param];
}

function matchesActionFromJs(param) {
  return _revMap$127[param];
}

var _map$167 = {"Basic":"Basic","PredefinedFramework":"Predefined Framework","PredefinedFrameworkWithPredefinedValues":"Predefined Framework with Predefined Values","CustomFramework":"Custom Framework"};

var _revMap$128 = {"Basic":"Basic","Predefined Framework":"PredefinedFramework","Predefined Framework with Predefined Values":"PredefinedFrameworkWithPredefinedValues","Custom Framework":"CustomFramework"};

function issueReportEventNamingConventionToJs(param) {
  return _map$167[param];
}

function issueReportEventNamingConventionFromJs(param) {
  return _revMap$128[param];
}

var _map$168 = {"Change":"Change","Remove":"Remove","Add":"Add"};

function constraintActionToJs(param) {
  return param;
}

function constraintActionFromJs(param) {
  return _map$168[param];
}

var _map$169 = {"String":"String","Int":"Int","Float":"Float","Boolean":"Boolean","Object":"Object","Long":"Long"};

function propertyValueTypeBeforeToJs(param) {
  return param;
}

function propertyValueTypeBeforeFromJs(param) {
  return _map$169[param];
}

var _map$170 = {"UpdateName":"Update Name","UpdateValueType":"Update Value Type","UpdateListToggle":"Update List Toggle","UpdatePropertyPresenceGlobally":"Update Property Presence Globally","UpdatePropertyPresenceByEvent":"Update Property Presence by Event","UpdatePropertyPresenceByEventAndSource":"Update Property Presence by Event and Source","AddConstraint":"Add Constraint","RemoveConstraint":"Remove Constraint","UpdateConstraintPresenceBySource":"Update Constraint Presence by Source","AddNameMapping":"Add Name Mapping","RemoveNameMapping":"Remove Name Mapping","UpdateNameMapping":"Update Name Mapping","AddDescription":"Add Description","UpdateDescription":"Update Description","RemoveDescription":"Remove Description","AddEvent":"Add Event","RemoveEvent":"Remove Event","UpdateNestedObjectKeyPresence":"Update Nested Object Key Presence"};

var _revMap$129 = {"Update Name":"UpdateName","Update Value Type":"UpdateValueType","Update List Toggle":"UpdateListToggle","Update Property Presence Globally":"UpdatePropertyPresenceGlobally","Update Property Presence by Event":"UpdatePropertyPresenceByEvent","Update Property Presence by Event and Source":"UpdatePropertyPresenceByEventAndSource","Add Constraint":"AddConstraint","Remove Constraint":"RemoveConstraint","Update Constraint Presence by Source":"UpdateConstraintPresenceBySource","Add Name Mapping":"AddNameMapping","Remove Name Mapping":"RemoveNameMapping","Update Name Mapping":"UpdateNameMapping","Add Description":"AddDescription","Update Description":"UpdateDescription","Remove Description":"RemoveDescription","Add Event":"AddEvent","Remove Event":"RemoveEvent","Update Nested Object Key Presence":"UpdateNestedObjectKeyPresence"};

function propertyConfigureActionToJs(param) {
  return _map$170[param];
}

function propertyConfigureActionFromJs(param) {
  return _revMap$129[param];
}

var _map$171 = {"Python":"Python","Android":"Android","IOS":"iOS","ReactNative":"React Native","Web":"Web","Node":"Node","Unity":"Unity","Java":"Java","JsonSchema":"JsonSchema","Expo":"Expo","Ruby":"Ruby","Gtm":"GTM","Php":"PHP","DotNet":"DotNet","MacOS":"MacOS","Other":"Other","Flutter":"Flutter","Go":"Go"};

var _revMap$130 = {"Python":"Python","Android":"Android","iOS":"IOS","React Native":"ReactNative","Web":"Web","Node":"Node","Unity":"Unity","Java":"Java","JsonSchema":"JsonSchema","Expo":"Expo","Ruby":"Ruby","GTM":"Gtm","PHP":"Php","DotNet":"DotNet","MacOS":"MacOS","Other":"Other","Flutter":"Flutter","Go":"Go"};

function dataSourceToJs(param) {
  return _map$171[param];
}

function dataSourceFromJs(param) {
  return _revMap$130[param];
}

var _map$172 = {"None":"None","Matches":"Matches","Min":"Min","Max":"Max","Shape":"Shape","NestedProperty":"NestedProperty","Regex":"Regex"};

function fromConstraintTypeToJs(param) {
  return param;
}

function fromConstraintTypeFromJs(param) {
  return _map$172[param];
}

var _map$173 = {"Source":"Source","Version":"Version","EventName":"Event name","IssueType":"Issue type","PropertyName":"Property name","Category":"Category","Tag":"Tag","Release":"Release"};

var _revMap$131 = {"Source":"Source","Version":"Version","Event name":"EventName","Issue type":"IssueType","Property name":"PropertyName","Category":"Category","Tag":"Tag","Release":"Release"};

function inspectorIssuesActiveFiltersToJs(param) {
  return _map$173[param];
}

function inspectorIssuesActiveFiltersFromJs(param) {
  return _revMap$131[param];
}

var _map$174 = {"Free":"free","Freev2":"freev2","Starter":"starter","Growth":"growth","Growthv2":"growthv2","Enterprise":"enterprise","Enterprisev2":"enterprisev2","Team":"team","Custom":"custom","Teamv2":"teamv2","Scholarship":"scholarship","Freev3":"freev3","Teamv3":"teamv3"};

var _revMap$132 = {"free":"Free","freev2":"Freev2","starter":"Starter","growth":"Growth","growthv2":"Growthv2","enterprise":"Enterprise","enterprisev2":"Enterprisev2","team":"Team","custom":"Custom","teamv2":"Teamv2","scholarship":"Scholarship","freev3":"Freev3","teamv3":"Teamv3"};

function subscriptionPlanSlugToJs(param) {
  return _map$174[param];
}

function subscriptionPlanSlugFromJs(param) {
  return _revMap$132[param];
}

var _map$175 = {"EventSegmentation":"Event Segmentation","Funnel":"Funnel","Retention":"Retention","Proportion":"Proportion"};

var _revMap$133 = {"Event Segmentation":"EventSegmentation","Funnel":"Funnel","Retention":"Retention","Proportion":"Proportion"};

function metricTypeToJs(param) {
  return _map$175[param];
}

function metricTypeFromJs(param) {
  return _revMap$133[param];
}

var _map$176 = {"Ascending":"Ascending","Descending":"Descending"};

function inspectorOrderToJs(param) {
  return param;
}

function inspectorOrderFromJs(param) {
  return _map$176[param];
}

var _map$177 = {"Inherited":"Inherited","SentenceCase":"Sentence Case","TitleCase":"Title Case","PascalCase":"Pascal Case","HeaderCase":"Header Case","CapitalCase":"Capital Case","UpperCase":"Upper Case","KebabCase":"Kebab Case","CamelCase":"Camel Case","ConstantCase":"Constant Case","SnakeCase":"Snake Case","LowerCase":"Lower Case","Advanced":"Advanced"};

var _revMap$134 = {"Inherited":"Inherited","Sentence Case":"SentenceCase","Title Case":"TitleCase","Pascal Case":"PascalCase","Header Case":"HeaderCase","Capital Case":"CapitalCase","Upper Case":"UpperCase","Kebab Case":"KebabCase","Camel Case":"CamelCase","Constant Case":"ConstantCase","Snake Case":"SnakeCase","Lower Case":"LowerCase","Advanced":"Advanced"};

function issueReportEventCasingToJs(param) {
  return _map$177[param];
}

function issueReportEventCasingFromJs(param) {
  return _revMap$134[param];
}

var _map$178 = {"AvoCLIDocsLinkClicked":"Avo CLI docs link clicked","SourceSwitched":"Source switched","PullSnippetCopied":"Pull snippet copied","CodeSnippetCopied":"Code snippet copied","LinkToSnippetCopied":"Link to snippet copied","LinkToSnippetOpened":"Link to snippet opened","CodeSnippetCopiedManually":"Code snippet copied manually","SnippetExpanded":"Snippet expanded","SnippetCollapsed":"Snippet collapsed"};

var _revMap$135 = {"Avo CLI docs link clicked":"AvoCLIDocsLinkClicked","Source switched":"SourceSwitched","Pull snippet copied":"PullSnippetCopied","Code snippet copied":"CodeSnippetCopied","Link to snippet copied":"LinkToSnippetCopied","Link to snippet opened":"LinkToSnippetOpened","Code snippet copied manually":"CodeSnippetCopiedManually","Snippet expanded":"SnippetExpanded","Snippet collapsed":"SnippetCollapsed"};

function eventImplementationSnippetInteractionTypeToJs(param) {
  return _map$178[param];
}

function eventImplementationSnippetInteractionTypeFromJs(param) {
  return _revMap$135[param];
}

var _map$179 = {"Bool":"bool","Float":"float","Int":"int","String":"string","Object":"object","Any":"any","Long":"long"};

var _revMap$136 = {"bool":"Bool","float":"Float","int":"Int","string":"String","object":"Object","any":"Any","long":"Long"};

function fromToJs(param) {
  return _map$179[param];
}

function fromFromJs(param) {
  return _revMap$136[param];
}

var _map$180 = {"Off":"Off","Warning":"Warning","Enforce":"Enforce"};

function issueReportConfigureActionToJs(param) {
  return param;
}

function issueReportConfigureActionFromJs(param) {
  return _map$180[param];
}

var _map$181 = {"Dismissed":"Dismissed","CreateWorkspace":"Create workspace","GoToMyWorkspace":"Go to my workspace","KeepExploring":"Keep exploring","LetsGo":"Let's go!","SignUp":"Sign up","WatchAvoDemo":"Watch Avo demo","ContinueAnyway":"Continue anyway"};

var _revMap$137 = {"Dismissed":"Dismissed","Create workspace":"CreateWorkspace","Go to my workspace":"GoToMyWorkspace","Keep exploring":"KeepExploring","Let's go!":"LetsGo","Sign up":"SignUp","Watch Avo demo":"WatchAvoDemo","Continue anyway":"ContinueAnyway"};

function sandboxInteractionToJs(param) {
  return _map$181[param];
}

function sandboxInteractionFromJs(param) {
  return _revMap$137[param];
}

var _map$182 = {"Open":"Open","Merged":"Merged","Deleted":"Deleted","ReadyForReview":"ReadyForReview","Approved":"Approved","ChangesRequested":"ChangesRequested"};

function branchStatusToJs(param) {
  return param;
}

function branchStatusFromJs(param) {
  return _map$182[param];
}

var _map$183 = {"Python":"Python","Swift":"Swift","Java":"Java","Reason":"Reason","JavaScript":"JavaScript","Kotlin":"Kotlin","CSharp":"C#","TypeScript":"TypeScript","ObjectiveMinusC":"Objective-C","JsonSchema":"Json Schema","Ruby":"Ruby","Php":"PHP","Python3":"Python3","Dart":"Dart","Go":"Go"};

var _revMap$138 = {"Python":"Python","Swift":"Swift","Java":"Java","Reason":"Reason","JavaScript":"JavaScript","Kotlin":"Kotlin","C#":"CSharp","TypeScript":"TypeScript","Objective-C":"ObjectiveMinusC","Json Schema":"JsonSchema","Ruby":"Ruby","PHP":"Php","Python3":"Python3","Dart":"Dart","Go":"Go"};

function programmingLanguageToJs(param) {
  return _map$183[param];
}

function programmingLanguageFromJs(param) {
  return _revMap$138[param];
}

var _map$184 = {"Public":"Public","Workspace":"Workspace"};

function branchImplementationViewAccessTypeToJs(param) {
  return param;
}

function branchImplementationViewAccessTypeFromJs(param) {
  return _map$184[param];
}

var _map$185 = {"ProductAnalytics":"Product analytics","DataScience":"Data science","DataAnalyticsEngineering":"Data/analytics engineering","ProductManagement":"Product management","Marketing":"Marketing","Other":"Other","SoftwareEngineering":"Software Engineering"};

var _revMap$139 = {"Product analytics":"ProductAnalytics","Data science":"DataScience","Data/analytics engineering":"DataAnalyticsEngineering","Product management":"ProductManagement","Marketing":"Marketing","Other":"Other","Software Engineering":"SoftwareEngineering"};

function jobFunctionToJs(param) {
  return _map$185[param];
}

function jobFunctionFromJs(param) {
  return _revMap$139[param];
}

var _map$186 = {"ListChecked":"List Checked","ListNotChecked":"List not Checked"};

var _revMap$140 = {"List Checked":"ListChecked","List not Checked":"ListNotChecked"};

function propertyListToggleBeforeToJs(param) {
  return _map$186[param];
}

function propertyListToggleBeforeFromJs(param) {
  return _revMap$140[param];
}

var _map$187 = {"Image":"Image","None":"None"};

function triggerContentTypeToJs(param) {
  return param;
}

function triggerContentTypeFromJs(param) {
  return _map$187[param];
}

var _map$188 = {"ImplementAnalytics":"Implement analytics","DetectDataAnomalies":"Detect data anomalies","SetProductMinusrelatedGoalsAndMetrics":"Set product-related goals and metrics","Other":"Other","DefineEventDataStructures":"Define event data structures","ReviewEventDataStructures":"Review event data structures"};

var _revMap$141 = {"Implement analytics":"ImplementAnalytics","Detect data anomalies":"DetectDataAnomalies","Set product-related goals and metrics":"SetProductMinusrelatedGoalsAndMetrics","Other":"Other","Define event data structures":"DefineEventDataStructures","Review event data structures":"ReviewEventDataStructures"};

function trackingContributionToJs(param) {
  return _map$188[param];
}

function trackingContributionFromJs(param) {
  return _revMap$141[param];
}

var _map$189 = {"SeedFundingAnnouncement":"Seed funding announcement","LaunchOfInspector":"launch of Inspector","RegisterNow":"Register now!","Announcement":"Announcement"};

var _revMap$142 = {"Seed funding announcement":"SeedFundingAnnouncement","launch of Inspector":"LaunchOfInspector","Register now!":"RegisterNow","Announcement":"Announcement"};

function linkNameToJs(param) {
  return _map$189[param];
}

function linkNameFromJs(param) {
  return _revMap$142[param];
}

var _map$190 = {"Scholarship":"Scholarship","Growth":"Growth","Enterprise":"Enterprise","Free":"Free","Starter":"Starter","Team":"Team"};

function schemaTrialPlanToJs(param) {
  return param;
}

function schemaTrialPlanFromJs(param) {
  return _map$190[param];
}

var _map$191 = {"Source":"Source","PropertyGroup":"Property Group","CodeSnippet":"Code Snippet","Releases":"Releases"};

var _revMap$143 = {"Source":"Source","Property Group":"PropertyGroup","Code Snippet":"CodeSnippet","Releases":"Releases"};

function collapsibleItemTypeToJs(param) {
  return _map$191[param];
}

function collapsibleItemTypeFromJs(param) {
  return _revMap$143[param];
}

var _map$192 = {"UpTo20People":"Up to 20 people","_21Minus50People":"21-50 people","_51Minus100People":"51-100 people","_101Minus500People":"101-500 people","_501Minus1000People":"501-1000 people","_1001Minus5000People":"1001-5000 people","MoreThan5000People":"More than 5000 people"};

var _revMap$144 = {"Up to 20 people":"UpTo20People","21-50 people":"_21Minus50People","51-100 people":"_51Minus100People","101-500 people":"_101Minus500People","501-1000 people":"_501Minus1000People","1001-5000 people":"_1001Minus5000People","More than 5000 people":"MoreThan5000People"};

function workspaceCompanySizeToJs(param) {
  return _map$192[param];
}

function workspaceCompanySizeFromJs(param) {
  return _revMap$144[param];
}

var _map$193 = {"Onboarding":"Onboarding","TrialOnboarding":"Trial Onboarding","GettingStarted":"Getting Started"};

var _revMap$145 = {"Onboarding":"Onboarding","Trial Onboarding":"TrialOnboarding","Getting Started":"GettingStarted"};

function checklistTypeToJs(param) {
  return _map$193[param];
}

function checklistTypeFromJs(param) {
  return _revMap$145[param];
}

var _map$194 = {"Web":"Web","Desktop":"Desktop","Backend":"Backend","IOS":"iOS","Android":"Android","CrossMinusplatform":"Cross-platform","GameEngine":"Game Engine","Other":"Other"};

var _revMap$146 = {"Web":"Web","Desktop":"Desktop","Backend":"Backend","iOS":"IOS","Android":"Android","Cross-platform":"CrossMinusplatform","Game Engine":"GameEngine","Other":"Other"};

function sourceCategoryToJs(param) {
  return _map$194[param];
}

function sourceCategoryFromJs(param) {
  return _revMap$146[param];
}

var _map$195 = {"NoAccess":"No Access","UnlimitedAccess":"Unlimited Access"};

var _revMap$147 = {"No Access":"NoAccess","Unlimited Access":"UnlimitedAccess"};

function avoIntelligenceAccessTypeToJs(param) {
  return _map$195[param];
}

function avoIntelligenceAccessTypeFromJs(param) {
  return _revMap$147[param];
}

var _map$196 = {"Free":"Free","Scholarship":"Scholarship","Growth":"Growth","Enterprise":"Enterprise","Starter":"Starter","Team":"Team"};

function workspaceTrialPlanToJs(param) {
  return param;
}

function workspaceTrialPlanFromJs(param) {
  return _map$196[param];
}

var _map$197 = {"Event":"Event"};

function propertyAttributeToJs(param) {
  return param;
}

function propertyAttributeFromJs(param) {
  return _map$197[param];
}

var _map$198 = {"NoConflictingProperties":"No conflicting properties","UniqueEventNames":"Unique event names","ConsistentEventNameCasing":"Consistent event name casing","PreferredEventNameCasing":"Preferred event name casing","UniquePropertyNames":"Unique property names","ConsistentPropertyNameCasing":"Consistent property name casing","PreferredPropertyNameCasing":"Preferred property name casing","AllPropertiesHaveTypes":"All properties have types","AllEventsHaveDescriptions":"All events have descriptions","AllPropertiesHaveDescriptions":"All properties have descriptions","ForceEventCasing":"Force event casing","ForcePropertyCasing":"Force property casing","BranchEnforcementStatus":"Branch Enforcement Status"};

var _revMap$148 = {"No conflicting properties":"NoConflictingProperties","Unique event names":"UniqueEventNames","Consistent event name casing":"ConsistentEventNameCasing","Preferred event name casing":"PreferredEventNameCasing","Unique property names":"UniquePropertyNames","Consistent property name casing":"ConsistentPropertyNameCasing","Preferred property name casing":"PreferredPropertyNameCasing","All properties have types":"AllPropertiesHaveTypes","All events have descriptions":"AllEventsHaveDescriptions","All properties have descriptions":"AllPropertiesHaveDescriptions","Force event casing":"ForceEventCasing","Force property casing":"ForcePropertyCasing","Branch Enforcement Status":"BranchEnforcementStatus"};

function issueReportConfigureItemToJs(param) {
  return _map$198[param];
}

function issueReportConfigureItemFromJs(param) {
  return _revMap$148[param];
}

var _map$199 = {"Team":"Team"};

function planToTrialToJs(param) {
  return param;
}

function planToTrialFromJs(param) {
  return _map$199[param];
}

var _map$200 = {"OrderView":"Order view","FilterView":"Filter view","RemoveFilter":"Remove filter","SelectIssueStatus":"Select issue status"};

var _revMap$149 = {"Order view":"OrderView","Filter view":"FilterView","Remove filter":"RemoveFilter","Select issue status":"SelectIssueStatus"};

function inspectorIssuesConfigureActionToJs(param) {
  return _map$200[param];
}

function inspectorIssuesConfigureActionFromJs(param) {
  return _revMap$149[param];
}

var _map$201 = {"Markdown":"Markdown","JiraMarkdown":"Jira Markdown"};

var _revMap$150 = {"Markdown":"Markdown","Jira Markdown":"JiraMarkdown"};

function implementationInstructionFormatToJs(param) {
  return _map$201[param];
}

function implementationInstructionFormatFromJs(param) {
  return _revMap$150[param];
}

var _map$202 = {"Slack":"Slack","Other":"Other"};

function inspectorAlertTypeToJs(param) {
  return param;
}

function inspectorAlertTypeFromJs(param) {
  return _map$202[param];
}

var _map$203 = {"IncreaseColumnWidth":"Increase Column Width","DecreaseColumnWidth":"Decrease Column Width","SetViewAsList":"Set View as List","SetViewAsGroupedByCategory":"Set View as Grouped By Category","ShowColumn":"Show Column","HideColumn":"Hide Column","Reset":"Reset","Reorder":"Reorder"};

var _revMap$151 = {"Increase Column Width":"IncreaseColumnWidth","Decrease Column Width":"DecreaseColumnWidth","Set View as List":"SetViewAsList","Set View as Grouped By Category":"SetViewAsGroupedByCategory","Show Column":"ShowColumn","Hide Column":"HideColumn","Reset":"Reset","Reorder":"Reorder"};

function eventsViewConfiguredActionToJs(param) {
  return _map$203[param];
}

function eventsViewConfiguredActionFromJs(param) {
  return _revMap$151[param];
}

var _map$204 = {"Codegen":"Codegen","Manual":"Manual","All":"All"};

function implementationMethodToJs(param) {
  return param;
}

function implementationMethodFromJs(param) {
  return _map$204[param];
}

var _map$205 = {"SometimesSent":"Sometimes Sent","AlwaysSent":"Always Sent"};

var _revMap$152 = {"Sometimes Sent":"SometimesSent","Always Sent":"AlwaysSent"};

function propertyPresenceWhenInObjectToggleBeforeToJs(param) {
  return _map$205[param];
}

function propertyPresenceWhenInObjectToggleBeforeFromJs(param) {
  return _revMap$152[param];
}

var _map$206 = {"Inherit":"Inherit","SentenceCase":"Sentence Case","TitleCase":"Title Case","PascalCase":"Pascal Case","HeaderCase":"Header Case","CapitalCase":"Capital Case","UpperCase":"Upper Case","KebabCase":"Kebab Case","CamelCase":"Camel Case","ConstantCase":"Constant Case","SnakeCase":"Snake Case","LowerCase":"Lower Case","Separator":"Separator"};

var _revMap$153 = {"Inherit":"Inherit","Sentence Case":"SentenceCase","Title Case":"TitleCase","Pascal Case":"PascalCase","Header Case":"HeaderCase","Capital Case":"CapitalCase","Upper Case":"UpperCase","Kebab Case":"KebabCase","Camel Case":"CamelCase","Constant Case":"ConstantCase","Snake Case":"SnakeCase","Lower Case":"LowerCase","Separator":"Separator"};

function advancedEventNameComponentCasingsToJs(param) {
  return _map$206[param];
}

function advancedEventNameComponentCasingsFromJs(param) {
  return _revMap$153[param];
}

var _map$207 = {"EventVariant":"Event Variant","Event":"Event"};

var _revMap$154 = {"Event Variant":"EventVariant","Event":"Event"};

function eventTypeToJs(param) {
  return _map$207[param];
}

function eventTypeFromJs(param) {
  return _revMap$154[param];
}

var _map$208 = {"LiveData":"Live Data","Manual":"Manual"};

var _revMap$155 = {"Live Data":"LiveData","Manual":"Manual"};

function checklistPathToJs(param) {
  return _map$208[param];
}

function checklistPathFromJs(param) {
  return _revMap$155[param];
}

var _map$209 = {"Dismiss":"Dismiss","ClickLink":"Click Link"};

var _revMap$156 = {"Dismiss":"Dismiss","Click Link":"ClickLink"};

function overlayItemInteractionTypeToJs(param) {
  return _map$209[param];
}

function overlayItemInteractionTypeFromJs(param) {
  return _revMap$156[param];
}

var _map$210 = {"Creator":"Creator","Visitor":"Visitor"};

function inspectorSavedViewRelationshipToJs(param) {
  return param;
}

function inspectorSavedViewRelationshipFromJs(param) {
  return _map$210[param];
}

var _map$211 = {"Authenticated":"Authenticated","Anonymous":"Anonymous"};

function userAuthenticationStatusToJs(param) {
  return param;
}

function userAuthenticationStatusFromJs(param) {
  return _map$211[param];
}

var _map$212 = {"EditingDisabled":"Editing Disabled","AuthenticationMethods":"Authentication Methods","AddComment":"Add Comment","AddDestinations":"Add Destinations","InspectorTimeWindow":"Inspector Time Window","InviteMembers":"Invite Members","AddSources":"Add Sources","ConfigureEventsPerPlatform":"Configure Events Per Platform","InviteCommentOnly":"Invite Comment Only","ChangeMemberRole":"Change Member Role","AddEventTrigger":"Add Event Trigger","AddBranchCollaborator":"Add Branch Collaborator","OpenAuditLog":"Open Audit Log","ExpandActivityLog":"Expand Activity Log","PublishIntegration":"Publish Integration","AddIntegration":"Add Integration","BranchApprovalWorkflows":"Branch Approval Workflows","ImplementWithAvo":"Implement With Avo","IssueReportConfig":"Issue Report Config","IssueReportConfigForceCasing":"Issue Report Config Force Casing","TryForFree":"Try For Free","PropertyMatchesBySource":"Property Matches by Source","PinnedProperties":"Pinned Properties","NameMapping":"Name Mapping","DuplicateEvents":"Duplicate Events","ExpandBranchAudit":"Expand Branch Audit","ForceBranchAudit":"Force Branch Audit","AddServiceAccount":"Add Service Account","InspectorVolume":"Inspector Volume","AddEventVariant":"Add Event Variant"};

var _revMap$157 = {"Editing Disabled":"EditingDisabled","Authentication Methods":"AuthenticationMethods","Add Comment":"AddComment","Add Destinations":"AddDestinations","Inspector Time Window":"InspectorTimeWindow","Invite Members":"InviteMembers","Add Sources":"AddSources","Configure Events Per Platform":"ConfigureEventsPerPlatform","Invite Comment Only":"InviteCommentOnly","Change Member Role":"ChangeMemberRole","Add Event Trigger":"AddEventTrigger","Add Branch Collaborator":"AddBranchCollaborator","Open Audit Log":"OpenAuditLog","Expand Activity Log":"ExpandActivityLog","Publish Integration":"PublishIntegration","Add Integration":"AddIntegration","Branch Approval Workflows":"BranchApprovalWorkflows","Implement With Avo":"ImplementWithAvo","Issue Report Config":"IssueReportConfig","Issue Report Config Force Casing":"IssueReportConfigForceCasing","Try For Free":"TryForFree","Property Matches by Source":"PropertyMatchesBySource","Pinned Properties":"PinnedProperties","Name Mapping":"NameMapping","Duplicate Events":"DuplicateEvents","Expand Branch Audit":"ExpandBranchAudit","Force Branch Audit":"ForceBranchAudit","Add Service Account":"AddServiceAccount","Inspector Volume":"InspectorVolume","Add Event Variant":"AddEventVariant"};

function paywallNameToJs(param) {
  return _map$212[param];
}

function paywallNameFromJs(param) {
  return _revMap$157[param];
}

var _map$213 = {"Rename":"Rename","AddRule":"Add Rule","RemoveRule":"Remove Rule","UpdateRule":"Update Rule"};

var _revMap$158 = {"Rename":"Rename","Add Rule":"AddRule","Remove Rule":"RemoveRule","Update Rule":"UpdateRule"};

function propertyUpdateActionToJs(param) {
  return _map$213[param];
}

function propertyUpdateActionFromJs(param) {
  return _revMap$158[param];
}

var _map$214 = {"Sidebar":"Sidebar","BranchReview":"Branch Review","InlineReview":"Inline Review"};

var _revMap$159 = {"Sidebar":"Sidebar","Branch Review":"BranchReview","Inline Review":"InlineReview"};

function trackingPlanAuditLocationToJs(param) {
  return _map$214[param];
}

function trackingPlanAuditLocationFromJs(param) {
  return _revMap$159[param];
}

var _map$215 = {"All":"All","Event":"Event","User":"User","System":"System","Group":"Group"};

function propertiesViewSegmentationAfterToJs(param) {
  return param;
}

function propertiesViewSegmentationAfterFromJs(param) {
  return _map$215[param];
}

var _map$216 = {"String":"String","Int":"Int","Float":"Float","Long":"Long","Boolean":"Boolean","Object":"Object"};

function propertyValueTypeAfterToJs(param) {
  return param;
}

function propertyValueTypeAfterFromJs(param) {
  return _map$216[param];
}

var _map$217 = {"EventProperty":"Event Property","Name":"Name","Description":"Description","Category":"Category","PropertyGroup":"Property Group","Tag":"Tag","Action":"Action","Source":"Source","Destination":"Destination","Metric":"Metric","GroupProperty":"Group Property"};

var _revMap$160 = {"Event Property":"EventProperty","Name":"Name","Description":"Description","Category":"Category","Property Group":"PropertyGroup","Tag":"Tag","Action":"Action","Source":"Source","Destination":"Destination","Metric":"Metric","Group Property":"GroupProperty"};

function eventAttributeToJs(param) {
  return _map$217[param];
}

function eventAttributeFromJs(param) {
  return _revMap$160[param];
}

var _map$218 = {"EventName":"Event Name","Category":"Category","PropertyGroups":"Property Groups","EventProperties":"Event Properties","Sources":"Sources","Actions":"Actions","Tags":"Tags","Destinations":"Destinations","Metrics":"Metrics","GroupProperties":"Group Properties"};

var _revMap$161 = {"Event Name":"EventName","Category":"Category","Property Groups":"PropertyGroups","Event Properties":"EventProperties","Sources":"Sources","Actions":"Actions","Tags":"Tags","Destinations":"Destinations","Metrics":"Metrics","Group Properties":"GroupProperties"};

function columnsVisibleAfterChangeToJs(param) {
  return _map$218[param];
}

function columnsVisibleAfterChangeFromJs(param) {
  return _revMap$161[param];
}

var _map$219 = {"ColumnWidth":"Column Width","View":"View","ColumnVisibility":"Column Visibility"};

var _revMap$162 = {"Column Width":"ColumnWidth","View":"View","Column Visibility":"ColumnVisibility"};

function eventsViewConfiguredItemToJs(param) {
  return _map$219[param];
}

function eventsViewConfiguredItemFromJs(param) {
  return _revMap$162[param];
}

var _map$220 = {"Page":"Page","Alias":"Alias","Revenue":"Revenue","Unidentify":"Unidentify","Identify":"Identify","AssociateEventWithGroup":"Associate Event with Group","AssociateUserWithGroup":"Associate User with Group","UpdateGroupProperties":"Update Group Properties","LogEvent":"Log Event"};

var _revMap$163 = {"Page":"Page","Alias":"Alias","Revenue":"Revenue","Unidentify":"Unidentify","Identify":"Identify","Associate Event with Group":"AssociateEventWithGroup","Associate User with Group":"AssociateUserWithGroup","Update Group Properties":"UpdateGroupProperties","Log Event":"LogEvent"};

function eventActionsIncludedToJs(param) {
  return _map$220[param];
}

function eventActionsIncludedFromJs(param) {
  return _revMap$163[param];
}

var _map$221 = {"ClickBackButton":"Click back button","CloseIssueDetails":"Close issue details","CopyIssueLink":"Copy issue link","ClickAddEventToTrackingPlan":"Click add event to tracking plan","ClickAddPropertyToTrackingPlan":"Click add property to tracking plan","ClickViewEventInTrackingPlan":"Click view event in tracking plan","ClickViewPropertyInTrackingPlan":"Click view property in tracking plan","ClickRelevantIssue":"Click relevant issue","OpenIssueDetails":"Open issue details","ClickViewSourceInTrackingPlan":"Click view source in tracking plan","ClickViewCategoryInTrackingPlan":"Click view category in tracking plan","UpdateIssueStatus":"Update issue status","ClickViewEventVariantInTrackingPlan":"Click view event variant in tracking plan"};

var _revMap$164 = {"Click back button":"ClickBackButton","Close issue details":"CloseIssueDetails","Copy issue link":"CopyIssueLink","Click add event to tracking plan":"ClickAddEventToTrackingPlan","Click add property to tracking plan":"ClickAddPropertyToTrackingPlan","Click view event in tracking plan":"ClickViewEventInTrackingPlan","Click view property in tracking plan":"ClickViewPropertyInTrackingPlan","Click relevant issue":"ClickRelevantIssue","Open issue details":"OpenIssueDetails","Click view source in tracking plan":"ClickViewSourceInTrackingPlan","Click view category in tracking plan":"ClickViewCategoryInTrackingPlan","Update issue status":"UpdateIssueStatus","Click view event variant in tracking plan":"ClickViewEventVariantInTrackingPlan"};

function inspectorIssueDetailsInteractionTypeToJs(param) {
  return _map$221[param];
}

function inspectorIssueDetailsInteractionTypeFromJs(param) {
  return _revMap$164[param];
}

var _map$222 = {"On":"On","Off":"Off"};

function settingValueToJs(param) {
  return param;
}

function settingValueFromJs(param) {
  return _map$222[param];
}

var _map$223 = {"Success":"Success","Error":"Error"};

function importParserResultToJs(param) {
  return param;
}

function importParserResultFromJs(param) {
  return _map$223[param];
}

var _map$224 = {"Inherited":"Inherited","SentenceCase":"Sentence Case","TitleCase":"Title Case","PascalCase":"Pascal Case","HeaderCase":"Header Case","CapitalCase":"Capital Case","UpperCase":"Upper Case","KebabCase":"Kebab Case","CamelCase":"Camel Case","ConstantCase":"Constant Case","SnakeCase":"Snake Case","LowerCase":"Lower Case"};

var _revMap$165 = {"Inherited":"Inherited","Sentence Case":"SentenceCase","Title Case":"TitleCase","Pascal Case":"PascalCase","Header Case":"HeaderCase","Capital Case":"CapitalCase","Upper Case":"UpperCase","Kebab Case":"KebabCase","Camel Case":"CamelCase","Constant Case":"ConstantCase","Snake Case":"SnakeCase","Lower Case":"LowerCase"};

function workspaceIssueReportPropertyCasingToJs(param) {
  return _map$224[param];
}

function workspaceIssueReportPropertyCasingFromJs(param) {
  return _revMap$165[param];
}

var _map$225 = {"AmplitudeGovern":"Amplitude Govern","MixpanelLexicon":"Mixpanel Lexicon","SegmentProtocols":"Segment Protocols","Webhook":"Webhook","MParticleDataMaster":"mParticle Data Master","RudderstackTrackingPlans":"Rudderstack Tracking Plans","SnowplowDataStructures":"Snowplow Data Structures"};

var _revMap$166 = {"Amplitude Govern":"AmplitudeGovern","Mixpanel Lexicon":"MixpanelLexicon","Segment Protocols":"SegmentProtocols","Webhook":"Webhook","mParticle Data Master":"MParticleDataMaster","Rudderstack Tracking Plans":"RudderstackTrackingPlans","Snowplow Data Structures":"SnowplowDataStructures"};

function integrationTypeToJs(param) {
  return _map$225[param];
}

function integrationTypeFromJs(param) {
  return _revMap$166[param];
}

var _map$226 = {"Events":"Events","Properties":"Properties","PropertyGroups":"Property Groups","Metrics":"Metrics","Publishing":"Publishing","Codegen":"Codegen","BranchesOverview":"Branches Overview","InspectorIssues":"Inspector Issues","InspectorEvents":"Inspector Events"};

var _revMap$167 = {"Events":"Events","Properties":"Properties","Property Groups":"PropertyGroups","Metrics":"Metrics","Publishing":"Publishing","Codegen":"Codegen","Branches Overview":"BranchesOverview","Inspector Issues":"InspectorIssues","Inspector Events":"InspectorEvents"};

function emptyStateLocationToJs(param) {
  return _map$226[param];
}

function emptyStateLocationFromJs(param) {
  return _revMap$167[param];
}

var _map$227 = {"InspectorSidebar":"Inspector Sidebar","InspectorTable":"Inspector Table","ActivityLog":"Activity Log","NewQueryParameter":"New Query Parameter","CommandPalette":"Command Palette","EventsView":"Events View","OnboardingStep":"Onboarding Step","PropertyDetails":"Property Details","MetricDetails":"Metric Details","CategoryDetails":"Category Details","InspectorIssueDetails":"Inspector Issue Details"};

var _revMap$168 = {"Inspector Sidebar":"InspectorSidebar","Inspector Table":"InspectorTable","Activity Log":"ActivityLog","New Query Parameter":"NewQueryParameter","Command Palette":"CommandPalette","Events View":"EventsView","Onboarding Step":"OnboardingStep","Property Details":"PropertyDetails","Metric Details":"MetricDetails","Category Details":"CategoryDetails","Inspector Issue Details":"InspectorIssueDetails"};

function eventOriginToJs(param) {
  return _map$227[param];
}

function eventOriginFromJs(param) {
  return _revMap$168[param];
}

var _map$228 = {"Collapsed":"Collapsed","Expanded":"Expanded"};

function collapseStateToJs(param) {
  return param;
}

function collapseStateFromJs(param) {
  return _map$228[param];
}

var _map$229 = {"UpTo20People":"Up to 20 people","_21Minus50People":"21-50 people","_51Minus100People":"51-100 people","_101Minus500People":"101-500 people","_501Minus1000People":"501-1000 people","_1001Minus5000People":"1001-5000 people","MoreThan5000People":"More than 5000 people"};

var _revMap$169 = {"Up to 20 people":"UpTo20People","21-50 people":"_21Minus50People","51-100 people":"_51Minus100People","101-500 people":"_101Minus500People","501-1000 people":"_501Minus1000People","1001-5000 people":"_1001Minus5000People","More than 5000 people":"MoreThan5000People"};

function onboardingCompanySizeToJs(param) {
  return _map$229[param];
}

function onboardingCompanySizeFromJs(param) {
  return _revMap$169[param];
}

var _map$230 = {"Loading":"Loading","InspectorNotInstalled":"Inspector not installed","NotImplementedWithFunctions":"Not implemented with Functions","AlwaysSentCorrectly":"Always sent correctly","SometimesSentCorrectly":"Sometimes sent correctly","NeverSentCorrectly":"Never sent correctly","AlwaysSentCorrectlyInPreviousVersion":"Always sent correctly in previous version","SometimesSentCorrectlyInPreviousVersion":"Sometimes sent correctly in previous version","NeverSentCorrectlyInPreviousVersion":"Never sent correctly in previous version","NeverSeen":"Never seen"};

var _revMap$170 = {"Loading":"Loading","Inspector not installed":"InspectorNotInstalled","Not implemented with Functions":"NotImplementedWithFunctions","Always sent correctly":"AlwaysSentCorrectly","Sometimes sent correctly":"SometimesSentCorrectly","Never sent correctly":"NeverSentCorrectly","Always sent correctly in previous version":"AlwaysSentCorrectlyInPreviousVersion","Sometimes sent correctly in previous version":"SometimesSentCorrectlyInPreviousVersion","Never sent correctly in previous version":"NeverSentCorrectlyInPreviousVersion","Never seen":"NeverSeen"};

function highlightedImplementationStatusStateToJs(param) {
  return _map$230[param];
}

function highlightedImplementationStatusStateFromJs(param) {
  return _revMap$170[param];
}

var _map$231 = {"MenuOpened":"Menu Opened","MenuClosed":"Menu Closed","BranchChosen":"Branch Chosen","SearchTermEntered":"Search term entered"};

var _revMap$171 = {"Menu Opened":"MenuOpened","Menu Closed":"MenuClosed","Branch Chosen":"BranchChosen","Search term entered":"SearchTermEntered"};

function activityLogMenuInteractionToJs(param) {
  return _map$231[param];
}

function activityLogMenuInteractionFromJs(param) {
  return _revMap$171[param];
}

var _map$232 = {"UpgradeNow":"Upgrade now","Dismiss":"Dismiss","StartTrial":"Start Trial"};

var _revMap$172 = {"Upgrade now":"UpgradeNow","Dismiss":"Dismiss","Start Trial":"StartTrial"};

function upgradePromptReactionToJs(param) {
  return _map$232[param];
}

function upgradePromptReactionFromJs(param) {
  return _revMap$172[param];
}

var _map$233 = {"NameUpdated":"Name Updated","EventAdded":"Event Added","EventRemoved":"Event Removed","DescriptionUpdated":"Description Updated","MetricTypeChanged":"Metric Type Changed"};

var _revMap$173 = {"Name Updated":"NameUpdated","Event Added":"EventAdded","Event Removed":"EventRemoved","Description Updated":"DescriptionUpdated","Metric Type Changed":"MetricTypeChanged"};

function metricUpdateActionToJs(param) {
  return _map$233[param];
}

function metricUpdateActionFromJs(param) {
  return _revMap$173[param];
}

var _map$234 = {"Suggestion":"Suggestion","Warning":"Warning","Error":"Error"};

function feedbackTypeToJs(param) {
  return param;
}

function feedbackTypeFromJs(param) {
  return _map$234[param];
}

var _map$235 = {"FreeInput":"Free Input","AllowedValues":"Allowed Values","Separator":"Separator"};

var _revMap$174 = {"Free Input":"FreeInput","Allowed Values":"AllowedValues","Separator":"Separator"};

function advancedEventNameComponentTypesToJs(param) {
  return _map$235[param];
}

function advancedEventNameComponentTypesFromJs(param) {
  return _revMap$174[param];
}

var _map$236 = {"Unresolved":"Unresolved","Ignored":"Ignored","Resolved":"Resolved"};

function inspectorIssueStatusToJs(param) {
  return param;
}

function inspectorIssueStatusFromJs(param) {
  return _map$236[param];
}

var _map$237 = {"Inherited":"Inherited","SentenceCase":"Sentence Case","TitleCase":"Title Case","PascalCase":"Pascal Case","HeaderCase":"Header Case","CapitalCase":"Capital Case","UpperCase":"Upper Case","KebabCase":"Kebab Case","CamelCase":"Camel Case","ConstantCase":"Constant Case","SnakeCase":"Snake Case","LowerCase":"Lower Case"};

var _revMap$175 = {"Inherited":"Inherited","Sentence Case":"SentenceCase","Title Case":"TitleCase","Pascal Case":"PascalCase","Header Case":"HeaderCase","Capital Case":"CapitalCase","Upper Case":"UpperCase","Kebab Case":"KebabCase","Camel Case":"CamelCase","Constant Case":"ConstantCase","Snake Case":"SnakeCase","Lower Case":"LowerCase"};

function issueReportConfigureCasingToJs(param) {
  return _map$237[param];
}

function issueReportConfigureCasingFromJs(param) {
  return _revMap$175[param];
}

var _map$238 = {"None":"None","Sources":"Sources","Destinations":"Destinations","Tags":"Tags"};

function integrationFiltersToJs(param) {
  return param;
}

function integrationFiltersFromJs(param) {
  return _map$238[param];
}

var _map$239 = {"EventsList":"Events List","Sources":"Sources"};

var _revMap$176 = {"Events List":"EventsList","Sources":"Sources"};

function inspectorViewToJs(param) {
  return _map$239[param];
}

function inspectorViewFromJs(param) {
  return _revMap$176[param];
}

var _map$240 = {"Collapsed":"Collapsed","Expanded":"Expanded","CodeChangesLinkClicked":"Code Changes Link Clicked","CodeChangesEventLinkClicked":"Code Changes Event Link Clicked"};

var _revMap$177 = {"Collapsed":"Collapsed","Expanded":"Expanded","Code Changes Link Clicked":"CodeChangesLinkClicked","Code Changes Event Link Clicked":"CodeChangesEventLinkClicked"};

function codeChangesAuditInteractionTypeToJs(param) {
  return _map$240[param];
}

function codeChangesAuditInteractionTypeFromJs(param) {
  return _revMap$177[param];
}

var _map$241 = {"Documentation":"Documentation","ProductTip":"Product tip"};

var _revMap$178 = {"Documentation":"Documentation","Product tip":"ProductTip"};

function tooltipPurposeToJs(param) {
  return _map$241[param];
}

function tooltipPurposeFromJs(param) {
  return _revMap$178[param];
}

var _map$242 = {"ClickOutside":"Click Outside","PressEscapeKey":"Press Escape Key","PressCancelButton":"Press Cancel Button","PressConfirmButton":"Press Confirm Button","PressEnterKey":"Press Enter Key"};

var _revMap$179 = {"Click Outside":"ClickOutside","Press Escape Key":"PressEscapeKey","Press Cancel Button":"PressCancelButton","Press Confirm Button":"PressConfirmButton","Press Enter Key":"PressEnterKey"};

function closingMethodToJs(param) {
  return _map$242[param];
}

function closingMethodFromJs(param) {
  return _revMap$179[param];
}

var _map$243 = {"BookOnboarding11":"Book Onboarding 1:1","OpenChat":"Open Chat","OpenDocs":"Open Docs","OpenSandbox":"Open Sandbox"};

var _revMap$180 = {"Book Onboarding 1:1":"BookOnboarding11","Open Chat":"OpenChat","Open Docs":"OpenDocs","Open Sandbox":"OpenSandbox"};

function onboardingChecklistInteractionToJs(param) {
  return _map$243[param];
}

function onboardingChecklistInteractionFromJs(param) {
  return _revMap$180[param];
}

var _map$244 = {"ReadDocs":"Read docs","TakeTour":"Take tour"};

var _revMap$181 = {"Read docs":"ReadDocs","Take tour":"TakeTour"};

function learnHubContentCallToActionsToJs(param) {
  return _map$244[param];
}

function learnHubContentCallToActionsFromJs(param) {
  return _revMap$181[param];
}

var _map$245 = {"Free":"free","Freev2":"freev2","Starter":"starter","Scholarship":"scholarship","Growthv2":"growthv2","Growth":"growth","Enterprise":"enterprise","Enterprisev2":"enterprisev2","Teamv2":"teamv2","Freev3":"freev3","Teamv3":"teamv3"};

var _revMap$182 = {"free":"Free","freev2":"Freev2","starter":"Starter","scholarship":"Scholarship","growthv2":"Growthv2","growth":"Growth","enterprise":"Enterprise","enterprisev2":"Enterprisev2","teamv2":"Teamv2","freev3":"Freev3","teamv3":"Teamv3"};

function schemaTrialPlanSlugToJs(param) {
  return _map$245[param];
}

function schemaTrialPlanSlugFromJs(param) {
  return _revMap$182[param];
}

var _map$246 = {"FirstEvent":"First Event","NewBranchModal":"New Branch Modal","Import":"Import"};

var _revMap$183 = {"First Event":"FirstEvent","New Branch Modal":"NewBranchModal","Import":"Import"};

function branchOriginToJs(param) {
  return _map$246[param];
}

function branchOriginFromJs(param) {
  return _revMap$183[param];
}

var _map$247 = {"GroupProperties":"Group Properties","PropertyType":"Property Type","PropertyName":"Property Name","ValueIsList":"Value is List","Events":"Events","EventPropertyGroup":"Event Property Group","NameMapping":"Name Mapping","PropertyEvents":"Property Events","PropertyConstraints":"Property Constraints","PropertyPresence":"Property Presence"};

var _revMap$184 = {"Group Properties":"GroupProperties","Property Type":"PropertyType","Property Name":"PropertyName","Value is List":"ValueIsList","Events":"Events","Event Property Group":"EventPropertyGroup","Name Mapping":"NameMapping","Property Events":"PropertyEvents","Property Constraints":"PropertyConstraints","Property Presence":"PropertyPresence"};

function propertiesViewColumnNameToJs(param) {
  return _map$247[param];
}

function propertiesViewColumnNameFromJs(param) {
  return _revMap$184[param];
}

var _map$248 = {"WelcomeModal":"Welcome Modal","ProgressModal":"Progress Modal","Banner":"Banner","LimitationModal":"Limitation Modal","TinyScreenAlert":"Tiny Screen Alert"};

var _revMap$185 = {"Welcome Modal":"WelcomeModal","Progress Modal":"ProgressModal","Banner":"Banner","Limitation Modal":"LimitationModal","Tiny Screen Alert":"TinyScreenAlert"};

function sandboxElementToJs(param) {
  return _map$248[param];
}

function sandboxElementFromJs(param) {
  return _revMap$185[param];
}

var _map$249 = {"AlwaysSent":"Always Sent","SometimesSent":"Sometimes Sent","NeverSent":"Never Sent","DependingOnSource":"Depending on Source"};

var _revMap$186 = {"Always Sent":"AlwaysSent","Sometimes Sent":"SometimesSent","Never Sent":"NeverSent","Depending on Source":"DependingOnSource"};

function propertyPresenceTypeBeforeToJs(param) {
  return _map$249[param];
}

function propertyPresenceTypeBeforeFromJs(param) {
  return _revMap$186[param];
}

var _map$250 = {"Web":"Web","IOS":"iOS","MacOS":"macOS","Android":"Android","ReactNative":"React Native","Expo":"Expo","NodeJs":"Node.js","Python":"Python","Php":"PHP","Unity":"Unity","Ruby":"Ruby","Net":".NET","Java":"Java","Flutter":"Flutter","Go":"Go","Other":"Other","JSONSchema":"JSON Schema","GoogleTagManager":"Google Tag Manager"};

var _revMap$187 = {"Web":"Web","iOS":"IOS","macOS":"MacOS","Android":"Android","React Native":"ReactNative","Expo":"Expo","Node.js":"NodeJs","Python":"Python","PHP":"Php","Unity":"Unity","Ruby":"Ruby",".NET":"Net","Java":"Java","Flutter":"Flutter","Go":"Go","Other":"Other","JSON Schema":"JSONSchema","Google Tag Manager":"GoogleTagManager"};

function sourceTypeToJs(param) {
  return _map$250[param];
}

function sourceTypeFromJs(param) {
  return _revMap$187[param];
}

var _map$251 = {"SearchForEvent":"Search for Event","OrderByEventVolume":"Order by Event Volume","OrderByIssues":"Order by Issues","OrderBySourceIssues":"Order by Source Issues","OrderBySourceEventVolume":"Order by Source Event Volume","Environment":"Environment","TimeWindow":"Time Window","OrderByEventName":"Order by Event Name"};

var _revMap$188 = {"Search for Event":"SearchForEvent","Order by Event Volume":"OrderByEventVolume","Order by Issues":"OrderByIssues","Order by Source Issues":"OrderBySourceIssues","Order by Source Event Volume":"OrderBySourceEventVolume","Environment":"Environment","Time Window":"TimeWindow","Order by Event Name":"OrderByEventName"};

function inspectorFilterTypeUpdatedToJs(param) {
  return _map$251[param];
}

function inspectorFilterTypeUpdatedFromJs(param) {
  return _revMap$188[param];
}

var _map$252 = {"Automatic":"Automatic","Manual":"Manual","OnHover":"On hover"};

var _revMap$189 = {"Automatic":"Automatic","Manual":"Manual","On hover":"OnHover"};

function tooltipDisplayMethodToJs(param) {
  return _map$252[param];
}

function tooltipDisplayMethodFromJs(param) {
  return _revMap$189[param];
}

var _map$253 = {"EventName":"Event Name","Category":"Category","PropertyGroups":"Property Groups","EventProperties":"Event Properties","Sources":"Sources","Actions":"Actions","Tags":"Tags","Destinations":"Destinations","Metrics":"Metrics","GroupProperties":"Group Properties"};

var _revMap$190 = {"Event Name":"EventName","Category":"Category","Property Groups":"PropertyGroups","Event Properties":"EventProperties","Sources":"Sources","Actions":"Actions","Tags":"Tags","Destinations":"Destinations","Metrics":"Metrics","Group Properties":"GroupProperties"};

function eventsViewColumnNameToJs(param) {
  return _map$253[param];
}

function eventsViewColumnNameFromJs(param) {
  return _revMap$190[param];
}

var _map$254 = {"AlwaysSent":"Always Sent","SometimesSent":"Sometimes Sent"};

var _revMap$191 = {"Always Sent":"AlwaysSent","Sometimes Sent":"SometimesSent"};

function propertyPresenceWhenInObjectToggleAfterToJs(param) {
  return _map$254[param];
}

function propertyPresenceWhenInObjectToggleAfterFromJs(param) {
  return _revMap$191[param];
}

var _map$255 = {"NoDiff":"No Diff","Unified":"Unified","Split":"Split"};

var _revMap$192 = {"No Diff":"NoDiff","Unified":"Unified","Split":"Split"};

function fromDiffTypeToJs(param) {
  return _map$255[param];
}

function fromDiffTypeFromJs(param) {
  return _revMap$192[param];
}

var _map$256 = {"ProtectedMainBranch":"Protected Main Branch","None":"None"};

var _revMap$193 = {"Protected Main Branch":"ProtectedMainBranch","None":"None"};

function workspaceBranchPermissionsEnabledToJs(param) {
  return _map$256[param];
}

function workspaceBranchPermissionsEnabledFromJs(param) {
  return _revMap$193[param];
}

var _map$257 = {"PropertyConflicts":"Property Conflicts","SameNameEvents":"Same Name Events","EventCommonCasingDiscrepancy":"Event Common Casing Discrepancy","SameNameProperty":"Same Name Property","PropertyCommonCasingDiscrepancy":"Property Common Casing Discrepancy","PropertyTypeNotDefined":"Property Type not Defined","PropertyMissingDescription":"Property Missing Description","EventMissingDescription":"Event Missing Description","EventAdvancedNamingConventionDiscrepancy":"Event Advanced Naming Convention Discrepancy"};

var _revMap$194 = {"Property Conflicts":"PropertyConflicts","Same Name Events":"SameNameEvents","Event Common Casing Discrepancy":"EventCommonCasingDiscrepancy","Same Name Property":"SameNameProperty","Property Common Casing Discrepancy":"PropertyCommonCasingDiscrepancy","Property Type not Defined":"PropertyTypeNotDefined","Property Missing Description":"PropertyMissingDescription","Event Missing Description":"EventMissingDescription","Event Advanced Naming Convention Discrepancy":"EventAdvancedNamingConventionDiscrepancy"};

function issueTypeToJs(param) {
  return _map$257[param];
}

function issueTypeFromJs(param) {
  return _revMap$194[param];
}

function schema(schemaId, schemaName, schemaBillingStatus, branchId, branchName, schemaSubscriptionPlan, schemaTrialPlan, authenticationConfig, schemaSubscriptionPlanSlug, schemaTrialPlanSlug, schemaType) {
  return {
          schemaId: schemaId,
          schemaName: schemaName,
          schemaBillingStatus: schemaBillingStatus,
          branchId: branchId,
          branchName: branchName,
          schemaSubscriptionPlan: schemaSubscriptionPlan,
          schemaTrialPlan: schemaTrialPlan,
          authenticationConfig: authenticationConfig,
          schemaSubscriptionPlanSlug: schemaSubscriptionPlanSlug,
          schemaTrialPlanSlug: schemaTrialPlanSlug,
          schemaType: schemaType
        };
}

function propertyGroup(propertyGroupId, propertyGroupName, numProperties) {
  return {
          propertyGroupId: propertyGroupId,
          propertyGroupName: propertyGroupName,
          numProperties: numProperties
        };
}

function property(propertyId, propertyName, namingConvention, propertyType, correctCase) {
  return {
          propertyId: propertyId,
          propertyName: propertyName,
          namingConvention: namingConvention,
          propertyType: propertyType,
          correctCase: correctCase
        };
}

function source(sourceId, sourceName) {
  return {
          sourceId: sourceId,
          sourceName: sourceName
        };
}

function onboarding(onboardingSources, onboardingDestinations, onboardingJobFunction, onboardingSeenJoinInstructions, onboardingTrackingContributions, onboardingCompanyRole, onboardingCompanySize) {
  return {
          onboardingSources: onboardingSources,
          onboardingDestinations: onboardingDestinations,
          onboardingJobFunction: onboardingJobFunction,
          onboardingSeenJoinInstructions: onboardingSeenJoinInstructions,
          onboardingTrackingContributions: onboardingTrackingContributions,
          onboardingCompanyRole: onboardingCompanyRole,
          onboardingCompanySize: onboardingCompanySize
        };
}

function currentFilters(currentTagFilters, currentSourceFilters, currentDestinationFilters, currentSorting, currentPropertyGroupFilters, currentPropertyFilters, currentActionFilters, currentCategoryFilters) {
  return {
          currentTagFilters: currentTagFilters,
          currentSourceFilters: currentSourceFilters,
          currentDestinationFilters: currentDestinationFilters,
          currentSorting: currentSorting,
          currentPropertyGroupFilters: currentPropertyGroupFilters,
          currentPropertyFilters: currentPropertyFilters,
          currentActionFilters: currentActionFilters,
          currentCategoryFilters: currentCategoryFilters
        };
}

function inspectorEventsViewFilters(inspectorEventNameFilter, inspectorOrder, inspectorColumnOrderedBy, inspectorSourceIdOrderedBy, inspectorTimeWindow, inspectorEnvironment, inspectorSourceNameOrderedBy) {
  return {
          inspectorEventNameFilter: inspectorEventNameFilter,
          inspectorOrder: inspectorOrder,
          inspectorColumnOrderedBy: inspectorColumnOrderedBy,
          inspectorSourceIdOrderedBy: inspectorSourceIdOrderedBy,
          inspectorTimeWindow: inspectorTimeWindow,
          inspectorEnvironment: inspectorEnvironment,
          inspectorSourceNameOrderedBy: inspectorSourceNameOrderedBy
        };
}

function inspectorTablePosition(inspectorEventNameSelected, inspectorColumnSelected, inspectorPropertyNameSelected, inspectorSourceIdSelected, inspectorSourceNameSelected, numPropertiesNotFoundOnEventInTrackingPlan) {
  return {
          inspectorEventNameSelected: inspectorEventNameSelected,
          inspectorColumnSelected: inspectorColumnSelected,
          inspectorPropertyNameSelected: inspectorPropertyNameSelected,
          inspectorSourceIdSelected: inspectorSourceIdSelected,
          inspectorSourceNameSelected: inspectorSourceNameSelected,
          numPropertiesNotFoundOnEventInTrackingPlan: numPropertiesNotFoundOnEventInTrackingPlan
        };
}

function integrationConfig(integrationType, integrationFilters, integrationName, integrationId, integrationPayloadFormat) {
  return {
          integrationType: integrationType,
          integrationFilters: integrationFilters,
          integrationName: integrationName,
          integrationId: integrationId,
          integrationPayloadFormat: integrationPayloadFormat
        };
}

function propertyConfiguration(propertyComponent, propertyConfigureAction, propertyValueTypeBefore, propertyPresenceTypeBefore, propertyListToggleAfter, propertyPresenceTypeAfter, propertyValueTypeAfter, propertyListToggleBefore, propertyValueConstraintType, propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleAfter) {
  return {
          propertyComponent: propertyComponent,
          propertyConfigureAction: propertyConfigureAction,
          propertyValueTypeBefore: propertyValueTypeBefore,
          propertyPresenceTypeBefore: propertyPresenceTypeBefore,
          propertyListToggleAfter: propertyListToggleAfter,
          propertyPresenceTypeAfter: propertyPresenceTypeAfter,
          propertyValueTypeAfter: propertyValueTypeAfter,
          propertyListToggleBefore: propertyListToggleBefore,
          propertyValueConstraintType: propertyValueConstraintType,
          propertyPresenceWhenInObjectToggleBefore: propertyPresenceWhenInObjectToggleBefore,
          propertyPresenceWhenInObjectToggleAfter: propertyPresenceWhenInObjectToggleAfter
        };
}

function cmdPalette(cmdPaletteActivePath, cmdPalettePathDepth, cmdPaletteSearchTerm) {
  return {
          cmdPaletteActivePath: cmdPaletteActivePath,
          cmdPalettePathDepth: cmdPalettePathDepth,
          cmdPaletteSearchTerm: cmdPaletteSearchTerm
        };
}

function tooltipInfo(tooltipName, tooltipPurpose, tooltipDisplayMethod) {
  return {
          tooltipName: tooltipName,
          tooltipPurpose: tooltipPurpose,
          tooltipDisplayMethod: tooltipDisplayMethod
        };
}

function inspectorIssueDetails(inspectorIssueProportion, inspectorIssueVolume, inspectorEventVolume, inspectorIssueSourceCount, inspectorIssueFirstSeenHoursAgo, inspectorIssueLastSeenHoursAgo, inspectorIssueType, inspectorIssueId, inspectorIssueSharedId, inspectorIssueDetailsInteractionType, inspectorIssueStatus) {
  return {
          inspectorIssueProportion: inspectorIssueProportion,
          inspectorIssueVolume: inspectorIssueVolume,
          inspectorEventVolume: inspectorEventVolume,
          inspectorIssueSourceCount: inspectorIssueSourceCount,
          inspectorIssueFirstSeenHoursAgo: inspectorIssueFirstSeenHoursAgo,
          inspectorIssueLastSeenHoursAgo: inspectorIssueLastSeenHoursAgo,
          inspectorIssueType: inspectorIssueType,
          inspectorIssueId: inspectorIssueId,
          inspectorIssueSharedId: inspectorIssueSharedId,
          inspectorIssueDetailsInteractionType: inspectorIssueDetailsInteractionType,
          inspectorIssueStatus: inspectorIssueStatus
        };
}

function inspectorSavedViews(inspectorSavedViewName, inspectorSavedViewId, inspectorSavedViewRelationship, inspectorSavedViewCount) {
  return {
          inspectorSavedViewName: inspectorSavedViewName,
          inspectorSavedViewId: inspectorSavedViewId,
          inspectorSavedViewRelationship: inspectorSavedViewRelationship,
          inspectorSavedViewCount: inspectorSavedViewCount
        };
}

function productFlows(productFlowName, productFlowId, productFlowVersion) {
  return {
          productFlowName: productFlowName,
          productFlowId: productFlowId,
          productFlowVersion: productFlowVersion
        };
}

function inspectorBreakingChangeInfo(breakingChangeImpactedEventCount, breakingChangeImpactedEventIds, breakingChangeImpactedSourceIds, breakingChangeImpactedSourceCount) {
  return {
          breakingChangeImpactedEventCount: breakingChangeImpactedEventCount,
          breakingChangeImpactedEventIds: breakingChangeImpactedEventIds,
          breakingChangeImpactedSourceIds: breakingChangeImpactedSourceIds,
          breakingChangeImpactedSourceCount: breakingChangeImpactedSourceCount
        };
}

var Bundle = {
  schema: schema,
  propertyGroup: propertyGroup,
  property: property,
  source: source,
  onboarding: onboarding,
  currentFilters: currentFilters,
  inspectorEventsViewFilters: inspectorEventsViewFilters,
  inspectorTablePosition: inspectorTablePosition,
  integrationConfig: integrationConfig,
  propertyConfiguration: propertyConfiguration,
  cmdPalette: cmdPalette,
  tooltipInfo: tooltipInfo,
  inspectorIssueDetails: inspectorIssueDetails,
  inspectorSavedViews: inspectorSavedViews,
  productFlows: productFlows,
  inspectorBreakingChangeInfo: inspectorBreakingChangeInfo
};

var DestinationOptions = {};

var AvoSystemProperties = {};

var __WEB_DEBUGGER__ = {
  contents: true
};

var AvoAssertMessage = {};

var AvoAssertMessageBody = {};

var __MOBILE_DEBUGGER__ = {
  contents: undefined
};

function _mobile_debugger_set_schema_id(schemaId) {
  if (__MOBILE_DEBUGGER__.contents !== undefined) {
    Belt_Option.getExn(__MOBILE_DEBUGGER__.contents).schemaId = schemaId;
    return ;
  }
  
}

function _mobile_debugger_given(param) {
  return __MOBILE_DEBUGGER__.contents !== undefined;
}

function _mobile_debugger_enabled(param) {
  if (__MOBILE_DEBUGGER__.contents !== undefined) {
    return Belt_Option.getExn(__MOBILE_DEBUGGER__.contents).isEnabled();
  } else {
    return false;
  }
}

function _mobile_debugger_post_event(eventId, timestamp, eventName, messages, eventProperties, userProperties) {
  if (__MOBILE_DEBUGGER__.contents !== undefined) {
    Belt_Option.getExn(__MOBILE_DEBUGGER__.contents).postEvent(eventId, timestamp, eventName, messages, eventProperties, userProperties);
    return ;
  }
  
}

var _map$258 = {"prod":"prod","staging":"staging","dev":"dev","unset":"unset"};

function avoEnvTypeToJs(param) {
  return param;
}

function avoEnvTypeFromJs(param) {
  return _map$258[param];
}

var __ENV__ = {
  contents: "unset"
};

var __AVO_NOOP__ = {
  contents: false
};

// polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }
;

function assertMaxInt(propertyId, propName, max, value) {
  if (value <= max) {
    return [];
  }
  var message = [
      propName,
      "has a maximum value of",
      String(max),
      "but you provided the value",
      String(value)
    ].join(" ");
  console.error("[avo] " + message);
  return [{
            tag: "expectedMax",
            propertyId: propertyId,
            message: message
          }];
}

function assertMaxFloat(propertyId, propName, max, value) {
  if (value <= max) {
    return [];
  }
  var message = [
      propName,
      "has a maximum value of",
      max.toString(),
      "but you provided the value",
      value.toString()
    ].join(" ");
  console.error("[avo] " + message);
  return [{
            tag: "expectedMax",
            propertyId: propertyId,
            message: message
          }];
}

function assertMinInt(propertyId, propName, min, value) {
  if (value >= min) {
    return [];
  }
  var message = [
      propName,
      "has a minimum value of",
      String(min),
      "but you provided the value",
      String(value)
    ].join(" ");
  console.error("[avo] " + message);
  return [{
            tag: "expectedMin",
            propertyId: propertyId,
            message: message
          }];
}

function assertMinFloat(propertyId, propName, min, value) {
  if (value >= min) {
    return [];
  }
  var message = [
      propName,
      "has a minimum value of",
      min.toString(),
      "but you provided the value",
      value.toString()
    ].join(" ");
  console.error("[avo] " + message);
  return [{
            tag: "expectedMin",
            propertyId: propertyId,
            message: message
          }];
}

var AvoAssert = {
  assertMaxInt: assertMaxInt,
  assertMaxFloat: assertMaxFloat,
  assertMinInt: assertMinInt,
  assertMinFloat: assertMinFloat
};

function logEventSent(eventName, eventProperties, userProperties) {
  console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  
}

var AvoLogger = {
  logEventSent: logEventSent
};

(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})()
;

var _avo_sampling_rate = 1.0
;

function _avo_invoke(env, eventId, hash, messages) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "p5NJHFB8cShSH5tGRmz0",
          "br": "-f95Jm_U3",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "fwtXqAc0fCLy7b7oGW40",
          "se": (new Date()).toISOString(),
          "so": "WUFObVFR4PVbZGtT1hQ5",
          "va": messages.length === 0,
          "me": messages,
          "or": "event"
        })
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}
;

function _avo_invoke_meta(env, type, messages) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "p5NJHFB8cShSH5tGRmz0",
          "br": "-f95Jm_U3",
          "en": env,
          "ty": type,
          "sc": "fwtXqAc0fCLy7b7oGW40",
          "se": (new Date()).toISOString(),
          "so": "WUFObVFR4PVbZGtT1hQ5",
          "va": messages.length === 0,
          "me": messages
        })
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}
;

function avo_invoke(eventId, hash, messages) {
  _avo_invoke(__ENV__.contents, eventId, hash, messages);
  
}

function avo_invoke_meta(type_, message) {
  _avo_invoke_meta(__ENV__.contents, type_, message);
  
}

var _avo_debugger_events_during_boot = [];
;

var _avo_debugger_ready = false;
;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "fwtXqAc0fCLy7b7oGW40",
        href: window.location.href,
        events: _avo_debugger_events_during_boot,
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}
;

var _map$259 = {"BottomRight":"BottomRight","BottomLeft":"BottomLeft","TopLeft":"TopLeft","TopRight":"TopRight"};

function rawDebuggerPositionToJs(param) {
  return param;
}

function rawDebuggerPositionFromJs(param) {
  return _map$259[param];
}

function make(apiKey, optionsOpt, param) {
  var options = optionsOpt !== undefined ? Caml_option.valFromOption(optionsOpt) : ({});
  var apply = (function (options) {
        if (typeof window === 'undefined') { console.warn('window.Intercom is not available in Node.js'); return; }
        if (typeof window.Intercom === "undefined") {
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/APP_ID';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          window.Intercom("boot", options);
        }
      });
  var options$1 = Object.assign(options, {
        app_id: apiKey
      });
  apply(options$1);
  
}

function logEvent(eventName, eventProperties) {
  var apply = (function (eventName, eventProperties) {
        if (typeof window === 'undefined') { return; }
        window.Intercom("trackEvent", eventName, eventProperties);
      });
  apply(eventName, eventProperties);
  
}

function setUserProperties(userProperties) {
  var apply = (function (userProperties) {
        if (typeof window === 'undefined') { return; }
        window.Intercom('update', userProperties);
      });
  apply(userProperties);
  
}

function identify(userId) {
  var apply = (function (userId) {
        if (typeof window === 'undefined') { return; }
        window.Intercom('update', {"user_id": userId});
      });
  apply(userId);
  
}

function unidentify(param) {
  var apply = (function () {
        if (typeof window === 'undefined') { return; }
        window.Intercom('shutdown');
        // TODO Boot intercom again
      });
  apply();
  
}

var Intercom = {
  make: make,
  logEvent: logEvent,
  setUserProperties: setUserProperties,
  identify: identify,
  unidentify: unidentify
};

function make$1(apiKey, $staropt$star, param) {
  if ($staropt$star !== undefined) {
    Caml_option.valFromOption($staropt$star);
  } else {
    ({});
  }
  var apply = (function (apiKey) {
        if (typeof window === 'undefined') { console.warn('window.FS is not available in Node.js'); return; }
        if (typeof window.FS === "undefined") {
          window['_fs_debug'] = false;
          window['_fs_host'] = 'fullstory.com';
          window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
          window['_fs_org'] = apiKey;
          window['_fs_namespace'] = 'FS';
          (function(m,n,e,t,l,o,g,y){
              if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
              g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
              o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
              y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
              g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
              g.anonymize=function(){g.identify(!!0)};
              g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
              g.log = function(a,b){g("log",[a,b])};
              g.consent=function(a){g("consent",!arguments.length||a)};
              g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
              g.clearUserCookie=function(){};
              g.setVars=function(n, p){g('setVars',[n,p]);};
              g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
              if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
              g._v="1.3.0";
          })(window,document,window['_fs_namespace'],'script','user');
        }
      });
  apply(apiKey);
  
}

function logEvent$1(eventName, eventProperties) {
  var apply = (function (eventName, eventProperties) {
        if (typeof window === 'undefined') { return; }
        window.FS.event(eventName, eventProperties);
      });
  apply(eventName, eventProperties);
  
}

function setUserProperties$1(userProperties) {
  var apply = (function (userProperties) {
        if (typeof window === 'undefined') { return; }
        window.FS.setUserVars(userProperties);
      });
  apply(userProperties);
  
}

function identify$1(userId) {
  var apply = (function (userId) {
        if (typeof window === 'undefined') { return; }
        window.FS.identify(userId);
      });
  apply(userId);
  
}

function unidentify$1(param) {
  var apply = (function () {
        if (typeof window === 'undefined') { return; }
        window.FS.identify(false);
      });
  apply();
  
}

var Fullstory = {
  make: make$1,
  logEvent: logEvent$1,
  setUserProperties: setUserProperties$1,
  identify: identify$1,
  unidentify: unidentify$1
};

function make$2(apiKey, optionsOpt, param) {
  var options = optionsOpt !== undefined ? Caml_option.valFromOption(optionsOpt) : ({});
  var apply = (function (apiKey, options) {
        if (typeof window === 'undefined') { console.warn('window.mixpanel is not available in Node.js'); return; }
        if (typeof window.mixpanel === "undefined") {
          (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
          for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){var call2_args=arguments;var call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
          MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
        }
        window.mixpanel.init(apiKey, options);
      });
  apply(apiKey, options);
  
}

function addCurrentUserToGroup(groupTypeName, groupId) {
  var apply = (function (groupTypeName, groupId) {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.set_group(groupTypeName, groupId);
      });
  apply(groupTypeName, groupId);
  
}

function logEventWithGroups(eventName, eventProperties, groupTypeNamesToGroupIds) {
  var apply = (function (eventName, eventProperties, groupTypeNamesToGroupIdsObject) {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.track_with_groups(eventName, eventProperties, groupTypeNamesToGroupIdsObject);
      });
  apply(eventName, eventProperties, groupTypeNamesToGroupIds);
  
}

function logEvent$2(eventName, eventProperties) {
  var apply = (function (eventName, eventProperties) {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.track(eventName, eventProperties);
      });
  apply(eventName, eventProperties);
  
}

function setUserProperties$2(userProperties) {
  var apply = (function (userProperties) {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.people.set(userProperties);
      });
  apply(userProperties);
  
}

function setUserPropertiesOnce(userProperties) {
  var apply = (function (userProperties) {
        if (typeof window === 'undefined' || !window.mixpanel.people.set_once) { return; }
        window.mixpanel.people.set_once(userProperties);
      });
  apply(userProperties);
  
}

function setGroupProperties(groupTypeName, groupId, groupProperties) {
  var apply = (function (groupTypeName, groupType, groupProperties) {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.get_group(groupTypeName, groupType).set(groupProperties);
      });
  apply(groupTypeName, groupId, groupProperties);
  
}

function revenue(amount, eventProperties) {
  var apply = (function (amount, eventProperties) {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.people.track_charge(amount, eventProperties);
      });
  apply(amount, eventProperties);
  
}

function identify$2(userId) {
  var apply = (function (userId) {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.identify(userId);
      });
  apply(userId);
  
}

function unidentify$2(param) {
  var apply = (function () {
        if (typeof window === 'undefined') { return; }
        window.mixpanel.reset();
      });
  apply();
  
}

var Mixpanel = {
  make: make$2,
  addCurrentUserToGroup: addCurrentUserToGroup,
  logEventWithGroups: logEventWithGroups,
  logEvent: logEvent$2,
  setUserProperties: setUserProperties$2,
  setUserPropertiesOnce: setUserPropertiesOnce,
  setGroupProperties: setGroupProperties,
  revenue: revenue,
  identify: identify$2,
  unidentify: unidentify$2
};

function assertNumSourcesUpdatedOnBranchWithTriggers(numSourcesUpdatedOnBranchWithTriggers) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("15DfinuqFE", "Num Sources Updated on Branch With Triggers", 0, numSourcesUpdatedOnBranchWithTriggers));
}

function assertBreakingChangeImpactedEventCount(breakingChangeImpactedEventCount) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("1PcfiRNBI", "Breaking Change Impacted Event Count", 1, breakingChangeImpactedEventCount));
}

function assertNumGloballyOptionalPropertiesInSchema(numGloballyOptionalPropertiesInSchema) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("2zj0XISSov", "Num Globally Optional Properties in Schema", 0, numGloballyOptionalPropertiesInSchema));
}

function assertDaysSinceTrialStart(daysSinceTrialStart) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("5a7bc4ac-8f1d-4f25-947e-0a8283aa1c5a", "Days Since Trial Start", 0, daysSinceTrialStart));
}

function assertInspectorDisplayedIssueCountOptional(inspectorDisplayedIssueCount) {
  var messages = [];
  return Belt_Array.concat(messages, inspectorDisplayedIssueCount !== undefined ? Belt_Array.concat(messages, assertMinInt("BRTP-mzzf", "Inspector Displayed Issue Count", 0, inspectorDisplayedIssueCount)) : messages);
}

function assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("BRTP-mzzf", "Inspector Displayed Issue Count", 0, inspectorDisplayedIssueCount));
}

function assertNumEventsInSchema(numEventsInSchema) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("Cyfje9QqIM", "Num Events in Schema", 0, numEventsInSchema));
}

function assertCmdPalettePathDepth(cmdPalettePathDepth) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("DAWbmWhXIl", "Cmd Palette Path Depth", 0, cmdPalettePathDepth));
}

function assertNumNewEventsWithTriggers(numNewEventsWithTriggers) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("ESJodwvKqG", "Num New Events With Triggers", 0, numNewEventsWithTriggers));
}

function assertInspectorIssueProportion(inspectorIssueProportion) {
  var messages = [];
  var messages$1 = Belt_Array.concat(messages, assertMinFloat("FhxqbTTFK", "Inspector Issue Proportion", 0.000000, inspectorIssueProportion));
  return Belt_Array.concat(messages$1, assertMaxFloat("FhxqbTTFK", "Inspector Issue Proportion", 1.000000, inspectorIssueProportion));
}

function assertNumChecklistStepsLeft(numChecklistStepsLeft) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("GDZiM7GiK", "Num Checklist Steps Left", 0, numChecklistStepsLeft));
}

function assertNumIntegrationsPublished(numIntegrationsPublished) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("GU53ROkPSl", "Num Integrations Published", 0, numIntegrationsPublished));
}

function assertNumUpdatedEventsWithTriggers(numUpdatedEventsWithTriggers) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("HtPijQLIwu", "Num Updated Events With Triggers", 0, numUpdatedEventsWithTriggers));
}

function assertBreakingChangeImpactedSourceCount(breakingChangeImpactedSourceCount) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("J6DF_puXQ", "Breaking Change Impacted Source Count", 1, breakingChangeImpactedSourceCount));
}

function assertNumComments(numComments) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("J9zcb15BBu", "Num Comments", 0, numComments));
}

function assertInspectorIssueVolume(inspectorIssueVolume) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("JioDYuCXb", "Inspector Issue Volume", 0, inspectorIssueVolume));
}

function assertInspectorEventVolume(inspectorEventVolume) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("Jq3Fw3nm1", "Inspector Event Volume", 0, inspectorEventVolume));
}

function assertProportionOfInspectorEventsNotFoundInTrackingPlan(proportionOfInspectorEventsNotFoundInTrackingPlan) {
  var messages = [];
  var messages$1 = Belt_Array.concat(messages, assertMinFloat("PFRG978Zv", "Proportion of Inspector Events Not Found in Tracking Plan", 0.000000, proportionOfInspectorEventsNotFoundInTrackingPlan));
  return Belt_Array.concat(messages$1, assertMaxFloat("PFRG978Zv", "Proportion of Inspector Events Not Found in Tracking Plan", 1.000000, proportionOfInspectorEventsNotFoundInTrackingPlan));
}

function assertNumNewProperties(numNewProperties) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("QdSM2v9BoJ", "Num New Properties", 0, numNewProperties));
}

function assertNumNewGloballyOptionalProperties(numNewGloballyOptionalProperties) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("TRnooYxfQb", "Num New Globally Optional Properties", 0, numNewGloballyOptionalProperties));
}

function assertNumIssueReportConfigsOn(numIssueReportConfigsOn) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("Td8i_XgFo", "Num Issue Report Configs On", 0, numIssueReportConfigsOn));
}

function assertNumTriggerSources(numTriggerSources) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("UAcH9V5q9Q", "Num Trigger Sources", 0, numTriggerSources));
}

function assertInspectorIssueLastSeenHoursAgo(inspectorIssueLastSeenHoursAgo) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("VqRh4QggM", "Inspector Issue Last Seen Hours Ago", 0, inspectorIssueLastSeenHoursAgo));
}

function assertInspectorIssueFirstSeenHoursAgo(inspectorIssueFirstSeenHoursAgo) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("WWIKORM2D", "Inspector Issue First Seen Hours Ago", 0, inspectorIssueFirstSeenHoursAgo));
}

function assertProportionOfUpdatedEventInstancesImplementedOnBranchOptional(proportionOfUpdatedEventInstancesImplementedOnBranch) {
  var messages = [];
  var tmp;
  if (proportionOfUpdatedEventInstancesImplementedOnBranch !== undefined) {
    var messages$1 = Belt_Array.concat(messages, assertMinFloat("WeAPrAlkjw", "Proportion of Updated Event Instances Implemented on Branch", 0.000000, proportionOfUpdatedEventInstancesImplementedOnBranch));
    tmp = Belt_Array.concat(messages$1, assertMaxFloat("WeAPrAlkjw", "Proportion of Updated Event Instances Implemented on Branch", 1.000000, proportionOfUpdatedEventInstancesImplementedOnBranch));
  } else {
    tmp = messages;
  }
  return Belt_Array.concat(messages, tmp);
}

function assertNumPropertiesInSchema(numPropertiesInSchema) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("ZUpmS4p1y", "Num Properties in Schema", 0, numPropertiesInSchema));
}

function assertChecklistProgressRatio(checklistProgressRatio) {
  var messages = [];
  var messages$1 = Belt_Array.concat(messages, assertMinFloat("_tPoZx3Or", "Checklist Progress Ratio", 0.000000, checklistProgressRatio));
  return Belt_Array.concat(messages$1, assertMaxFloat("_tPoZx3Or", "Checklist Progress Ratio", 1.000000, checklistProgressRatio));
}

function assertInspectorIssueSourceCount(inspectorIssueSourceCount) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("c6YWp34Db", "Inspector Issue Source Count", 0, inspectorIssueSourceCount));
}

function assertProportionOfUpdatedSourcesImplementedOnBranchOptional(proportionOfUpdatedSourcesImplementedOnBranch) {
  var messages = [];
  var tmp;
  if (proportionOfUpdatedSourcesImplementedOnBranch !== undefined) {
    var messages$1 = Belt_Array.concat(messages, assertMinFloat("cPiHeiKv7", "Proportion of Updated Sources Implemented on Branch", 0.000000, proportionOfUpdatedSourcesImplementedOnBranch));
    tmp = Belt_Array.concat(messages$1, assertMaxFloat("cPiHeiKv7", "Proportion of Updated Sources Implemented on Branch", 1.000000, proportionOfUpdatedSourcesImplementedOnBranch));
  } else {
    tmp = messages;
  }
  return Belt_Array.concat(messages, tmp);
}

function assertNumProperties(numProperties) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("dbd1e302-296d-4ce3-9a5b-c5f12f694d78", "Num Properties", 0, numProperties));
}

function assertNumberOfNamingSuggestions(numberOfNamingSuggestions) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("lf4kMDqAm", "Number of Naming Suggestions", 0, numberOfNamingSuggestions));
}

function assertNumTriggersOnEvent(numTriggersOnEvent) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("oJg68u5oqc", "Num Triggers on Event", 0, numTriggersOnEvent));
}

function assertInspectorSavedViewCount(inspectorSavedViewCount) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("optDeUTmv", "Inspector Saved View Count", 0, inspectorSavedViewCount));
}

function assertSchemaCreatedIntegrationsCount(schemaCreatedIntegrationsCount) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("qWYnYb-nad", "Schema Created Integrations Count", 0, schemaCreatedIntegrationsCount));
}

function assertReleaseVersionConfiguredSourceCount(releaseVersionConfiguredSourceCount) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("sxOJ8K-0U", "Release Version Configured Source Count", 1, releaseVersionConfiguredSourceCount));
}

function assertNumUpdatedProperties(numUpdatedProperties) {
  var messages = [];
  return Belt_Array.concat(messages, assertMinInt("vXVK6UQZE", "Num Updated Properties", 0, numUpdatedProperties));
}

var sysClient = {
  contents: undefined
};

var sysVersion = {
  contents: undefined
};

function setSystemProperties(systemProperties) {
  if (Caml_option.undefined_to_opt(systemProperties.client) !== undefined) {
    sysClient.contents = Caml_option.undefined_to_opt(systemProperties.client);
  }
  if (Caml_option.undefined_to_opt(systemProperties.version) !== undefined) {
    sysVersion.contents = Caml_option.undefined_to_opt(systemProperties.version);
    return ;
  }
  
}

function setSystemProperties_(client, version, param) {
  if (client !== undefined) {
    sysClient.contents = client;
  }
  if (version !== undefined) {
    sysVersion.contents = version;
    return ;
  }
  
}

var postHog = {
  contents: undefined
};

var segment = {
  contents: undefined
};

var clearbit = {
  contents: undefined
};

var intercomDestinationInterface = {
  contents: undefined
};

var inspectorCustomDestination = {
  contents: undefined
};

function initAvo(webDebuggerOpt, webDebuggerOptions, noopOpt, mobileDebugger, env, systemProperties, destinationOptions, postHogDestination, segmentDestination, clearbitDestination, intercomDestinationInterfaceDestination, inspectorCustomDestinationDestination, param) {
  var webDebugger = webDebuggerOpt !== undefined ? webDebuggerOpt : true;
  var noop = noopOpt !== undefined ? noopOpt : false;
  if (__ENV__.contents !== "unset") {
    return ;
  }
  __ENV__.contents = env;
  __AVO_NOOP__.contents = noop;
  if (__AVO_NOOP__.contents && __ENV__.contents === "prod") {
    console.warn("[avo] ****************************************************");
    console.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    console.warn("[avo] - Overwriting configuration with noop=false.");
    console.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    console.warn("[avo] ****************************************************");
    __AVO_NOOP__.contents = false;
  }
  if (__AVO_NOOP__.contents) {
    console.log("[avo] ****************************************************");
    console.log("[avo] Avo is now initialized in noop mode. This means:");
    console.log("[avo] - No events will be sent");
    console.log("[avo] - No network requests are made");
    console.log("[avo] ****************************************************");
  }
  __WEB_DEBUGGER__.contents = !__AVO_NOOP__.contents && (typeof window !== "undefined" && window.location.search.indexOf("avo_debug=1") > -1 || webDebugger && env !== "prod");
  if (!__AVO_NOOP__.contents) {
    __MOBILE_DEBUGGER__.contents = mobileDebugger;
    _mobile_debugger_set_schema_id("fwtXqAc0fCLy7b7oGW40");
  }
  setSystemProperties(systemProperties);
  if (__WEB_DEBUGGER__.contents) {
    var debuggerPosition;
    if (webDebuggerOptions !== undefined) {
      var position = webDebuggerOptions.position;
      switch (position.TAG | 0) {
        case /* BottomRight */0 :
            var offset = position._0;
            debuggerPosition = {
              position: "BottomRight",
              right: offset.right,
              bottom: offset.bottom
            };
            break;
        case /* BottomLeft */1 :
            var offset$1 = position._0;
            debuggerPosition = {
              position: "BottomLeft",
              bottom: offset$1.bottom,
              left: offset$1.left
            };
            break;
        case /* TopRight */2 :
            var offset$2 = position._0;
            debuggerPosition = {
              position: "TopRight",
              top: offset$2.top,
              right: offset$2.right
            };
            break;
        case /* TopLeft */3 :
            var offset$3 = position._0;
            debuggerPosition = {
              position: "TopLeft",
              top: offset$3.top,
              left: offset$3.left
            };
            break;
        
      }
    } else {
      debuggerPosition = {
        position: "BottomRight",
        right: 0,
        bottom: 0
      };
    }
    _avo_debugger_send_position(debuggerPosition);
    ((function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    var iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
}()));
  }
  if (!__AVO_NOOP__.contents) {
    if (__ENV__.contents === "prod") {
      make("q694k0ra", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.intercom);
                })), undefined);
      make$1("E2KTW", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.fullstory);
                })), undefined);
      make$2("b22a20c38601eb0f14364e8cfdfaa415", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.mixpanel);
                })), undefined);
    }
    if (__ENV__.contents === "dev") {
      make("hc03k7sl", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.intercom);
                })), undefined);
      make$1("KBA1X", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.fullstory);
                })), undefined);
      make$2("3d4e18198e980f120436a561cc7e2a1f", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.mixpanel);
                })), undefined);
    }
    if (__ENV__.contents === "staging") {
      console.error("[avo] No staging key is set for Intercom. Head to destination settings in Avo to set a staging key.");
      make("", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.intercom);
                })), undefined);
      console.error("[avo] No staging key is set for fullstory. Head to destination settings in Avo to set a staging key.");
      make$1("", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.fullstory);
                })), undefined);
      make$2("3d4e18198e980f120436a561cc7e2a1f", Belt_Option.flatMap(destinationOptions, (function (options) {
                  return Caml_option.undefined_to_opt(options.mixpanel);
                })), undefined);
    }
    postHog.contents = postHogDestination;
    if (__ENV__.contents === "prod" || __ENV__.contents === "dev") {
      Curry._2(Belt_Option.getExn(postHog.contents).make, __ENV__.contents, "phc_KuimORATdTX1DNZhtMqD0PxV9M48VNHq9qybNfH0rEO");
    } else {
      console.error("[avo] No staging key is set for PostHog. Head to destination settings in Avo to set a staging key.");
      Curry._2(Belt_Option.getExn(postHog.contents).make, __ENV__.contents, undefined);
    }
    segment.contents = segmentDestination;
    if (__ENV__.contents === "prod") {
      Curry._2(Belt_Option.getExn(segment.contents).make, __ENV__.contents, "Gt02cZdFC65QWZuJypDeWSHHVYZfZweo");
    } else if (__ENV__.contents === "dev") {
      Curry._2(Belt_Option.getExn(segment.contents).make, __ENV__.contents, "8bKQv9Ik8ooRtQTl3lFknKbKW9DQjf9S");
    } else {
      console.error("[avo] No staging key is set for segment. Head to destination settings in Avo to set a staging key.");
      Curry._2(Belt_Option.getExn(segment.contents).make, __ENV__.contents, undefined);
    }
    clearbit.contents = clearbitDestination;
    if (__ENV__.contents === "prod" || __ENV__.contents === "dev") {
      Curry._2(Belt_Option.getExn(clearbit.contents).make, __ENV__.contents, undefined);
    } else {
      console.error("[avo] No staging key is set for Clearbit. Head to destination settings in Avo to set a staging key.");
      Curry._2(Belt_Option.getExn(clearbit.contents).make, __ENV__.contents, undefined);
    }
    intercomDestinationInterface.contents = intercomDestinationInterfaceDestination;
    if (__ENV__.contents === "prod") {
      Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).make, __ENV__.contents, "dG9rOjBiYWVhZTg0X2ZhNmRfNDgxNl9hYzliXzg1ZDdlMjZiNWI5ZDoxOjA=");
    } else if (__ENV__.contents === "dev") {
      Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).make, __ENV__.contents, "dG9rOmJkMzFkMjEyXzJjYzdfNGE5NV9iNjY5X2VhOWE5MDBhY2I3ZjoxOjA=");
    } else {
      console.error("[avo] No staging key is set for Intercom Destination Interface. Head to destination settings in Avo to set a staging key.");
      Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).make, __ENV__.contents, undefined);
    }
    inspectorCustomDestination.contents = inspectorCustomDestinationDestination;
    if (__ENV__.contents === "prod" || __ENV__.contents === "dev") {
      Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).make, __ENV__.contents, undefined);
    } else {
      console.error("[avo] No staging key is set for Inspector custom destination. Head to destination settings in Avo to set a staging key.");
      Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).make, __ENV__.contents, undefined);
    }
    if (__ENV__.contents !== "prod") {
      return avo_invoke_meta("init", []);
    } else {
      return ;
    }
  }
  
}

function constraintValueUpdated(schema, eventName, constraintType, propertyName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("01d166a4-54c4-42ab-ae31-d370debc66f4", "b30d3d6f4a8b1f7448a91bf9a5c4b870b96d701c6d9800a99db599f952734c77", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Constraint Value Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Name": eventName,
          "Constraint Type": constraintType,
          "Property Name": propertyName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("01d166a4-54c4-42ab-ae31-d370debc66f4", "Constraint Value Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "cf949577-c7c7-4d90-a5ee-4b21dba7db1c",
              name: "Constraint Type",
              value: constraintType
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("01d166a4-54c4-42ab-ae31-d370debc66f4", Date.now(), "Constraint Value Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "cf949577-c7c7-4d90-a5ee-4b21dba7db1c",
              name: "Constraint Type",
              value: constraintType
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Constraint Value Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Constraint Type": constraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Constraint Value Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Constraint Value Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Constraint Type": constraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Constraint Value Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Constraint Type": constraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Constraint Value Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Constraint Type": constraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Constraint Value Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Constraint Type": constraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Constraint Value Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Name": eventName,
              "Constraint Type": constraintType,
              "Property Name": propertyName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function matchUpdated(schema, property, matchName, numMatchesAfterUpdate, matchesAfterUpdate, matchesAction, numTotalPropertySources, numActivePropertySources, pageName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("037ea0c2-7795-467e-8ba4-0547a1c9b266", "83a4b4218e2c67c61b612a54424060e8c2741448e5fe7660d850ae88b76bea0f", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Match Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Match Name": matchName,
          "Num Matches After Update": numMatchesAfterUpdate,
          "Matches After Update": matchesAfterUpdate,
          "Matches Action": matchesActionToJs(matchesAction),
          "Num Total Property Sources": numTotalPropertySources,
          "Num Active Property Sources": numActivePropertySources,
          "Page Name": pageNameToJs(pageName),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("037ea0c2-7795-467e-8ba4-0547a1c9b266", "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("037ea0c2-7795-467e-8ba4-0547a1c9b266", Date.now(), "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Match Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Match Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Match Name": matchName,
              "Num Matches After Update": numMatchesAfterUpdate,
              "Matches After Update": matchesAfterUpdate,
              "Matches Action": matchesActionToJs(matchesAction),
              "Num Total Property Sources": numTotalPropertySources,
              "Num Active Property Sources": numActivePropertySources,
              "Page Name": pageNameToJs(pageName),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function matchUpdatedForEvent(schema, property, matchName, numMatchesAfterUpdate, matchesAfterUpdate, matchesAction, numTotalPropertySources, numActivePropertySources, pageName, eventId, eventName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("037ea0c2-7795-467e-8ba4-0547a1c9b266.jVB9MvBkn", "2e5d7d8a6ffc4866ed79945f03a4102b586328630a0dad7618bc078e6ac3b314", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Match Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Match Name": matchName,
          "Num Matches After Update": numMatchesAfterUpdate,
          "Matches After Update": matchesAfterUpdate,
          "Matches Action": matchesActionToJs(matchesAction),
          "Num Total Property Sources": numTotalPropertySources,
          "Num Active Property Sources": numActivePropertySources,
          "Page Name": pageNameToJs(pageName),
          "Event Id": eventId,
          "Event Name": eventName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("037ea0c2-7795-467e-8ba4-0547a1c9b266.jVB9MvBkn", "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("037ea0c2-7795-467e-8ba4-0547a1c9b266.jVB9MvBkn", Date.now(), "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Match Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Match Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Match Name": matchName,
              "Num Matches After Update": numMatchesAfterUpdate,
              "Matches After Update": matchesAfterUpdate,
              "Matches Action": matchesActionToJs(matchesAction),
              "Num Total Property Sources": numTotalPropertySources,
              "Num Active Property Sources": numActivePropertySources,
              "Page Name": pageNameToJs(pageName),
              "Event Id": eventId,
              "Event Name": eventName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function matchUpdatedForSource(schema, property, matchName, numMatchesAfterUpdate, matchesAfterUpdate, matchesAction, numTotalPropertySources, numActivePropertySources, pageName, sourceName, sourceId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("037ea0c2-7795-467e-8ba4-0547a1c9b266.DhGBT5ay5", "1dd6ee94ac768780e918e839e6069d0862b2474cb636148794767e9f6c419ebf", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Match Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Match Name": matchName,
          "Num Matches After Update": numMatchesAfterUpdate,
          "Matches After Update": matchesAfterUpdate,
          "Matches Action": matchesActionToJs(matchesAction),
          "Num Total Property Sources": numTotalPropertySources,
          "Num Active Property Sources": numActivePropertySources,
          "Page Name": pageNameToJs(pageName),
          "Source Name": sourceName,
          "Source Id": sourceId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("037ea0c2-7795-467e-8ba4-0547a1c9b266.DhGBT5ay5", "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: sourceId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("037ea0c2-7795-467e-8ba4-0547a1c9b266.DhGBT5ay5", Date.now(), "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: sourceId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Match Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Match Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Match Name": matchName,
              "Num Matches After Update": numMatchesAfterUpdate,
              "Matches After Update": matchesAfterUpdate,
              "Matches Action": matchesActionToJs(matchesAction),
              "Num Total Property Sources": numTotalPropertySources,
              "Num Active Property Sources": numActivePropertySources,
              "Page Name": pageNameToJs(pageName),
              "Source Name": sourceName,
              "Source Id": sourceId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function matchUpdatedForEventSource(schema, property, matchName, numMatchesAfterUpdate, matchesAfterUpdate, matchesAction, numTotalPropertySources, numActivePropertySources, pageName, sourceName, eventId, eventName, sourceId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("037ea0c2-7795-467e-8ba4-0547a1c9b266.6WU4gU7a4", "6aaaa5602f85aa2414faf5da148a0a393a5c93064d60a066941a1312630e567b", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Match Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Match Name": matchName,
          "Num Matches After Update": numMatchesAfterUpdate,
          "Matches After Update": matchesAfterUpdate,
          "Matches Action": matchesActionToJs(matchesAction),
          "Num Total Property Sources": numTotalPropertySources,
          "Num Active Property Sources": numActivePropertySources,
          "Page Name": pageNameToJs(pageName),
          "Source Name": sourceName,
          "Event Id": eventId,
          "Event Name": eventName,
          "Source Id": sourceId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("037ea0c2-7795-467e-8ba4-0547a1c9b266.6WU4gU7a4", "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: sourceId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("037ea0c2-7795-467e-8ba4-0547a1c9b266.6WU4gU7a4", Date.now(), "Match Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "1c896069-8767-4df8-a357-0f59ad1b0831",
              name: "Match Name",
              value: matchName
            },
            {
              id: "2e7c81ea-f77a-455e-b224-334e40bccc9f",
              name: "Num Matches After Update",
              value: numMatchesAfterUpdate
            },
            {
              id: "38d24717-9a43-493c-917a-ea7b504c57e6",
              name: "Matches After Update",
              value: matchesAfterUpdate
            },
            {
              id: "d7794d27-a35d-4c8a-a396-afec1ca73f40",
              name: "Matches Action",
              value: matchesActionToJs(matchesAction)
            },
            {
              id: "NQBFC658cf",
              name: "Num Total Property Sources",
              value: numTotalPropertySources
            },
            {
              id: "ntmstWcKjd",
              name: "Num Active Property Sources",
              value: numActivePropertySources
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: sourceId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Match Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Match Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Match Name": matchName,
        "Num Matches After Update": numMatchesAfterUpdate,
        "Matches After Update": matchesAfterUpdate,
        "Matches Action": matchesActionToJs(matchesAction),
        "Num Total Property Sources": numTotalPropertySources,
        "Num Active Property Sources": numActivePropertySources,
        "Page Name": pageNameToJs(pageName),
        "Source Name": sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Match Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Match Name": matchName,
              "Num Matches After Update": numMatchesAfterUpdate,
              "Matches After Update": matchesAfterUpdate,
              "Matches Action": matchesActionToJs(matchesAction),
              "Num Total Property Sources": numTotalPropertySources,
              "Num Active Property Sources": numActivePropertySources,
              "Page Name": pageNameToJs(pageName),
              "Source Name": sourceName,
              "Event Id": eventId,
              "Event Name": eventName,
              "Source Id": sourceId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function membersModalClosed(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("35a7ab89-da35-4bc0-8dec-6fea3c65cdd9", "fd98c3ffaa12913e088bc41bb11c43cabb8318c1e51dd5c96ca9969554129914", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Members Modal Closed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("35a7ab89-da35-4bc0-8dec-6fea3c65cdd9", "Members Modal Closed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("35a7ab89-da35-4bc0-8dec-6fea3c65cdd9", Date.now(), "Members Modal Closed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Members Modal Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Members Modal Closed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Members Modal Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Members Modal Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Members Modal Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Members Modal Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Members Modal Closed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function membersModalOpened(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("366c4471-e004-4c75-bc92-73d21de321fe", "7bb0da2198a053938e424ccdcb149cbd114032a8716e4dfd5345ef7cd356d1a9", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Members Modal Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("366c4471-e004-4c75-bc92-73d21de321fe", "Members Modal Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("366c4471-e004-4c75-bc92-73d21de321fe", Date.now(), "Members Modal Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Members Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Members Modal Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Members Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Members Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Members Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Members Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Members Modal Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyAdded(schema, property, currentFilters, eventId, eventName, nameLength, propertyState, nameWordCount, inputValueLength, propertyLocation, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("47926ee9-2893-40aa-aef1-6133a8bb29c0", "02a480bc7f4ec321c7da3669082271544bf09bcaa0046b3ce09e77c6bdaa40ce", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Added", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Event Id": eventId,
          "Event Name": eventName,
          "Name Length": nameLength,
          "Property State": propertyState,
          "Name Word Count": nameWordCount,
          "Input Value Length": inputValueLength,
          "Property Location": propertyLocationToJs(propertyLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("47926ee9-2893-40aa-aef1-6133a8bb29c0", "Property Added", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "577d9179-045e-4cb3-a835-ddc8c3eb0ce3",
              name: "Name Length",
              value: nameLength
            },
            {
              id: "8f1e46d6-7dfb-4324-b915-725a06da198b",
              name: "Property State",
              value: propertyState
            },
            {
              id: "df08657a-d3fb-4e09-a49e-0cf6e64ada7b",
              name: "Name Word Count",
              value: nameWordCount
            },
            {
              id: "ed409b75-a18a-4c47-a443-671dfd9c54d7",
              name: "Input Value Length",
              value: inputValueLength
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("47926ee9-2893-40aa-aef1-6133a8bb29c0", Date.now(), "Property Added", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "577d9179-045e-4cb3-a835-ddc8c3eb0ce3",
              name: "Name Length",
              value: nameLength
            },
            {
              id: "8f1e46d6-7dfb-4324-b915-725a06da198b",
              name: "Property State",
              value: propertyState
            },
            {
              id: "df08657a-d3fb-4e09-a49e-0cf6e64ada7b",
              name: "Name Word Count",
              value: nameWordCount
            },
            {
              id: "ed409b75-a18a-4c47-a443-671dfd9c54d7",
              name: "Input Value Length",
              value: inputValueLength
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Name Length": nameLength,
        "Property State": propertyState,
        "Name Word Count": nameWordCount,
        "Input Value Length": inputValueLength,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Added", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Name Length": nameLength,
        "Property State": propertyState,
        "Name Word Count": nameWordCount,
        "Input Value Length": inputValueLength,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Name Length": nameLength,
        "Property State": propertyState,
        "Name Word Count": nameWordCount,
        "Input Value Length": inputValueLength,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Name Length": nameLength,
        "Property State": propertyState,
        "Name Word Count": nameWordCount,
        "Input Value Length": inputValueLength,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Name Length": nameLength,
        "Property State": propertyState,
        "Name Word Count": nameWordCount,
        "Input Value Length": inputValueLength,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Added", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Event Id": eventId,
              "Event Name": eventName,
              "Name Length": nameLength,
              "Property State": propertyState,
              "Name Word Count": nameWordCount,
              "Input Value Length": inputValueLength,
              "Property Location": propertyLocationToJs(propertyLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function navigated(customDestinationPageName_, toScreen, fromScreen, fromSchemaId, toSchemaId, fromBranchId, toBranchId) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("4c9f4e62-cd30-41f0-bd4b-48d1799df07d", "0c66e528e9f2c2c5417853f08bdcf6a68968ff0f772f46d3620780a7e6be8000", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Navigated", {
          "To Screen": toScreenToJs(toScreen),
          "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
          "From Schema Id": fromSchemaId,
          "To Schema Id": toSchemaId,
          "From Branch Id": fromBranchId,
          "To Branch Id": toBranchId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("4c9f4e62-cd30-41f0-bd4b-48d1799df07d", "Navigated", messages, [
            {
              id: "1ff0f447-d048-41c4-92f8-b6703e8d6fd1",
              name: "To Screen",
              value: toScreenToJs(toScreen)
            },
            {
              id: "bffcc6e2-e330-4112-bce3-6f1d64373830",
              name: "From Screen",
              value: Belt_Option.map(fromScreen, fromScreenToJs)
            },
            {
              id: "REX6Pobze",
              name: "From Schema Id",
              value: fromSchemaId
            },
            {
              id: "AttR95guJ",
              name: "To Schema Id",
              value: toSchemaId
            },
            {
              id: "I9nc4hXXE",
              name: "From Branch Id",
              value: fromBranchId
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("4c9f4e62-cd30-41f0-bd4b-48d1799df07d", Date.now(), "Navigated", messages, [
            {
              id: "1ff0f447-d048-41c4-92f8-b6703e8d6fd1",
              name: "To Screen",
              value: toScreenToJs(toScreen)
            },
            {
              id: "bffcc6e2-e330-4112-bce3-6f1d64373830",
              name: "From Screen",
              value: Belt_Option.map(fromScreen, fromScreenToJs)
            },
            {
              id: "REX6Pobze",
              name: "From Schema Id",
              value: fromSchemaId
            },
            {
              id: "AttR95guJ",
              name: "To Schema Id",
              value: toSchemaId
            },
            {
              id: "I9nc4hXXE",
              name: "From Branch Id",
              value: fromBranchId
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Navigated", {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(postHog.contents).logPage, customDestinationPageName_, {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {
    "To Screen": toScreenToJs(toScreen)
  };
  logEvent("Navigated", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Navigated", {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(segment.contents).logPage, customDestinationPageName_, {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Navigated", {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Navigated", {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logPage, customDestinationPageName_, {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Navigated", {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logPage, customDestinationPageName_, {
        "To Screen": toScreenToJs(toScreen),
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        "From Schema Id": fromSchemaId,
        "To Schema Id": toSchemaId,
        "From Branch Id": fromBranchId,
        "To Branch Id": toBranchId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Navigated", {
              "To Screen": toScreenToJs(toScreen),
              "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
              "From Schema Id": fromSchemaId,
              "To Schema Id": toSchemaId,
              "From Branch Id": fromBranchId,
              "To Branch Id": toBranchId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function signedIn(userId_, email, authenticationMethod) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("54e92613-090c-4f0b-afeb-ed720eff3422", "f7e3224c7c10b07ed51f71947bb7861e85ec156c8ed8e49a9502994f73bd1c04", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Signed In", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          Email: email
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("54e92613-090c-4f0b-afeb-ed720eff3422", "Signed In", messages, [
            {
              id: "R9pE83vdh",
              name: "Authentication Method",
              value: authenticationMethodToJs(authenticationMethod)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([{
                  id: "92588d93-5307-4fa2-be00-be0821596abe",
                  name: "Email",
                  value: email
                }], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("54e92613-090c-4f0b-afeb-ed720eff3422", Date.now(), "Signed In", messages, [
            {
              id: "R9pE83vdh",
              name: "Authentication Method",
              value: authenticationMethodToJs(authenticationMethod)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [{
              id: "92588d93-5307-4fa2-be00-be0821596abe",
              name: "Email",
              value: email
            }]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._1(Belt_Option.getExn(postHog.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Signed In", {
        "Authentication Method": authenticationMethodToJs(authenticationMethod),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify(userId_);
  var intercomUserProperties = {
    Email: email
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {};
  logEvent("Signed In", intercomProperties);
  Curry._1(Belt_Option.getExn(segment.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Signed In", {
        "Authentication Method": authenticationMethodToJs(authenticationMethod),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(clearbit.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(clearbit.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._2(Belt_Option.getExn(clearbit.contents).logEvent, "Signed In", {
        "Authentication Method": authenticationMethodToJs(authenticationMethod),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify$1(userId_);
  setUserProperties$1({
        displayName: email
      });
  logEvent$1("Signed In", {
        "Authentication Method": authenticationMethodToJs(authenticationMethod),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(intercomDestinationInterface.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Signed In", {
        "Authentication Method": authenticationMethodToJs(authenticationMethod),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(inspectorCustomDestination.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Signed In", {
        "Authentication Method": authenticationMethodToJs(authenticationMethod),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify$2(userId_);
  setUserProperties$2({
        Email: email
      });
  return logEvent$2("Signed In", {
              "Authentication Method": authenticationMethodToJs(authenticationMethod),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function membersClicked(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("62229772-6810-46a9-88f8-8d44066f5632", "4a04455682858d2448d56f6e11358c41a29fec904890626004cefb4c3fe7cccf", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Members Clicked", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("62229772-6810-46a9-88f8-8d44066f5632", "Members Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("62229772-6810-46a9-88f8-8d44066f5632", Date.now(), "Members Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Members Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Members Clicked", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Members Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Members Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Members Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Members Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Members Clicked", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function upgradePlanPrompted(schema, paywallName, upgradePromptType, pageName, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceBillingStatus, workspaceTrialPlan, workspaceSubscriptionPlanSlug, workspaceSubscriptionPlan, workspaceTrialPlanSlug, workspaceName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("64038ddd-7d92-4408-a881-ddb7c8317d04", "416efce1a085b9d65063f5e6c2b264903cff1e70b21a63caecb9cfc5eb210ad1", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Upgrade Plan Prompted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Paywall Name": paywallNameToJs(paywallName),
          "Upgrade Prompt Type": upgradePromptType,
          "Page Name": pageNameToJs(pageName),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("64038ddd-7d92-4408-a881-ddb7c8317d04", "Upgrade Plan Prompted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "nSW3_5PPhK",
              name: "Paywall Name",
              value: paywallNameToJs(paywallName)
            },
            {
              id: "KNdIP7W9b",
              name: "Upgrade Prompt Type",
              value: upgradePromptType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "JMzL9twjbS",
                  name: "Workspace Billing Status (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceBillingStatus
                },
                {
                  id: "iqJjwnPpbS",
                  name: "Workspace Trial Plan (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs)
                },
                {
                  id: "DacluSrVBD",
                  name: "Workspace Subscription Plan Slug (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceSubscriptionPlanSlugToJs(workspaceSubscriptionPlanSlug)
                },
                {
                  id: "2eAp5ia9Zc",
                  name: "Workspace Subscription Plan (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceSubscriptionPlan
                },
                {
                  id: "77ZuKOaP1j",
                  name: "Workspace Trial Plan Slug (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceTrialPlanSlug, workspaceTrialPlanSlugToJs)
                },
                {
                  id: "EHjllhnus",
                  name: "Workspace Name (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceName
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("64038ddd-7d92-4408-a881-ddb7c8317d04", Date.now(), "Upgrade Plan Prompted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "nSW3_5PPhK",
              name: "Paywall Name",
              value: paywallNameToJs(paywallName)
            },
            {
              id: "KNdIP7W9b",
              name: "Upgrade Prompt Type",
              value: upgradePromptType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan Slug": workspaceSubscriptionPlanSlugToJs(workspaceSubscriptionPlanSlug),
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Trial Plan Slug": Belt_Option.map(workspaceTrialPlanSlug, workspaceTrialPlanSlugToJs),
        "Workspace Name": workspaceName
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Upgrade Plan Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Paywall Name": paywallNameToJs(paywallName),
        "Upgrade Prompt Type": upgradePromptType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus,
    "Schema Subscription Plan": schema.schemaSubscriptionPlan,
    "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
    "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
    "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
  };
  logEvent("Upgrade Plan Prompted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan Slug": workspaceSubscriptionPlanSlugToJs(workspaceSubscriptionPlanSlug),
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Trial Plan Slug": Belt_Option.map(workspaceTrialPlanSlug, workspaceTrialPlanSlugToJs),
        "Workspace Name": workspaceName
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Upgrade Plan Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Paywall Name": paywallNameToJs(paywallName),
        "Upgrade Prompt Type": upgradePromptType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Upgrade Plan Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Paywall Name": paywallNameToJs(paywallName),
        "Upgrade Prompt Type": upgradePromptType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan Slug": workspaceSubscriptionPlanSlugToJs(workspaceSubscriptionPlanSlug),
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Trial Plan Slug": Belt_Option.map(workspaceTrialPlanSlug, workspaceTrialPlanSlugToJs),
        "Workspace Name": workspaceName
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Upgrade Plan Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Paywall Name": paywallNameToJs(paywallName),
        "Upgrade Prompt Type": upgradePromptType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan Slug": workspaceSubscriptionPlanSlugToJs(workspaceSubscriptionPlanSlug),
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Trial Plan Slug": Belt_Option.map(workspaceTrialPlanSlug, workspaceTrialPlanSlugToJs),
        "Workspace Name": workspaceName
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Upgrade Plan Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Paywall Name": paywallNameToJs(paywallName),
        "Upgrade Prompt Type": upgradePromptType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan Slug": workspaceSubscriptionPlanSlugToJs(workspaceSubscriptionPlanSlug),
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Trial Plan Slug": Belt_Option.map(workspaceTrialPlanSlug, workspaceTrialPlanSlugToJs),
        "Workspace Name": workspaceName
      });
  return logEventWithGroups("Upgrade Plan Prompted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Paywall Name": paywallNameToJs(paywallName),
              "Upgrade Prompt Type": upgradePromptType,
              "Page Name": pageNameToJs(pageName),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function cancelSubscriptionButtonClicked(schema, subscriptionPlan, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("6c7460f6-c7fd-4f7b-ac7a-38b433d77427", "e39ba257fca115c9ae3aec5a64600857185b29a53e88153fbb9e0ca0cd47f914", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Cancel Subscription Button Clicked", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("6c7460f6-c7fd-4f7b-ac7a-38b433d77427", "Cancel Subscription Button Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("6c7460f6-c7fd-4f7b-ac7a-38b433d77427", Date.now(), "Cancel Subscription Button Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Cancel Subscription Button Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Cancel Subscription Button Clicked", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Cancel Subscription Button Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Cancel Subscription Button Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Cancel Subscription Button Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Cancel Subscription Button Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Cancel Subscription Button Clicked", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventInitiated(currentFilters, schema, eventId, eventName, correctCase, namingConvention, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("73fdf2d0-7ec6-4702-87cd-d117142240fd", "bb9c40bdff82b025c57241567459263dd99d4ebdb3ed757d8d08e3ccf6c401d8", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Initiated", {
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Correct Case": correctCase,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("73fdf2d0-7ec6-4702-87cd-d117142240fd", "Event Initiated", messages, [
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("73fdf2d0-7ec6-4702-87cd-d117142240fd", Date.now(), "Event Initiated", messages, [
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Initiated", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Initiated", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Initiated", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Initiated", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Initiated", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Initiated", {
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Correct Case": correctCase,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function signInFailed(authenticationError, emailInput) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("7aa64217-bb89-44f5-9a68-f3bc0255a864", "3e27ec1871ba7f339c748de934eafe226c36804f97f1715ef3a18f7a4cc77039", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sign In Failed", {
          "Authentication Error": authenticationErrorToJs(authenticationError),
          "Email Input": emailInput,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("7aa64217-bb89-44f5-9a68-f3bc0255a864", "Sign In Failed", messages, [
            {
              id: "106d4596-7330-49f3-93d8-487c92f877d3",
              name: "Authentication Error",
              value: authenticationErrorToJs(authenticationError)
            },
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("7aa64217-bb89-44f5-9a68-f3bc0255a864", Date.now(), "Sign In Failed", messages, [
            {
              id: "106d4596-7330-49f3-93d8-487c92f877d3",
              name: "Authentication Error",
              value: authenticationErrorToJs(authenticationError)
            },
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sign In Failed", {
        "Authentication Error": authenticationErrorToJs(authenticationError),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sign In Failed", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sign In Failed", {
        "Authentication Error": authenticationErrorToJs(authenticationError),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sign In Failed", {
        "Authentication Error": authenticationErrorToJs(authenticationError),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sign In Failed", {
        "Authentication Error": authenticationErrorToJs(authenticationError),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sign In Failed", {
        "Authentication Error": authenticationErrorToJs(authenticationError),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sign In Failed", {
              "Authentication Error": authenticationErrorToJs(authenticationError),
              "Email Input": emailInput,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function typeChanged(schema, eventName, to_, from, propertyName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("88acb502-c5c6-42b6-9d2b-79c61cd9e062", "381c0bba627c2447a392ae57dd70e1ec17505020ca6016a468fd28fea8e9efdb", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Type Changed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Name": eventName,
          To: to_ToJs(to_),
          From: fromToJs(from),
          "Property Name": propertyName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("88acb502-c5c6-42b6-9d2b-79c61cd9e062", "Type Changed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "823aca20-39f3-40df-895d-32688687f584",
              name: "To",
              value: to_ToJs(to_)
            },
            {
              id: "f503536f-99f6-41b5-8d2c-0a05332fc353",
              name: "From",
              value: fromToJs(from)
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("88acb502-c5c6-42b6-9d2b-79c61cd9e062", Date.now(), "Type Changed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "823aca20-39f3-40df-895d-32688687f584",
              name: "To",
              value: to_ToJs(to_)
            },
            {
              id: "f503536f-99f6-41b5-8d2c-0a05332fc353",
              name: "From",
              value: fromToJs(from)
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Type Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        To: to_ToJs(to_),
        From: fromToJs(from),
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Type Changed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Type Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        To: to_ToJs(to_),
        From: fromToJs(from),
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Type Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        To: to_ToJs(to_),
        From: fromToJs(from),
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Type Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        To: to_ToJs(to_),
        From: fromToJs(from),
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Type Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        To: to_ToJs(to_),
        From: fromToJs(from),
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Type Changed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Name": eventName,
              To: to_ToJs(to_),
              From: fromToJs(from),
              "Property Name": propertyName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function requiredToggled(schema, changedTo, eventName, propertyName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("8e847a4c-81b6-41fa-94e6-2b789d3367e5", "8fce0e96b8e74808170f2636acc2c1e36b2579a9ead937869e6d44e1271985f2", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Required Toggled", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Changed To": changedTo,
          "Event Name": eventName,
          "Property Name": propertyName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("8e847a4c-81b6-41fa-94e6-2b789d3367e5", "Required Toggled", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "30a912a0-2983-49b0-9e88-c1036c26eb8a",
              name: "Changed To",
              value: changedTo
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("8e847a4c-81b6-41fa-94e6-2b789d3367e5", Date.now(), "Required Toggled", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "30a912a0-2983-49b0-9e88-c1036c26eb8a",
              name: "Changed To",
              value: changedTo
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Required Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Changed To": changedTo,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Required Toggled", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Required Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Changed To": changedTo,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Required Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Changed To": changedTo,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Required Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Changed To": changedTo,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Required Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Changed To": changedTo,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Required Toggled", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Changed To": changedTo,
              "Event Name": eventName,
              "Property Name": propertyName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function memberInvited(schema, role, emailAddress, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("a0408c38-0de8-4ef0-b80d-db5e00c29a59", "a7c299472db12870563ad8da592046b4e108c68799ac4c8e578b0e74f9075b35", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Member Invited", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Role: roleToJs(role),
          "Email Address": emailAddress,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("a0408c38-0de8-4ef0-b80d-db5e00c29a59", "Member Invited", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "c6b1621c-7d08-4dd0-8c8b-e0eaa6e64148",
              name: "Email Address",
              value: emailAddress
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("a0408c38-0de8-4ef0-b80d-db5e00c29a59", Date.now(), "Member Invited", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "c6b1621c-7d08-4dd0-8c8b-e0eaa6e64148",
              name: "Email Address",
              value: emailAddress
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Member Invited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus
  };
  logEvent("Member Invited", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Member Invited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Member Invited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Member Invited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Member Invited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Member Invited", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Role: roleToJs(role),
              "Email Address": emailAddress,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventRemoved(schema, currentFilters, eventId, eventName, numProperties, correctCase, namingConvention, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumProperties(numProperties));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("a19bc008-21e5-42ac-a142-5cfa491af7b1", "7aaae446af03a9fe08c93619588567a79760f8179f0bc747383e773d77b3ed6f", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Removed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Event Id": eventId,
          "Event Name": eventName,
          "Num Properties": numProperties,
          "Correct Case": correctCase,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("a19bc008-21e5-42ac-a142-5cfa491af7b1", "Event Removed", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: numProperties
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("a19bc008-21e5-42ac-a142-5cfa491af7b1", Date.now(), "Event Removed", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: numProperties
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Properties": numProperties,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Removed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Properties": numProperties,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Properties": numProperties,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Properties": numProperties,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Properties": numProperties,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Removed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Event Id": eventId,
              "Event Name": eventName,
              "Num Properties": numProperties,
              "Correct Case": correctCase,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function signUpFailed(emailInput, authenticationError) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ae94e94e-8b63-4f3b-9b49-7ea47c442256", "91c560478c49c0a0ba1fded989f388a7912fdf9627abe41c47837576ccf2abc0", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sign Up Failed", {
          "Email Input": emailInput,
          "Authentication Error": authenticationErrorToJs(authenticationError),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ae94e94e-8b63-4f3b-9b49-7ea47c442256", "Sign Up Failed", messages, [
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "106d4596-7330-49f3-93d8-487c92f877d3",
              name: "Authentication Error",
              value: authenticationErrorToJs(authenticationError)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ae94e94e-8b63-4f3b-9b49-7ea47c442256", Date.now(), "Sign Up Failed", messages, [
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "106d4596-7330-49f3-93d8-487c92f877d3",
              name: "Authentication Error",
              value: authenticationErrorToJs(authenticationError)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sign Up Failed", {
        "Email Input": emailInput,
        "Authentication Error": authenticationErrorToJs(authenticationError),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sign Up Failed", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sign Up Failed", {
        "Email Input": emailInput,
        "Authentication Error": authenticationErrorToJs(authenticationError),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sign Up Failed", {
        "Email Input": emailInput,
        "Authentication Error": authenticationErrorToJs(authenticationError),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sign Up Failed", {
        "Email Input": emailInput,
        "Authentication Error": authenticationErrorToJs(authenticationError),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sign Up Failed", {
        "Email Input": emailInput,
        "Authentication Error": authenticationErrorToJs(authenticationError),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sign Up Failed", {
              "Email Input": emailInput,
              "Authentication Error": authenticationErrorToJs(authenticationError),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function subscriptionInterestIndicated(schema, daysSinceTrialStart, trialLength, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertDaysSinceTrialStart(daysSinceTrialStart));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("b8d025f4-beb5-4dc3-8032-cf6c0d643667", "3e50d8653db1618a5374fd08dd6443635991d80ee44ccbf494ce16e62695cdc7", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Subscription Interest Indicated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Days Since Trial Start": daysSinceTrialStart,
          "Trial Length": trialLength,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("b8d025f4-beb5-4dc3-8032-cf6c0d643667", "Subscription Interest Indicated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5a7bc4ac-8f1d-4f25-947e-0a8283aa1c5a",
              name: "Days Since Trial Start",
              value: daysSinceTrialStart
            },
            {
              id: "713732cb-8c41-43c9-916b-0a705718a547",
              name: "Trial Length",
              value: trialLength
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("b8d025f4-beb5-4dc3-8032-cf6c0d643667", Date.now(), "Subscription Interest Indicated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5a7bc4ac-8f1d-4f25-947e-0a8283aa1c5a",
              name: "Days Since Trial Start",
              value: daysSinceTrialStart
            },
            {
              id: "713732cb-8c41-43c9-916b-0a705718a547",
              name: "Trial Length",
              value: trialLength
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Subscription Interest Indicated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Days Since Trial Start": daysSinceTrialStart,
        "Trial Length": trialLength,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Subscription Interest Indicated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Subscription Interest Indicated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Days Since Trial Start": daysSinceTrialStart,
        "Trial Length": trialLength,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Subscription Interest Indicated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Days Since Trial Start": daysSinceTrialStart,
        "Trial Length": trialLength,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Subscription Interest Indicated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Days Since Trial Start": daysSinceTrialStart,
        "Trial Length": trialLength,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Subscription Interest Indicated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Days Since Trial Start": daysSinceTrialStart,
        "Trial Length": trialLength,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Subscription Interest Indicated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Days Since Trial Start": daysSinceTrialStart,
              "Trial Length": trialLength,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function signedOut(param) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("bf8066de-042c-4d92-a07c-a5f9cce48848", "7cd50fe462108cacc855fd74755622aafbd3360e5bd7a2f8a8cc397aa03ac5e0", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Signed Out", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("bf8066de-042c-4d92-a07c-a5f9cce48848", "Signed Out", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("bf8066de-042c-4d92-a07c-a5f9cce48848", Date.now(), "Signed Out", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Signed Out", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(postHog.contents).unidentify, undefined);
  var intercomProperties = {};
  logEvent("Signed Out", intercomProperties);
  unidentify(undefined);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Signed Out", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(segment.contents).unidentify, undefined);
  logEvent$1("Signed Out", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  unidentify$1(undefined);
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Signed Out", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(intercomDestinationInterface.contents).unidentify, undefined);
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Signed Out", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(inspectorCustomDestination.contents).unidentify, undefined);
  logEvent$2("Signed Out", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return unidentify$2(undefined);
}

function eventNameUpdated(schema, currentFilters, eventId, eventName, namingConvention, oldEventName, nameLength, nameWordCount, correctCase, associateEventWithEvent, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForEvent, eventGroupEventName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("c8c1784a-6f52-42cb-8b35-8712b14e1fcf", "adc05173f3e14ceab210d728bde5446829c846752d29963f99bfdeeec546dc46", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Name Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Event Id": eventId,
          "Event Name": eventName,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          "Old Event Name": oldEventName,
          "Name Length": nameLength,
          "Name Word Count": nameWordCount,
          "Correct Case": correctCase,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("c8c1784a-6f52-42cb-8b35-8712b14e1fcf", "Event Name Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "39a05b90-fa38-4934-a96a-b906677ac62d",
              name: "Old Event Name",
              value: oldEventName
            },
            {
              id: "577d9179-045e-4cb3-a835-ddc8c3eb0ce3",
              name: "Name Length",
              value: nameLength
            },
            {
              id: "df08657a-d3fb-4e09-a49e-0cf6e64ada7b",
              name: "Name Word Count",
              value: nameWordCount
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "nDNV1U81H",
                  name: "Event Name (Event " + (updateGroupPropertiesForEvent + " group property)"),
                  value: eventGroupEventName
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("c8c1784a-6f52-42cb-8b35-8712b14e1fcf", Date.now(), "Event Name Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "39a05b90-fa38-4934-a96a-b906677ac62d",
              name: "Old Event Name",
              value: oldEventName
            },
            {
              id: "577d9179-045e-4cb3-a835-ddc8c3eb0ce3",
              name: "Name Length",
              value: nameLength
            },
            {
              id: "df08657a-d3fb-4e09-a49e-0cf6e64ada7b",
              name: "Name Word Count",
              value: nameWordCount
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Name Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  return logEventWithGroups("Event Name Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Event Id": eventId,
              "Event Name": eventName,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              "Old Event Name": oldEventName,
              "Name Length": nameLength,
              "Name Word Count": nameWordCount,
              "Correct Case": correctCase,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Event: associateEventWithEvent,
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventNameUpdatedAdvancedStructure(schema, currentFilters, eventId, eventName, oldEventName, nameLength, nameWordCount, correctCase, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, associateEventWithEvent, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForEvent, eventGroupEventName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("c8c1784a-6f52-42cb-8b35-8712b14e1fcf.G09H9Sg1b", "a153db661c325fb77aaa8800d7a54840d8674f2523d3ee960e4b562b29f1d8af", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Name Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Naming Convention": "Advanced",
          "Event Id": eventId,
          "Event Name": eventName,
          "Old Event Name": oldEventName,
          "Name Length": nameLength,
          "Name Word Count": nameWordCount,
          "Correct Case": correctCase,
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("c8c1784a-6f52-42cb-8b35-8712b14e1fcf.G09H9Sg1b", "Event Name Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: "Advanced"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "39a05b90-fa38-4934-a96a-b906677ac62d",
              name: "Old Event Name",
              value: oldEventName
            },
            {
              id: "577d9179-045e-4cb3-a835-ddc8c3eb0ce3",
              name: "Name Length",
              value: nameLength
            },
            {
              id: "df08657a-d3fb-4e09-a49e-0cf6e64ada7b",
              name: "Name Word Count",
              value: nameWordCount
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "nDNV1U81H",
                  name: "Event Name (Event " + (updateGroupPropertiesForEvent + " group property)"),
                  value: eventGroupEventName
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("c8c1784a-6f52-42cb-8b35-8712b14e1fcf.G09H9Sg1b", Date.now(), "Event Name Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: "Advanced"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "39a05b90-fa38-4934-a96a-b906677ac62d",
              name: "Old Event Name",
              value: oldEventName
            },
            {
              id: "577d9179-045e-4cb3-a835-ddc8c3eb0ce3",
              name: "Name Length",
              value: nameLength
            },
            {
              id: "df08657a-d3fb-4e09-a49e-0cf6e64ada7b",
              name: "Name Word Count",
              value: nameWordCount
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Naming Convention": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Name Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Naming Convention": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Naming Convention": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Naming Convention": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Naming Convention": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Old Event Name": oldEventName,
        "Name Length": nameLength,
        "Name Word Count": nameWordCount,
        "Correct Case": correctCase,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Event", updateGroupPropertiesForEvent, {
        "Event Name": eventGroupEventName
      });
  return logEventWithGroups("Event Name Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Naming Convention": "Advanced",
              "Event Id": eventId,
              "Event Name": eventName,
              "Old Event Name": oldEventName,
              "Name Length": nameLength,
              "Name Word Count": nameWordCount,
              "Correct Case": correctCase,
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Event: associateEventWithEvent,
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function signedUp(userId_, email, authenticationMethod, referrer, userName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("d19b67bb-1875-4d24-9453-d6650a64b486", "9cd035ea320d17979b6b40ce4fb2df8cfc89894f17c1e5ff90da2837839e8491", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Signed Up", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          Email: email,
          "User Name": userName
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("d19b67bb-1875-4d24-9453-d6650a64b486", "Signed Up", messages, [
            {
              id: "R9pE83vdh",
              name: "Authentication Method",
              value: authenticationMethodToJs(authenticationMethod)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([
                {
                  id: "92588d93-5307-4fa2-be00-be0821596abe",
                  name: "Email",
                  value: email
                },
                {
                  id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
                  name: "User Name",
                  value: userName
                }
              ], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("d19b67bb-1875-4d24-9453-d6650a64b486", Date.now(), "Signed Up", messages, [
            {
              id: "R9pE83vdh",
              name: "Authentication Method",
              value: authenticationMethodToJs(authenticationMethod)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [
            {
              id: "92588d93-5307-4fa2-be00-be0821596abe",
              name: "Email",
              value: email
            },
            {
              id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
              name: "User Name",
              value: userName
            }
          ]);
    }
    
  }
  if (!__AVO_NOOP__.contents) {
    Curry._1(Belt_Option.getExn(postHog.contents).identify, userId_);
    Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
          Email: email,
          "User Name": userName
        });
    Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Signed Up", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        });
    Curry._1(Belt_Option.getExn(segment.contents).identify, userId_);
    Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
          Email: email,
          "User Name": userName
        });
    Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Signed Up", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        });
    Curry._1(Belt_Option.getExn(clearbit.contents).identify, userId_);
    Curry._2(Belt_Option.getExn(clearbit.contents).setUserProperties, userId_, {
          Email: email,
          "User Name": userName
        });
    Curry._2(Belt_Option.getExn(clearbit.contents).logEvent, "Signed Up", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        });
    identify$1(userId_);
    setUserProperties$1({
          displayName: email,
          "User Name": userName
        });
    logEvent$1("Signed Up", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        });
    Curry._1(Belt_Option.getExn(intercomDestinationInterface.contents).identify, userId_);
    Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
          Email: email,
          name: userName
        });
    Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Signed Up", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        });
    Curry._1(Belt_Option.getExn(inspectorCustomDestination.contents).identify, userId_);
    Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
          Email: email,
          "User Name": userName
        });
    Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Signed Up", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        });
    identify$2(userId_);
    setUserProperties$2({
          Email: email,
          "User Name": userName
        });
    setUserPropertiesOnce({
          Referrer: referrer
        });
    return logEvent$2("Signed Up", {
                "Authentication Method": authenticationMethodToJs(authenticationMethod),
                Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
                Version: Belt_Option.getExn(sysVersion.contents)
              });
  }
  
}

function propertyRemoved(schema, property, eventId, eventName, propertyLocation, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("d3476b81-f437-424b-8255-d383e6e84c0b", "b4fdcfa8c19235c60df62e13432cab16a520e480f72050dc20230f4fe0663de3", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Removed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Event Id": eventId,
          "Event Name": eventName,
          "Property Location": propertyLocationToJs(propertyLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("d3476b81-f437-424b-8255-d383e6e84c0b", "Property Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("d3476b81-f437-424b-8255-d383e6e84c0b", Date.now(), "Property Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Event Id": eventId,
        "Event Name": eventName,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Removed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Event Id": eventId,
        "Event Name": eventName,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Event Id": eventId,
        "Event Name": eventName,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Event Id": eventId,
        "Event Name": eventName,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Event Id": eventId,
        "Event Name": eventName,
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Removed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Event Id": eventId,
              "Event Name": eventName,
              "Property Location": propertyLocationToJs(propertyLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function descriptionUpdated(schema, oldDescription, describedObject, descriptionWordCount, newDescription, descriptionLength, objectName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("d707dcc5-dfb2-4750-bb4b-21307525cc08", "50ff57034db2a2f8bd3dbcee974d1a969f8453fc2ad5f1ab742c0d4150bcec6a", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Description Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Old Description": oldDescription,
          "Described Object": describedObject,
          "Description Word Count": descriptionWordCount,
          "New Description": newDescription,
          "Description Length": descriptionLength,
          "Object Name": objectName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("d707dcc5-dfb2-4750-bb4b-21307525cc08", "Description Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "94c22f23-1d80-4294-a744-fc1e3f5a1921",
              name: "Old Description",
              value: oldDescription
            },
            {
              id: "9be5623e-19e4-44bc-84a6-e1986513ad9d",
              name: "Described Object",
              value: describedObject
            },
            {
              id: "c87a7e00-dac3-4f0b-b053-5ca77794ad96",
              name: "Description Word Count",
              value: descriptionWordCount
            },
            {
              id: "cb0d1abc-b923-43b1-9a8f-1f4f5fdd14f5",
              name: "New Description",
              value: newDescription
            },
            {
              id: "dd9181b5-dd04-467f-a09e-28750a48ee0f",
              name: "Description Length",
              value: descriptionLength
            },
            {
              id: "edd671ea-63ec-4e37-b2eb-efadb8316e49",
              name: "Object Name",
              value: objectName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("d707dcc5-dfb2-4750-bb4b-21307525cc08", Date.now(), "Description Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "94c22f23-1d80-4294-a744-fc1e3f5a1921",
              name: "Old Description",
              value: oldDescription
            },
            {
              id: "9be5623e-19e4-44bc-84a6-e1986513ad9d",
              name: "Described Object",
              value: describedObject
            },
            {
              id: "c87a7e00-dac3-4f0b-b053-5ca77794ad96",
              name: "Description Word Count",
              value: descriptionWordCount
            },
            {
              id: "cb0d1abc-b923-43b1-9a8f-1f4f5fdd14f5",
              name: "New Description",
              value: newDescription
            },
            {
              id: "dd9181b5-dd04-467f-a09e-28750a48ee0f",
              name: "Description Length",
              value: descriptionLength
            },
            {
              id: "edd671ea-63ec-4e37-b2eb-efadb8316e49",
              name: "Object Name",
              value: objectName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Description Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Old Description": oldDescription,
        "Described Object": describedObject,
        "Description Word Count": descriptionWordCount,
        "New Description": newDescription,
        "Description Length": descriptionLength,
        "Object Name": objectName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Description Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Description Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Old Description": oldDescription,
        "Described Object": describedObject,
        "Description Word Count": descriptionWordCount,
        "New Description": newDescription,
        "Description Length": descriptionLength,
        "Object Name": objectName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Description Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Old Description": oldDescription,
        "Described Object": describedObject,
        "Description Word Count": descriptionWordCount,
        "New Description": newDescription,
        "Description Length": descriptionLength,
        "Object Name": objectName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Description Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Old Description": oldDescription,
        "Described Object": describedObject,
        "Description Word Count": descriptionWordCount,
        "New Description": newDescription,
        "Description Length": descriptionLength,
        "Object Name": objectName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Description Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Old Description": oldDescription,
        "Described Object": describedObject,
        "Description Word Count": descriptionWordCount,
        "New Description": newDescription,
        "Description Length": descriptionLength,
        "Object Name": objectName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Description Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Old Description": oldDescription,
              "Described Object": describedObject,
              "Description Word Count": descriptionWordCount,
              "New Description": newDescription,
              "Description Length": descriptionLength,
              "Object Name": objectName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function constraintTypeUpdated(schema, toConstraintType, constraintAction, fromConstraintType, propertyName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("e8fc3493-f29a-4b37-8c6e-571d60201364", "eb6110225f69fe8a300e2ca62e91fe2fb5dba55b4f83441fe0277f81d5a63871", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Constraint Type Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "To Constraint Type": toConstraintType,
          "Constraint Action": constraintAction,
          "From Constraint Type": fromConstraintType,
          "Property Name": propertyName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("e8fc3493-f29a-4b37-8c6e-571d60201364", "Constraint Type Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "ae83013e-fb4a-4982-a353-cd009261770a",
              name: "To Constraint Type",
              value: toConstraintType
            },
            {
              id: "dc5a4ed6-079a-4867-95d0-7641e3b1c530",
              name: "Constraint Action",
              value: constraintAction
            },
            {
              id: "e4f3a6d5-f92f-4e2d-9509-02683e7ceddb",
              name: "From Constraint Type",
              value: fromConstraintType
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("e8fc3493-f29a-4b37-8c6e-571d60201364", Date.now(), "Constraint Type Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "ae83013e-fb4a-4982-a353-cd009261770a",
              name: "To Constraint Type",
              value: toConstraintType
            },
            {
              id: "dc5a4ed6-079a-4867-95d0-7641e3b1c530",
              name: "Constraint Action",
              value: constraintAction
            },
            {
              id: "e4f3a6d5-f92f-4e2d-9509-02683e7ceddb",
              name: "From Constraint Type",
              value: fromConstraintType
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Constraint Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Constraint Type": toConstraintType,
        "Constraint Action": constraintAction,
        "From Constraint Type": fromConstraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Constraint Type Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Constraint Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Constraint Type": toConstraintType,
        "Constraint Action": constraintAction,
        "From Constraint Type": fromConstraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Constraint Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Constraint Type": toConstraintType,
        "Constraint Action": constraintAction,
        "From Constraint Type": fromConstraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Constraint Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Constraint Type": toConstraintType,
        "Constraint Action": constraintAction,
        "From Constraint Type": fromConstraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Constraint Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Constraint Type": toConstraintType,
        "Constraint Action": constraintAction,
        "From Constraint Type": fromConstraintType,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Constraint Type Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "To Constraint Type": toConstraintType,
              "Constraint Action": constraintAction,
              "From Constraint Type": fromConstraintType,
              "Property Name": propertyName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function appOpened(param) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ebe0fc5c-6171-4974-8351-097769f82cea", "87adf53dd2654e968d381b5eaf9fa6bc22be5eabad75f52acf2573ec8f6dbf1b", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("App Opened", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ebe0fc5c-6171-4974-8351-097769f82cea", "App Opened", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ebe0fc5c-6171-4974-8351-097769f82cea", Date.now(), "App Opened", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "App Opened", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("App Opened", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "App Opened", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("App Opened", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "App Opened", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "App Opened", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("App Opened", {
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function inviteAccepted(schema, userId_, invitedBy, userRole, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("fc3ff08c-83d5-4da9-a298-b62e76737fca", "5bc3e360cceaa9e646f3f84698c9877b92a854f47ce992aca1c1ac5b6a1ab984", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Invite Accepted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Invited By": invitedBy,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          "User Role": userRoleToJs(userRole)
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("fc3ff08c-83d5-4da9-a298-b62e76737fca", "Invite Accepted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bba3fd90-d5f9-44c3-94fb-7da0395a7837",
              name: "Invited By",
              value: invitedBy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([{
                  id: "2bAS7lA72H",
                  name: "User Role",
                  value: userRoleToJs(userRole)
                }], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("fc3ff08c-83d5-4da9-a298-b62e76737fca", Date.now(), "Invite Accepted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bba3fd90-d5f9-44c3-94fb-7da0395a7837",
              name: "Invited By",
              value: invitedBy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [{
              id: "2bAS7lA72H",
              name: "User Role",
              value: userRoleToJs(userRole)
            }]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        "User Role": userRoleToJs(userRole)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Invite Accepted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomUserProperties = {
    "User Role": userRoleToJs(userRole)
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {};
  logEvent("Invite Accepted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
        "User Role": userRoleToJs(userRole)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Invite Accepted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setUserProperties$1({
        "User Role": userRoleToJs(userRole)
      });
  logEvent$1("Invite Accepted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        "User Role": userRoleToJs(userRole)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Invite Accepted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
        "User Role": userRoleToJs(userRole)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Invite Accepted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setUserProperties$2({
        "User Role": userRoleToJs(userRole)
      });
  return logEventWithGroups("Invite Accepted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Invited By": invitedBy,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function schemaNotFound(pendingInvite, schemaId) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("71835e77-ea67-4fcb-acab-28ab4ad382ef", "92c3c7b124ed0243e129f92f3030de976c0915e98dc9fd027a79a3d279154759", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Schema Not Found", {
          "Pending Invite": pendingInvite,
          "Schema Id": schemaId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("71835e77-ea67-4fcb-acab-28ab4ad382ef", "Schema Not Found", messages, [
            {
              id: "a630799d-5b8b-460c-a861-d6195da94680",
              name: "Pending Invite",
              value: pendingInvite
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schemaId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("71835e77-ea67-4fcb-acab-28ab4ad382ef", Date.now(), "Schema Not Found", messages, [
            {
              id: "a630799d-5b8b-460c-a861-d6195da94680",
              name: "Pending Invite",
              value: pendingInvite
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schemaId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Schema Not Found", {
        "Pending Invite": pendingInvite,
        "Schema Id": schemaId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Schema Not Found", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Schema Not Found", {
        "Pending Invite": pendingInvite,
        "Schema Id": schemaId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Schema Not Found", {
        "Pending Invite": pendingInvite,
        "Schema Id": schemaId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Schema Not Found", {
        "Pending Invite": pendingInvite,
        "Schema Id": schemaId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Schema Not Found", {
        "Pending Invite": pendingInvite,
        "Schema Id": schemaId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Schema Not Found", {
              "Pending Invite": pendingInvite,
              "Schema Id": schemaId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function signUpVerifyRequested(param) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("e1bd9589-a613-462e-b35c-744f9f2442aa", "66e1c632f5266e5bd5b88e9f225c06581ea42b84457633fd22cfa4ea62f4108d", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sign Up Verify Requested", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("e1bd9589-a613-462e-b35c-744f9f2442aa", "Sign Up Verify Requested", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("e1bd9589-a613-462e-b35c-744f9f2442aa", Date.now(), "Sign Up Verify Requested", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sign Up Verify Requested", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sign Up Verify Requested", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sign Up Verify Requested", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sign Up Verify Requested", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sign Up Verify Requested", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sign Up Verify Requested", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sign Up Verify Requested", {
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function signUpVerifyCompleted(param) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("cc907aff-93b3-469e-b7e7-f6f7e70419e2", "aa30c1507edfbce37f73a4acbd2990c3bc1226fd32a9b9f95e716b9ea057a740", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sign Up Verify Completed", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("cc907aff-93b3-469e-b7e7-f6f7e70419e2", "Sign Up Verify Completed", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("cc907aff-93b3-469e-b7e7-f6f7e70419e2", Date.now(), "Sign Up Verify Completed", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sign Up Verify Completed", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sign Up Verify Completed", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sign Up Verify Completed", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sign Up Verify Completed", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sign Up Verify Completed", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sign Up Verify Completed", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sign Up Verify Completed", {
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function listToggled(schema, isList, eventName, propertyName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("d551b3ed-0374-417a-bc17-fdd095605220", "798807439729faeeb50d60c4761b53e364ab1ef53a8e28608889c4f484ea655d", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("List Toggled", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Is List": isList,
          "Event Name": eventName,
          "Property Name": propertyName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("d551b3ed-0374-417a-bc17-fdd095605220", "List Toggled", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "63c87dbe-bf31-4d6c-89dd-91074c61917a",
              name: "Is List",
              value: isList
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("d551b3ed-0374-417a-bc17-fdd095605220", Date.now(), "List Toggled", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "63c87dbe-bf31-4d6c-89dd-91074c61917a",
              name: "Is List",
              value: isList
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "List Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Is List": isList,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("List Toggled", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "List Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Is List": isList,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("List Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Is List": isList,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "List Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Is List": isList,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "List Toggled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Is List": isList,
        "Event Name": eventName,
        "Property Name": propertyName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("List Toggled", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Is List": isList,
              "Event Name": eventName,
              "Property Name": propertyName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function dataSourceUpdated(schema, sourceName, fromSourceName, developmentPlatform, fromDevelopmentPlatform, programmingLanguage, fromProgrammingLanguage, libraryName, fromLibraryName, libraryDestination, fromLibraryDestination, sourceChange, fromSourceConfig, sourceConfig, destinationId, destinationName, dataDestination, fromDestinationMode, destinationMode, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("7444f578-b273-410e-8a2a-f3c1e6797ed3", "601f545b7908b0f75b7a3a08597dd1ef7ca4b4a4d5ac7d2e6bcc06c288cec300", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Data Source Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Source Name": sourceName,
          "From Source Name": fromSourceName,
          "Development Platform": Belt_Option.map(developmentPlatform, developmentPlatformToJs),
          "From Development Platform": Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs),
          "Programming Language": Belt_Option.map(programmingLanguage, programmingLanguageToJs),
          "From Programming Language": Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs),
          "Library Name": libraryName,
          "From Library Name": fromLibraryName,
          "Library Destination": libraryDestination,
          "From Library Destination": fromLibraryDestination,
          "Source Change": sourceChangeToJs(sourceChange),
          "From Source Config": Belt_Option.map(fromSourceConfig, (function (maybe) {
                  return Belt_Array.map(maybe, fromSourceConfigToJs);
                })),
          "Source Config": Belt_Array.map(sourceConfig, sourceConfigToJs),
          "Destination Id": destinationId,
          "Destination Name": destinationName,
          "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
          "From Destination Mode": Belt_Option.map(fromDestinationMode, fromDestinationModeToJs),
          "Destination Mode": Belt_Option.map(destinationMode, destinationModeToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("7444f578-b273-410e-8a2a-f3c1e6797ed3", "Data Source Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "59840dcb-fe7a-4e4c-9606-4df9c09b0326",
              name: "From Source Name",
              value: fromSourceName
            },
            {
              id: "406aa49f-64f1-4ac0-9a28-da4147138813",
              name: "Development Platform",
              value: Belt_Option.map(developmentPlatform, developmentPlatformToJs)
            },
            {
              id: "51a16729-9ec3-4c16-9b31-ec17998bff67",
              name: "From Development Platform",
              value: Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs)
            },
            {
              id: "fd56b221-b319-42be-a178-34093d6978bd",
              name: "Programming Language",
              value: Belt_Option.map(programmingLanguage, programmingLanguageToJs)
            },
            {
              id: "d652fa56-6f25-4235-a02d-c04cc097037e",
              name: "From Programming Language",
              value: Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs)
            },
            {
              id: "97f6a074-1b9a-4754-9e17-479c6f656257",
              name: "Library Name",
              value: libraryName
            },
            {
              id: "5e20619b-0319-4586-8494-621344050856",
              name: "From Library Name",
              value: fromLibraryName
            },
            {
              id: "da2d1179-65f2-4cb7-915a-e086ecaf149e",
              name: "Library Destination",
              value: libraryDestination
            },
            {
              id: "acc043c6-ef47-4454-8690-3258083f3d49",
              name: "From Library Destination",
              value: fromLibraryDestination
            },
            {
              id: "a1ce9660-6aeb-4204-ab1a-0311fc25b940",
              name: "Source Change",
              value: sourceChangeToJs(sourceChange)
            },
            {
              id: "WaTLuQqnIY",
              name: "From Source Config",
              value: Belt_Option.map(fromSourceConfig, (function (maybe) {
                      return Belt_Array.map(maybe, fromSourceConfigToJs);
                    }))
            },
            {
              id: "RwHPDjkvkf",
              name: "Source Config",
              value: Belt_Array.map(sourceConfig, sourceConfigToJs)
            },
            {
              id: "juSnkwqFHi",
              name: "Destination Id",
              value: destinationId
            },
            {
              id: "fcc392a3-088d-4db4-82d5-611e098cdb33",
              name: "Destination Name",
              value: destinationName
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Data Destination",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "OD8d0yE_X",
              name: "From Destination Mode",
              value: Belt_Option.map(fromDestinationMode, fromDestinationModeToJs)
            },
            {
              id: "7HWrjgrAH",
              name: "Destination Mode",
              value: Belt_Option.map(destinationMode, destinationModeToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("7444f578-b273-410e-8a2a-f3c1e6797ed3", Date.now(), "Data Source Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "59840dcb-fe7a-4e4c-9606-4df9c09b0326",
              name: "From Source Name",
              value: fromSourceName
            },
            {
              id: "406aa49f-64f1-4ac0-9a28-da4147138813",
              name: "Development Platform",
              value: Belt_Option.map(developmentPlatform, developmentPlatformToJs)
            },
            {
              id: "51a16729-9ec3-4c16-9b31-ec17998bff67",
              name: "From Development Platform",
              value: Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs)
            },
            {
              id: "fd56b221-b319-42be-a178-34093d6978bd",
              name: "Programming Language",
              value: Belt_Option.map(programmingLanguage, programmingLanguageToJs)
            },
            {
              id: "d652fa56-6f25-4235-a02d-c04cc097037e",
              name: "From Programming Language",
              value: Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs)
            },
            {
              id: "97f6a074-1b9a-4754-9e17-479c6f656257",
              name: "Library Name",
              value: libraryName
            },
            {
              id: "5e20619b-0319-4586-8494-621344050856",
              name: "From Library Name",
              value: fromLibraryName
            },
            {
              id: "da2d1179-65f2-4cb7-915a-e086ecaf149e",
              name: "Library Destination",
              value: libraryDestination
            },
            {
              id: "acc043c6-ef47-4454-8690-3258083f3d49",
              name: "From Library Destination",
              value: fromLibraryDestination
            },
            {
              id: "a1ce9660-6aeb-4204-ab1a-0311fc25b940",
              name: "Source Change",
              value: sourceChangeToJs(sourceChange)
            },
            {
              id: "WaTLuQqnIY",
              name: "From Source Config",
              value: Belt_Option.map(fromSourceConfig, (function (maybe) {
                      return Belt_Array.map(maybe, fromSourceConfigToJs);
                    }))
            },
            {
              id: "RwHPDjkvkf",
              name: "Source Config",
              value: Belt_Array.map(sourceConfig, sourceConfigToJs)
            },
            {
              id: "juSnkwqFHi",
              name: "Destination Id",
              value: destinationId
            },
            {
              id: "fcc392a3-088d-4db4-82d5-611e098cdb33",
              name: "Destination Name",
              value: destinationName
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Data Destination",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "OD8d0yE_X",
              name: "From Destination Mode",
              value: Belt_Option.map(fromDestinationMode, fromDestinationModeToJs)
            },
            {
              id: "7HWrjgrAH",
              name: "Destination Mode",
              value: Belt_Option.map(destinationMode, destinationModeToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Data Source Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "Development Platform": Belt_Option.map(developmentPlatform, developmentPlatformToJs),
        "From Development Platform": Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs),
        "Programming Language": Belt_Option.map(programmingLanguage, programmingLanguageToJs),
        "From Programming Language": Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs),
        "Library Name": libraryName,
        "From Library Name": fromLibraryName,
        "Library Destination": libraryDestination,
        "From Library Destination": fromLibraryDestination,
        "Source Change": sourceChangeToJs(sourceChange),
        "From Source Config": Belt_Option.map(fromSourceConfig, (function (maybe) {
                return Belt_Array.map(maybe, fromSourceConfigToJs);
              })),
        "Source Config": Belt_Array.map(sourceConfig, sourceConfigToJs),
        "Destination Id": destinationId,
        "Destination Name": destinationName,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "From Destination Mode": Belt_Option.map(fromDestinationMode, fromDestinationModeToJs),
        "Destination Mode": Belt_Option.map(destinationMode, destinationModeToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Data Source Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Data Source Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "Development Platform": Belt_Option.map(developmentPlatform, developmentPlatformToJs),
        "From Development Platform": Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs),
        "Programming Language": Belt_Option.map(programmingLanguage, programmingLanguageToJs),
        "From Programming Language": Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs),
        "Library Name": libraryName,
        "From Library Name": fromLibraryName,
        "Library Destination": libraryDestination,
        "From Library Destination": fromLibraryDestination,
        "Source Change": sourceChangeToJs(sourceChange),
        "From Source Config": Belt_Option.map(fromSourceConfig, (function (maybe) {
                return Belt_Array.map(maybe, fromSourceConfigToJs);
              })),
        "Source Config": Belt_Array.map(sourceConfig, sourceConfigToJs),
        "Destination Id": destinationId,
        "Destination Name": destinationName,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "From Destination Mode": Belt_Option.map(fromDestinationMode, fromDestinationModeToJs),
        "Destination Mode": Belt_Option.map(destinationMode, destinationModeToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Data Source Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "Development Platform": Belt_Option.map(developmentPlatform, developmentPlatformToJs),
        "From Development Platform": Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs),
        "Programming Language": Belt_Option.map(programmingLanguage, programmingLanguageToJs),
        "From Programming Language": Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs),
        "Library Name": libraryName,
        "From Library Name": fromLibraryName,
        "Library Destination": libraryDestination,
        "From Library Destination": fromLibraryDestination,
        "Source Change": sourceChangeToJs(sourceChange),
        "From Source Config": Belt_Option.map(fromSourceConfig, (function (maybe) {
                return Belt_Array.map(maybe, fromSourceConfigToJs);
              })),
        "Source Config": Belt_Array.map(sourceConfig, sourceConfigToJs),
        "Destination Id": destinationId,
        "Destination Name": destinationName,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "From Destination Mode": Belt_Option.map(fromDestinationMode, fromDestinationModeToJs),
        "Destination Mode": Belt_Option.map(destinationMode, destinationModeToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Data Source Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "Development Platform": Belt_Option.map(developmentPlatform, developmentPlatformToJs),
        "From Development Platform": Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs),
        "Programming Language": Belt_Option.map(programmingLanguage, programmingLanguageToJs),
        "From Programming Language": Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs),
        "Library Name": libraryName,
        "From Library Name": fromLibraryName,
        "Library Destination": libraryDestination,
        "From Library Destination": fromLibraryDestination,
        "Source Change": sourceChangeToJs(sourceChange),
        "From Source Config": Belt_Option.map(fromSourceConfig, (function (maybe) {
                return Belt_Array.map(maybe, fromSourceConfigToJs);
              })),
        "Source Config": Belt_Array.map(sourceConfig, sourceConfigToJs),
        "Destination Id": destinationId,
        "Destination Name": destinationName,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "From Destination Mode": Belt_Option.map(fromDestinationMode, fromDestinationModeToJs),
        "Destination Mode": Belt_Option.map(destinationMode, destinationModeToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Data Source Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "Development Platform": Belt_Option.map(developmentPlatform, developmentPlatformToJs),
        "From Development Platform": Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs),
        "Programming Language": Belt_Option.map(programmingLanguage, programmingLanguageToJs),
        "From Programming Language": Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs),
        "Library Name": libraryName,
        "From Library Name": fromLibraryName,
        "Library Destination": libraryDestination,
        "From Library Destination": fromLibraryDestination,
        "Source Change": sourceChangeToJs(sourceChange),
        "From Source Config": Belt_Option.map(fromSourceConfig, (function (maybe) {
                return Belt_Array.map(maybe, fromSourceConfigToJs);
              })),
        "Source Config": Belt_Array.map(sourceConfig, sourceConfigToJs),
        "Destination Id": destinationId,
        "Destination Name": destinationName,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "From Destination Mode": Belt_Option.map(fromDestinationMode, fromDestinationModeToJs),
        "Destination Mode": Belt_Option.map(destinationMode, destinationModeToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Data Source Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Source Name": sourceName,
              "From Source Name": fromSourceName,
              "Development Platform": Belt_Option.map(developmentPlatform, developmentPlatformToJs),
              "From Development Platform": Belt_Option.map(fromDevelopmentPlatform, fromDevelopmentPlatformToJs),
              "Programming Language": Belt_Option.map(programmingLanguage, programmingLanguageToJs),
              "From Programming Language": Belt_Option.map(fromProgrammingLanguage, fromProgrammingLanguageToJs),
              "Library Name": libraryName,
              "From Library Name": fromLibraryName,
              "Library Destination": libraryDestination,
              "From Library Destination": fromLibraryDestination,
              "Source Change": sourceChangeToJs(sourceChange),
              "From Source Config": Belt_Option.map(fromSourceConfig, (function (maybe) {
                      return Belt_Array.map(maybe, fromSourceConfigToJs);
                    })),
              "Source Config": Belt_Array.map(sourceConfig, sourceConfigToJs),
              "Destination Id": destinationId,
              "Destination Name": destinationName,
              "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
              "From Destination Mode": Belt_Option.map(fromDestinationMode, fromDestinationModeToJs),
              "Destination Mode": Belt_Option.map(destinationMode, destinationModeToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function dataDestinationUpdated(schema, destinationChange, destinationName, fromDestinationName, apiKeysMatch, devApiKey, fromDevApiKey, prodApiKey, fromProdApiKey, fromDataDestination, includeUserPropsWithEventProps, stagingApiKey, fromStagingApiKey, disabledByDefault, dataDestination, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("e4339b0b-0b21-496b-8054-9fc9181c7bed", "69b94a68bfa15ab177d397931c659ee5276b33a2b5fd986a40ce753143d2da02", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Data Destination Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Destination Change": destinationChangeToJs(destinationChange),
          "Destination Name": destinationName,
          "From Destination Name": fromDestinationName,
          "Api Keys Match": apiKeysMatch,
          "Dev Api Key": devApiKey,
          "From Dev Api Key": fromDevApiKey,
          "Prod Api Key": prodApiKey,
          "From Prod Api Key": fromProdApiKey,
          "From Analytics Tool": Belt_Option.map(fromDataDestination, fromDataDestinationToJs),
          "Include User Props with Event Props": includeUserPropsWithEventProps,
          "Staging Api Key": stagingApiKey,
          "From Staging Api Key": fromStagingApiKey,
          "Disabled by Default": disabledByDefault,
          "Analytics Tool": Belt_Option.map(dataDestination, dataDestinationToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("e4339b0b-0b21-496b-8054-9fc9181c7bed", "Data Destination Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "b9d33a02-c118-4298-9d43-3caf21433bf7",
              name: "Destination Change",
              value: destinationChangeToJs(destinationChange)
            },
            {
              id: "fcc392a3-088d-4db4-82d5-611e098cdb33",
              name: "Destination Name",
              value: destinationName
            },
            {
              id: "56d49306-ee95-41c0-88a5-948978e3d889",
              name: "From Destination Name",
              value: fromDestinationName
            },
            {
              id: "831d85c9-d3d7-4152-963c-8f5d104d97ef",
              name: "Api Keys Match",
              value: apiKeysMatch
            },
            {
              id: "28ddf74a-72f9-4571-b401-a47ff2e943a7",
              name: "Dev Api Key",
              value: devApiKey
            },
            {
              id: "9b6adfb6-3ae9-421a-93a3-370735d7fbf9",
              name: "From Dev Api Key",
              value: fromDevApiKey
            },
            {
              id: "b1b5a1d1-f9fe-40ab-93d7-3f2c0a90e2c6",
              name: "Prod Api Key",
              value: prodApiKey
            },
            {
              id: "b7ffb1a2-179a-4230-b4ee-f8b0d8a46b10",
              name: "From Prod Api Key",
              value: fromProdApiKey
            },
            {
              id: "d3d6f55b-dc43-4d05-9089-d41cff81f44a",
              name: "From Analytics Tool",
              value: Belt_Option.map(fromDataDestination, fromDataDestinationToJs)
            },
            {
              id: "7936d28c-3a49-417e-b0fb-a7f11f284d53",
              name: "Include User Props with Event Props",
              value: includeUserPropsWithEventProps
            },
            {
              id: "BuibPf41W",
              name: "Staging Api Key",
              value: stagingApiKey
            },
            {
              id: "HMrkbPpND",
              name: "From Staging Api Key",
              value: fromStagingApiKey
            },
            {
              id: "UJxQ95sN_j",
              name: "Disabled by Default",
              value: disabledByDefault
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Analytics Tool",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("e4339b0b-0b21-496b-8054-9fc9181c7bed", Date.now(), "Data Destination Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "b9d33a02-c118-4298-9d43-3caf21433bf7",
              name: "Destination Change",
              value: destinationChangeToJs(destinationChange)
            },
            {
              id: "fcc392a3-088d-4db4-82d5-611e098cdb33",
              name: "Destination Name",
              value: destinationName
            },
            {
              id: "56d49306-ee95-41c0-88a5-948978e3d889",
              name: "From Destination Name",
              value: fromDestinationName
            },
            {
              id: "831d85c9-d3d7-4152-963c-8f5d104d97ef",
              name: "Api Keys Match",
              value: apiKeysMatch
            },
            {
              id: "28ddf74a-72f9-4571-b401-a47ff2e943a7",
              name: "Dev Api Key",
              value: devApiKey
            },
            {
              id: "9b6adfb6-3ae9-421a-93a3-370735d7fbf9",
              name: "From Dev Api Key",
              value: fromDevApiKey
            },
            {
              id: "b1b5a1d1-f9fe-40ab-93d7-3f2c0a90e2c6",
              name: "Prod Api Key",
              value: prodApiKey
            },
            {
              id: "b7ffb1a2-179a-4230-b4ee-f8b0d8a46b10",
              name: "From Prod Api Key",
              value: fromProdApiKey
            },
            {
              id: "d3d6f55b-dc43-4d05-9089-d41cff81f44a",
              name: "From Analytics Tool",
              value: Belt_Option.map(fromDataDestination, fromDataDestinationToJs)
            },
            {
              id: "7936d28c-3a49-417e-b0fb-a7f11f284d53",
              name: "Include User Props with Event Props",
              value: includeUserPropsWithEventProps
            },
            {
              id: "BuibPf41W",
              name: "Staging Api Key",
              value: stagingApiKey
            },
            {
              id: "HMrkbPpND",
              name: "From Staging Api Key",
              value: fromStagingApiKey
            },
            {
              id: "UJxQ95sN_j",
              name: "Disabled by Default",
              value: disabledByDefault
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Analytics Tool",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Data Destination Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Destination Change": destinationChangeToJs(destinationChange),
        "Destination Name": destinationName,
        "From Destination Name": fromDestinationName,
        "Api Keys Match": apiKeysMatch,
        "Dev Api Key": devApiKey,
        "From Dev Api Key": fromDevApiKey,
        "Prod Api Key": prodApiKey,
        "From Prod Api Key": fromProdApiKey,
        "From Analytics Tool": Belt_Option.map(fromDataDestination, fromDataDestinationToJs),
        "Include User Props with Event Props": includeUserPropsWithEventProps,
        "Staging Api Key": stagingApiKey,
        "From Staging Api Key": fromStagingApiKey,
        "Disabled by Default": disabledByDefault,
        "Analytics Tool": Belt_Option.map(dataDestination, dataDestinationToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Data Destination Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Data Destination Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Destination Change": destinationChangeToJs(destinationChange),
        "Destination Name": destinationName,
        "From Destination Name": fromDestinationName,
        "Api Keys Match": apiKeysMatch,
        "Dev Api Key": devApiKey,
        "From Dev Api Key": fromDevApiKey,
        "Prod Api Key": prodApiKey,
        "From Prod Api Key": fromProdApiKey,
        "From Analytics Tool": Belt_Option.map(fromDataDestination, fromDataDestinationToJs),
        "Include User Props with Event Props": includeUserPropsWithEventProps,
        "Staging Api Key": stagingApiKey,
        "From Staging Api Key": fromStagingApiKey,
        "Disabled by Default": disabledByDefault,
        "Analytics Tool": Belt_Option.map(dataDestination, dataDestinationToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Data Destination Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Destination Change": destinationChangeToJs(destinationChange),
        "Destination Name": destinationName,
        "From Destination Name": fromDestinationName,
        "Api Keys Match": apiKeysMatch,
        "Dev Api Key": devApiKey,
        "From Dev Api Key": fromDevApiKey,
        "Prod Api Key": prodApiKey,
        "From Prod Api Key": fromProdApiKey,
        "From Analytics Tool": Belt_Option.map(fromDataDestination, fromDataDestinationToJs),
        "Include User Props with Event Props": includeUserPropsWithEventProps,
        "Staging Api Key": stagingApiKey,
        "From Staging Api Key": fromStagingApiKey,
        "Disabled by Default": disabledByDefault,
        "Analytics Tool": Belt_Option.map(dataDestination, dataDestinationToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Data Destination Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Destination Change": destinationChangeToJs(destinationChange),
        "Destination Name": destinationName,
        "From Destination Name": fromDestinationName,
        "Api Keys Match": apiKeysMatch,
        "Dev Api Key": devApiKey,
        "From Dev Api Key": fromDevApiKey,
        "Prod Api Key": prodApiKey,
        "From Prod Api Key": fromProdApiKey,
        "From Analytics Tool": Belt_Option.map(fromDataDestination, fromDataDestinationToJs),
        "Include User Props with Event Props": includeUserPropsWithEventProps,
        "Staging Api Key": stagingApiKey,
        "From Staging Api Key": fromStagingApiKey,
        "Disabled by Default": disabledByDefault,
        "Analytics Tool": Belt_Option.map(dataDestination, dataDestinationToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Data Destination Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Destination Change": destinationChangeToJs(destinationChange),
        "Destination Name": destinationName,
        "From Destination Name": fromDestinationName,
        "Api Keys Match": apiKeysMatch,
        "Dev Api Key": devApiKey,
        "From Dev Api Key": fromDevApiKey,
        "Prod Api Key": prodApiKey,
        "From Prod Api Key": fromProdApiKey,
        "From Analytics Tool": Belt_Option.map(fromDataDestination, fromDataDestinationToJs),
        "Include User Props with Event Props": includeUserPropsWithEventProps,
        "Staging Api Key": stagingApiKey,
        "From Staging Api Key": fromStagingApiKey,
        "Disabled by Default": disabledByDefault,
        "Analytics Tool": Belt_Option.map(dataDestination, dataDestinationToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Data Destination Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Destination Change": destinationChangeToJs(destinationChange),
              "Destination Name": destinationName,
              "From Destination Name": fromDestinationName,
              "Api Keys Match": apiKeysMatch,
              "Dev Api Key": devApiKey,
              "From Dev Api Key": fromDevApiKey,
              "Prod Api Key": prodApiKey,
              "From Prod Api Key": fromProdApiKey,
              "From Analytics Tool": Belt_Option.map(fromDataDestination, fromDataDestinationToJs),
              "Include User Props with Event Props": includeUserPropsWithEventProps,
              "Staging Api Key": stagingApiKey,
              "From Staging Api Key": fromStagingApiKey,
              "Disabled by Default": disabledByDefault,
              "Analytics Tool": Belt_Option.map(dataDestination, dataDestinationToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function dataPathUpdated(schema, dataSource, dataDestination, pathUpdate, numEventsSelected, numEventsAvailable, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("6c101de5-24db-46a3-aef4-07fc61bc8408", "f445781323a546a8a68c1a76128268bd705e63ad957d5270c78b49b68f0b2c9e", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Data Path Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Data Source": dataSourceToJs(dataSource),
          "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
          "Path Update": pathUpdate,
          "Num Events Selected": numEventsSelected,
          "Num Events Available": numEventsAvailable,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("6c101de5-24db-46a3-aef4-07fc61bc8408", "Data Path Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "e2c6513e-f168-4e30-9f0e-97ca43953a25",
              name: "Data Source",
              value: dataSourceToJs(dataSource)
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Data Destination",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "7f01bb87-74f8-42e6-a18f-aa0becaee0d0",
              name: "Path Update",
              value: pathUpdate
            },
            {
              id: "HOC5wsrqk",
              name: "Num Events Selected",
              value: numEventsSelected
            },
            {
              id: "_PlRiKIjy",
              name: "Num Events Available",
              value: numEventsAvailable
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("6c101de5-24db-46a3-aef4-07fc61bc8408", Date.now(), "Data Path Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "e2c6513e-f168-4e30-9f0e-97ca43953a25",
              name: "Data Source",
              value: dataSourceToJs(dataSource)
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Data Destination",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "7f01bb87-74f8-42e6-a18f-aa0becaee0d0",
              name: "Path Update",
              value: pathUpdate
            },
            {
              id: "HOC5wsrqk",
              name: "Num Events Selected",
              value: numEventsSelected
            },
            {
              id: "_PlRiKIjy",
              name: "Num Events Available",
              value: numEventsAvailable
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Data Path Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Data Source": dataSourceToJs(dataSource),
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Path Update": pathUpdate,
        "Num Events Selected": numEventsSelected,
        "Num Events Available": numEventsAvailable,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Data Path Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Data Path Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Data Source": dataSourceToJs(dataSource),
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Path Update": pathUpdate,
        "Num Events Selected": numEventsSelected,
        "Num Events Available": numEventsAvailable,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Data Path Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Data Source": dataSourceToJs(dataSource),
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Path Update": pathUpdate,
        "Num Events Selected": numEventsSelected,
        "Num Events Available": numEventsAvailable,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Data Path Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Data Source": dataSourceToJs(dataSource),
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Path Update": pathUpdate,
        "Num Events Selected": numEventsSelected,
        "Num Events Available": numEventsAvailable,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Data Path Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Data Source": dataSourceToJs(dataSource),
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Path Update": pathUpdate,
        "Num Events Selected": numEventsSelected,
        "Num Events Available": numEventsAvailable,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Data Path Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Data Source": dataSourceToJs(dataSource),
              "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
              "Path Update": pathUpdate,
              "Num Events Selected": numEventsSelected,
              "Num Events Available": numEventsAvailable,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTypeUpdated(schema, currentFilters, eventId, eventName, eventTypesSelectedBefore, eventTypesSelectedAfter, associateEventWithEvent, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForEvent, eventActionsIncluded) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("bc0d76b4-7e06-47e9-a331-da007ae7c7f0", "228df683cf263875b5e1bdfe30c27a5f771b5aec99f15ac29f369bb25d1dcaa3", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Type Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Types Selected Before": Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs),
          "Event Types Selected After": Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("bc0d76b4-7e06-47e9-a331-da007ae7c7f0", "Event Type Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "76bbfcdb-9f69-44a4-ba79-b28a7187b155",
              name: "Event Types Selected Before",
              value: Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs)
            },
            {
              id: "25319a2d-fdce-4949-ad5d-3ad2dfd10f30",
              name: "Event Types Selected After",
              value: Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "pYGpYEDTfe",
                  name: "Event Actions Included (Event " + (updateGroupPropertiesForEvent + " group property)"),
                  value: Belt_Array.map(eventActionsIncluded, eventActionsIncludedToJs)
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("bc0d76b4-7e06-47e9-a331-da007ae7c7f0", Date.now(), "Event Type Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "76bbfcdb-9f69-44a4-ba79-b28a7187b155",
              name: "Event Types Selected Before",
              value: Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs)
            },
            {
              id: "25319a2d-fdce-4949-ad5d-3ad2dfd10f30",
              name: "Event Types Selected After",
              value: Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Array.map(eventActionsIncluded, eventActionsIncludedToJs)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Types Selected Before": Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs),
        "Event Types Selected After": Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Type Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Array.map(eventActionsIncluded, eventActionsIncludedToJs)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Types Selected Before": Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs),
        "Event Types Selected After": Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Types Selected Before": Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs),
        "Event Types Selected After": Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Array.map(eventActionsIncluded, eventActionsIncludedToJs)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Types Selected Before": Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs),
        "Event Types Selected After": Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Array.map(eventActionsIncluded, eventActionsIncludedToJs)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Type Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Types Selected Before": Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs),
        "Event Types Selected After": Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Array.map(eventActionsIncluded, eventActionsIncludedToJs)
      });
  return logEventWithGroups("Event Type Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Types Selected Before": Belt_Array.map(eventTypesSelectedBefore, eventTypesSelectedBeforeToJs),
              "Event Types Selected After": Belt_Array.map(eventTypesSelectedAfter, eventTypesSelectedAfterToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Event: associateEventWithEvent,
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function authenticated(userId_, email, userName, userAuthenticationStatus, authenticationStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ba4329e2-a4e1-42a8-a5b1-957da592e9a4", "bcd72b5c998247558fbb083b9f752cd8729f964707bb92f1d7c25fe6b26339ae", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Authenticated", {
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          Email: email,
          "User Name": userName,
          "Authentication Status": authenticationStatus
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ba4329e2-a4e1-42a8-a5b1-957da592e9a4", "Authenticated", messages, [
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([
                {
                  id: "92588d93-5307-4fa2-be00-be0821596abe",
                  name: "Email",
                  value: email
                },
                {
                  id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
                  name: "User Name",
                  value: userName
                },
                {
                  id: "-egwDt1oV",
                  name: "Authentication Status",
                  value: authenticationStatus
                }
              ], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ba4329e2-a4e1-42a8-a5b1-957da592e9a4", Date.now(), "Authenticated", messages, [
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [
            {
              id: "92588d93-5307-4fa2-be00-be0821596abe",
              name: "Email",
              value: email
            },
            {
              id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
              name: "User Name",
              value: userName
            },
            {
              id: "-egwDt1oV",
              name: "Authentication Status",
              value: authenticationStatus
            }
          ]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._1(Belt_Option.getExn(postHog.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        Email: email,
        "User Name": userName,
        "Authentication Status": authenticationStatus
      });
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Authenticated", {
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify(userId_);
  var intercomUserProperties = {
    Email: email,
    name: userName,
    "Authentication Status": authenticationStatus
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {};
  logEvent("Authenticated", intercomProperties);
  Curry._1(Belt_Option.getExn(segment.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
        Email: email,
        "User Name": userName,
        "Authentication Status": authenticationStatus
      });
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Authenticated", {
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify$1(userId_);
  setUserProperties$1({
        displayName: email,
        "User Name": userName,
        "Authentication Status": authenticationStatus
      });
  logEvent$1("Authenticated", {
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(intercomDestinationInterface.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        Email: email,
        name: userName,
        "Authentication Status": authenticationStatus
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Authenticated", {
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(inspectorCustomDestination.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
        Email: email,
        "User Name": userName,
        "Authentication Status": authenticationStatus
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Authenticated", {
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify$2(userId_);
  setUserProperties$2({
        Email: email,
        "User Name": userName,
        "Authentication Status": authenticationStatus
      });
  return logEvent$2("Authenticated", {
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function passwordResetRequested(emailInput) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("dfd72395-1028-4ee5-baa5-3b62289c2db8", "8a3d68c158b07e23c267db53cada6961d5f790f3e363fb286c4eaadc3632ceb5", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Password Reset Requested", {
          "Email Input": emailInput,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("dfd72395-1028-4ee5-baa5-3b62289c2db8", "Password Reset Requested", messages, [
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("dfd72395-1028-4ee5-baa5-3b62289c2db8", Date.now(), "Password Reset Requested", messages, [
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Password Reset Requested", {
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Password Reset Requested", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Password Reset Requested", {
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Password Reset Requested", {
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Password Reset Requested", {
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Password Reset Requested", {
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Password Reset Requested", {
              "Email Input": emailInput,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function versionUpdatePrompted(toVersion) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("5952c81c-76f0-4fb2-bec0-483ddc597b3d", "48e5522c565389f91e04d8c27563a8b7b4eefa0510d2f4a737e71f3eb5d1144f", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Version Update Prompted", {
          "To Version": toVersion,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("5952c81c-76f0-4fb2-bec0-483ddc597b3d", "Version Update Prompted", messages, [
            {
              id: "4aa064e5-a803-4a1f-b6e0-eff0ca4b03a3",
              name: "To Version",
              value: toVersion
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("5952c81c-76f0-4fb2-bec0-483ddc597b3d", Date.now(), "Version Update Prompted", messages, [
            {
              id: "4aa064e5-a803-4a1f-b6e0-eff0ca4b03a3",
              name: "To Version",
              value: toVersion
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Version Update Prompted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Version Update Prompted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Version Update Prompted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Version Update Prompted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Version Update Prompted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Version Update Prompted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Version Update Prompted", {
              "To Version": toVersion,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function versionUpdateAccepted(toVersion) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("322b083d-cbc9-4b82-9bac-2151a7ccb20e", "eeae346c872825de9c4c7981eb33a664c5f9d99d728ab84d79a924bfa2c2a4bd", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Version Update Accepted", {
          "To Version": toVersion,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("322b083d-cbc9-4b82-9bac-2151a7ccb20e", "Version Update Accepted", messages, [
            {
              id: "4aa064e5-a803-4a1f-b6e0-eff0ca4b03a3",
              name: "To Version",
              value: toVersion
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("322b083d-cbc9-4b82-9bac-2151a7ccb20e", Date.now(), "Version Update Accepted", messages, [
            {
              id: "4aa064e5-a803-4a1f-b6e0-eff0ca4b03a3",
              name: "To Version",
              value: toVersion
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Version Update Accepted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Version Update Accepted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Version Update Accepted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Version Update Accepted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Version Update Accepted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Version Update Accepted", {
        "To Version": toVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Version Update Accepted", {
              "To Version": toVersion,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function versionUpdated(fromVersion) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("53a80400-d517-4d9f-8931-73191135611a", "9bf5e42e86a3ca4a6ce78a1accd4e69f4ff4e4d69a9c2ba502bce4696de2da23", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Version Updated", {
          "From Version": fromVersion,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("53a80400-d517-4d9f-8931-73191135611a", "Version Updated", messages, [
            {
              id: "6980c34c-fae3-434a-a3b3-31606d6875ff",
              name: "From Version",
              value: fromVersion
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("53a80400-d517-4d9f-8931-73191135611a", Date.now(), "Version Updated", messages, [
            {
              id: "6980c34c-fae3-434a-a3b3-31606d6875ff",
              name: "From Version",
              value: fromVersion
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Version Updated", {
        "From Version": fromVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Version Updated", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Version Updated", {
        "From Version": fromVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Version Updated", {
        "From Version": fromVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Version Updated", {
        "From Version": fromVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Version Updated", {
        "From Version": fromVersion,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Version Updated", {
              "From Version": fromVersion,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function propertyDeletePrompted(schema, eventName, propertyName, propertyId, eventId, propertyType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("7b350e4a-0401-4ca0-adef-3e88ab890760", "094e04ad29eaa64951285106beb4ce8cf9a28dab9cf0ea9d261a157dfa20e080", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Delete Prompted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Name": eventName,
          "Property Name": propertyName,
          "Property Id": propertyId,
          "Event Id": eventId,
          "Property Type": propertyTypeToJs(propertyType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("7b350e4a-0401-4ca0-adef-3e88ab890760", "Property Delete Prompted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(propertyType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("7b350e4a-0401-4ca0-adef-3e88ab890760", Date.now(), "Property Delete Prompted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(propertyType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Delete Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Property Id": propertyId,
        "Event Id": eventId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Delete Prompted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Delete Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Property Id": propertyId,
        "Event Id": eventId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Delete Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Property Id": propertyId,
        "Event Id": eventId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Delete Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Property Id": propertyId,
        "Event Id": eventId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Delete Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Property Id": propertyId,
        "Event Id": eventId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Delete Prompted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Name": eventName,
              "Property Name": propertyName,
              "Property Id": propertyId,
              "Event Id": eventId,
              "Property Type": propertyTypeToJs(propertyType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyDeleted(schema, eventName, propertyName, eventId, propertyId, propertyType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("fa54cf55-9619-41fb-b4f4-c6cdf2b686e1", "95da8ba35088e7abe72e92e3e83e8335a4aad6ac5e23e64fbf27dbf0f08289e4", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Deleted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Name": eventName,
          "Property Name": propertyName,
          "Event Id": eventId,
          "Property Id": propertyId,
          "Property Type": propertyTypeToJs(propertyType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("fa54cf55-9619-41fb-b4f4-c6cdf2b686e1", "Property Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(propertyType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("fa54cf55-9619-41fb-b4f4-c6cdf2b686e1", Date.now(), "Property Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(propertyType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Deleted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Name": eventName,
        "Property Name": propertyName,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Property Type": propertyTypeToJs(propertyType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Deleted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Name": eventName,
              "Property Name": propertyName,
              "Event Id": eventId,
              "Property Id": propertyId,
              "Property Type": propertyTypeToJs(propertyType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventsFilterUpdated(schema, currentFilters, pageName, filterAction, filterName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("fb5063df-8a0b-4dd3-955d-0c98d077313b", "31c84f8fd106a2045e733819bc421dfb0216a8c826a799eea9f03aa90b799edb", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Events Filter Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Page Name": pageNameToJs(pageName),
          "Filter Action": filterActionToJs(filterAction),
          "Filter Name": filterName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("fb5063df-8a0b-4dd3-955d-0c98d077313b", "Events Filter Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "98346508-5db0-413e-8c2c-5474c67b4809",
              name: "Filter Action",
              value: filterActionToJs(filterAction)
            },
            {
              id: "2ec7f4e4-b866-4a08-af4b-270c3e90ff6e",
              name: "Filter Name",
              value: filterName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("fb5063df-8a0b-4dd3-955d-0c98d077313b", Date.now(), "Events Filter Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "98346508-5db0-413e-8c2c-5474c67b4809",
              name: "Filter Action",
              value: filterActionToJs(filterAction)
            },
            {
              id: "2ec7f4e4-b866-4a08-af4b-270c3e90ff6e",
              name: "Filter Name",
              value: filterName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Events Filter Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Page Name": pageNameToJs(pageName),
        "Filter Action": filterActionToJs(filterAction),
        "Filter Name": filterName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Events Filter Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Events Filter Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Page Name": pageNameToJs(pageName),
        "Filter Action": filterActionToJs(filterAction),
        "Filter Name": filterName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Events Filter Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Page Name": pageNameToJs(pageName),
        "Filter Action": filterActionToJs(filterAction),
        "Filter Name": filterName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Events Filter Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Page Name": pageNameToJs(pageName),
        "Filter Action": filterActionToJs(filterAction),
        "Filter Name": filterName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Events Filter Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Page Name": pageNameToJs(pageName),
        "Filter Action": filterActionToJs(filterAction),
        "Filter Name": filterName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Events Filter Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Page Name": pageNameToJs(pageName),
              "Filter Action": filterActionToJs(filterAction),
              "Filter Name": filterName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventListSorted(schema, sortBefore, sortAfter, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("5d4915d2-82d7-49b7-9e28-c5bf8d5c2c2b", "5c3fe268ae76f60199871f6650750a4d7c49a2b8ffa6c330e030436127dd28ba", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event List Sorted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Sort Before": sortBeforeToJs(sortBefore),
          "Sort After": sortAfterToJs(sortAfter),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("5d4915d2-82d7-49b7-9e28-c5bf8d5c2c2b", "Event List Sorted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "76274cda-b52f-4891-91ed-27c2938ba4e7",
              name: "Sort Before",
              value: sortBeforeToJs(sortBefore)
            },
            {
              id: "6c733e80-e090-426e-9b42-10a381a0afd7",
              name: "Sort After",
              value: sortAfterToJs(sortAfter)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("5d4915d2-82d7-49b7-9e28-c5bf8d5c2c2b", Date.now(), "Event List Sorted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "76274cda-b52f-4891-91ed-27c2938ba4e7",
              name: "Sort Before",
              value: sortBeforeToJs(sortBefore)
            },
            {
              id: "6c733e80-e090-426e-9b42-10a381a0afd7",
              name: "Sort After",
              value: sortAfterToJs(sortAfter)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event List Sorted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sort Before": sortBeforeToJs(sortBefore),
        "Sort After": sortAfterToJs(sortAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event List Sorted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event List Sorted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sort Before": sortBeforeToJs(sortBefore),
        "Sort After": sortAfterToJs(sortAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event List Sorted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sort Before": sortBeforeToJs(sortBefore),
        "Sort After": sortAfterToJs(sortAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event List Sorted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sort Before": sortBeforeToJs(sortBefore),
        "Sort After": sortAfterToJs(sortAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event List Sorted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sort Before": sortBeforeToJs(sortBefore),
        "Sort After": sortAfterToJs(sortAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event List Sorted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Sort Before": sortBeforeToJs(sortBefore),
              "Sort After": sortAfterToJs(sortAfter),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function commentAdded(schema, commentId, eventId, propertyId, itemName, authorId, itemType, numUsersMentioned, numEventsMentioned, mentionedUserIds, mentionedEventIds, mentionedUserDisplayNames, mentionedEventDisplayNames, authorDisplayName, metricId, itemId, commentLocation, integrationId, inspectorIssueId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("79779434-918f-420e-a115-ba8c4626e25c", "80a6ab8918268ccbd136f61d542749905180024da3187f4b9b1eab4a9e1f1691", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Comment Added", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Comment Id": commentId,
          "Event Id": eventId,
          "Property Id": propertyId,
          "Item Name": itemName,
          "Author Id": authorId,
          "Item Type": itemTypeToJs(itemType),
          "Num Users Mentioned": numUsersMentioned,
          "Num Events Mentioned": numEventsMentioned,
          "Mentioned User Ids": mentionedUserIds,
          "Mentioned Event Ids": mentionedEventIds,
          "Mentioned User Display Names": mentionedUserDisplayNames,
          "Mentioned Event Display Names": mentionedEventDisplayNames,
          "Author Display Name": authorDisplayName,
          "Metric Id": metricId,
          "Item Id": itemId,
          "Comment Location": commentLocationToJs(commentLocation),
          "Integration Id": integrationId,
          "Inspector Issue Id": inspectorIssueId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("79779434-918f-420e-a115-ba8c4626e25c", "Comment Added", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "a305b8d5-9332-4643-b7ec-c1ee4a061047",
              name: "Comment Id",
              value: commentId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "da437b9d-d424-4b32-b174-2ce4e4951b70",
              name: "Author Id",
              value: authorId
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "0e3fab52-626a-4c8c-8ca8-00b4e5aca52a",
              name: "Num Users Mentioned",
              value: numUsersMentioned
            },
            {
              id: "a3e30521-8fe0-4586-9a41-6723f322fbb2",
              name: "Num Events Mentioned",
              value: numEventsMentioned
            },
            {
              id: "943fa284-f3e7-4ebf-9ed5-8da1ab123df8",
              name: "Mentioned User Ids",
              value: mentionedUserIds
            },
            {
              id: "804a8cd2-dc86-44f7-8160-acc0803a804a",
              name: "Mentioned Event Ids",
              value: mentionedEventIds
            },
            {
              id: "8186dab4-f101-4305-86e2-373f4ba9c7b1",
              name: "Mentioned User Display Names",
              value: mentionedUserDisplayNames
            },
            {
              id: "16b46837-4731-4995-970b-0ed66cc2ca38",
              name: "Mentioned Event Display Names",
              value: mentionedEventDisplayNames
            },
            {
              id: "0553f96e-e705-49ef-995f-746ee1abbfef",
              name: "Author Display Name",
              value: authorDisplayName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "7z7fOnLi2",
              name: "Comment Location",
              value: commentLocationToJs(commentLocation)
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationId
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("79779434-918f-420e-a115-ba8c4626e25c", Date.now(), "Comment Added", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "a305b8d5-9332-4643-b7ec-c1ee4a061047",
              name: "Comment Id",
              value: commentId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "da437b9d-d424-4b32-b174-2ce4e4951b70",
              name: "Author Id",
              value: authorId
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "0e3fab52-626a-4c8c-8ca8-00b4e5aca52a",
              name: "Num Users Mentioned",
              value: numUsersMentioned
            },
            {
              id: "a3e30521-8fe0-4586-9a41-6723f322fbb2",
              name: "Num Events Mentioned",
              value: numEventsMentioned
            },
            {
              id: "943fa284-f3e7-4ebf-9ed5-8da1ab123df8",
              name: "Mentioned User Ids",
              value: mentionedUserIds
            },
            {
              id: "804a8cd2-dc86-44f7-8160-acc0803a804a",
              name: "Mentioned Event Ids",
              value: mentionedEventIds
            },
            {
              id: "8186dab4-f101-4305-86e2-373f4ba9c7b1",
              name: "Mentioned User Display Names",
              value: mentionedUserDisplayNames
            },
            {
              id: "16b46837-4731-4995-970b-0ed66cc2ca38",
              name: "Mentioned Event Display Names",
              value: mentionedEventDisplayNames
            },
            {
              id: "0553f96e-e705-49ef-995f-746ee1abbfef",
              name: "Author Display Name",
              value: authorDisplayName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "7z7fOnLi2",
              name: "Comment Location",
              value: commentLocationToJs(commentLocation)
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationId
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Comment Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Author Id": authorId,
        "Item Type": itemTypeToJs(itemType),
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Item Id": itemId,
        "Comment Location": commentLocationToJs(commentLocation),
        "Integration Id": integrationId,
        "Inspector Issue Id": inspectorIssueId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Comment Added", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Comment Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Author Id": authorId,
        "Item Type": itemTypeToJs(itemType),
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Item Id": itemId,
        "Comment Location": commentLocationToJs(commentLocation),
        "Integration Id": integrationId,
        "Inspector Issue Id": inspectorIssueId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Comment Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Author Id": authorId,
        "Item Type": itemTypeToJs(itemType),
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Item Id": itemId,
        "Comment Location": commentLocationToJs(commentLocation),
        "Integration Id": integrationId,
        "Inspector Issue Id": inspectorIssueId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Comment Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Author Id": authorId,
        "Item Type": itemTypeToJs(itemType),
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Item Id": itemId,
        "Comment Location": commentLocationToJs(commentLocation),
        "Integration Id": integrationId,
        "Inspector Issue Id": inspectorIssueId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Comment Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Author Id": authorId,
        "Item Type": itemTypeToJs(itemType),
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Item Id": itemId,
        "Comment Location": commentLocationToJs(commentLocation),
        "Integration Id": integrationId,
        "Inspector Issue Id": inspectorIssueId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Comment Added", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Comment Id": commentId,
              "Event Id": eventId,
              "Property Id": propertyId,
              "Item Name": itemName,
              "Author Id": authorId,
              "Item Type": itemTypeToJs(itemType),
              "Num Users Mentioned": numUsersMentioned,
              "Num Events Mentioned": numEventsMentioned,
              "Mentioned User Ids": mentionedUserIds,
              "Mentioned Event Ids": mentionedEventIds,
              "Mentioned User Display Names": mentionedUserDisplayNames,
              "Mentioned Event Display Names": mentionedEventDisplayNames,
              "Author Display Name": authorDisplayName,
              "Metric Id": metricId,
              "Item Id": itemId,
              "Comment Location": commentLocationToJs(commentLocation),
              "Integration Id": integrationId,
              "Inspector Issue Id": inspectorIssueId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function commentSeen(schema, commentId, eventId, propertyId, authorId, itemName, itemType, isAuthor, wasMentioned, authorDisplayName, metricId, integrationId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("15edc254-c0b1-4b78-99dd-a235cb1c6d85", "c1ea55679f049152d6f1bf18381ac98ebeaee2844e2f75231b72253b127a36b3", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Comment Seen", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Comment Id": commentId,
          "Event Id": eventId,
          "Property Id": propertyId,
          "Author Id": authorId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Is Author": isAuthor,
          "Was Mentioned": wasMentioned,
          "Author Display Name": authorDisplayName,
          "Metric Id": metricId,
          "Integration Id": integrationId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("15edc254-c0b1-4b78-99dd-a235cb1c6d85", "Comment Seen", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "a305b8d5-9332-4643-b7ec-c1ee4a061047",
              name: "Comment Id",
              value: commentId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "da437b9d-d424-4b32-b174-2ce4e4951b70",
              name: "Author Id",
              value: authorId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "dd92d777-4111-4d22-8b3c-e1911aad4185",
              name: "Is Author",
              value: isAuthor
            },
            {
              id: "554828d0-cffb-4ae0-939b-42f0befbb6a1",
              name: "Was Mentioned",
              value: wasMentioned
            },
            {
              id: "0553f96e-e705-49ef-995f-746ee1abbfef",
              name: "Author Display Name",
              value: authorDisplayName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("15edc254-c0b1-4b78-99dd-a235cb1c6d85", Date.now(), "Comment Seen", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "a305b8d5-9332-4643-b7ec-c1ee4a061047",
              name: "Comment Id",
              value: commentId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "da437b9d-d424-4b32-b174-2ce4e4951b70",
              name: "Author Id",
              value: authorId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "dd92d777-4111-4d22-8b3c-e1911aad4185",
              name: "Is Author",
              value: isAuthor
            },
            {
              id: "554828d0-cffb-4ae0-939b-42f0befbb6a1",
              name: "Was Mentioned",
              value: wasMentioned
            },
            {
              id: "0553f96e-e705-49ef-995f-746ee1abbfef",
              name: "Author Display Name",
              value: authorDisplayName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Comment Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Author Id": authorId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Is Author": isAuthor,
        "Was Mentioned": wasMentioned,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Comment Seen", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Comment Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Author Id": authorId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Is Author": isAuthor,
        "Was Mentioned": wasMentioned,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Comment Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Author Id": authorId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Is Author": isAuthor,
        "Was Mentioned": wasMentioned,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Comment Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Author Id": authorId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Is Author": isAuthor,
        "Was Mentioned": wasMentioned,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Comment Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Author Id": authorId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Is Author": isAuthor,
        "Was Mentioned": wasMentioned,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Comment Seen", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Comment Id": commentId,
              "Event Id": eventId,
              "Property Id": propertyId,
              "Author Id": authorId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Is Author": isAuthor,
              "Was Mentioned": wasMentioned,
              "Author Display Name": authorDisplayName,
              "Metric Id": metricId,
              "Integration Id": integrationId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function commentDeleted(schema, commentId, eventId, propertyId, itemName, itemType, authorId, numUsersMentioned, numEventsMentioned, mentionedUserIds, mentionedEventIds, mentionedUserDisplayNames, mentionedEventDisplayNames, authorDisplayName, metricId, integrationId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("27c603c9-e6c6-4cb4-94b3-9ce7c0ca9f3a", "84538a8581901a262a1771e5752fbad5937973248f16a334d42f06f30708339b", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Comment Deleted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Comment Id": commentId,
          "Event Id": eventId,
          "Property Id": propertyId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Author Id": authorId,
          "Num Users Mentioned": numUsersMentioned,
          "Num Events Mentioned": numEventsMentioned,
          "Mentioned User Ids": mentionedUserIds,
          "Mentioned Event Ids": mentionedEventIds,
          "Mentioned User Display Names": mentionedUserDisplayNames,
          "Mentioned Event Display Names": mentionedEventDisplayNames,
          "Author Display Name": authorDisplayName,
          "Metric Id": metricId,
          "Integration Id": integrationId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("27c603c9-e6c6-4cb4-94b3-9ce7c0ca9f3a", "Comment Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "a305b8d5-9332-4643-b7ec-c1ee4a061047",
              name: "Comment Id",
              value: commentId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "da437b9d-d424-4b32-b174-2ce4e4951b70",
              name: "Author Id",
              value: authorId
            },
            {
              id: "0e3fab52-626a-4c8c-8ca8-00b4e5aca52a",
              name: "Num Users Mentioned",
              value: numUsersMentioned
            },
            {
              id: "a3e30521-8fe0-4586-9a41-6723f322fbb2",
              name: "Num Events Mentioned",
              value: numEventsMentioned
            },
            {
              id: "943fa284-f3e7-4ebf-9ed5-8da1ab123df8",
              name: "Mentioned User Ids",
              value: mentionedUserIds
            },
            {
              id: "804a8cd2-dc86-44f7-8160-acc0803a804a",
              name: "Mentioned Event Ids",
              value: mentionedEventIds
            },
            {
              id: "8186dab4-f101-4305-86e2-373f4ba9c7b1",
              name: "Mentioned User Display Names",
              value: mentionedUserDisplayNames
            },
            {
              id: "16b46837-4731-4995-970b-0ed66cc2ca38",
              name: "Mentioned Event Display Names",
              value: mentionedEventDisplayNames
            },
            {
              id: "0553f96e-e705-49ef-995f-746ee1abbfef",
              name: "Author Display Name",
              value: authorDisplayName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("27c603c9-e6c6-4cb4-94b3-9ce7c0ca9f3a", Date.now(), "Comment Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "a305b8d5-9332-4643-b7ec-c1ee4a061047",
              name: "Comment Id",
              value: commentId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "da437b9d-d424-4b32-b174-2ce4e4951b70",
              name: "Author Id",
              value: authorId
            },
            {
              id: "0e3fab52-626a-4c8c-8ca8-00b4e5aca52a",
              name: "Num Users Mentioned",
              value: numUsersMentioned
            },
            {
              id: "a3e30521-8fe0-4586-9a41-6723f322fbb2",
              name: "Num Events Mentioned",
              value: numEventsMentioned
            },
            {
              id: "943fa284-f3e7-4ebf-9ed5-8da1ab123df8",
              name: "Mentioned User Ids",
              value: mentionedUserIds
            },
            {
              id: "804a8cd2-dc86-44f7-8160-acc0803a804a",
              name: "Mentioned Event Ids",
              value: mentionedEventIds
            },
            {
              id: "8186dab4-f101-4305-86e2-373f4ba9c7b1",
              name: "Mentioned User Display Names",
              value: mentionedUserDisplayNames
            },
            {
              id: "16b46837-4731-4995-970b-0ed66cc2ca38",
              name: "Mentioned Event Display Names",
              value: mentionedEventDisplayNames
            },
            {
              id: "0553f96e-e705-49ef-995f-746ee1abbfef",
              name: "Author Display Name",
              value: authorDisplayName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Comment Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Author Id": authorId,
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Comment Deleted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Comment Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Author Id": authorId,
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Comment Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Author Id": authorId,
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Comment Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Author Id": authorId,
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Comment Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Comment Id": commentId,
        "Event Id": eventId,
        "Property Id": propertyId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Author Id": authorId,
        "Num Users Mentioned": numUsersMentioned,
        "Num Events Mentioned": numEventsMentioned,
        "Mentioned User Ids": mentionedUserIds,
        "Mentioned Event Ids": mentionedEventIds,
        "Mentioned User Display Names": mentionedUserDisplayNames,
        "Mentioned Event Display Names": mentionedEventDisplayNames,
        "Author Display Name": authorDisplayName,
        "Metric Id": metricId,
        "Integration Id": integrationId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Comment Deleted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Comment Id": commentId,
              "Event Id": eventId,
              "Property Id": propertyId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Author Id": authorId,
              "Num Users Mentioned": numUsersMentioned,
              "Num Events Mentioned": numEventsMentioned,
              "Mentioned User Ids": mentionedUserIds,
              "Mentioned Event Ids": mentionedEventIds,
              "Mentioned User Display Names": mentionedUserDisplayNames,
              "Mentioned Event Display Names": mentionedEventDisplayNames,
              "Author Display Name": authorDisplayName,
              "Metric Id": metricId,
              "Integration Id": integrationId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function profileUpdated(userId_, userName, profileUpdate, userNameBefore, userNameAfter, hadProfilePicBefore, hasProfilePicAfter, hasProfilePic, profilePicUrl, profilePicUrlAfter) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("31f466e9-ea0f-46f9-88e3-6970b4227de0", "d1d17d0532fd8bf65116e97644b4cb1af42ef7c304dac2b1391925083f385f42", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Profile Updated", {
          "Profile Update": profileUpdateToJs(profileUpdate),
          "User Name Before": userNameBefore,
          "User Name After": userNameAfter,
          "Had Profile Pic Before": hadProfilePicBefore,
          "Has Profile Pic After": hasProfilePicAfter,
          "Profile Pic URL After": profilePicUrlAfter,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          "User Name": userName,
          "Has Profile Pic": hasProfilePic,
          "Profile Pic URL": profilePicUrl
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("31f466e9-ea0f-46f9-88e3-6970b4227de0", "Profile Updated", messages, [
            {
              id: "7d28a404-70b1-469b-9b41-d2472018e1d2",
              name: "Profile Update",
              value: profileUpdateToJs(profileUpdate)
            },
            {
              id: "1dde4dec-c243-4998-afdf-14f210b449f4",
              name: "User Name Before",
              value: userNameBefore
            },
            {
              id: "b903be60-0f95-43de-ae5c-fbcc4e9b0d47",
              name: "User Name After",
              value: userNameAfter
            },
            {
              id: "8917bdcb-c037-4b56-a1a3-039ce65d903d",
              name: "Had Profile Pic Before",
              value: hadProfilePicBefore
            },
            {
              id: "f145135a-c1b9-4ae5-8312-edb11072a345",
              name: "Has Profile Pic After",
              value: hasProfilePicAfter
            },
            {
              id: "77e66cda-c1a3-4aeb-8e44-beeb73e902ec",
              name: "Profile Pic URL After",
              value: profilePicUrlAfter
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([
                {
                  id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
                  name: "User Name",
                  value: userName
                },
                {
                  id: "9f10744b-f571-42f9-9c8f-51d87414d9d0",
                  name: "Has Profile Pic",
                  value: hasProfilePic
                },
                {
                  id: "010b52d1-c7ee-4a92-a0fd-5deb535d1d8e",
                  name: "Profile Pic URL",
                  value: profilePicUrl
                }
              ], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("31f466e9-ea0f-46f9-88e3-6970b4227de0", Date.now(), "Profile Updated", messages, [
            {
              id: "7d28a404-70b1-469b-9b41-d2472018e1d2",
              name: "Profile Update",
              value: profileUpdateToJs(profileUpdate)
            },
            {
              id: "1dde4dec-c243-4998-afdf-14f210b449f4",
              name: "User Name Before",
              value: userNameBefore
            },
            {
              id: "b903be60-0f95-43de-ae5c-fbcc4e9b0d47",
              name: "User Name After",
              value: userNameAfter
            },
            {
              id: "8917bdcb-c037-4b56-a1a3-039ce65d903d",
              name: "Had Profile Pic Before",
              value: hadProfilePicBefore
            },
            {
              id: "f145135a-c1b9-4ae5-8312-edb11072a345",
              name: "Has Profile Pic After",
              value: hasProfilePicAfter
            },
            {
              id: "77e66cda-c1a3-4aeb-8e44-beeb73e902ec",
              name: "Profile Pic URL After",
              value: profilePicUrlAfter
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [
            {
              id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
              name: "User Name",
              value: userName
            },
            {
              id: "9f10744b-f571-42f9-9c8f-51d87414d9d0",
              name: "Has Profile Pic",
              value: hasProfilePic
            },
            {
              id: "010b52d1-c7ee-4a92-a0fd-5deb535d1d8e",
              name: "Profile Pic URL",
              value: profilePicUrl
            }
          ]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        "User Name": userName,
        "Has Profile Pic": hasProfilePic,
        "Profile Pic URL": profilePicUrl
      });
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Profile Updated", {
        "Profile Update": profileUpdateToJs(profileUpdate),
        "User Name Before": userNameBefore,
        "User Name After": userNameAfter,
        "Had Profile Pic Before": hadProfilePicBefore,
        "Has Profile Pic After": hasProfilePicAfter,
        "Profile Pic URL After": profilePicUrlAfter,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomUserProperties = {
    name: userName,
    "Has Profile Pic": hasProfilePic,
    "Profile Pic URL": profilePicUrl
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {};
  logEvent("Profile Updated", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
        "User Name": userName,
        "Has Profile Pic": hasProfilePic,
        "Profile Pic URL": profilePicUrl
      });
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Profile Updated", {
        "Profile Update": profileUpdateToJs(profileUpdate),
        "User Name Before": userNameBefore,
        "User Name After": userNameAfter,
        "Had Profile Pic Before": hadProfilePicBefore,
        "Has Profile Pic After": hasProfilePicAfter,
        "Profile Pic URL After": profilePicUrlAfter,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  setUserProperties$1({
        "User Name": userName,
        "Has Profile Pic": hasProfilePic,
        "Profile Pic URL": profilePicUrl
      });
  logEvent$1("Profile Updated", {
        "Profile Update": profileUpdateToJs(profileUpdate),
        "User Name Before": userNameBefore,
        "User Name After": userNameAfter,
        "Had Profile Pic Before": hadProfilePicBefore,
        "Has Profile Pic After": hasProfilePicAfter,
        "Profile Pic URL After": profilePicUrlAfter,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        name: userName,
        "Has Profile Pic": hasProfilePic,
        "Profile Pic URL": profilePicUrl
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Profile Updated", {
        "Profile Update": profileUpdateToJs(profileUpdate),
        "User Name Before": userNameBefore,
        "User Name After": userNameAfter,
        "Had Profile Pic Before": hadProfilePicBefore,
        "Has Profile Pic After": hasProfilePicAfter,
        "Profile Pic URL After": profilePicUrlAfter,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
        "User Name": userName,
        "Has Profile Pic": hasProfilePic,
        "Profile Pic URL": profilePicUrl
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Profile Updated", {
        "Profile Update": profileUpdateToJs(profileUpdate),
        "User Name Before": userNameBefore,
        "User Name After": userNameAfter,
        "Had Profile Pic Before": hadProfilePicBefore,
        "Has Profile Pic After": hasProfilePicAfter,
        "Profile Pic URL After": profilePicUrlAfter,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  setUserProperties$2({
        "User Name": userName,
        "Has Profile Pic": hasProfilePic,
        "Profile Pic URL": profilePicUrl
      });
  return logEvent$2("Profile Updated", {
              "Profile Update": profileUpdateToJs(profileUpdate),
              "User Name Before": userNameBefore,
              "User Name After": userNameAfter,
              "Had Profile Pic Before": hadProfilePicBefore,
              "Has Profile Pic After": hasProfilePicAfter,
              "Profile Pic URL After": profilePicUrlAfter,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function profileViewed(userId, profilePic, displayName, isOwnProfile) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("4c0e8f0a-0175-45e7-9bf2-578e674942fa", "ee24c6e08da5559f0b4ff4d14c1595fe684a886b9468f7ff8f786c26b2d694fd", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Profile Viewed", {
          "User Id": userId,
          "Profile Pic": profilePic,
          "Display Name": displayName,
          "Is Own Profile": isOwnProfile,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("4c0e8f0a-0175-45e7-9bf2-578e674942fa", "Profile Viewed", messages, [
            {
              id: "49bb759b-0d16-4698-8622-807e3c1e5bd4",
              name: "User Id",
              value: userId
            },
            {
              id: "a0120de3-69da-47da-befa-b777dcff39fa",
              name: "Profile Pic",
              value: profilePic
            },
            {
              id: "8e03a92a-c09f-4407-be89-65463ad6bb54",
              name: "Display Name",
              value: displayName
            },
            {
              id: "dcc5550b-5cb6-4496-91e5-de8879c1e02e",
              name: "Is Own Profile",
              value: isOwnProfile
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("4c0e8f0a-0175-45e7-9bf2-578e674942fa", Date.now(), "Profile Viewed", messages, [
            {
              id: "49bb759b-0d16-4698-8622-807e3c1e5bd4",
              name: "User Id",
              value: userId
            },
            {
              id: "a0120de3-69da-47da-befa-b777dcff39fa",
              name: "Profile Pic",
              value: profilePic
            },
            {
              id: "8e03a92a-c09f-4407-be89-65463ad6bb54",
              name: "Display Name",
              value: displayName
            },
            {
              id: "dcc5550b-5cb6-4496-91e5-de8879c1e02e",
              name: "Is Own Profile",
              value: isOwnProfile
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Profile Viewed", {
        "User Id": userId,
        "Profile Pic": profilePic,
        "Display Name": displayName,
        "Is Own Profile": isOwnProfile,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Profile Viewed", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Profile Viewed", {
        "User Id": userId,
        "Profile Pic": profilePic,
        "Display Name": displayName,
        "Is Own Profile": isOwnProfile,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Profile Viewed", {
        "User Id": userId,
        "Profile Pic": profilePic,
        "Display Name": displayName,
        "Is Own Profile": isOwnProfile,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Profile Viewed", {
        "User Id": userId,
        "Profile Pic": profilePic,
        "Display Name": displayName,
        "Is Own Profile": isOwnProfile,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Profile Viewed", {
        "User Id": userId,
        "Profile Pic": profilePic,
        "Display Name": displayName,
        "Is Own Profile": isOwnProfile,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Profile Viewed", {
              "User Id": userId,
              "Profile Pic": profilePic,
              "Display Name": displayName,
              "Is Own Profile": isOwnProfile,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function memberRemoved(schema, role, userId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("10801e4b-bbf4-4351-bc77-e284772911e5", "7b99c8d7b2f4e404265c9d8e82a9130c7019e5c44ee63d5c982226081173227c", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Member Removed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Role: roleToJs(role),
          "User Id": userId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("10801e4b-bbf4-4351-bc77-e284772911e5", "Member Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "49bb759b-0d16-4698-8622-807e3c1e5bd4",
              name: "User Id",
              value: userId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("10801e4b-bbf4-4351-bc77-e284772911e5", Date.now(), "Member Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "49bb759b-0d16-4698-8622-807e3c1e5bd4",
              name: "User Id",
              value: userId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Member Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "User Id": userId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Member Removed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Member Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "User Id": userId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Member Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "User Id": userId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Member Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "User Id": userId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Member Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "User Id": userId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Member Removed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Role: roleToJs(role),
              "User Id": userId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function memberRoleChanged(schema, fromRole, userId, toRole, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("24311733-a8c2-400f-8a1b-905343224e02", "5bdc64ab12ca0767db99d9629fc1722e8586142a6d98aa700f49481e1d838ac2", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Member Role Changed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "From Role": fromRoleToJs(fromRole),
          "User Id": userId,
          "To Role": toRoleToJs(toRole),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("24311733-a8c2-400f-8a1b-905343224e02", "Member Role Changed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1235dce3-8d74-4a36-a217-94455c32a86e",
              name: "From Role",
              value: fromRoleToJs(fromRole)
            },
            {
              id: "49bb759b-0d16-4698-8622-807e3c1e5bd4",
              name: "User Id",
              value: userId
            },
            {
              id: "11c4e8ee-a2d2-4ec1-8559-37bfce104024",
              name: "To Role",
              value: toRoleToJs(toRole)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("24311733-a8c2-400f-8a1b-905343224e02", Date.now(), "Member Role Changed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1235dce3-8d74-4a36-a217-94455c32a86e",
              name: "From Role",
              value: fromRoleToJs(fromRole)
            },
            {
              id: "49bb759b-0d16-4698-8622-807e3c1e5bd4",
              name: "User Id",
              value: userId
            },
            {
              id: "11c4e8ee-a2d2-4ec1-8559-37bfce104024",
              name: "To Role",
              value: toRoleToJs(toRole)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Member Role Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Role": fromRoleToJs(fromRole),
        "User Id": userId,
        "To Role": toRoleToJs(toRole),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Member Role Changed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Member Role Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Role": fromRoleToJs(fromRole),
        "User Id": userId,
        "To Role": toRoleToJs(toRole),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Member Role Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Role": fromRoleToJs(fromRole),
        "User Id": userId,
        "To Role": toRoleToJs(toRole),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Member Role Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Role": fromRoleToJs(fromRole),
        "User Id": userId,
        "To Role": toRoleToJs(toRole),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Member Role Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Role": fromRoleToJs(fromRole),
        "User Id": userId,
        "To Role": toRoleToJs(toRole),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Member Role Changed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "From Role": fromRoleToJs(fromRole),
              "User Id": userId,
              "To Role": toRoleToJs(toRole),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function upgradeBannerClicked(schema, role, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("56334e66-37d2-496a-b9a9-7fa41ca26c13", "30f781fa7e50f8cab7a4b40d10a92b7361385a3f0414ec53d06f4629adfa82bd", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Upgrade Banner Clicked", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Role: roleToJs(role),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("56334e66-37d2-496a-b9a9-7fa41ca26c13", "Upgrade Banner Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("56334e66-37d2-496a-b9a9-7fa41ca26c13", Date.now(), "Upgrade Banner Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Upgrade Banner Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Upgrade Banner Clicked", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Upgrade Banner Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Upgrade Banner Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Upgrade Banner Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Upgrade Banner Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Upgrade Banner Clicked", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Role: roleToJs(role),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTagsUpdated(schema, tagAction, tag, eventTags, eventId, eventName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("2c8bc17e-c484-473b-9916-b8c804dc2107", "f96161e397b34280da877153f583c2d6a0f8ae328910134eb6b434c803007068", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Tags Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Tag Action": tagAction,
          Tag: tag,
          "Event Tags": eventTags,
          "Event Id": eventId,
          "Event Name": eventName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("2c8bc17e-c484-473b-9916-b8c804dc2107", "Event Tags Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bb6a4988-7ba0-4e7a-aa30-451e71e1db00",
              name: "Tag Action",
              value: tagAction
            },
            {
              id: "d36fd9b0-fb3c-4bfa-913b-4e68982c9679",
              name: "Tag",
              value: tag
            },
            {
              id: "a96f904b-41a7-4c3e-888a-8b266ab5a222",
              name: "Event Tags",
              value: eventTags
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("2c8bc17e-c484-473b-9916-b8c804dc2107", Date.now(), "Event Tags Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bb6a4988-7ba0-4e7a-aa30-451e71e1db00",
              name: "Tag Action",
              value: tagAction
            },
            {
              id: "d36fd9b0-fb3c-4bfa-913b-4e68982c9679",
              name: "Tag",
              value: tag
            },
            {
              id: "a96f904b-41a7-4c3e-888a-8b266ab5a222",
              name: "Event Tags",
              value: eventTags
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Tags Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tag Action": tagAction,
        Tag: tag,
        "Event Tags": eventTags,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Tags Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Tags Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tag Action": tagAction,
        Tag: tag,
        "Event Tags": eventTags,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Tags Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tag Action": tagAction,
        Tag: tag,
        "Event Tags": eventTags,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Tags Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tag Action": tagAction,
        Tag: tag,
        "Event Tags": eventTags,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Tags Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tag Action": tagAction,
        Tag: tag,
        "Event Tags": eventTags,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Tags Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Tag Action": tagAction,
              Tag: tag,
              "Event Tags": eventTags,
              "Event Id": eventId,
              "Event Name": eventName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function filterLinkOpened(schema, currentFilters, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("48257b8e-2050-462b-9cb5-377d3bb269e1", "b253bd06d37e94a33958673d32091b78301e2bc97cb60abcac5f06ff2675c4d5", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Filter Link Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("48257b8e-2050-462b-9cb5-377d3bb269e1", "Filter Link Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("48257b8e-2050-462b-9cb5-377d3bb269e1", Date.now(), "Filter Link Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Filter Link Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Filter Link Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Filter Link Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Filter Link Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Filter Link Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Filter Link Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Filter Link Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function categoryCreated(schema, categoryId, categoryName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("BcW50njEB", "46c4dc523fec3f6d35c5e4f0ba3dcddcf97201bd0f75a3c1dc09796d2ebf0206", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Category Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Category Id": categoryId,
          "Category Name": categoryName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("BcW50njEB", "Category Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "-cEuG_adl",
              name: "Category Id",
              value: categoryId
            },
            {
              id: "H9MtLwQR3",
              name: "Category Name",
              value: categoryName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("BcW50njEB", Date.now(), "Category Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "-cEuG_adl",
              name: "Category Id",
              value: categoryId
            },
            {
              id: "H9MtLwQR3",
              name: "Category Name",
              value: categoryName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Category Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Category Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Category Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Category Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Category Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Category Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Category Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Category Id": categoryId,
              "Category Name": categoryName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function categoryUpdated(schema, categoryName, categoryId, categoryUpdateAction, metricName, metricId, eventName, eventId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("0tcIgp4NV", "1409a5f7774a087755a66f8afc4018c84bab89dcf149af7ffe3e80c7df4cd5ef", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Category Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Category Name": categoryName,
          "Category Id": categoryId,
          "Category Update Action": categoryUpdateActionToJs(categoryUpdateAction),
          "Metric Name": metricName,
          "Metric Id": metricId,
          "Event Name": eventName,
          "Event Id": eventId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("0tcIgp4NV", "Category Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "H9MtLwQR3",
              name: "Category Name",
              value: categoryName
            },
            {
              id: "-cEuG_adl",
              name: "Category Id",
              value: categoryId
            },
            {
              id: "SzFVxC9t_",
              name: "Category Update Action",
              value: categoryUpdateActionToJs(categoryUpdateAction)
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("0tcIgp4NV", Date.now(), "Category Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "H9MtLwQR3",
              name: "Category Name",
              value: categoryName
            },
            {
              id: "-cEuG_adl",
              name: "Category Id",
              value: categoryId
            },
            {
              id: "SzFVxC9t_",
              name: "Category Update Action",
              value: categoryUpdateActionToJs(categoryUpdateAction)
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Category Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Name": categoryName,
        "Category Id": categoryId,
        "Category Update Action": categoryUpdateActionToJs(categoryUpdateAction),
        "Metric Name": metricName,
        "Metric Id": metricId,
        "Event Name": eventName,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Category Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Category Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Name": categoryName,
        "Category Id": categoryId,
        "Category Update Action": categoryUpdateActionToJs(categoryUpdateAction),
        "Metric Name": metricName,
        "Metric Id": metricId,
        "Event Name": eventName,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Category Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Name": categoryName,
        "Category Id": categoryId,
        "Category Update Action": categoryUpdateActionToJs(categoryUpdateAction),
        "Metric Name": metricName,
        "Metric Id": metricId,
        "Event Name": eventName,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Category Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Name": categoryName,
        "Category Id": categoryId,
        "Category Update Action": categoryUpdateActionToJs(categoryUpdateAction),
        "Metric Name": metricName,
        "Metric Id": metricId,
        "Event Name": eventName,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Category Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Name": categoryName,
        "Category Id": categoryId,
        "Category Update Action": categoryUpdateActionToJs(categoryUpdateAction),
        "Metric Name": metricName,
        "Metric Id": metricId,
        "Event Name": eventName,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Category Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Category Name": categoryName,
              "Category Id": categoryId,
              "Category Update Action": categoryUpdateActionToJs(categoryUpdateAction),
              "Metric Name": metricName,
              "Metric Id": metricId,
              "Event Name": eventName,
              "Event Id": eventId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function categoryArchived(schema, categoryId, categoryName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("sbbQlD8kZ", "547d0d62bbc89301e2bb3485618ca46f617128c63f45c34986b587623f7bb4f8", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Category Archived", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Category Id": categoryId,
          "Category Name": categoryName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("sbbQlD8kZ", "Category Archived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "-cEuG_adl",
              name: "Category Id",
              value: categoryId
            },
            {
              id: "H9MtLwQR3",
              name: "Category Name",
              value: categoryName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("sbbQlD8kZ", Date.now(), "Category Archived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "-cEuG_adl",
              name: "Category Id",
              value: categoryId
            },
            {
              id: "H9MtLwQR3",
              name: "Category Name",
              value: categoryName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Category Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Category Archived", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Category Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Category Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Category Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Category Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Category Id": categoryId,
        "Category Name": categoryName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Category Archived", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Category Id": categoryId,
              "Category Name": categoryName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function metricInitiated(schema, metricId, metricName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("mzjJ10eld", "508c0eacc295e5a20b2c6e874cc1e056df010bf4e86452ebde301ca3ded75101", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Metric Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Metric Id": metricId,
          "Metric Name": metricName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("mzjJ10eld", "Metric Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("mzjJ10eld", Date.now(), "Metric Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Metric Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Metric Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Metric Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Metric Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Metric Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Metric Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Metric Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Metric Id": metricId,
              "Metric Name": metricName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function metricArchived(schema, metricId, metricName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("5Bf10LXIV", "41a86d79d4a3c3d27f1960cf6178629409a4ab5fcbd31b4214030821e78f0464", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Metric Archived", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Metric Id": metricId,
          "Metric Name": metricName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("5Bf10LXIV", "Metric Archived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("5Bf10LXIV", Date.now(), "Metric Archived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Metric Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Metric Archived", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Metric Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Metric Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Metric Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Metric Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Metric Archived", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Metric Id": metricId,
              "Metric Name": metricName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function metricUpdated(schema, metricId, metricName, eventId, eventName, metricUpdateAction, metricType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("swI0J5jLl", "d13c312c4da27c6cfd9f44824dfe505f3f1013480af8413505b84d3d33fc5162", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Metric Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Metric Id": metricId,
          "Metric Name": metricName,
          "Event Id": eventId,
          "Event Name": eventName,
          "Metric Update Action": metricUpdateActionToJs(metricUpdateAction),
          "Metric Type": metricTypeToJs(metricType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("swI0J5jLl", "Metric Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "tJ_fP64Ki",
              name: "Metric Update Action",
              value: metricUpdateActionToJs(metricUpdateAction)
            },
            {
              id: "eZBaaNVwB",
              name: "Metric Type",
              value: metricTypeToJs(metricType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("swI0J5jLl", Date.now(), "Metric Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HJrNUAYBR",
              name: "Metric Id",
              value: metricId
            },
            {
              id: "gs-RQE8VE",
              name: "Metric Name",
              value: metricName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "tJ_fP64Ki",
              name: "Metric Update Action",
              value: metricUpdateActionToJs(metricUpdateAction)
            },
            {
              id: "eZBaaNVwB",
              name: "Metric Type",
              value: metricTypeToJs(metricType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Metric Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Metric Update Action": metricUpdateActionToJs(metricUpdateAction),
        "Metric Type": metricTypeToJs(metricType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Metric Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Metric Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Metric Update Action": metricUpdateActionToJs(metricUpdateAction),
        "Metric Type": metricTypeToJs(metricType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Metric Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Metric Update Action": metricUpdateActionToJs(metricUpdateAction),
        "Metric Type": metricTypeToJs(metricType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Metric Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Metric Update Action": metricUpdateActionToJs(metricUpdateAction),
        "Metric Type": metricTypeToJs(metricType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Metric Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Metric Id": metricId,
        "Metric Name": metricName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Metric Update Action": metricUpdateActionToJs(metricUpdateAction),
        "Metric Type": metricTypeToJs(metricType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Metric Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Metric Id": metricId,
              "Metric Name": metricName,
              "Event Id": eventId,
              "Event Name": eventName,
              "Metric Update Action": metricUpdateActionToJs(metricUpdateAction),
              "Metric Type": metricTypeToJs(metricType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchCreated(schema, toBranchId, toBranchName, branchOrigin, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("fYjtgy4Uo", "196437ac9fa0101b754f6d57341ac12a93b4f33c0cdd3b07f76c7aad6cf7eb75", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "To Branch Id": toBranchId,
          "To Branch Name": toBranchName,
          "Branch Origin": branchOriginToJs(branchOrigin),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("fYjtgy4Uo", "Branch Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "2FDcrPy2f",
              name: "To Branch Name",
              value: toBranchName
            },
            {
              id: "wTDC7-iosl",
              name: "Branch Origin",
              value: branchOriginToJs(branchOrigin)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("fYjtgy4Uo", Date.now(), "Branch Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "2FDcrPy2f",
              name: "To Branch Name",
              value: toBranchName
            },
            {
              id: "wTDC7-iosl",
              name: "Branch Origin",
              value: branchOriginToJs(branchOrigin)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "To Branch Name": toBranchName,
        "Branch Origin": branchOriginToJs(branchOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "To Branch Name": toBranchName,
        "Branch Origin": branchOriginToJs(branchOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "To Branch Name": toBranchName,
        "Branch Origin": branchOriginToJs(branchOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "To Branch Name": toBranchName,
        "Branch Origin": branchOriginToJs(branchOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "To Branch Name": toBranchName,
        "Branch Origin": branchOriginToJs(branchOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branch Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "To Branch Id": toBranchId,
              "To Branch Name": toBranchName,
              "Branch Origin": branchOriginToJs(branchOrigin),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchMerged(schema, numEventsUpdated, numNewEvents, numComments, numIntegrationsPublished, integrationTypesPublished, numNewProperties, numUpdatedProperties, numNewGloballyOptionalProperties, numPropertiesInSchema, numEventsInSchema, numGloballyOptionalPropertiesInSchema, numEventInstancesUpdatedOnBranch, numEventInstancesImplementedOnBranch, numSourcesUpdatedOnBranch, numSourcesImplementedOnBranch, proportionOfUpdatedEventInstancesImplementedOnBranch, proportionOfUpdatedSourcesImplementedOnBranch, numSourcesUpdatedOnBranchConnectedToAvoFunctions, numSourcesUpdatedOnBranchConnectedToInspector, numSourcesUpdatedOnBranchConnectedToImplementationStatus, proportionOfEventInstancesImplementedPerSource, numNewEventsWithTriggers, numUpdatedEventsWithTriggers, numSourcesUpdatedOnBranchWithTriggers, numBranchApprovalsRequired, numBranchApprovals, minimumApprovalRoleRequired, role, branchMergePermissionsBypassed, numNewSchemaIssuesOnBranch, numSchemaIssues, numSchemaIssuesDelta, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForBranch, branchNumEventsUpdated, branchNumPropertiesUpdated, updateGroupPropertiesForWorkspace, workspaceNumIssues) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumComments(numComments));
    var messages$2 = Belt_Array.concat(messages$1, assertNumIntegrationsPublished(numIntegrationsPublished));
    var messages$3 = Belt_Array.concat(messages$2, assertNumNewProperties(numNewProperties));
    var messages$4 = Belt_Array.concat(messages$3, assertNumUpdatedProperties(numUpdatedProperties));
    var messages$5 = Belt_Array.concat(messages$4, assertNumNewGloballyOptionalProperties(numNewGloballyOptionalProperties));
    var messages$6 = Belt_Array.concat(messages$5, assertNumPropertiesInSchema(numPropertiesInSchema));
    var messages$7 = Belt_Array.concat(messages$6, assertNumEventsInSchema(numEventsInSchema));
    var messages$8 = Belt_Array.concat(messages$7, assertNumGloballyOptionalPropertiesInSchema(numGloballyOptionalPropertiesInSchema));
    var messages$9 = Belt_Array.concat(messages$8, assertProportionOfUpdatedEventInstancesImplementedOnBranchOptional(proportionOfUpdatedEventInstancesImplementedOnBranch));
    var messages$10 = Belt_Array.concat(messages$9, assertProportionOfUpdatedSourcesImplementedOnBranchOptional(proportionOfUpdatedSourcesImplementedOnBranch));
    var messages$11 = Belt_Array.concat(messages$10, assertNumNewEventsWithTriggers(numNewEventsWithTriggers));
    var messages$12 = Belt_Array.concat(messages$11, assertNumUpdatedEventsWithTriggers(numUpdatedEventsWithTriggers));
    var messages$13 = Belt_Array.concat(messages$12, assertNumSourcesUpdatedOnBranchWithTriggers(numSourcesUpdatedOnBranchWithTriggers));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("lAeqY3Lv_", "c3888a7be6e771d6233aec26674d2d744ce0735875256ff9c8b0e4a9318e9024", Belt_Array.map(messages$13, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Merged", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Events Updated": numEventsUpdated,
          "Num New Events": numNewEvents,
          "Num Comments": numComments,
          "Num Integrations Published": numIntegrationsPublished,
          "Integration Types Published": Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs),
          "Num New Properties": numNewProperties,
          "Num Updated Properties": numUpdatedProperties,
          "Num New Globally Optional Properties": numNewGloballyOptionalProperties,
          "Num Properties in Schema": numPropertiesInSchema,
          "Num Events in Schema": numEventsInSchema,
          "Num Globally Optional Properties in Schema": numGloballyOptionalPropertiesInSchema,
          "Num Event Instances Updated on Branch": numEventInstancesUpdatedOnBranch,
          "Num Event Instances Implemented on Branch": numEventInstancesImplementedOnBranch,
          "Num Sources Updated on Branch": numSourcesUpdatedOnBranch,
          "Num Sources Implemented on Branch": numSourcesImplementedOnBranch,
          "Proportion of Updated Event Instances Implemented on Branch": proportionOfUpdatedEventInstancesImplementedOnBranch,
          "Proportion of Updated Sources Implemented on Branch": proportionOfUpdatedSourcesImplementedOnBranch,
          "Num Sources Updated on Branch Connected to Avo Functions": numSourcesUpdatedOnBranchConnectedToAvoFunctions,
          "Num Sources Updated on Branch Connected to Inspector": numSourcesUpdatedOnBranchConnectedToInspector,
          "Num Sources Updated on Branch Connected to Implementation Status": numSourcesUpdatedOnBranchConnectedToImplementationStatus,
          "Proportion of Event Instances Implemented Per Source": proportionOfEventInstancesImplementedPerSource,
          "Num New Events With Triggers": numNewEventsWithTriggers,
          "Num Updated Events With Triggers": numUpdatedEventsWithTriggers,
          "Num Sources Updated on Branch With Triggers": numSourcesUpdatedOnBranchWithTriggers,
          "Num Branch Approvals Required": numBranchApprovalsRequired,
          "Num Branch Approvals": numBranchApprovals,
          "Minimum Approval Role Required": minimumApprovalRoleRequired,
          Role: roleToJs(role),
          "Branch Merge Permissions Bypassed": branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed),
          "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
          "Num Schema Issues": numSchemaIssues,
          "Num Schema Issues Delta": numSchemaIssuesDelta,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("lAeqY3Lv_", "Branch Merged", messages$13, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "kyJDcNT_mD",
              name: "Num Events Updated",
              value: numEventsUpdated
            },
            {
              id: "jv0BDyo0J9",
              name: "Num New Events",
              value: numNewEvents
            },
            {
              id: "J9zcb15BBu",
              name: "Num Comments",
              value: numComments
            },
            {
              id: "GU53ROkPSl",
              name: "Num Integrations Published",
              value: numIntegrationsPublished
            },
            {
              id: "T1LoZn_jPp",
              name: "Integration Types Published",
              value: Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs)
            },
            {
              id: "QdSM2v9BoJ",
              name: "Num New Properties",
              value: numNewProperties
            },
            {
              id: "vXVK6UQZE",
              name: "Num Updated Properties",
              value: numUpdatedProperties
            },
            {
              id: "TRnooYxfQb",
              name: "Num New Globally Optional Properties",
              value: numNewGloballyOptionalProperties
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "2zj0XISSov",
              name: "Num Globally Optional Properties in Schema",
              value: numGloballyOptionalPropertiesInSchema
            },
            {
              id: "kx3L7A5-Gj",
              name: "Num Event Instances Updated on Branch",
              value: numEventInstancesUpdatedOnBranch
            },
            {
              id: "3wRXmW1Qy6",
              name: "Num Event Instances Implemented on Branch",
              value: numEventInstancesImplementedOnBranch
            },
            {
              id: "tQLDhfVd8_",
              name: "Num Sources Updated on Branch",
              value: numSourcesUpdatedOnBranch
            },
            {
              id: "KEbpZkK_tX",
              name: "Num Sources Implemented on Branch",
              value: numSourcesImplementedOnBranch
            },
            {
              id: "WeAPrAlkjw",
              name: "Proportion of Updated Event Instances Implemented on Branch",
              value: proportionOfUpdatedEventInstancesImplementedOnBranch
            },
            {
              id: "cPiHeiKv7",
              name: "Proportion of Updated Sources Implemented on Branch",
              value: proportionOfUpdatedSourcesImplementedOnBranch
            },
            {
              id: "KyCZp3bEBh",
              name: "Num Sources Updated on Branch Connected to Avo Functions",
              value: numSourcesUpdatedOnBranchConnectedToAvoFunctions
            },
            {
              id: "xUHSaTtCS2",
              name: "Num Sources Updated on Branch Connected to Inspector",
              value: numSourcesUpdatedOnBranchConnectedToInspector
            },
            {
              id: "c4K3Kexl9",
              name: "Num Sources Updated on Branch Connected to Implementation Status",
              value: numSourcesUpdatedOnBranchConnectedToImplementationStatus
            },
            {
              id: "AiUaYd_mLRA",
              name: "Proportion of Event Instances Implemented Per Source",
              value: proportionOfEventInstancesImplementedPerSource
            },
            {
              id: "ESJodwvKqG",
              name: "Num New Events With Triggers",
              value: numNewEventsWithTriggers
            },
            {
              id: "HtPijQLIwu",
              name: "Num Updated Events With Triggers",
              value: numUpdatedEventsWithTriggers
            },
            {
              id: "15DfinuqFE",
              name: "Num Sources Updated on Branch With Triggers",
              value: numSourcesUpdatedOnBranchWithTriggers
            },
            {
              id: "O9MVK9OPv9",
              name: "Num Branch Approvals Required",
              value: numBranchApprovalsRequired
            },
            {
              id: "A6fFIqgbb",
              name: "Num Branch Approvals",
              value: numBranchApprovals
            },
            {
              id: "JbVdm6KxfI",
              name: "Minimum Approval Role Required",
              value: minimumApprovalRoleRequired
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "L5S_6ih12n",
              name: "Branch Merge Permissions Bypassed",
              value: branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed)
            },
            {
              id: "dvg1AKQZk",
              name: "Num New Schema Issues on Branch",
              value: numNewSchemaIssuesOnBranch
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "Q1MrBS64p",
              name: "Num Schema Issues Delta",
              value: numSchemaIssuesDelta
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "NeuPbAtCEr",
                  name: "Branch Num Events Updated (Branch " + (updateGroupPropertiesForBranch + " group property)"),
                  value: branchNumEventsUpdated
                },
                {
                  id: "sa41GSQrDy",
                  name: "Branch Num Properties Updated (Branch " + (updateGroupPropertiesForBranch + " group property)"),
                  value: branchNumPropertiesUpdated
                },
                {
                  id: "TAeRBeXfx1",
                  name: "Workspace Num Issues (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumIssues
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("lAeqY3Lv_", Date.now(), "Branch Merged", messages$13, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "kyJDcNT_mD",
              name: "Num Events Updated",
              value: numEventsUpdated
            },
            {
              id: "jv0BDyo0J9",
              name: "Num New Events",
              value: numNewEvents
            },
            {
              id: "J9zcb15BBu",
              name: "Num Comments",
              value: numComments
            },
            {
              id: "GU53ROkPSl",
              name: "Num Integrations Published",
              value: numIntegrationsPublished
            },
            {
              id: "T1LoZn_jPp",
              name: "Integration Types Published",
              value: Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs)
            },
            {
              id: "QdSM2v9BoJ",
              name: "Num New Properties",
              value: numNewProperties
            },
            {
              id: "vXVK6UQZE",
              name: "Num Updated Properties",
              value: numUpdatedProperties
            },
            {
              id: "TRnooYxfQb",
              name: "Num New Globally Optional Properties",
              value: numNewGloballyOptionalProperties
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "2zj0XISSov",
              name: "Num Globally Optional Properties in Schema",
              value: numGloballyOptionalPropertiesInSchema
            },
            {
              id: "kx3L7A5-Gj",
              name: "Num Event Instances Updated on Branch",
              value: numEventInstancesUpdatedOnBranch
            },
            {
              id: "3wRXmW1Qy6",
              name: "Num Event Instances Implemented on Branch",
              value: numEventInstancesImplementedOnBranch
            },
            {
              id: "tQLDhfVd8_",
              name: "Num Sources Updated on Branch",
              value: numSourcesUpdatedOnBranch
            },
            {
              id: "KEbpZkK_tX",
              name: "Num Sources Implemented on Branch",
              value: numSourcesImplementedOnBranch
            },
            {
              id: "WeAPrAlkjw",
              name: "Proportion of Updated Event Instances Implemented on Branch",
              value: proportionOfUpdatedEventInstancesImplementedOnBranch
            },
            {
              id: "cPiHeiKv7",
              name: "Proportion of Updated Sources Implemented on Branch",
              value: proportionOfUpdatedSourcesImplementedOnBranch
            },
            {
              id: "KyCZp3bEBh",
              name: "Num Sources Updated on Branch Connected to Avo Functions",
              value: numSourcesUpdatedOnBranchConnectedToAvoFunctions
            },
            {
              id: "xUHSaTtCS2",
              name: "Num Sources Updated on Branch Connected to Inspector",
              value: numSourcesUpdatedOnBranchConnectedToInspector
            },
            {
              id: "c4K3Kexl9",
              name: "Num Sources Updated on Branch Connected to Implementation Status",
              value: numSourcesUpdatedOnBranchConnectedToImplementationStatus
            },
            {
              id: "AiUaYd_mLRA",
              name: "Proportion of Event Instances Implemented Per Source",
              value: proportionOfEventInstancesImplementedPerSource
            },
            {
              id: "ESJodwvKqG",
              name: "Num New Events With Triggers",
              value: numNewEventsWithTriggers
            },
            {
              id: "HtPijQLIwu",
              name: "Num Updated Events With Triggers",
              value: numUpdatedEventsWithTriggers
            },
            {
              id: "15DfinuqFE",
              name: "Num Sources Updated on Branch With Triggers",
              value: numSourcesUpdatedOnBranchWithTriggers
            },
            {
              id: "O9MVK9OPv9",
              name: "Num Branch Approvals Required",
              value: numBranchApprovalsRequired
            },
            {
              id: "A6fFIqgbb",
              name: "Num Branch Approvals",
              value: numBranchApprovals
            },
            {
              id: "JbVdm6KxfI",
              name: "Minimum Approval Role Required",
              value: minimumApprovalRoleRequired
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "L5S_6ih12n",
              name: "Branch Merge Permissions Bypassed",
              value: branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed)
            },
            {
              id: "dvg1AKQZk",
              name: "Num New Schema Issues on Branch",
              value: numNewSchemaIssuesOnBranch
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "Q1MrBS64p",
              name: "Num Schema Issues Delta",
              value: numSchemaIssuesDelta
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Num Events Updated": branchNumEventsUpdated,
        "Branch Num Properties Updated": branchNumPropertiesUpdated
      });
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Issues": workspaceNumIssues
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Merged", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events Updated": numEventsUpdated,
        "Num New Events": numNewEvents,
        "Num Comments": numComments,
        "Num Integrations Published": numIntegrationsPublished,
        "Integration Types Published": Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs),
        "Num New Properties": numNewProperties,
        "Num Updated Properties": numUpdatedProperties,
        "Num New Globally Optional Properties": numNewGloballyOptionalProperties,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Events in Schema": numEventsInSchema,
        "Num Globally Optional Properties in Schema": numGloballyOptionalPropertiesInSchema,
        "Num Event Instances Updated on Branch": numEventInstancesUpdatedOnBranch,
        "Num Event Instances Implemented on Branch": numEventInstancesImplementedOnBranch,
        "Num Sources Updated on Branch": numSourcesUpdatedOnBranch,
        "Num Sources Implemented on Branch": numSourcesImplementedOnBranch,
        "Proportion of Updated Event Instances Implemented on Branch": proportionOfUpdatedEventInstancesImplementedOnBranch,
        "Proportion of Updated Sources Implemented on Branch": proportionOfUpdatedSourcesImplementedOnBranch,
        "Num Sources Updated on Branch Connected to Avo Functions": numSourcesUpdatedOnBranchConnectedToAvoFunctions,
        "Num Sources Updated on Branch Connected to Inspector": numSourcesUpdatedOnBranchConnectedToInspector,
        "Num Sources Updated on Branch Connected to Implementation Status": numSourcesUpdatedOnBranchConnectedToImplementationStatus,
        "Proportion of Event Instances Implemented Per Source": proportionOfEventInstancesImplementedPerSource,
        "Num New Events With Triggers": numNewEventsWithTriggers,
        "Num Updated Events With Triggers": numUpdatedEventsWithTriggers,
        "Num Sources Updated on Branch With Triggers": numSourcesUpdatedOnBranchWithTriggers,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Role: roleToJs(role),
        "Branch Merge Permissions Bypassed": branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Num Schema Issues": numSchemaIssues,
        "Num Schema Issues Delta": numSchemaIssuesDelta,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus
  };
  logEvent("Branch Merged", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Num Events Updated": branchNumEventsUpdated,
        "Branch Num Properties Updated": branchNumPropertiesUpdated
      });
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Issues": workspaceNumIssues
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Merged", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events Updated": numEventsUpdated,
        "Num New Events": numNewEvents,
        "Num Comments": numComments,
        "Num Integrations Published": numIntegrationsPublished,
        "Integration Types Published": Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs),
        "Num New Properties": numNewProperties,
        "Num Updated Properties": numUpdatedProperties,
        "Num New Globally Optional Properties": numNewGloballyOptionalProperties,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Events in Schema": numEventsInSchema,
        "Num Globally Optional Properties in Schema": numGloballyOptionalPropertiesInSchema,
        "Num Event Instances Updated on Branch": numEventInstancesUpdatedOnBranch,
        "Num Event Instances Implemented on Branch": numEventInstancesImplementedOnBranch,
        "Num Sources Updated on Branch": numSourcesUpdatedOnBranch,
        "Num Sources Implemented on Branch": numSourcesImplementedOnBranch,
        "Proportion of Updated Event Instances Implemented on Branch": proportionOfUpdatedEventInstancesImplementedOnBranch,
        "Proportion of Updated Sources Implemented on Branch": proportionOfUpdatedSourcesImplementedOnBranch,
        "Num Sources Updated on Branch Connected to Avo Functions": numSourcesUpdatedOnBranchConnectedToAvoFunctions,
        "Num Sources Updated on Branch Connected to Inspector": numSourcesUpdatedOnBranchConnectedToInspector,
        "Num Sources Updated on Branch Connected to Implementation Status": numSourcesUpdatedOnBranchConnectedToImplementationStatus,
        "Proportion of Event Instances Implemented Per Source": proportionOfEventInstancesImplementedPerSource,
        "Num New Events With Triggers": numNewEventsWithTriggers,
        "Num Updated Events With Triggers": numUpdatedEventsWithTriggers,
        "Num Sources Updated on Branch With Triggers": numSourcesUpdatedOnBranchWithTriggers,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Role: roleToJs(role),
        "Branch Merge Permissions Bypassed": branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Num Schema Issues": numSchemaIssues,
        "Num Schema Issues Delta": numSchemaIssuesDelta,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Merged", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events Updated": numEventsUpdated,
        "Num New Events": numNewEvents,
        "Num Comments": numComments,
        "Num Integrations Published": numIntegrationsPublished,
        "Integration Types Published": Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs),
        "Num New Properties": numNewProperties,
        "Num Updated Properties": numUpdatedProperties,
        "Num New Globally Optional Properties": numNewGloballyOptionalProperties,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Events in Schema": numEventsInSchema,
        "Num Globally Optional Properties in Schema": numGloballyOptionalPropertiesInSchema,
        "Num Event Instances Updated on Branch": numEventInstancesUpdatedOnBranch,
        "Num Event Instances Implemented on Branch": numEventInstancesImplementedOnBranch,
        "Num Sources Updated on Branch": numSourcesUpdatedOnBranch,
        "Num Sources Implemented on Branch": numSourcesImplementedOnBranch,
        "Proportion of Updated Event Instances Implemented on Branch": proportionOfUpdatedEventInstancesImplementedOnBranch,
        "Proportion of Updated Sources Implemented on Branch": proportionOfUpdatedSourcesImplementedOnBranch,
        "Num Sources Updated on Branch Connected to Avo Functions": numSourcesUpdatedOnBranchConnectedToAvoFunctions,
        "Num Sources Updated on Branch Connected to Inspector": numSourcesUpdatedOnBranchConnectedToInspector,
        "Num Sources Updated on Branch Connected to Implementation Status": numSourcesUpdatedOnBranchConnectedToImplementationStatus,
        "Proportion of Event Instances Implemented Per Source": proportionOfEventInstancesImplementedPerSource,
        "Num New Events With Triggers": numNewEventsWithTriggers,
        "Num Updated Events With Triggers": numUpdatedEventsWithTriggers,
        "Num Sources Updated on Branch With Triggers": numSourcesUpdatedOnBranchWithTriggers,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Role: roleToJs(role),
        "Branch Merge Permissions Bypassed": branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Num Schema Issues": numSchemaIssues,
        "Num Schema Issues Delta": numSchemaIssuesDelta,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Num Events Updated": branchNumEventsUpdated,
        "Branch Num Properties Updated": branchNumPropertiesUpdated
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Issues": workspaceNumIssues
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Merged", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events Updated": numEventsUpdated,
        "Num New Events": numNewEvents,
        "Num Comments": numComments,
        "Num Integrations Published": numIntegrationsPublished,
        "Integration Types Published": Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs),
        "Num New Properties": numNewProperties,
        "Num Updated Properties": numUpdatedProperties,
        "Num New Globally Optional Properties": numNewGloballyOptionalProperties,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Events in Schema": numEventsInSchema,
        "Num Globally Optional Properties in Schema": numGloballyOptionalPropertiesInSchema,
        "Num Event Instances Updated on Branch": numEventInstancesUpdatedOnBranch,
        "Num Event Instances Implemented on Branch": numEventInstancesImplementedOnBranch,
        "Num Sources Updated on Branch": numSourcesUpdatedOnBranch,
        "Num Sources Implemented on Branch": numSourcesImplementedOnBranch,
        "Proportion of Updated Event Instances Implemented on Branch": proportionOfUpdatedEventInstancesImplementedOnBranch,
        "Proportion of Updated Sources Implemented on Branch": proportionOfUpdatedSourcesImplementedOnBranch,
        "Num Sources Updated on Branch Connected to Avo Functions": numSourcesUpdatedOnBranchConnectedToAvoFunctions,
        "Num Sources Updated on Branch Connected to Inspector": numSourcesUpdatedOnBranchConnectedToInspector,
        "Num Sources Updated on Branch Connected to Implementation Status": numSourcesUpdatedOnBranchConnectedToImplementationStatus,
        "Proportion of Event Instances Implemented Per Source": proportionOfEventInstancesImplementedPerSource,
        "Num New Events With Triggers": numNewEventsWithTriggers,
        "Num Updated Events With Triggers": numUpdatedEventsWithTriggers,
        "Num Sources Updated on Branch With Triggers": numSourcesUpdatedOnBranchWithTriggers,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Role: roleToJs(role),
        "Branch Merge Permissions Bypassed": branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Num Schema Issues": numSchemaIssues,
        "Num Schema Issues Delta": numSchemaIssuesDelta,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Num Events Updated": branchNumEventsUpdated,
        "Branch Num Properties Updated": branchNumPropertiesUpdated
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Issues": workspaceNumIssues
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Merged", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events Updated": numEventsUpdated,
        "Num New Events": numNewEvents,
        "Num Comments": numComments,
        "Num Integrations Published": numIntegrationsPublished,
        "Integration Types Published": Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs),
        "Num New Properties": numNewProperties,
        "Num Updated Properties": numUpdatedProperties,
        "Num New Globally Optional Properties": numNewGloballyOptionalProperties,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Events in Schema": numEventsInSchema,
        "Num Globally Optional Properties in Schema": numGloballyOptionalPropertiesInSchema,
        "Num Event Instances Updated on Branch": numEventInstancesUpdatedOnBranch,
        "Num Event Instances Implemented on Branch": numEventInstancesImplementedOnBranch,
        "Num Sources Updated on Branch": numSourcesUpdatedOnBranch,
        "Num Sources Implemented on Branch": numSourcesImplementedOnBranch,
        "Proportion of Updated Event Instances Implemented on Branch": proportionOfUpdatedEventInstancesImplementedOnBranch,
        "Proportion of Updated Sources Implemented on Branch": proportionOfUpdatedSourcesImplementedOnBranch,
        "Num Sources Updated on Branch Connected to Avo Functions": numSourcesUpdatedOnBranchConnectedToAvoFunctions,
        "Num Sources Updated on Branch Connected to Inspector": numSourcesUpdatedOnBranchConnectedToInspector,
        "Num Sources Updated on Branch Connected to Implementation Status": numSourcesUpdatedOnBranchConnectedToImplementationStatus,
        "Proportion of Event Instances Implemented Per Source": proportionOfEventInstancesImplementedPerSource,
        "Num New Events With Triggers": numNewEventsWithTriggers,
        "Num Updated Events With Triggers": numUpdatedEventsWithTriggers,
        "Num Sources Updated on Branch With Triggers": numSourcesUpdatedOnBranchWithTriggers,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Role: roleToJs(role),
        "Branch Merge Permissions Bypassed": branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Num Schema Issues": numSchemaIssues,
        "Num Schema Issues Delta": numSchemaIssuesDelta,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Branch", updateGroupPropertiesForBranch, {
        "Branch Num Events Updated": branchNumEventsUpdated,
        "Branch Num Properties Updated": branchNumPropertiesUpdated
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Issues": workspaceNumIssues
      });
  return logEventWithGroups("Branch Merged", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Events Updated": numEventsUpdated,
              "Num New Events": numNewEvents,
              "Num Comments": numComments,
              "Num Integrations Published": numIntegrationsPublished,
              "Integration Types Published": Belt_Array.map(integrationTypesPublished, integrationTypesPublishedToJs),
              "Num New Properties": numNewProperties,
              "Num Updated Properties": numUpdatedProperties,
              "Num New Globally Optional Properties": numNewGloballyOptionalProperties,
              "Num Properties in Schema": numPropertiesInSchema,
              "Num Events in Schema": numEventsInSchema,
              "Num Globally Optional Properties in Schema": numGloballyOptionalPropertiesInSchema,
              "Num Event Instances Updated on Branch": numEventInstancesUpdatedOnBranch,
              "Num Event Instances Implemented on Branch": numEventInstancesImplementedOnBranch,
              "Num Sources Updated on Branch": numSourcesUpdatedOnBranch,
              "Num Sources Implemented on Branch": numSourcesImplementedOnBranch,
              "Proportion of Updated Event Instances Implemented on Branch": proportionOfUpdatedEventInstancesImplementedOnBranch,
              "Proportion of Updated Sources Implemented on Branch": proportionOfUpdatedSourcesImplementedOnBranch,
              "Num Sources Updated on Branch Connected to Avo Functions": numSourcesUpdatedOnBranchConnectedToAvoFunctions,
              "Num Sources Updated on Branch Connected to Inspector": numSourcesUpdatedOnBranchConnectedToInspector,
              "Num Sources Updated on Branch Connected to Implementation Status": numSourcesUpdatedOnBranchConnectedToImplementationStatus,
              "Proportion of Event Instances Implemented Per Source": proportionOfEventInstancesImplementedPerSource,
              "Num New Events With Triggers": numNewEventsWithTriggers,
              "Num Updated Events With Triggers": numUpdatedEventsWithTriggers,
              "Num Sources Updated on Branch With Triggers": numSourcesUpdatedOnBranchWithTriggers,
              "Num Branch Approvals Required": numBranchApprovalsRequired,
              "Num Branch Approvals": numBranchApprovals,
              "Minimum Approval Role Required": minimumApprovalRoleRequired,
              Role: roleToJs(role),
              "Branch Merge Permissions Bypassed": branchMergePermissionsBypassedToJs(branchMergePermissionsBypassed),
              "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
              "Num Schema Issues": numSchemaIssues,
              "Num Schema Issues Delta": numSchemaIssuesDelta,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchDeleted(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("6uIsstiCj", "4ae24e11f83e6b9d93e68f6d0ff031a44e665171db96d0c7aa8314c04550edd6", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Deleted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("6uIsstiCj", "Branch Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("6uIsstiCj", Date.now(), "Branch Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Deleted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branch Deleted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventCreated(currentFilters, schema, eventId, eventName, correctCase, namingConvention, eventOrigin, issueReportEventCasing, issueReportEventNamingConvention, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("a93IGsboJ", "54c7c3f0ff45c8e1a090114db609ae8d89cd91ae062480ea6d5f1fbda0101903", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Created", {
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Correct Case": correctCase,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          "Event Origin": eventOriginToJs(eventOrigin),
          "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
          "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("a93IGsboJ", "Event Created", messages, [
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "rTvwpPpbt",
              name: "Event Origin",
              value: eventOriginToJs(eventOrigin)
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("a93IGsboJ", Date.now(), "Event Created", messages, [
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "rTvwpPpbt",
              name: "Event Origin",
              value: eventOriginToJs(eventOrigin)
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Created", {
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Correct Case": correctCase,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              "Event Origin": eventOriginToJs(eventOrigin),
              "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
              "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventCreatedAdvancedNamingConvention(currentFilters, schema, eventId, eventName, correctCase, namingConvention, eventOrigin, issueReportEventNamingConvention, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("a93IGsboJ.VznOIBRVI", "cfaa94835accba12bc17a80533740fe97208a0d10c93e94271b568758f4b595f", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Created", {
          "Current Tag Filters": currentFilters.currentTagFilters,
          "Current Source Filters": currentFilters.currentSourceFilters,
          "Current Destination Filters": currentFilters.currentDestinationFilters,
          "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
          "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
          "Current Property Filters": currentFilters.currentPropertyFilters,
          "Current Action Filters": currentFilters.currentActionFilters,
          "Current Category Filters": currentFilters.currentCategoryFilters,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Issue Report Event Casing": "Advanced",
          "Event Id": eventId,
          "Event Name": eventName,
          "Correct Case": correctCase,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          "Event Origin": eventOriginToJs(eventOrigin),
          "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("a93IGsboJ.VznOIBRVI", "Event Created", messages, [
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: "Advanced"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "rTvwpPpbt",
              name: "Event Origin",
              value: eventOriginToJs(eventOrigin)
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("a93IGsboJ.VznOIBRVI", Date.now(), "Event Created", messages, [
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentFilters.currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentFilters.currentSourceFilters
            },
            {
              id: "dXGlAYek-",
              name: "Current Destination Filters",
              value: currentFilters.currentDestinationFilters
            },
            {
              id: "1c2ad546-95ad-4272-8cc2-80e936c869ea",
              name: "Current Sorting",
              value: currentSortingToJs(currentFilters.currentSorting)
            },
            {
              id: "CAfCAl26-_N",
              name: "Current Property Group Filters",
              value: currentFilters.currentPropertyGroupFilters
            },
            {
              id: "b4qEum4fJ4H",
              name: "Current Property Filters",
              value: currentFilters.currentPropertyFilters
            },
            {
              id: "zg59QGK1LpB",
              name: "Current Action Filters",
              value: currentFilters.currentActionFilters
            },
            {
              id: "4MZxihw08B6",
              name: "Current Category Filters",
              value: currentFilters.currentCategoryFilters
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: "Advanced"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "rTvwpPpbt",
              name: "Event Origin",
              value: eventOriginToJs(eventOrigin)
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Created", {
        "Current Tag Filters": currentFilters.currentTagFilters,
        "Current Source Filters": currentFilters.currentSourceFilters,
        "Current Destination Filters": currentFilters.currentDestinationFilters,
        "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
        "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
        "Current Property Filters": currentFilters.currentPropertyFilters,
        "Current Action Filters": currentFilters.currentActionFilters,
        "Current Category Filters": currentFilters.currentCategoryFilters,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": "Advanced",
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Event Origin": eventOriginToJs(eventOrigin),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Created", {
              "Current Tag Filters": currentFilters.currentTagFilters,
              "Current Source Filters": currentFilters.currentSourceFilters,
              "Current Destination Filters": currentFilters.currentDestinationFilters,
              "Current Sorting": currentSortingToJs(currentFilters.currentSorting),
              "Current Property Group Filters": currentFilters.currentPropertyGroupFilters,
              "Current Property Filters": currentFilters.currentPropertyFilters,
              "Current Action Filters": currentFilters.currentActionFilters,
              "Current Category Filters": currentFilters.currentCategoryFilters,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Issue Report Event Casing": "Advanced",
              "Event Id": eventId,
              "Event Name": eventName,
              "Correct Case": correctCase,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              "Event Origin": eventOriginToJs(eventOrigin),
              "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyUpdated(schema, property, propertyUpdateAction, propertyNameBefore, ruleType, numPropertiesInSchemaWithNameMapping, numPropertyNameMappingsInSchema, numPropertyNameMappings, numPropertiesInSchema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumPropertiesInSchema(numPropertiesInSchema));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("oDKmrwPTt", "cb1f3f7241f7a8291a02a81a0ba3adf223f2e2b8d3c9ced3ddcf844c03b85c91", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Property Update Action": propertyUpdateActionToJs(propertyUpdateAction),
          "Property Name Before": propertyNameBefore,
          "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
          "Num Properties in Schema With Name Mapping": numPropertiesInSchemaWithNameMapping,
          "Num Property Name Mappings in Schema": numPropertyNameMappingsInSchema,
          "Num Property Name Mappings": numPropertyNameMappings,
          "Num Properties in Schema": numPropertiesInSchema,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("oDKmrwPTt", "Property Updated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "nYOVv8XG6",
              name: "Property Update Action",
              value: propertyUpdateActionToJs(propertyUpdateAction)
            },
            {
              id: "1jIQDtmb0",
              name: "Property Name Before",
              value: propertyNameBefore
            },
            {
              id: "TWk3eXUCI",
              name: "Rule Type",
              value: Belt_Option.map(ruleType, ruleTypeToJs)
            },
            {
              id: "vThV6gtum",
              name: "Num Properties in Schema With Name Mapping",
              value: numPropertiesInSchemaWithNameMapping
            },
            {
              id: "h6bR4D2bu",
              name: "Num Property Name Mappings in Schema",
              value: numPropertyNameMappingsInSchema
            },
            {
              id: "TRRJx6mWc",
              name: "Num Property Name Mappings",
              value: numPropertyNameMappings
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("oDKmrwPTt", Date.now(), "Property Updated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "nYOVv8XG6",
              name: "Property Update Action",
              value: propertyUpdateActionToJs(propertyUpdateAction)
            },
            {
              id: "1jIQDtmb0",
              name: "Property Name Before",
              value: propertyNameBefore
            },
            {
              id: "TWk3eXUCI",
              name: "Rule Type",
              value: Belt_Option.map(ruleType, ruleTypeToJs)
            },
            {
              id: "vThV6gtum",
              name: "Num Properties in Schema With Name Mapping",
              value: numPropertiesInSchemaWithNameMapping
            },
            {
              id: "h6bR4D2bu",
              name: "Num Property Name Mappings in Schema",
              value: numPropertyNameMappingsInSchema
            },
            {
              id: "TRRJx6mWc",
              name: "Num Property Name Mappings",
              value: numPropertyNameMappings
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Property Update Action": propertyUpdateActionToJs(propertyUpdateAction),
        "Property Name Before": propertyNameBefore,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Num Properties in Schema With Name Mapping": numPropertiesInSchemaWithNameMapping,
        "Num Property Name Mappings in Schema": numPropertyNameMappingsInSchema,
        "Num Property Name Mappings": numPropertyNameMappings,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Property Update Action": propertyUpdateActionToJs(propertyUpdateAction),
        "Property Name Before": propertyNameBefore,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Num Properties in Schema With Name Mapping": numPropertiesInSchemaWithNameMapping,
        "Num Property Name Mappings in Schema": numPropertyNameMappingsInSchema,
        "Num Property Name Mappings": numPropertyNameMappings,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Property Update Action": propertyUpdateActionToJs(propertyUpdateAction),
        "Property Name Before": propertyNameBefore,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Num Properties in Schema With Name Mapping": numPropertiesInSchemaWithNameMapping,
        "Num Property Name Mappings in Schema": numPropertyNameMappingsInSchema,
        "Num Property Name Mappings": numPropertyNameMappings,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Property Update Action": propertyUpdateActionToJs(propertyUpdateAction),
        "Property Name Before": propertyNameBefore,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Num Properties in Schema With Name Mapping": numPropertiesInSchemaWithNameMapping,
        "Num Property Name Mappings in Schema": numPropertyNameMappingsInSchema,
        "Num Property Name Mappings": numPropertyNameMappings,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Property Update Action": propertyUpdateActionToJs(propertyUpdateAction),
        "Property Name Before": propertyNameBefore,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Num Properties in Schema With Name Mapping": numPropertiesInSchemaWithNameMapping,
        "Num Property Name Mappings in Schema": numPropertyNameMappingsInSchema,
        "Num Property Name Mappings": numPropertyNameMappings,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Property Update Action": propertyUpdateActionToJs(propertyUpdateAction),
              "Property Name Before": propertyNameBefore,
              "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
              "Num Properties in Schema With Name Mapping": numPropertiesInSchemaWithNameMapping,
              "Num Property Name Mappings in Schema": numPropertyNameMappingsInSchema,
              "Num Property Name Mappings": numPropertyNameMappings,
              "Num Properties in Schema": numPropertiesInSchema,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyInitiated(schema, propertyId, propertyName, namingConvention, correctCase, propertyType, currentSourceFilters, currentTagFilters, eventId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("RfPXcqpFF", "b8872fcfb42aa7ce3be0fe6a7058ba4b87c1229b43672e1614f07abe5d93345e", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": propertyId,
          "Property Name": propertyName,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          "Correct Case": correctCase,
          "Property Type": propertyTypeToJs(propertyType),
          "Current Source Filters": currentSourceFilters,
          "Current Tag Filters": currentTagFilters,
          "Event Id": eventId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("RfPXcqpFF", "Property Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(propertyType)
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentSourceFilters
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentTagFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("RfPXcqpFF", Date.now(), "Property Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(propertyType)
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentSourceFilters
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentTagFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": propertyId,
        "Property Name": propertyName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Correct Case": correctCase,
        "Property Type": propertyTypeToJs(propertyType),
        "Current Source Filters": currentSourceFilters,
        "Current Tag Filters": currentTagFilters,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": propertyId,
        "Property Name": propertyName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Correct Case": correctCase,
        "Property Type": propertyTypeToJs(propertyType),
        "Current Source Filters": currentSourceFilters,
        "Current Tag Filters": currentTagFilters,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": propertyId,
        "Property Name": propertyName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Correct Case": correctCase,
        "Property Type": propertyTypeToJs(propertyType),
        "Current Source Filters": currentSourceFilters,
        "Current Tag Filters": currentTagFilters,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": propertyId,
        "Property Name": propertyName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Correct Case": correctCase,
        "Property Type": propertyTypeToJs(propertyType),
        "Current Source Filters": currentSourceFilters,
        "Current Tag Filters": currentTagFilters,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": propertyId,
        "Property Name": propertyName,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Correct Case": correctCase,
        "Property Type": propertyTypeToJs(propertyType),
        "Current Source Filters": currentSourceFilters,
        "Current Tag Filters": currentTagFilters,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": propertyId,
              "Property Name": propertyName,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              "Correct Case": correctCase,
              "Property Type": propertyTypeToJs(propertyType),
              "Current Source Filters": currentSourceFilters,
              "Current Tag Filters": currentTagFilters,
              "Event Id": eventId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyCreated(schema, property, currentTagFilters, currentSourceFilters, eventId, propertyLocation, propertyOrigin, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("T--3lVvXL", "0644614aad742d2d598e4c4072a47994c52fa95cc84f07926b10101cc633d874", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Current Tag Filters": currentTagFilters,
          "Current Source Filters": currentSourceFilters,
          "Event Id": eventId,
          "Property Location": propertyLocationToJs(propertyLocation),
          "Property Origin": propertyOriginToJs(propertyOrigin),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("T--3lVvXL", "Property Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentSourceFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "T0MyOzIFm",
              name: "Property Origin",
              value: propertyOriginToJs(propertyOrigin)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("T--3lVvXL", Date.now(), "Property Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "dfa6f2a7-5e46-4adb-90c8-b5bf3866728c",
              name: "Current Tag Filters",
              value: currentTagFilters
            },
            {
              id: "3edfacda-c904-4195-8fb5-82d23a18fb53",
              name: "Current Source Filters",
              value: currentSourceFilters
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "T0MyOzIFm",
              name: "Property Origin",
              value: propertyOriginToJs(propertyOrigin)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentTagFilters,
        "Current Source Filters": currentSourceFilters,
        "Event Id": eventId,
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Origin": propertyOriginToJs(propertyOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentTagFilters,
        "Current Source Filters": currentSourceFilters,
        "Event Id": eventId,
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Origin": propertyOriginToJs(propertyOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentTagFilters,
        "Current Source Filters": currentSourceFilters,
        "Event Id": eventId,
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Origin": propertyOriginToJs(propertyOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentTagFilters,
        "Current Source Filters": currentSourceFilters,
        "Event Id": eventId,
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Origin": propertyOriginToJs(propertyOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Current Tag Filters": currentTagFilters,
        "Current Source Filters": currentSourceFilters,
        "Event Id": eventId,
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Origin": propertyOriginToJs(propertyOrigin),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Current Tag Filters": currentTagFilters,
              "Current Source Filters": currentSourceFilters,
              "Event Id": eventId,
              "Property Location": propertyLocationToJs(propertyLocation),
              "Property Origin": propertyOriginToJs(propertyOrigin),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function namingFeedbackReceived(schema, feedbackType, itemType, namingActionType, namingConvention, feedbackDetails, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("wbMWPBzqV", "f7484525fabe3c539bdae9a24e5d73b3d3a3459a58bea4bb8762a51e89f48482", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Naming Feedback Received", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Feedback Type": feedbackType,
          "Item Type": itemTypeToJs(itemType),
          "Naming Action Type": namingActionType,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          "Feedback Details": feedbackDetailsToJs(feedbackDetails),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("wbMWPBzqV", "Naming Feedback Received", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "tj0kZn82I",
              name: "Feedback Type",
              value: feedbackType
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "RWG7_ZCDs",
              name: "Feedback Details",
              value: feedbackDetailsToJs(feedbackDetails)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("wbMWPBzqV", Date.now(), "Naming Feedback Received", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "tj0kZn82I",
              name: "Feedback Type",
              value: feedbackType
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "RWG7_ZCDs",
              name: "Feedback Details",
              value: feedbackDetailsToJs(feedbackDetails)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Naming Feedback Received", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Naming Feedback Received", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Naming Feedback Received", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Naming Feedback Received", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Naming Feedback Received", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Naming Feedback Received", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Naming Feedback Received", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Feedback Type": feedbackType,
              "Item Type": itemTypeToJs(itemType),
              "Naming Action Type": namingActionType,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              "Feedback Details": feedbackDetailsToJs(feedbackDetails),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function nameEditorClosed(schema, closingMethod, itemType, namingActionType, namingConvention, numberOfNamingSuggestions, interactionType, changeMade, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumberOfNamingSuggestions(numberOfNamingSuggestions));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("qxecp1zku", "9e0505ec5272449dbc7c1422f47e5f03b5772eedfd267a925c25b4fff73b3957", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Name Editor Closed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Closing Method": closingMethodToJs(closingMethod),
          "Item Type": itemTypeToJs(itemType),
          "Naming Action Type": namingActionType,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          "Number of Naming Suggestions": numberOfNamingSuggestions,
          "Interaction Type": interactionType,
          "Change Made": changeMade,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("qxecp1zku", "Name Editor Closed", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "vuprYK4ET",
              name: "Closing Method",
              value: closingMethodToJs(closingMethod)
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "lf4kMDqAm",
              name: "Number of Naming Suggestions",
              value: numberOfNamingSuggestions
            },
            {
              id: "WTYbf-Kcj",
              name: "Interaction Type",
              value: interactionType
            },
            {
              id: "4HyTFeFpk",
              name: "Change Made",
              value: changeMade
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("qxecp1zku", Date.now(), "Name Editor Closed", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "vuprYK4ET",
              name: "Closing Method",
              value: closingMethodToJs(closingMethod)
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "lf4kMDqAm",
              name: "Number of Naming Suggestions",
              value: numberOfNamingSuggestions
            },
            {
              id: "WTYbf-Kcj",
              name: "Interaction Type",
              value: interactionType
            },
            {
              id: "4HyTFeFpk",
              name: "Change Made",
              value: changeMade
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Name Editor Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Closing Method": closingMethodToJs(closingMethod),
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Number of Naming Suggestions": numberOfNamingSuggestions,
        "Interaction Type": interactionType,
        "Change Made": changeMade,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Name Editor Closed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Name Editor Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Closing Method": closingMethodToJs(closingMethod),
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Number of Naming Suggestions": numberOfNamingSuggestions,
        "Interaction Type": interactionType,
        "Change Made": changeMade,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Name Editor Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Closing Method": closingMethodToJs(closingMethod),
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Number of Naming Suggestions": numberOfNamingSuggestions,
        "Interaction Type": interactionType,
        "Change Made": changeMade,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Name Editor Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Closing Method": closingMethodToJs(closingMethod),
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Number of Naming Suggestions": numberOfNamingSuggestions,
        "Interaction Type": interactionType,
        "Change Made": changeMade,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Name Editor Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Closing Method": closingMethodToJs(closingMethod),
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        "Number of Naming Suggestions": numberOfNamingSuggestions,
        "Interaction Type": interactionType,
        "Change Made": changeMade,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Name Editor Closed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Closing Method": closingMethodToJs(closingMethod),
              "Item Type": itemTypeToJs(itemType),
              "Naming Action Type": namingActionType,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              "Number of Naming Suggestions": numberOfNamingSuggestions,
              "Interaction Type": interactionType,
              "Change Made": changeMade,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventUpdated(schema, source, eventId, eventName, eventAction, propertyGroupId, propertyGroupName, eventSourceIncludedAfter, eventSourceIncludedBefore, dataDestination, numEventsInSchemaWithNameMapping, numEventNameMappingsInSchema, numEventNameMappings, numEvents, ruleType, implementSourceWithAvo, numSourceEventsInSchemaNotImplementedWithAvo, numSourceEventsInSchemaImplementedWithAvo, pageName, numTriggersOnEvent, correctCase, namingConvention, associateEventWithEvent, associateEventWithWorkspace, associateEventWithBranch, updateGroupPropertiesForEvent, eventActionsIncluded, eventGroupEventName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("UaLx-xo9H", "4912c23be6f54799ed461f756d758c015b8390137f6c43e32c49befd309eca5c", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Source Id": source.sourceId,
          "Source Name": source.sourceName,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Action": eventActionToJs(eventAction),
          "Property Group Id": propertyGroupId,
          "Property Group Name": propertyGroupName,
          "Event Source Included After": eventSourceIncludedAfter,
          "Event Source Included Before": eventSourceIncludedBefore,
          "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
          "Num Events in Schema With Name Mapping": numEventsInSchemaWithNameMapping,
          "Num Event Name Mappings in Schema": numEventNameMappingsInSchema,
          "Num Event Name Mappings": numEventNameMappings,
          "Num Events": numEvents,
          "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
          "Implement Source with Avo": implementSourceWithAvo,
          "Num Source Events in Schema Not Implemented with Avo": numSourceEventsInSchemaNotImplementedWithAvo,
          "Num Source Events in Schema Implemented with Avo": numSourceEventsInSchemaImplementedWithAvo,
          "Page Name": Belt_Option.map(pageName, pageNameToJs),
          "Num Triggers on Event": numTriggersOnEvent,
          "Correct Case": correctCase,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("UaLx-xo9H", "Event Updated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "FYCZjnmEy",
              name: "Event Action",
              value: eventActionToJs(eventAction)
            },
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroupName
            },
            {
              id: "657259be-07d1-4d6d-a055-d7114b0f4497",
              name: "Event Source Included After",
              value: eventSourceIncludedAfter
            },
            {
              id: "7a27d065-875f-4562-81de-107ec2d2833f",
              name: "Event Source Included Before",
              value: eventSourceIncludedBefore
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Data Destination",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "DB3ogz42S",
              name: "Num Events in Schema With Name Mapping",
              value: numEventsInSchemaWithNameMapping
            },
            {
              id: "b9uaa_CM4",
              name: "Num Event Name Mappings in Schema",
              value: numEventNameMappingsInSchema
            },
            {
              id: "n_K9s60I1",
              name: "Num Event Name Mappings",
              value: numEventNameMappings
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "TWk3eXUCI",
              name: "Rule Type",
              value: Belt_Option.map(ruleType, ruleTypeToJs)
            },
            {
              id: "-wh9o3hs3",
              name: "Implement Source with Avo",
              value: implementSourceWithAvo
            },
            {
              id: "I-o974gK0D",
              name: "Num Source Events in Schema Not Implemented with Avo",
              value: numSourceEventsInSchemaNotImplementedWithAvo
            },
            {
              id: "5ianUrWZDO",
              name: "Num Source Events in Schema Implemented with Avo",
              value: numSourceEventsInSchemaImplementedWithAvo
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: Belt_Option.map(pageName, pageNameToJs)
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "pYGpYEDTfe",
                  name: "Event Actions Included (Event " + (updateGroupPropertiesForEvent + " group property)"),
                  value: Belt_Option.map(eventActionsIncluded, (function (maybe) {
                          return Belt_Array.map(maybe, eventActionsIncludedToJs);
                        }))
                },
                {
                  id: "nDNV1U81H",
                  name: "Event Name (Event " + (updateGroupPropertiesForEvent + " group property)"),
                  value: eventGroupEventName
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("UaLx-xo9H", Date.now(), "Event Updated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "FYCZjnmEy",
              name: "Event Action",
              value: eventActionToJs(eventAction)
            },
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroupName
            },
            {
              id: "657259be-07d1-4d6d-a055-d7114b0f4497",
              name: "Event Source Included After",
              value: eventSourceIncludedAfter
            },
            {
              id: "7a27d065-875f-4562-81de-107ec2d2833f",
              name: "Event Source Included Before",
              value: eventSourceIncludedBefore
            },
            {
              id: "299e0a06-2ac4-455e-bef9-e4ed5a117da5",
              name: "Data Destination",
              value: Belt_Option.map(dataDestination, dataDestinationToJs)
            },
            {
              id: "DB3ogz42S",
              name: "Num Events in Schema With Name Mapping",
              value: numEventsInSchemaWithNameMapping
            },
            {
              id: "b9uaa_CM4",
              name: "Num Event Name Mappings in Schema",
              value: numEventNameMappingsInSchema
            },
            {
              id: "n_K9s60I1",
              name: "Num Event Name Mappings",
              value: numEventNameMappings
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "TWk3eXUCI",
              name: "Rule Type",
              value: Belt_Option.map(ruleType, ruleTypeToJs)
            },
            {
              id: "-wh9o3hs3",
              name: "Implement Source with Avo",
              value: implementSourceWithAvo
            },
            {
              id: "I-o974gK0D",
              name: "Num Source Events in Schema Not Implemented with Avo",
              value: numSourceEventsInSchemaNotImplementedWithAvo
            },
            {
              id: "5ianUrWZDO",
              name: "Num Source Events in Schema Implemented with Avo",
              value: numSourceEventsInSchemaImplementedWithAvo
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: Belt_Option.map(pageName, pageNameToJs)
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Option.map(eventActionsIncluded, (function (maybe) {
                return Belt_Array.map(maybe, eventActionsIncludedToJs);
              })),
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Action": eventActionToJs(eventAction),
        "Property Group Id": propertyGroupId,
        "Property Group Name": propertyGroupName,
        "Event Source Included After": eventSourceIncludedAfter,
        "Event Source Included Before": eventSourceIncludedBefore,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Num Events in Schema With Name Mapping": numEventsInSchemaWithNameMapping,
        "Num Event Name Mappings in Schema": numEventNameMappingsInSchema,
        "Num Event Name Mappings": numEventNameMappings,
        "Num Events": numEvents,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Implement Source with Avo": implementSourceWithAvo,
        "Num Source Events in Schema Not Implemented with Avo": numSourceEventsInSchemaNotImplementedWithAvo,
        "Num Source Events in Schema Implemented with Avo": numSourceEventsInSchemaImplementedWithAvo,
        "Page Name": Belt_Option.map(pageName, pageNameToJs),
        "Num Triggers on Event": numTriggersOnEvent,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus,
    "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
    "Num Events": numEvents
  };
  logEvent("Event Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Option.map(eventActionsIncluded, (function (maybe) {
                return Belt_Array.map(maybe, eventActionsIncludedToJs);
              })),
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Action": eventActionToJs(eventAction),
        "Property Group Id": propertyGroupId,
        "Property Group Name": propertyGroupName,
        "Event Source Included After": eventSourceIncludedAfter,
        "Event Source Included Before": eventSourceIncludedBefore,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Num Events in Schema With Name Mapping": numEventsInSchemaWithNameMapping,
        "Num Event Name Mappings in Schema": numEventNameMappingsInSchema,
        "Num Event Name Mappings": numEventNameMappings,
        "Num Events": numEvents,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Implement Source with Avo": implementSourceWithAvo,
        "Num Source Events in Schema Not Implemented with Avo": numSourceEventsInSchemaNotImplementedWithAvo,
        "Num Source Events in Schema Implemented with Avo": numSourceEventsInSchemaImplementedWithAvo,
        "Page Name": Belt_Option.map(pageName, pageNameToJs),
        "Num Triggers on Event": numTriggersOnEvent,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Event Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Action": eventActionToJs(eventAction),
        "Property Group Id": propertyGroupId,
        "Property Group Name": propertyGroupName,
        "Event Source Included After": eventSourceIncludedAfter,
        "Event Source Included Before": eventSourceIncludedBefore,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Num Events in Schema With Name Mapping": numEventsInSchemaWithNameMapping,
        "Num Event Name Mappings in Schema": numEventNameMappingsInSchema,
        "Num Event Name Mappings": numEventNameMappings,
        "Num Events": numEvents,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Implement Source with Avo": implementSourceWithAvo,
        "Num Source Events in Schema Not Implemented with Avo": numSourceEventsInSchemaNotImplementedWithAvo,
        "Num Source Events in Schema Implemented with Avo": numSourceEventsInSchemaImplementedWithAvo,
        "Page Name": Belt_Option.map(pageName, pageNameToJs),
        "Num Triggers on Event": numTriggersOnEvent,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Option.map(eventActionsIncluded, (function (maybe) {
                return Belt_Array.map(maybe, eventActionsIncludedToJs);
              })),
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Action": eventActionToJs(eventAction),
        "Property Group Id": propertyGroupId,
        "Property Group Name": propertyGroupName,
        "Event Source Included After": eventSourceIncludedAfter,
        "Event Source Included Before": eventSourceIncludedBefore,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Num Events in Schema With Name Mapping": numEventsInSchemaWithNameMapping,
        "Num Event Name Mappings in Schema": numEventNameMappingsInSchema,
        "Num Event Name Mappings": numEventNameMappings,
        "Num Events": numEvents,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Implement Source with Avo": implementSourceWithAvo,
        "Num Source Events in Schema Not Implemented with Avo": numSourceEventsInSchemaNotImplementedWithAvo,
        "Num Source Events in Schema Implemented with Avo": numSourceEventsInSchemaImplementedWithAvo,
        "Page Name": Belt_Option.map(pageName, pageNameToJs),
        "Num Triggers on Event": numTriggersOnEvent,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Option.map(eventActionsIncluded, (function (maybe) {
                return Belt_Array.map(maybe, eventActionsIncludedToJs);
              })),
        "Event Name": eventGroupEventName
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Action": eventActionToJs(eventAction),
        "Property Group Id": propertyGroupId,
        "Property Group Name": propertyGroupName,
        "Event Source Included After": eventSourceIncludedAfter,
        "Event Source Included Before": eventSourceIncludedBefore,
        "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
        "Num Events in Schema With Name Mapping": numEventsInSchemaWithNameMapping,
        "Num Event Name Mappings in Schema": numEventNameMappingsInSchema,
        "Num Event Name Mappings": numEventNameMappings,
        "Num Events": numEvents,
        "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
        "Implement Source with Avo": implementSourceWithAvo,
        "Num Source Events in Schema Not Implemented with Avo": numSourceEventsInSchemaNotImplementedWithAvo,
        "Num Source Events in Schema Implemented with Avo": numSourceEventsInSchemaImplementedWithAvo,
        "Page Name": Belt_Option.map(pageName, pageNameToJs),
        "Num Triggers on Event": numTriggersOnEvent,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Event: associateEventWithEvent,
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  setGroupProperties("Event", updateGroupPropertiesForEvent, {
        "Event Actions Included": Belt_Option.map(eventActionsIncluded, (function (maybe) {
                return Belt_Array.map(maybe, eventActionsIncludedToJs);
              })),
        "Event Name": eventGroupEventName
      });
  return logEventWithGroups("Event Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Source Id": source.sourceId,
              "Source Name": source.sourceName,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Action": eventActionToJs(eventAction),
              "Property Group Id": propertyGroupId,
              "Property Group Name": propertyGroupName,
              "Event Source Included After": eventSourceIncludedAfter,
              "Event Source Included Before": eventSourceIncludedBefore,
              "Data Destination": Belt_Option.map(dataDestination, dataDestinationToJs),
              "Num Events in Schema With Name Mapping": numEventsInSchemaWithNameMapping,
              "Num Event Name Mappings in Schema": numEventNameMappingsInSchema,
              "Num Event Name Mappings": numEventNameMappings,
              "Num Events": numEvents,
              "Rule Type": Belt_Option.map(ruleType, ruleTypeToJs),
              "Implement Source with Avo": implementSourceWithAvo,
              "Num Source Events in Schema Not Implemented with Avo": numSourceEventsInSchemaNotImplementedWithAvo,
              "Num Source Events in Schema Implemented with Avo": numSourceEventsInSchemaImplementedWithAvo,
              "Page Name": Belt_Option.map(pageName, pageNameToJs),
              "Num Triggers on Event": numTriggersOnEvent,
              "Correct Case": correctCase,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Event: associateEventWithEvent,
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function propertyGroupCreated(propertyGroup, schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumProperties(propertyGroup.numProperties));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Rf84umKtb", "c046cc8a6ec5aedc2909ecaa86ad0f468d70d7a1b3784584d1498879beaf58f5", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Group Created", {
          "Property Group Id": propertyGroup.propertyGroupId,
          "Property Group Name": propertyGroup.propertyGroupName,
          "Num Properties": propertyGroup.numProperties,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Rf84umKtb", "Property Group Created", messages$1, [
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Rf84umKtb", Date.now(), "Property Group Created", messages$1, [
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Group Created", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Group Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Group Created", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Group Created", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Group Created", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Group Created", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Group Created", {
              "Property Group Id": propertyGroup.propertyGroupId,
              "Property Group Name": propertyGroup.propertyGroupName,
              "Num Properties": propertyGroup.numProperties,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyGroupUpdated(propertyGroup, schema, propertyGroupAction, propertyGroupNameBefore, propertyId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumProperties(propertyGroup.numProperties));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ga6AWZJfJ", "9a582c7ec16b3d67bb82f841ad78de09d1c6811cbd2c17a3a9b88f588a90f2ba", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Group Updated", {
          "Property Group Id": propertyGroup.propertyGroupId,
          "Property Group Name": propertyGroup.propertyGroupName,
          "Num Properties": propertyGroup.numProperties,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Group Action": propertyGroupActionToJs(propertyGroupAction),
          "Property Group Name Before": propertyGroupNameBefore,
          "Property Id": propertyId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ga6AWZJfJ", "Property Group Updated", messages$1, [
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "UMs8twYf0",
              name: "Property Group Action",
              value: propertyGroupActionToJs(propertyGroupAction)
            },
            {
              id: "2uk4NQCTI",
              name: "Property Group Name Before",
              value: propertyGroupNameBefore
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ga6AWZJfJ", Date.now(), "Property Group Updated", messages$1, [
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "UMs8twYf0",
              name: "Property Group Action",
              value: propertyGroupActionToJs(propertyGroupAction)
            },
            {
              id: "2uk4NQCTI",
              name: "Property Group Name Before",
              value: propertyGroupNameBefore
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Group Updated", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Action": propertyGroupActionToJs(propertyGroupAction),
        "Property Group Name Before": propertyGroupNameBefore,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Group Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Group Updated", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Action": propertyGroupActionToJs(propertyGroupAction),
        "Property Group Name Before": propertyGroupNameBefore,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Group Updated", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Action": propertyGroupActionToJs(propertyGroupAction),
        "Property Group Name Before": propertyGroupNameBefore,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Group Updated", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Action": propertyGroupActionToJs(propertyGroupAction),
        "Property Group Name Before": propertyGroupNameBefore,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Group Updated", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Action": propertyGroupActionToJs(propertyGroupAction),
        "Property Group Name Before": propertyGroupNameBefore,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Group Updated", {
              "Property Group Id": propertyGroup.propertyGroupId,
              "Property Group Name": propertyGroup.propertyGroupName,
              "Num Properties": propertyGroup.numProperties,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Group Action": propertyGroupActionToJs(propertyGroupAction),
              "Property Group Name Before": propertyGroupNameBefore,
              "Property Id": propertyId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyGroupArchived(propertyGroup, schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumProperties(propertyGroup.numProperties));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("NP84sh0OA", "595781492c5b73736723d3bcfe7ead732036b29b8a47080df1e5295b6dd6fcf0", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Group Archived", {
          "Property Group Id": propertyGroup.propertyGroupId,
          "Property Group Name": propertyGroup.propertyGroupName,
          "Num Properties": propertyGroup.numProperties,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("NP84sh0OA", "Property Group Archived", messages$1, [
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("NP84sh0OA", Date.now(), "Property Group Archived", messages$1, [
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Group Archived", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Group Archived", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Group Archived", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Group Archived", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Group Archived", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Group Archived", {
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Group Archived", {
              "Property Group Id": propertyGroup.propertyGroupId,
              "Property Group Name": propertyGroup.propertyGroupName,
              "Num Properties": propertyGroup.numProperties,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyGroupInitiated(schema, propertyGroup, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumProperties(propertyGroup.numProperties));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("QCNHU7Vuz", "7a3498f28a577b32b6373cc69068d408b67575656ca1488c52f5e6207d277ea3", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Group Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Group Id": propertyGroup.propertyGroupId,
          "Property Group Name": propertyGroup.propertyGroupName,
          "Num Properties": propertyGroup.numProperties,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("QCNHU7Vuz", "Property Group Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("QCNHU7Vuz", Date.now(), "Property Group Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5_FIxvuHF",
              name: "Property Group Id",
              value: propertyGroup.propertyGroupId
            },
            {
              id: "DIq03ESJQ",
              name: "Property Group Name",
              value: propertyGroup.propertyGroupName
            },
            {
              id: "dbd1e302-296d-4ce3-9a5b-c5f12f694d78",
              name: "Num Properties",
              value: propertyGroup.numProperties
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Group Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Group Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Group Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Group Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Group Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Group Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Group Id": propertyGroup.propertyGroupId,
        "Property Group Name": propertyGroup.propertyGroupName,
        "Num Properties": propertyGroup.numProperties,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Group Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Group Id": propertyGroup.propertyGroupId,
              "Property Group Name": propertyGroup.propertyGroupName,
              "Num Properties": propertyGroup.numProperties,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function codeSnippetInteracted(schema, source, eventId, eventName, codeSnippetInteraction, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("6sVNYRVZz", "c683fd054a41593eef7376f868336af3e7398ca716400793af6c7852a825d2ef", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Code Snippet Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Source Id": source.sourceId,
          "Source Name": source.sourceName,
          "Event Id": eventId,
          "Event Name": eventName,
          "Code Snippet Interaction": codeSnippetInteractionToJs(codeSnippetInteraction),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("6sVNYRVZz", "Code Snippet Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_ndbqARdc",
              name: "Code Snippet Interaction",
              value: codeSnippetInteractionToJs(codeSnippetInteraction)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("6sVNYRVZz", Date.now(), "Code Snippet Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_ndbqARdc",
              name: "Code Snippet Interaction",
              value: codeSnippetInteractionToJs(codeSnippetInteraction)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Code Snippet Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Code Snippet Interaction": codeSnippetInteractionToJs(codeSnippetInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Code Snippet Interacted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Code Snippet Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Code Snippet Interaction": codeSnippetInteractionToJs(codeSnippetInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Code Snippet Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Code Snippet Interaction": codeSnippetInteractionToJs(codeSnippetInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Code Snippet Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Code Snippet Interaction": codeSnippetInteractionToJs(codeSnippetInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Code Snippet Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Code Snippet Interaction": codeSnippetInteractionToJs(codeSnippetInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Code Snippet Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Source Id": source.sourceId,
              "Source Name": source.sourceName,
              "Event Id": eventId,
              "Event Name": eventName,
              "Code Snippet Interaction": codeSnippetInteractionToJs(codeSnippetInteraction),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchPromptInteraction(schema, branchPromptInteraction$1, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("85XS456XE", "7d86ad2e935cdd125daf90a6ba8aec46a0e7f2f3ff1b86ed31c73dc97f29bca6", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Prompt Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Branch Prompt Interaction": branchPromptInteractionToJs(branchPromptInteraction$1),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("85XS456XE", "Branch Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HC5owTREY",
              name: "Branch Prompt Interaction",
              value: branchPromptInteractionToJs(branchPromptInteraction$1)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("85XS456XE", Date.now(), "Branch Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "HC5owTREY",
              name: "Branch Prompt Interaction",
              value: branchPromptInteractionToJs(branchPromptInteraction$1)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Prompt Interaction": branchPromptInteractionToJs(branchPromptInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Prompt Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Prompt Interaction": branchPromptInteractionToJs(branchPromptInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Prompt Interaction": branchPromptInteractionToJs(branchPromptInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Prompt Interaction": branchPromptInteractionToJs(branchPromptInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Prompt Interaction": branchPromptInteractionToJs(branchPromptInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branch Prompt Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Branch Prompt Interaction": branchPromptInteractionToJs(branchPromptInteraction$1),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function upgradePromptInteraction(schema, upgradePromptReaction, upgradePromptType, hoursLeftOfTrial, paywallName, pageName, trialLength, planToTrial, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("WHSS3s6WW", "8ff2fbe4240bfad0a3a04e81e6821600d6165f5c06a199de1ea8277ddea92a9e", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Upgrade Prompt Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction),
          "Upgrade Prompt Type": upgradePromptType,
          "Hours Left of Trial": hoursLeftOfTrial,
          "Paywall Name": paywallNameToJs(paywallName),
          "Page Name": pageNameToJs(pageName),
          "Trial Length": trialLength,
          "Plan to Trial": Belt_Option.map(planToTrial, planToTrialToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("WHSS3s6WW", "Upgrade Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "tGztXtw8a",
              name: "Upgrade Prompt Reaction",
              value: upgradePromptReactionToJs(upgradePromptReaction)
            },
            {
              id: "KNdIP7W9b",
              name: "Upgrade Prompt Type",
              value: upgradePromptType
            },
            {
              id: "epoeGU6T0",
              name: "Hours Left of Trial",
              value: hoursLeftOfTrial
            },
            {
              id: "nSW3_5PPhK",
              name: "Paywall Name",
              value: paywallNameToJs(paywallName)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "713732cb-8c41-43c9-916b-0a705718a547",
              name: "Trial Length",
              value: trialLength
            },
            {
              id: "jogMjwwGpX",
              name: "Plan to Trial",
              value: Belt_Option.map(planToTrial, planToTrialToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("WHSS3s6WW", Date.now(), "Upgrade Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "tGztXtw8a",
              name: "Upgrade Prompt Reaction",
              value: upgradePromptReactionToJs(upgradePromptReaction)
            },
            {
              id: "KNdIP7W9b",
              name: "Upgrade Prompt Type",
              value: upgradePromptType
            },
            {
              id: "epoeGU6T0",
              name: "Hours Left of Trial",
              value: hoursLeftOfTrial
            },
            {
              id: "nSW3_5PPhK",
              name: "Paywall Name",
              value: paywallNameToJs(paywallName)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "713732cb-8c41-43c9-916b-0a705718a547",
              name: "Trial Length",
              value: trialLength
            },
            {
              id: "jogMjwwGpX",
              name: "Plan to Trial",
              value: Belt_Option.map(planToTrial, planToTrialToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Upgrade Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction),
        "Upgrade Prompt Type": upgradePromptType,
        "Hours Left of Trial": hoursLeftOfTrial,
        "Paywall Name": paywallNameToJs(paywallName),
        "Page Name": pageNameToJs(pageName),
        "Trial Length": trialLength,
        "Plan to Trial": Belt_Option.map(planToTrial, planToTrialToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus,
    "Schema Subscription Plan": schema.schemaSubscriptionPlan,
    "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
    "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
    "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction)
  };
  logEvent("Upgrade Prompt Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Upgrade Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction),
        "Upgrade Prompt Type": upgradePromptType,
        "Hours Left of Trial": hoursLeftOfTrial,
        "Paywall Name": paywallNameToJs(paywallName),
        "Page Name": pageNameToJs(pageName),
        "Trial Length": trialLength,
        "Plan to Trial": Belt_Option.map(planToTrial, planToTrialToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Upgrade Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction),
        "Upgrade Prompt Type": upgradePromptType,
        "Hours Left of Trial": hoursLeftOfTrial,
        "Paywall Name": paywallNameToJs(paywallName),
        "Page Name": pageNameToJs(pageName),
        "Trial Length": trialLength,
        "Plan to Trial": Belt_Option.map(planToTrial, planToTrialToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Upgrade Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction),
        "Upgrade Prompt Type": upgradePromptType,
        "Hours Left of Trial": hoursLeftOfTrial,
        "Paywall Name": paywallNameToJs(paywallName),
        "Page Name": pageNameToJs(pageName),
        "Trial Length": trialLength,
        "Plan to Trial": Belt_Option.map(planToTrial, planToTrialToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Upgrade Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction),
        "Upgrade Prompt Type": upgradePromptType,
        "Hours Left of Trial": hoursLeftOfTrial,
        "Paywall Name": paywallNameToJs(paywallName),
        "Page Name": pageNameToJs(pageName),
        "Trial Length": trialLength,
        "Plan to Trial": Belt_Option.map(planToTrial, planToTrialToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Upgrade Prompt Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Upgrade Prompt Reaction": upgradePromptReactionToJs(upgradePromptReaction),
              "Upgrade Prompt Type": upgradePromptType,
              "Hours Left of Trial": hoursLeftOfTrial,
              "Paywall Name": paywallNameToJs(paywallName),
              "Page Name": pageNameToJs(pageName),
              "Trial Length": trialLength,
              "Plan to Trial": Belt_Option.map(planToTrial, planToTrialToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function onboardingStarted(param) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("83z8S_6jb", "4cceec959c58656022613dc507e3e3832db994a1ef61b8ee63b0204a2b2bf942", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Started", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("83z8S_6jb", "Onboarding Started", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("83z8S_6jb", Date.now(), "Onboarding Started", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Onboarding Started", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Onboarding Started", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Onboarding Started", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Onboarding Started", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Onboarding Started", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Onboarding Started", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Onboarding Started", {
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function onboardingAction(onboarding, onboardingActionType, emailInput) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("S0oC2oggU", "7d7fce8f2672c60c77ec0c118f482068012f62f40f0ade2078cc096bee3a72a4", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Action", {
          "Onboarding Sources": onboarding.onboardingSources,
          "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
          "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
          "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
          "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
          "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
          "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
          "Onboarding Action Type": onboardingActionTypeToJs(onboardingActionType),
          "Email Input": emailInput,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("S0oC2oggU", "Onboarding Action", messages, [
            {
              id: "L-vxP9O5q",
              name: "Onboarding Sources",
              value: onboarding.onboardingSources
            },
            {
              id: "5aT7rCk1w",
              name: "Onboarding Destinations",
              value: Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs)
            },
            {
              id: "VajaCklSoa",
              name: "Onboarding Job Function",
              value: Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs)
            },
            {
              id: "AzzNuoZQXr",
              name: "Onboarding Seen Join Instructions",
              value: onboarding.onboardingSeenJoinInstructions
            },
            {
              id: "U4mTy0_io",
              name: "Onboarding Tracking Contributions",
              value: Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs)
            },
            {
              id: "VJ5XlG2Ob",
              name: "Onboarding Company Role",
              value: Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs)
            },
            {
              id: "s62rnrF-R",
              name: "Onboarding Company Size",
              value: Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs)
            },
            {
              id: "EguZVlxf9",
              name: "Onboarding Action Type",
              value: onboardingActionTypeToJs(onboardingActionType)
            },
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("S0oC2oggU", Date.now(), "Onboarding Action", messages, [
            {
              id: "L-vxP9O5q",
              name: "Onboarding Sources",
              value: onboarding.onboardingSources
            },
            {
              id: "5aT7rCk1w",
              name: "Onboarding Destinations",
              value: Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs)
            },
            {
              id: "VajaCklSoa",
              name: "Onboarding Job Function",
              value: Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs)
            },
            {
              id: "AzzNuoZQXr",
              name: "Onboarding Seen Join Instructions",
              value: onboarding.onboardingSeenJoinInstructions
            },
            {
              id: "U4mTy0_io",
              name: "Onboarding Tracking Contributions",
              value: Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs)
            },
            {
              id: "VJ5XlG2Ob",
              name: "Onboarding Company Role",
              value: Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs)
            },
            {
              id: "s62rnrF-R",
              name: "Onboarding Company Size",
              value: Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs)
            },
            {
              id: "EguZVlxf9",
              name: "Onboarding Action Type",
              value: onboardingActionTypeToJs(onboardingActionType)
            },
            {
              id: "439349b2-72cb-4ac1-81d9-1c4aa89da524",
              name: "Email Input",
              value: emailInput
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Onboarding Action", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Onboarding Action Type": onboardingActionTypeToJs(onboardingActionType),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Onboarding Action", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Onboarding Action", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Onboarding Action Type": onboardingActionTypeToJs(onboardingActionType),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Onboarding Action", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Onboarding Action Type": onboardingActionTypeToJs(onboardingActionType),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Onboarding Action", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Onboarding Action Type": onboardingActionTypeToJs(onboardingActionType),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Onboarding Action", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Onboarding Action Type": onboardingActionTypeToJs(onboardingActionType),
        "Email Input": emailInput,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Onboarding Action", {
              "Onboarding Sources": onboarding.onboardingSources,
              "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
              "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
              "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
              "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
              "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
              "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
              "Onboarding Action Type": onboardingActionTypeToJs(onboardingActionType),
              "Email Input": emailInput,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function onboardingCompleted(onboarding, schema, userId_, userOnboardingJobFunction, companyRole, trackingContribution, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceCompanySize) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("kYMOzktsh", "eb2f4340d74fbed22481c7eb9de93ad86be2d50fd3106307fa3cf3b85becf127", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Completed", {
          "Onboarding Sources": onboarding.onboardingSources,
          "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
          "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
          "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
          "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
          "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
          "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs),
          "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
          "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs)
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("kYMOzktsh", "Onboarding Completed", messages, [
            {
              id: "L-vxP9O5q",
              name: "Onboarding Sources",
              value: onboarding.onboardingSources
            },
            {
              id: "5aT7rCk1w",
              name: "Onboarding Destinations",
              value: Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs)
            },
            {
              id: "VajaCklSoa",
              name: "Onboarding Job Function",
              value: Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs)
            },
            {
              id: "AzzNuoZQXr",
              name: "Onboarding Seen Join Instructions",
              value: onboarding.onboardingSeenJoinInstructions
            },
            {
              id: "U4mTy0_io",
              name: "Onboarding Tracking Contributions",
              value: Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs)
            },
            {
              id: "VJ5XlG2Ob",
              name: "Onboarding Company Role",
              value: Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs)
            },
            {
              id: "s62rnrF-R",
              name: "Onboarding Company Size",
              value: Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs)
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([
                {
                  id: "79mSn_Q6No",
                  name: "User Onboarding Job Function",
                  value: Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
                },
                {
                  id: "GdzG-4m3S",
                  name: "Company Role",
                  value: Belt_Option.map(companyRole, companyRoleToJs)
                },
                {
                  id: "gBPGobBFC",
                  name: "Tracking Contribution",
                  value: Belt_Array.map(trackingContribution, trackingContributionToJs)
                }
              ], [{
                  id: "iI_ICEiZBV",
                  name: "Workspace Company Size (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceCompanySize, workspaceCompanySizeToJs)
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("kYMOzktsh", Date.now(), "Onboarding Completed", messages, [
            {
              id: "L-vxP9O5q",
              name: "Onboarding Sources",
              value: onboarding.onboardingSources
            },
            {
              id: "5aT7rCk1w",
              name: "Onboarding Destinations",
              value: Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs)
            },
            {
              id: "VajaCklSoa",
              name: "Onboarding Job Function",
              value: Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs)
            },
            {
              id: "AzzNuoZQXr",
              name: "Onboarding Seen Join Instructions",
              value: onboarding.onboardingSeenJoinInstructions
            },
            {
              id: "U4mTy0_io",
              name: "Onboarding Tracking Contributions",
              value: Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs)
            },
            {
              id: "VJ5XlG2Ob",
              name: "Onboarding Company Role",
              value: Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs)
            },
            {
              id: "s62rnrF-R",
              name: "Onboarding Company Size",
              value: Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs)
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [
            {
              id: "79mSn_Q6No",
              name: "User Onboarding Job Function",
              value: Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
            },
            {
              id: "GdzG-4m3S",
              name: "Company Role",
              value: Belt_Option.map(companyRole, companyRoleToJs)
            },
            {
              id: "gBPGobBFC",
              name: "Tracking Contribution",
              value: Belt_Array.map(trackingContribution, trackingContributionToJs)
            }
          ]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Company Size": Belt_Option.map(workspaceCompanySize, workspaceCompanySizeToJs)
      });
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs),
        "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
        "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Completed", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomUserProperties = {
    "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs).join(","),
    "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
    "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs).join(",")
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {};
  logEvent("Onboarding Completed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Company Size": Belt_Option.map(workspaceCompanySize, workspaceCompanySizeToJs)
      });
  Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs),
        "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
        "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Completed", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setUserProperties$1({
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs),
        "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
        "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs)
      });
  logEvent$1("Onboarding Completed", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Company Size": Belt_Option.map(workspaceCompanySize, workspaceCompanySizeToJs)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs),
        "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
        "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Completed", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Company Size": Belt_Option.map(workspaceCompanySize, workspaceCompanySizeToJs)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs),
        "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
        "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Completed", {
        "Onboarding Sources": onboarding.onboardingSources,
        "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
        "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
        "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
        "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
        "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
        "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Company Size": Belt_Option.map(workspaceCompanySize, workspaceCompanySizeToJs)
      });
  setUserProperties$2({
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs),
        "Company Role": Belt_Option.map(companyRole, companyRoleToJs),
        "Tracking Contribution": Belt_Array.map(trackingContribution, trackingContributionToJs)
      });
  return logEventWithGroups("Onboarding Completed", {
              "Onboarding Sources": onboarding.onboardingSources,
              "Onboarding Destinations": Belt_Array.map(onboarding.onboardingDestinations, onboardingDestinationsToJs),
              "Onboarding Job Function": Belt_Array.map(onboarding.onboardingJobFunction, onboardingJobFunctionToJs),
              "Onboarding Seen Join Instructions": onboarding.onboardingSeenJoinInstructions,
              "Onboarding Tracking Contributions": Belt_Array.map(onboarding.onboardingTrackingContributions, onboardingTrackingContributionsToJs),
              "Onboarding Company Role": Belt_Option.map(onboarding.onboardingCompanyRole, onboardingCompanyRoleToJs),
              "Onboarding Company Size": Belt_Option.map(onboarding.onboardingCompanySize, onboardingCompanySizeToJs),
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function schemaCreated(schema, associateUserWithWorkspace, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceId, workspaceName, workspaceBillingStatus, workspaceTrialPlan, workspaceSubscriptionPlan) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("GLDVkQJKB", "830852b8c8cf7003e957073ca1964369a26ffa9b6c212489e9843c36f0290323", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Schema Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("GLDVkQJKB", "Schema Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "-oGxPramb5",
                  name: "Workspace Id (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceId
                },
                {
                  id: "EHjllhnus",
                  name: "Workspace Name (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceName
                },
                {
                  id: "JMzL9twjbS",
                  name: "Workspace Billing Status (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceBillingStatus
                },
                {
                  id: "iqJjwnPpbS",
                  name: "Workspace Trial Plan (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs)
                },
                {
                  id: "2eAp5ia9Zc",
                  name: "Workspace Subscription Plan (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceSubscriptionPlan
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("GLDVkQJKB", Date.now(), "Schema Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Schema Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Schema Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Schema Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Schema Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Schema Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Schema Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  addCurrentUserToGroup("Workspace", associateUserWithWorkspace);
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Id": workspaceId,
        "Workspace Name": workspaceName,
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Subscription Plan": workspaceSubscriptionPlan
      });
  return logEventWithGroups("Schema Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function onboardingChecklistProgressed(schema, checklistProgressRatio, numChecklistStepsLeft, checklistStepName, checklistStepsLeft, checklistType, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceInspectorInstallStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertChecklistProgressRatio(checklistProgressRatio));
    var messages$2 = Belt_Array.concat(messages$1, assertNumChecklistStepsLeft(numChecklistStepsLeft));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("rJB0pOaw0", "67466305547be548efd1a4512802acec655012ac64b3c5a6ebbf88e9f46538cf", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Progressed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Progress Ratio": checklistProgressRatio,
          "Num Checklist Steps Left": numChecklistStepsLeft,
          "Checklist Step Name": checklistStepNameToJs(checklistStepName),
          "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
          "Checklist Type": checklistTypeToJs(checklistType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("rJB0pOaw0", "Onboarding Checklist Progressed", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "_tPoZx3Or",
              name: "Checklist Progress Ratio",
              value: checklistProgressRatio
            },
            {
              id: "GDZiM7GiK",
              name: "Num Checklist Steps Left",
              value: numChecklistStepsLeft
            },
            {
              id: "5mB-XJ_FJ",
              name: "Checklist Step Name",
              value: checklistStepNameToJs(checklistStepName)
            },
            {
              id: "G1a4oEFQG",
              name: "Checklist Steps Left",
              value: Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs)
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "N9zV1896K9",
                  name: "Workspace Inspector Install Status (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("rJB0pOaw0", Date.now(), "Onboarding Checklist Progressed", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "_tPoZx3Or",
              name: "Checklist Progress Ratio",
              value: checklistProgressRatio
            },
            {
              id: "GDZiM7GiK",
              name: "Num Checklist Steps Left",
              value: numChecklistStepsLeft
            },
            {
              id: "5mB-XJ_FJ",
              name: "Checklist Step Name",
              value: checklistStepNameToJs(checklistStepName)
            },
            {
              id: "G1a4oEFQG",
              name: "Checklist Steps Left",
              value: Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs)
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Progressed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Progress Ratio": checklistProgressRatio,
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Progressed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Progressed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Progress Ratio": checklistProgressRatio,
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Progressed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Progress Ratio": checklistProgressRatio,
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Progressed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Progress Ratio": checklistProgressRatio,
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Progressed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Progress Ratio": checklistProgressRatio,
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  return logEventWithGroups("Onboarding Checklist Progressed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Progress Ratio": checklistProgressRatio,
              "Num Checklist Steps Left": numChecklistStepsLeft,
              "Checklist Step Name": checklistStepNameToJs(checklistStepName),
              "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
              "Checklist Type": checklistTypeToJs(checklistType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function exampleInteraction(schema, exampleInteraction$1, exampleType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("xbhWQAAu8", "5360f5ae200f68f1ed01c7c57e251165d65eed48d3afbb472d8abf6b3f175be7", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Example Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Example Interaction": exampleInteractionToJs(exampleInteraction$1),
          "Example Type": exampleTypeToJs(exampleType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("xbhWQAAu8", "Example Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Lenwb1URD",
              name: "Example Interaction",
              value: exampleInteractionToJs(exampleInteraction$1)
            },
            {
              id: "FC7oiVroB",
              name: "Example Type",
              value: exampleTypeToJs(exampleType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("xbhWQAAu8", Date.now(), "Example Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Lenwb1URD",
              name: "Example Interaction",
              value: exampleInteractionToJs(exampleInteraction$1)
            },
            {
              id: "FC7oiVroB",
              name: "Example Type",
              value: exampleTypeToJs(exampleType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Example Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Example Interaction": exampleInteractionToJs(exampleInteraction$1),
        "Example Type": exampleTypeToJs(exampleType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Example Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Example Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Example Interaction": exampleInteractionToJs(exampleInteraction$1),
        "Example Type": exampleTypeToJs(exampleType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Example Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Example Interaction": exampleInteractionToJs(exampleInteraction$1),
        "Example Type": exampleTypeToJs(exampleType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Example Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Example Interaction": exampleInteractionToJs(exampleInteraction$1),
        "Example Type": exampleTypeToJs(exampleType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Example Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Example Interaction": exampleInteractionToJs(exampleInteraction$1),
        "Example Type": exampleTypeToJs(exampleType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Example Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Example Interaction": exampleInteractionToJs(exampleInteraction$1),
              "Example Type": exampleTypeToJs(exampleType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function searchInputActivated(schema, pageName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("65hqkc7hg", "a659b7312413210f3b7779235c4311170576c089da8bcd014b8661c6d179d3c4", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Search Input Activated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Page Name": pageNameToJs(pageName),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("65hqkc7hg", "Search Input Activated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("65hqkc7hg", Date.now(), "Search Input Activated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Search Input Activated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Search Input Activated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Search Input Activated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Search Input Activated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Search Input Activated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Search Input Activated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Search Input Activated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Page Name": pageNameToJs(pageName),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function memberInviteRemoved(schema, emailAddress, role, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("oZyaaXraS", "341ccf93f1defc2473b6299ae3c3be209241ec788a443c35fb34c4ee9782e0c0", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Member Invite Removed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Email Address": emailAddress,
          Role: roleToJs(role),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("oZyaaXraS", "Member Invite Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c6b1621c-7d08-4dd0-8c8b-e0eaa6e64148",
              name: "Email Address",
              value: emailAddress
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("oZyaaXraS", Date.now(), "Member Invite Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c6b1621c-7d08-4dd0-8c8b-e0eaa6e64148",
              name: "Email Address",
              value: emailAddress
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Member Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Email Address": emailAddress,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Member Invite Removed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Member Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Email Address": emailAddress,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Member Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Email Address": emailAddress,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Member Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Email Address": emailAddress,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Member Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Email Address": emailAddress,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Member Invite Removed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Email Address": emailAddress,
              Role: roleToJs(role),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function memberInviteResent(schema, role, emailAddress, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Vd6xbLTFc", "e9648aab55f6bd90ade645dde02f4f12dfeb0f0f8751ad3273ee1c2079b0bbaa", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Member Invite Resent", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Role: roleToJs(role),
          "Email Address": emailAddress,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Vd6xbLTFc", "Member Invite Resent", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "c6b1621c-7d08-4dd0-8c8b-e0eaa6e64148",
              name: "Email Address",
              value: emailAddress
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Vd6xbLTFc", Date.now(), "Member Invite Resent", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "c6b1621c-7d08-4dd0-8c8b-e0eaa6e64148",
              name: "Email Address",
              value: emailAddress
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Member Invite Resent", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Member Invite Resent", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Member Invite Resent", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Member Invite Resent", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Member Invite Resent", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Member Invite Resent", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Role: roleToJs(role),
        "Email Address": emailAddress,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Member Invite Resent", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Role: roleToJs(role),
              "Email Address": emailAddress,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function issueReportOpened(schema, numSchemaIssues, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("aac2jLyqvm", "c8d6bf1f410611ea6b8ade3f9a1d20cab82ea23646ac9f9a474f0e5e698bfa90", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Report Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Schema Issues": numSchemaIssues,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("aac2jLyqvm", "Issue Report Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("aac2jLyqvm", Date.now(), "Issue Report Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Report Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Issue Report Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Report Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Issue Report Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Report Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Report Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Issue Report Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Schema Issues": numSchemaIssues,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function issueExpanded(schema, numSchemaIssues, issueType, trackingPlanAuditLocation, numNewSchemaIssuesOnBranch, issuesAutoCorrectOffered, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ke1AlA9AOb", "8e059865345d9d8dcf6395df386c6429b8eb4ca4c405377e0da32455c7fabe9e", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Expanded", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Schema Issues": numSchemaIssues,
          "Issue Type": Belt_Option.map(issueType, issueTypeToJs),
          "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
          "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
          "Issues Auto Correct Offered": issuesAutoCorrectOffered,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ke1AlA9AOb", "Issue Expanded", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "zh2pil15Lo",
              name: "Issue Type",
              value: Belt_Option.map(issueType, issueTypeToJs)
            },
            {
              id: "ndry-ZTdL4",
              name: "Tracking Plan Audit Location",
              value: trackingPlanAuditLocationToJs(trackingPlanAuditLocation)
            },
            {
              id: "dvg1AKQZk",
              name: "Num New Schema Issues on Branch",
              value: numNewSchemaIssuesOnBranch
            },
            {
              id: "hgkOkv7Ps-",
              name: "Issues Auto Correct Offered",
              value: issuesAutoCorrectOffered
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ke1AlA9AOb", Date.now(), "Issue Expanded", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "zh2pil15Lo",
              name: "Issue Type",
              value: Belt_Option.map(issueType, issueTypeToJs)
            },
            {
              id: "ndry-ZTdL4",
              name: "Tracking Plan Audit Location",
              value: trackingPlanAuditLocationToJs(trackingPlanAuditLocation)
            },
            {
              id: "dvg1AKQZk",
              name: "Num New Schema Issues on Branch",
              value: numNewSchemaIssuesOnBranch
            },
            {
              id: "hgkOkv7Ps-",
              name: "Issues Auto Correct Offered",
              value: issuesAutoCorrectOffered
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Expanded", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Issue Type": Belt_Option.map(issueType, issueTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Issues Auto Correct Offered": issuesAutoCorrectOffered,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Issue Expanded", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Expanded", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Issue Type": Belt_Option.map(issueType, issueTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Issues Auto Correct Offered": issuesAutoCorrectOffered,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Issue Expanded", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Issue Type": Belt_Option.map(issueType, issueTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Issues Auto Correct Offered": issuesAutoCorrectOffered,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Expanded", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Issue Type": Belt_Option.map(issueType, issueTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Issues Auto Correct Offered": issuesAutoCorrectOffered,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Expanded", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Issue Type": Belt_Option.map(issueType, issueTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        "Issues Auto Correct Offered": issuesAutoCorrectOffered,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Issue Expanded", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Schema Issues": numSchemaIssues,
              "Issue Type": Belt_Option.map(issueType, issueTypeToJs),
              "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
              "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
              "Issues Auto Correct Offered": issuesAutoCorrectOffered,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function issuePreviewDisplayed(schema, numSchemaIssues, trackingPlanAuditLocation, numNewSchemaIssuesOnBranch, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("4-GNn8NIiL", "cde7820bdf1fc594c9a1aac3f12ee5063ced10dcec304a1c9525b64a007d7f52", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Preview Displayed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Schema Issues": numSchemaIssues,
          "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
          "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("4-GNn8NIiL", "Issue Preview Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "ndry-ZTdL4",
              name: "Tracking Plan Audit Location",
              value: trackingPlanAuditLocationToJs(trackingPlanAuditLocation)
            },
            {
              id: "dvg1AKQZk",
              name: "Num New Schema Issues on Branch",
              value: numNewSchemaIssuesOnBranch
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("4-GNn8NIiL", Date.now(), "Issue Preview Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "ndry-ZTdL4",
              name: "Tracking Plan Audit Location",
              value: trackingPlanAuditLocationToJs(trackingPlanAuditLocation)
            },
            {
              id: "dvg1AKQZk",
              name: "Num New Schema Issues on Branch",
              value: numNewSchemaIssuesOnBranch
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Preview Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Issue Preview Displayed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Preview Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Issue Preview Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Preview Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Preview Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Schema Issues": numSchemaIssues,
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Issue Preview Displayed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Schema Issues": numSchemaIssues,
              "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
              "Num New Schema Issues on Branch": numNewSchemaIssuesOnBranch,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function signedUpIntercom(userId_, authenticationMethod, email, userName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("FYhApwp_b_", "a32e5f9243d6d6df1d7f57662e97977000768bc729e04b34b98c804199cddc49", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Signed Up (Intercom)", {
          "Authentication Method": authenticationMethodToJs(authenticationMethod),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          Email: email,
          "User Name": userName
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("FYhApwp_b_", "Signed Up (Intercom)", messages, [
            {
              id: "R9pE83vdh",
              name: "Authentication Method",
              value: authenticationMethodToJs(authenticationMethod)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([
                {
                  id: "92588d93-5307-4fa2-be00-be0821596abe",
                  name: "Email",
                  value: email
                },
                {
                  id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
                  name: "User Name",
                  value: userName
                }
              ], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("FYhApwp_b_", Date.now(), "Signed Up (Intercom)", messages, [
            {
              id: "R9pE83vdh",
              name: "Authentication Method",
              value: authenticationMethodToJs(authenticationMethod)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [
            {
              id: "92588d93-5307-4fa2-be00-be0821596abe",
              name: "Email",
              value: email
            },
            {
              id: "4a599424-369f-4efe-9af8-01f933d0ff2a",
              name: "User Name",
              value: userName
            }
          ]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        Email: email,
        "User Name": userName
      });
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Signed Up (Intercom)", {
        "Authentication Method": authenticationMethodToJs(authenticationMethod),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomUserProperties = {
    Email: email,
    name: userName
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {};
  logEvent("Signed Up", intercomProperties);
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        Email: email,
        name: userName
      });
  return Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Signed Up (Intercom)", {
              "Authentication Method": authenticationMethodToJs(authenticationMethod),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function inspectorEventsViewed(inspectorEventsViewFilters, inspectorTablePosition, schema, inspectorView, numInspectorEvents, inspectorShareIdOpened, inspectorShareIdCreated, utmMedium, utmCampaign, numInspectorEventsNotFoundInTrackingPlan, proportionOfInspectorEventsNotFoundInTrackingPlan, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceInspectorInstallStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertProportionOfInspectorEventsNotFoundInTrackingPlan(proportionOfInspectorEventsNotFoundInTrackingPlan));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Iwvpf4xx1q", "3d4594a9d0aefd3c1bf71b7ceb3e2317699349e03860f5696c0d8ff95490e83e", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Events Viewed", {
          "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
          "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
          "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
          "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
          "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
          "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
          "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
          "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
          "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
          "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
          "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
          "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
          "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector View": inspectorViewToJs(inspectorView),
          "Num Inspector Events": numInspectorEvents,
          "Inspector Share Id Opened": inspectorShareIdOpened,
          "Inspector Share Id Created": inspectorShareIdCreated,
          "UTM Medium": utmMedium,
          "UTM Campaign": utmCampaign,
          "Num Inspector Events Not Found in Tracking Plan": numInspectorEventsNotFoundInTrackingPlan,
          "Proportion of Inspector Events Not Found in Tracking Plan": proportionOfInspectorEventsNotFoundInTrackingPlan,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Iwvpf4xx1q", "Inspector Events Viewed", messages$1, [
            {
              id: "FRG8vQWyKS",
              name: "Inspector Event Name Filter",
              value: inspectorEventsViewFilters.inspectorEventNameFilter
            },
            {
              id: "efdEIxbEV9",
              name: "Inspector Order",
              value: inspectorEventsViewFilters.inspectorOrder
            },
            {
              id: "DuISKOHRbs",
              name: "Inspector Column Ordered by",
              value: inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy)
            },
            {
              id: "nojyD1ad9T",
              name: "Inspector Source Id Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceIdOrderedBy
            },
            {
              id: "Xr7_C3vsNK",
              name: "Inspector Time Window",
              value: inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEventsViewFilters.inspectorEnvironment
            },
            {
              id: "UF6h33XVe7",
              name: "Inspector Source Name Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceNameOrderedBy
            },
            {
              id: "b9jZkWYHED",
              name: "Inspector Event Name Selected",
              value: inspectorTablePosition.inspectorEventNameSelected
            },
            {
              id: "Li0n6tG60C",
              name: "Inspector Column Selected",
              value: Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs)
            },
            {
              id: "AqlHqOtKOd",
              name: "Inspector Property Name Selected",
              value: inspectorTablePosition.inspectorPropertyNameSelected
            },
            {
              id: "g9wbkafvsc",
              name: "Inspector Source Id Selected",
              value: inspectorTablePosition.inspectorSourceIdSelected
            },
            {
              id: "PKmvgpXhIi",
              name: "Inspector Source Name Selected",
              value: inspectorTablePosition.inspectorSourceNameSelected
            },
            {
              id: "jPYJb5h00",
              name: "Num Properties Not Found on Event in Tracking Plan",
              value: inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "vDTeWPTwU",
              name: "Inspector View",
              value: inspectorViewToJs(inspectorView)
            },
            {
              id: "PF97B--TTv",
              name: "Num Inspector Events",
              value: numInspectorEvents
            },
            {
              id: "wxttyu_Ezr",
              name: "Inspector Share Id Opened",
              value: inspectorShareIdOpened
            },
            {
              id: "lasnNsYef5",
              name: "Inspector Share Id Created",
              value: inspectorShareIdCreated
            },
            {
              id: "wx_FcQt9oN",
              name: "UTM Medium",
              value: utmMedium
            },
            {
              id: "wbB7aeGs47",
              name: "UTM Campaign",
              value: utmCampaign
            },
            {
              id: "66aNz-YYE",
              name: "Num Inspector Events Not Found in Tracking Plan",
              value: numInspectorEventsNotFoundInTrackingPlan
            },
            {
              id: "PFRG978Zv",
              name: "Proportion of Inspector Events Not Found in Tracking Plan",
              value: proportionOfInspectorEventsNotFoundInTrackingPlan
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "N9zV1896K9",
                  name: "Workspace Inspector Install Status (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Iwvpf4xx1q", Date.now(), "Inspector Events Viewed", messages$1, [
            {
              id: "FRG8vQWyKS",
              name: "Inspector Event Name Filter",
              value: inspectorEventsViewFilters.inspectorEventNameFilter
            },
            {
              id: "efdEIxbEV9",
              name: "Inspector Order",
              value: inspectorEventsViewFilters.inspectorOrder
            },
            {
              id: "DuISKOHRbs",
              name: "Inspector Column Ordered by",
              value: inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy)
            },
            {
              id: "nojyD1ad9T",
              name: "Inspector Source Id Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceIdOrderedBy
            },
            {
              id: "Xr7_C3vsNK",
              name: "Inspector Time Window",
              value: inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEventsViewFilters.inspectorEnvironment
            },
            {
              id: "UF6h33XVe7",
              name: "Inspector Source Name Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceNameOrderedBy
            },
            {
              id: "b9jZkWYHED",
              name: "Inspector Event Name Selected",
              value: inspectorTablePosition.inspectorEventNameSelected
            },
            {
              id: "Li0n6tG60C",
              name: "Inspector Column Selected",
              value: Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs)
            },
            {
              id: "AqlHqOtKOd",
              name: "Inspector Property Name Selected",
              value: inspectorTablePosition.inspectorPropertyNameSelected
            },
            {
              id: "g9wbkafvsc",
              name: "Inspector Source Id Selected",
              value: inspectorTablePosition.inspectorSourceIdSelected
            },
            {
              id: "PKmvgpXhIi",
              name: "Inspector Source Name Selected",
              value: inspectorTablePosition.inspectorSourceNameSelected
            },
            {
              id: "jPYJb5h00",
              name: "Num Properties Not Found on Event in Tracking Plan",
              value: inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "vDTeWPTwU",
              name: "Inspector View",
              value: inspectorViewToJs(inspectorView)
            },
            {
              id: "PF97B--TTv",
              name: "Num Inspector Events",
              value: numInspectorEvents
            },
            {
              id: "wxttyu_Ezr",
              name: "Inspector Share Id Opened",
              value: inspectorShareIdOpened
            },
            {
              id: "lasnNsYef5",
              name: "Inspector Share Id Created",
              value: inspectorShareIdCreated
            },
            {
              id: "wx_FcQt9oN",
              name: "UTM Medium",
              value: utmMedium
            },
            {
              id: "wbB7aeGs47",
              name: "UTM Campaign",
              value: utmCampaign
            },
            {
              id: "66aNz-YYE",
              name: "Num Inspector Events Not Found in Tracking Plan",
              value: numInspectorEventsNotFoundInTrackingPlan
            },
            {
              id: "PFRG978Zv",
              name: "Proportion of Inspector Events Not Found in Tracking Plan",
              value: proportionOfInspectorEventsNotFoundInTrackingPlan
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Events Viewed", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector View": inspectorViewToJs(inspectorView),
        "Num Inspector Events": numInspectorEvents,
        "Inspector Share Id Opened": inspectorShareIdOpened,
        "Inspector Share Id Created": inspectorShareIdCreated,
        "UTM Medium": utmMedium,
        "UTM Campaign": utmCampaign,
        "Num Inspector Events Not Found in Tracking Plan": numInspectorEventsNotFoundInTrackingPlan,
        "Proportion of Inspector Events Not Found in Tracking Plan": proportionOfInspectorEventsNotFoundInTrackingPlan,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Events Viewed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Events Viewed", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector View": inspectorViewToJs(inspectorView),
        "Num Inspector Events": numInspectorEvents,
        "Inspector Share Id Opened": inspectorShareIdOpened,
        "Inspector Share Id Created": inspectorShareIdCreated,
        "UTM Medium": utmMedium,
        "UTM Campaign": utmCampaign,
        "Num Inspector Events Not Found in Tracking Plan": numInspectorEventsNotFoundInTrackingPlan,
        "Proportion of Inspector Events Not Found in Tracking Plan": proportionOfInspectorEventsNotFoundInTrackingPlan,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Events Viewed", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector View": inspectorViewToJs(inspectorView),
        "Num Inspector Events": numInspectorEvents,
        "Inspector Share Id Opened": inspectorShareIdOpened,
        "Inspector Share Id Created": inspectorShareIdCreated,
        "UTM Medium": utmMedium,
        "UTM Campaign": utmCampaign,
        "Num Inspector Events Not Found in Tracking Plan": numInspectorEventsNotFoundInTrackingPlan,
        "Proportion of Inspector Events Not Found in Tracking Plan": proportionOfInspectorEventsNotFoundInTrackingPlan,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Events Viewed", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector View": inspectorViewToJs(inspectorView),
        "Num Inspector Events": numInspectorEvents,
        "Inspector Share Id Opened": inspectorShareIdOpened,
        "Inspector Share Id Created": inspectorShareIdCreated,
        "UTM Medium": utmMedium,
        "UTM Campaign": utmCampaign,
        "Num Inspector Events Not Found in Tracking Plan": numInspectorEventsNotFoundInTrackingPlan,
        "Proportion of Inspector Events Not Found in Tracking Plan": proportionOfInspectorEventsNotFoundInTrackingPlan,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Events Viewed", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector View": inspectorViewToJs(inspectorView),
        "Num Inspector Events": numInspectorEvents,
        "Inspector Share Id Opened": inspectorShareIdOpened,
        "Inspector Share Id Created": inspectorShareIdCreated,
        "UTM Medium": utmMedium,
        "UTM Campaign": utmCampaign,
        "Num Inspector Events Not Found in Tracking Plan": numInspectorEventsNotFoundInTrackingPlan,
        "Proportion of Inspector Events Not Found in Tracking Plan": proportionOfInspectorEventsNotFoundInTrackingPlan,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  return logEventWithGroups("Inspector Events Viewed", {
              "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
              "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
              "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
              "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
              "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
              "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
              "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
              "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
              "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
              "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
              "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
              "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
              "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector View": inspectorViewToJs(inspectorView),
              "Num Inspector Events": numInspectorEvents,
              "Inspector Share Id Opened": inspectorShareIdOpened,
              "Inspector Share Id Created": inspectorShareIdCreated,
              "UTM Medium": utmMedium,
              "UTM Campaign": utmCampaign,
              "Num Inspector Events Not Found in Tracking Plan": numInspectorEventsNotFoundInTrackingPlan,
              "Proportion of Inspector Events Not Found in Tracking Plan": proportionOfInspectorEventsNotFoundInTrackingPlan,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchOpened(schema, userId_, branchStatus, schemaCreatedIntegrationsCount, dotNewDomainRedirect, email, numEventsInSchema, numPropertiesInSchema, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForBranch, branchGroupBranchStatus, updateGroupPropertiesForWorkspace, workspaceNumEvents, workspaceNumProperties, workspaceName, workspaceId, workspaceTrialPlan, workspaceBillingStatus, workspaceSubscriptionPlan, workspaceNumDestinations, workspaceNumSources) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertSchemaCreatedIntegrationsCount(schemaCreatedIntegrationsCount));
    var messages$2 = Belt_Array.concat(messages$1, assertNumEventsInSchema(numEventsInSchema));
    var messages$3 = Belt_Array.concat(messages$2, assertNumPropertiesInSchema(numPropertiesInSchema));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("f3aJo4563Y", "d3b9ec51f4d35301c8c25f7d6af69fda2862afdf1b455cdc5a671aaf45968815", Belt_Array.map(messages$3, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Branch Status": branchStatus,
          "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
          "Dot New Domain Redirect": Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs),
          "Num Events in Schema": numEventsInSchema,
          "Num Properties in Schema": numPropertiesInSchema,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          Email: email
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("f3aJo4563Y", "Branch Opened", messages$3, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fWFcpfq5fO",
              name: "Branch Status",
              value: branchStatus
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "Mgb2w-bjlz",
              name: "Dot New Domain Redirect",
              value: Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs)
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([{
                  id: "92588d93-5307-4fa2-be00-be0821596abe",
                  name: "Email",
                  value: email
                }], [
                {
                  id: "JZgOLWGQYL",
                  name: "Branch Status (Branch " + (updateGroupPropertiesForBranch + " group property)"),
                  value: branchGroupBranchStatus
                },
                {
                  id: "kaaW-Pd07M",
                  name: "Workspace Num Events (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumEvents
                },
                {
                  id: "gKFpzuwel",
                  name: "Workspace Num Properties (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumProperties
                },
                {
                  id: "EHjllhnus",
                  name: "Workspace Name (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceName
                },
                {
                  id: "-oGxPramb5",
                  name: "Workspace Id (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceId
                },
                {
                  id: "iqJjwnPpbS",
                  name: "Workspace Trial Plan (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs)
                },
                {
                  id: "JMzL9twjbS",
                  name: "Workspace Billing Status (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceBillingStatus
                },
                {
                  id: "2eAp5ia9Zc",
                  name: "Workspace Subscription Plan (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceSubscriptionPlan
                },
                {
                  id: "wM3DLIpQ6h",
                  name: "Workspace Num Destinations (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumDestinations
                },
                {
                  id: "snB71FkjwY",
                  name: "Workspace Num Sources (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumSources
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("f3aJo4563Y", Date.now(), "Branch Opened", messages$3, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fWFcpfq5fO",
              name: "Branch Status",
              value: branchStatus
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "Mgb2w-bjlz",
              name: "Dot New Domain Redirect",
              value: Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs)
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [{
              id: "92588d93-5307-4fa2-be00-be0821596abe",
              name: "Email",
              value: email
            }]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Events": workspaceNumEvents,
        "Workspace Num Properties": workspaceNumProperties,
        "Workspace Name": workspaceName,
        "Workspace Id": workspaceId,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Num Destinations": workspaceNumDestinations,
        "Workspace Num Sources": workspaceNumSources
      });
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        "Dot New Domain Redirect": Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs),
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomUserProperties = {
    Email: email
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus,
    "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
  };
  logEvent("Branch Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Events": workspaceNumEvents,
        "Workspace Num Properties": workspaceNumProperties,
        "Workspace Name": workspaceName,
        "Workspace Id": workspaceId,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Num Destinations": workspaceNumDestinations,
        "Workspace Num Sources": workspaceNumSources
      });
  Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        "Dot New Domain Redirect": Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs),
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setUserProperties$1({
        displayName: email
      });
  logEvent$1("Branch Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        "Dot New Domain Redirect": Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs),
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Events": workspaceNumEvents,
        "Workspace Num Properties": workspaceNumProperties,
        "Workspace Name": workspaceName,
        "Workspace Id": workspaceId,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Num Destinations": workspaceNumDestinations,
        "Workspace Num Sources": workspaceNumSources
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        "Dot New Domain Redirect": Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs),
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Events": workspaceNumEvents,
        "Workspace Num Properties": workspaceNumProperties,
        "Workspace Name": workspaceName,
        "Workspace Id": workspaceId,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Num Destinations": workspaceNumDestinations,
        "Workspace Num Sources": workspaceNumSources
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
        Email: email
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        "Dot New Domain Redirect": Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs),
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Events": workspaceNumEvents,
        "Workspace Num Properties": workspaceNumProperties,
        "Workspace Name": workspaceName,
        "Workspace Id": workspaceId,
        "Workspace Trial Plan": Belt_Option.map(workspaceTrialPlan, workspaceTrialPlanToJs),
        "Workspace Billing Status": workspaceBillingStatus,
        "Workspace Subscription Plan": workspaceSubscriptionPlan,
        "Workspace Num Destinations": workspaceNumDestinations,
        "Workspace Num Sources": workspaceNumSources
      });
  setUserProperties$2({
        Email: email
      });
  return logEventWithGroups("Branch Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Branch Status": branchStatus,
              "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
              "Dot New Domain Redirect": Belt_Option.map(dotNewDomainRedirect, dotNewDomainRedirectToJs),
              "Num Events in Schema": numEventsInSchema,
              "Num Properties in Schema": numPropertiesInSchema,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function lockedBranchEditAttempted(schema, branchStatus, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForBranch, branchGroupBranchStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("QrPu2f3URn", "fb8df86abd5c4e49447216a60f6b40d6a165e5bba3cecd5ddb5884d03bca419b", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Locked Branch Edit Attempted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Branch Status": branchStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("QrPu2f3URn", "Locked Branch Edit Attempted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fWFcpfq5fO",
              name: "Branch Status",
              value: branchStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "JZgOLWGQYL",
                  name: "Branch Status (Branch " + (updateGroupPropertiesForBranch + " group property)"),
                  value: branchGroupBranchStatus
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("QrPu2f3URn", Date.now(), "Locked Branch Edit Attempted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fWFcpfq5fO",
              name: "Branch Status",
              value: branchStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Locked Branch Edit Attempted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Locked Branch Edit Attempted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Locked Branch Edit Attempted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Locked Branch Edit Attempted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Locked Branch Edit Attempted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Locked Branch Edit Attempted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  return logEventWithGroups("Locked Branch Edit Attempted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Branch Status": branchStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function collaboratorAdded(schema, itemId, itemName, itemType, collaboratorType, collaboratorEmail, collaboratorAdderAction, collaboratorAdderRelationship, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("mz8YqOnQ4N", "027a31c77b3ecaff4e25d6f01709284b924bc40c82bdcd8f17217212a190944d", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Collaborator Added", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Collaborator Type": collaboratorType,
          "Collaborator Email": collaboratorEmail,
          "Collaborator Adder Action": collaboratorAdderActionToJs(collaboratorAdderAction),
          "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("mz8YqOnQ4N", "Collaborator Added", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "LHZxjEjV8f",
              name: "Collaborator Type",
              value: collaboratorType
            },
            {
              id: "TEksL3XtQR",
              name: "Collaborator Email",
              value: collaboratorEmail
            },
            {
              id: "ZtUKWFWoNv",
              name: "Collaborator Adder Action",
              value: collaboratorAdderActionToJs(collaboratorAdderAction)
            },
            {
              id: "89YH0FCUhp",
              name: "Collaborator Adder Relationship",
              value: collaboratorAdderRelationshipToJs(collaboratorAdderRelationship)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("mz8YqOnQ4N", Date.now(), "Collaborator Added", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "LHZxjEjV8f",
              name: "Collaborator Type",
              value: collaboratorType
            },
            {
              id: "TEksL3XtQR",
              name: "Collaborator Email",
              value: collaboratorEmail
            },
            {
              id: "ZtUKWFWoNv",
              name: "Collaborator Adder Action",
              value: collaboratorAdderActionToJs(collaboratorAdderAction)
            },
            {
              id: "89YH0FCUhp",
              name: "Collaborator Adder Relationship",
              value: collaboratorAdderRelationshipToJs(collaboratorAdderRelationship)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Collaborator Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        "Collaborator Adder Action": collaboratorAdderActionToJs(collaboratorAdderAction),
        "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus,
    "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship)
  };
  logEvent("Collaborator Added", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Collaborator Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        "Collaborator Adder Action": collaboratorAdderActionToJs(collaboratorAdderAction),
        "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Collaborator Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        "Collaborator Adder Action": collaboratorAdderActionToJs(collaboratorAdderAction),
        "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Collaborator Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        "Collaborator Adder Action": collaboratorAdderActionToJs(collaboratorAdderAction),
        "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Collaborator Added", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        "Collaborator Adder Action": collaboratorAdderActionToJs(collaboratorAdderAction),
        "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Collaborator Added", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Collaborator Type": collaboratorType,
              "Collaborator Email": collaboratorEmail,
              "Collaborator Adder Action": collaboratorAdderActionToJs(collaboratorAdderAction),
              "Collaborator Adder Relationship": collaboratorAdderRelationshipToJs(collaboratorAdderRelationship),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function collaboratorRemoved(schema, itemId, itemName, itemType, collaboratorType, collaboratorEmail, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("SBsFRZ_9sG", "10d65b1860202d9ee314598d85adbb6ffc99e28db3e8e1e2d2734a5dd6499788", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Collaborator Removed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Collaborator Type": collaboratorType,
          "Collaborator Email": collaboratorEmail,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("SBsFRZ_9sG", "Collaborator Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "LHZxjEjV8f",
              name: "Collaborator Type",
              value: collaboratorType
            },
            {
              id: "TEksL3XtQR",
              name: "Collaborator Email",
              value: collaboratorEmail
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("SBsFRZ_9sG", Date.now(), "Collaborator Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "LHZxjEjV8f",
              name: "Collaborator Type",
              value: collaboratorType
            },
            {
              id: "TEksL3XtQR",
              name: "Collaborator Email",
              value: collaboratorEmail
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Collaborator Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Collaborator Removed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Collaborator Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Collaborator Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Collaborator Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Collaborator Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Collaborator Type": collaboratorType,
        "Collaborator Email": collaboratorEmail,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Collaborator Removed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Collaborator Type": collaboratorType,
              "Collaborator Email": collaboratorEmail,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function overlayItemDisplayed(schema, overlayItemType, overlayItemName, overlayItemPurpose, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("09T1mEVZO", "5d8b18eb330fcc6ebf5ea7c30d85886902ce6fb3591a0c3e7550980ce567e4ca", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Overlay Item Displayed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Overlay Item Type": overlayItemType,
          "Overlay Item Name": overlayItemNameToJs(overlayItemName),
          "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("09T1mEVZO", "Overlay Item Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "8gp2_Xor1V",
              name: "Overlay Item Type",
              value: overlayItemType
            },
            {
              id: "L-r5eSOznH",
              name: "Overlay Item Name",
              value: overlayItemNameToJs(overlayItemName)
            },
            {
              id: "UNwmPXwRx7",
              name: "Overlay Item Purpose",
              value: overlayItemPurposeToJs(overlayItemPurpose)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("09T1mEVZO", Date.now(), "Overlay Item Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "8gp2_Xor1V",
              name: "Overlay Item Type",
              value: overlayItemType
            },
            {
              id: "L-r5eSOznH",
              name: "Overlay Item Name",
              value: overlayItemNameToJs(overlayItemName)
            },
            {
              id: "UNwmPXwRx7",
              name: "Overlay Item Purpose",
              value: overlayItemPurposeToJs(overlayItemPurpose)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Overlay Item Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Overlay Item Displayed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Overlay Item Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Overlay Item Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Overlay Item Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Overlay Item Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Overlay Item Displayed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Overlay Item Type": overlayItemType,
              "Overlay Item Name": overlayItemNameToJs(overlayItemName),
              "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function overlayItemInteraction(schema, overlayItemInteractionType, linkName, overlayItemType, overlayItemName, overlayItemPurpose, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("XNqfNJP5FJ", "a24fb0cc9d0a2b24e3a34089a15f322b52cc7b3b7c5e0c444978d67dfaa47252", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Overlay Item Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
          "Link Name": Belt_Option.map(linkName, linkNameToJs),
          "Overlay Item Type": overlayItemType,
          "Overlay Item Name": overlayItemNameToJs(overlayItemName),
          "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("XNqfNJP5FJ", "Overlay Item Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mscBrhxP-0",
              name: "Overlay Item Interaction Type",
              value: overlayItemInteractionTypeToJs(overlayItemInteractionType)
            },
            {
              id: "gY8aNKPYEh",
              name: "Link Name",
              value: Belt_Option.map(linkName, linkNameToJs)
            },
            {
              id: "8gp2_Xor1V",
              name: "Overlay Item Type",
              value: overlayItemType
            },
            {
              id: "L-r5eSOznH",
              name: "Overlay Item Name",
              value: overlayItemNameToJs(overlayItemName)
            },
            {
              id: "UNwmPXwRx7",
              name: "Overlay Item Purpose",
              value: overlayItemPurposeToJs(overlayItemPurpose)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("XNqfNJP5FJ", Date.now(), "Overlay Item Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mscBrhxP-0",
              name: "Overlay Item Interaction Type",
              value: overlayItemInteractionTypeToJs(overlayItemInteractionType)
            },
            {
              id: "gY8aNKPYEh",
              name: "Link Name",
              value: Belt_Option.map(linkName, linkNameToJs)
            },
            {
              id: "8gp2_Xor1V",
              name: "Overlay Item Type",
              value: overlayItemType
            },
            {
              id: "L-r5eSOznH",
              name: "Overlay Item Name",
              value: overlayItemNameToJs(overlayItemName)
            },
            {
              id: "UNwmPXwRx7",
              name: "Overlay Item Purpose",
              value: overlayItemPurposeToJs(overlayItemPurpose)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Overlay Item Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        "Link Name": Belt_Option.map(linkName, linkNameToJs),
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Overlay Item Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Overlay Item Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        "Link Name": Belt_Option.map(linkName, linkNameToJs),
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Overlay Item Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        "Link Name": Belt_Option.map(linkName, linkNameToJs),
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Overlay Item Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        "Link Name": Belt_Option.map(linkName, linkNameToJs),
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Overlay Item Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        "Link Name": Belt_Option.map(linkName, linkNameToJs),
        "Overlay Item Type": overlayItemType,
        "Overlay Item Name": overlayItemNameToJs(overlayItemName),
        "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Overlay Item Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
              "Link Name": Belt_Option.map(linkName, linkNameToJs),
              "Overlay Item Type": overlayItemType,
              "Overlay Item Name": overlayItemNameToJs(overlayItemName),
              "Overlay Item Purpose": overlayItemPurposeToJs(overlayItemPurpose),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function inspectorFilterUpdated(inspectorEventsViewFilters, schema, inspectorFilterTypeUpdated, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("1bubnt9c-R", "4c64aae8009e41b887749e4222df9c1ba54a06e4a95cef86ae52b9335c9b07e1", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Filter Updated", {
          "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
          "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
          "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
          "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
          "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
          "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
          "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Filter Type Updated": inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("1bubnt9c-R", "Inspector Filter Updated", messages, [
            {
              id: "FRG8vQWyKS",
              name: "Inspector Event Name Filter",
              value: inspectorEventsViewFilters.inspectorEventNameFilter
            },
            {
              id: "efdEIxbEV9",
              name: "Inspector Order",
              value: inspectorEventsViewFilters.inspectorOrder
            },
            {
              id: "DuISKOHRbs",
              name: "Inspector Column Ordered by",
              value: inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy)
            },
            {
              id: "nojyD1ad9T",
              name: "Inspector Source Id Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceIdOrderedBy
            },
            {
              id: "Xr7_C3vsNK",
              name: "Inspector Time Window",
              value: inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEventsViewFilters.inspectorEnvironment
            },
            {
              id: "UF6h33XVe7",
              name: "Inspector Source Name Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceNameOrderedBy
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "yrxcClZqaW",
              name: "Inspector Filter Type Updated",
              value: inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("1bubnt9c-R", Date.now(), "Inspector Filter Updated", messages, [
            {
              id: "FRG8vQWyKS",
              name: "Inspector Event Name Filter",
              value: inspectorEventsViewFilters.inspectorEventNameFilter
            },
            {
              id: "efdEIxbEV9",
              name: "Inspector Order",
              value: inspectorEventsViewFilters.inspectorOrder
            },
            {
              id: "DuISKOHRbs",
              name: "Inspector Column Ordered by",
              value: inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy)
            },
            {
              id: "nojyD1ad9T",
              name: "Inspector Source Id Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceIdOrderedBy
            },
            {
              id: "Xr7_C3vsNK",
              name: "Inspector Time Window",
              value: inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEventsViewFilters.inspectorEnvironment
            },
            {
              id: "UF6h33XVe7",
              name: "Inspector Source Name Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceNameOrderedBy
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "yrxcClZqaW",
              name: "Inspector Filter Type Updated",
              value: inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Filter Updated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Filter Type Updated": inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Filter Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Filter Updated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Filter Type Updated": inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Filter Updated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Filter Type Updated": inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Filter Updated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Filter Type Updated": inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Filter Updated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Filter Type Updated": inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Filter Updated", {
              "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
              "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
              "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
              "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
              "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
              "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
              "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Filter Type Updated": inspectorFilterTypeUpdatedToJs(inspectorFilterTypeUpdated),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function inspectorEventsNavigated(inspectorEventsViewFilters, inspectorTablePosition, schema, numInspectorEvents, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("s6H7pCGPdg", "12448cece77b18d2786bd667376d14d32970cd27975fd4702970cade418ffd90", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Events Navigated", {
          "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
          "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
          "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
          "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
          "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
          "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
          "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
          "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
          "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
          "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
          "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
          "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
          "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Inspector Events": numInspectorEvents,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("s6H7pCGPdg", "Inspector Events Navigated", messages, [
            {
              id: "FRG8vQWyKS",
              name: "Inspector Event Name Filter",
              value: inspectorEventsViewFilters.inspectorEventNameFilter
            },
            {
              id: "efdEIxbEV9",
              name: "Inspector Order",
              value: inspectorEventsViewFilters.inspectorOrder
            },
            {
              id: "DuISKOHRbs",
              name: "Inspector Column Ordered by",
              value: inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy)
            },
            {
              id: "nojyD1ad9T",
              name: "Inspector Source Id Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceIdOrderedBy
            },
            {
              id: "Xr7_C3vsNK",
              name: "Inspector Time Window",
              value: inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEventsViewFilters.inspectorEnvironment
            },
            {
              id: "UF6h33XVe7",
              name: "Inspector Source Name Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceNameOrderedBy
            },
            {
              id: "b9jZkWYHED",
              name: "Inspector Event Name Selected",
              value: inspectorTablePosition.inspectorEventNameSelected
            },
            {
              id: "Li0n6tG60C",
              name: "Inspector Column Selected",
              value: Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs)
            },
            {
              id: "AqlHqOtKOd",
              name: "Inspector Property Name Selected",
              value: inspectorTablePosition.inspectorPropertyNameSelected
            },
            {
              id: "g9wbkafvsc",
              name: "Inspector Source Id Selected",
              value: inspectorTablePosition.inspectorSourceIdSelected
            },
            {
              id: "PKmvgpXhIi",
              name: "Inspector Source Name Selected",
              value: inspectorTablePosition.inspectorSourceNameSelected
            },
            {
              id: "jPYJb5h00",
              name: "Num Properties Not Found on Event in Tracking Plan",
              value: inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "PF97B--TTv",
              name: "Num Inspector Events",
              value: numInspectorEvents
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("s6H7pCGPdg", Date.now(), "Inspector Events Navigated", messages, [
            {
              id: "FRG8vQWyKS",
              name: "Inspector Event Name Filter",
              value: inspectorEventsViewFilters.inspectorEventNameFilter
            },
            {
              id: "efdEIxbEV9",
              name: "Inspector Order",
              value: inspectorEventsViewFilters.inspectorOrder
            },
            {
              id: "DuISKOHRbs",
              name: "Inspector Column Ordered by",
              value: inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy)
            },
            {
              id: "nojyD1ad9T",
              name: "Inspector Source Id Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceIdOrderedBy
            },
            {
              id: "Xr7_C3vsNK",
              name: "Inspector Time Window",
              value: inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEventsViewFilters.inspectorEnvironment
            },
            {
              id: "UF6h33XVe7",
              name: "Inspector Source Name Ordered by",
              value: inspectorEventsViewFilters.inspectorSourceNameOrderedBy
            },
            {
              id: "b9jZkWYHED",
              name: "Inspector Event Name Selected",
              value: inspectorTablePosition.inspectorEventNameSelected
            },
            {
              id: "Li0n6tG60C",
              name: "Inspector Column Selected",
              value: Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs)
            },
            {
              id: "AqlHqOtKOd",
              name: "Inspector Property Name Selected",
              value: inspectorTablePosition.inspectorPropertyNameSelected
            },
            {
              id: "g9wbkafvsc",
              name: "Inspector Source Id Selected",
              value: inspectorTablePosition.inspectorSourceIdSelected
            },
            {
              id: "PKmvgpXhIi",
              name: "Inspector Source Name Selected",
              value: inspectorTablePosition.inspectorSourceNameSelected
            },
            {
              id: "jPYJb5h00",
              name: "Num Properties Not Found on Event in Tracking Plan",
              value: inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "PF97B--TTv",
              name: "Num Inspector Events",
              value: numInspectorEvents
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Events Navigated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Inspector Events": numInspectorEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus
  };
  logEvent("Inspector Events Navigated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Events Navigated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Inspector Events": numInspectorEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Events Navigated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Inspector Events": numInspectorEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Events Navigated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Inspector Events": numInspectorEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Events Navigated", {
        "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
        "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
        "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
        "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
        "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
        "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
        "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
        "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
        "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
        "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
        "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
        "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
        "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Inspector Events": numInspectorEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Events Navigated", {
              "Inspector Event Name Filter": inspectorEventsViewFilters.inspectorEventNameFilter,
              "Inspector Order": inspectorEventsViewFilters.inspectorOrder,
              "Inspector Column Ordered by": inspectorColumnOrderedByToJs(inspectorEventsViewFilters.inspectorColumnOrderedBy),
              "Inspector Source Id Ordered by": inspectorEventsViewFilters.inspectorSourceIdOrderedBy,
              "Inspector Time Window": inspectorTimeWindowToJs(inspectorEventsViewFilters.inspectorTimeWindow),
              "Inspector Environment": inspectorEventsViewFilters.inspectorEnvironment,
              "Inspector Source Name Ordered by": inspectorEventsViewFilters.inspectorSourceNameOrderedBy,
              "Inspector Event Name Selected": inspectorTablePosition.inspectorEventNameSelected,
              "Inspector Column Selected": Belt_Option.map(inspectorTablePosition.inspectorColumnSelected, inspectorColumnSelectedToJs),
              "Inspector Property Name Selected": inspectorTablePosition.inspectorPropertyNameSelected,
              "Inspector Source Id Selected": inspectorTablePosition.inspectorSourceIdSelected,
              "Inspector Source Name Selected": inspectorTablePosition.inspectorSourceNameSelected,
              "Num Properties Not Found on Event in Tracking Plan": inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Inspector Events": numInspectorEvents,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function workspaceSettingUpdated(schema, settingValue, settingName, settingIntValue, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceBranchPermissionsEnabled, workspaceSlackIntegrationsEnabled, workspaceMinimumApprovalRoleRequired, workspaceNumberOfApprovalsRequired) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("byv-XuHi5c", "ceb7f0d84c72786a5b0631f3a55b3ba4b6fa110acdfe8a82d667fdd33563d56a", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Workspace Setting Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Setting Value": Belt_Option.map(settingValue, settingValueToJs),
          "Setting Name": settingNameToJs(settingName),
          "Setting Int Value": settingIntValue,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("byv-XuHi5c", "Workspace Setting Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "qRH2JIhgT_",
              name: "Setting Value",
              value: Belt_Option.map(settingValue, settingValueToJs)
            },
            {
              id: "cG1lRpGF9z",
              name: "Setting Name",
              value: settingNameToJs(settingName)
            },
            {
              id: "zR8j8cSw0R",
              name: "Setting Int Value",
              value: settingIntValue
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "z_Zx8AmXAv",
                  name: "Workspace Branch Permissions Enabled (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceBranchPermissionsEnabled, workspaceBranchPermissionsEnabledToJs)
                },
                {
                  id: "a2Felqfl6_",
                  name: "Workspace Slack Integrations Enabled (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceSlackIntegrationsEnabled, (function (maybe) {
                          return Belt_Array.map(maybe, workspaceSlackIntegrationsEnabledToJs);
                        }))
                },
                {
                  id: "R06Itl4kWr",
                  name: "Workspace Minimum Approval Role Required (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceMinimumApprovalRoleRequired, workspaceMinimumApprovalRoleRequiredToJs)
                },
                {
                  id: "wTrzzyJEZU",
                  name: "Workspace Number of Approvals Required (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumberOfApprovalsRequired
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("byv-XuHi5c", Date.now(), "Workspace Setting Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "qRH2JIhgT_",
              name: "Setting Value",
              value: Belt_Option.map(settingValue, settingValueToJs)
            },
            {
              id: "cG1lRpGF9z",
              name: "Setting Name",
              value: settingNameToJs(settingName)
            },
            {
              id: "zR8j8cSw0R",
              name: "Setting Int Value",
              value: settingIntValue
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Branch Permissions Enabled": Belt_Option.map(workspaceBranchPermissionsEnabled, workspaceBranchPermissionsEnabledToJs),
        "Workspace Slack Integrations Enabled": Belt_Option.map(workspaceSlackIntegrationsEnabled, (function (maybe) {
                return Belt_Array.map(maybe, workspaceSlackIntegrationsEnabledToJs);
              })),
        "Workspace Minimum Approval Role Required": Belt_Option.map(workspaceMinimumApprovalRoleRequired, workspaceMinimumApprovalRoleRequiredToJs),
        "Workspace Number of Approvals Required": workspaceNumberOfApprovalsRequired
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Workspace Setting Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Setting Value": Belt_Option.map(settingValue, settingValueToJs),
        "Setting Name": settingNameToJs(settingName),
        "Setting Int Value": settingIntValue,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Workspace Setting Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Branch Permissions Enabled": Belt_Option.map(workspaceBranchPermissionsEnabled, workspaceBranchPermissionsEnabledToJs),
        "Workspace Slack Integrations Enabled": Belt_Option.map(workspaceSlackIntegrationsEnabled, (function (maybe) {
                return Belt_Array.map(maybe, workspaceSlackIntegrationsEnabledToJs);
              })),
        "Workspace Minimum Approval Role Required": Belt_Option.map(workspaceMinimumApprovalRoleRequired, workspaceMinimumApprovalRoleRequiredToJs),
        "Workspace Number of Approvals Required": workspaceNumberOfApprovalsRequired
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Workspace Setting Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Setting Value": Belt_Option.map(settingValue, settingValueToJs),
        "Setting Name": settingNameToJs(settingName),
        "Setting Int Value": settingIntValue,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Workspace Setting Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Setting Value": Belt_Option.map(settingValue, settingValueToJs),
        "Setting Name": settingNameToJs(settingName),
        "Setting Int Value": settingIntValue,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Branch Permissions Enabled": Belt_Option.map(workspaceBranchPermissionsEnabled, workspaceBranchPermissionsEnabledToJs),
        "Workspace Slack Integrations Enabled": Belt_Option.map(workspaceSlackIntegrationsEnabled, (function (maybe) {
                return Belt_Array.map(maybe, workspaceSlackIntegrationsEnabledToJs);
              })),
        "Workspace Minimum Approval Role Required": Belt_Option.map(workspaceMinimumApprovalRoleRequired, workspaceMinimumApprovalRoleRequiredToJs),
        "Workspace Number of Approvals Required": workspaceNumberOfApprovalsRequired
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Workspace Setting Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Setting Value": Belt_Option.map(settingValue, settingValueToJs),
        "Setting Name": settingNameToJs(settingName),
        "Setting Int Value": settingIntValue,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Branch Permissions Enabled": Belt_Option.map(workspaceBranchPermissionsEnabled, workspaceBranchPermissionsEnabledToJs),
        "Workspace Slack Integrations Enabled": Belt_Option.map(workspaceSlackIntegrationsEnabled, (function (maybe) {
                return Belt_Array.map(maybe, workspaceSlackIntegrationsEnabledToJs);
              })),
        "Workspace Minimum Approval Role Required": Belt_Option.map(workspaceMinimumApprovalRoleRequired, workspaceMinimumApprovalRoleRequiredToJs),
        "Workspace Number of Approvals Required": workspaceNumberOfApprovalsRequired
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Workspace Setting Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Setting Value": Belt_Option.map(settingValue, settingValueToJs),
        "Setting Name": settingNameToJs(settingName),
        "Setting Int Value": settingIntValue,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Branch Permissions Enabled": Belt_Option.map(workspaceBranchPermissionsEnabled, workspaceBranchPermissionsEnabledToJs),
        "Workspace Slack Integrations Enabled": Belt_Option.map(workspaceSlackIntegrationsEnabled, (function (maybe) {
                return Belt_Array.map(maybe, workspaceSlackIntegrationsEnabledToJs);
              })),
        "Workspace Minimum Approval Role Required": Belt_Option.map(workspaceMinimumApprovalRoleRequired, workspaceMinimumApprovalRoleRequiredToJs),
        "Workspace Number of Approvals Required": workspaceNumberOfApprovalsRequired
      });
  return logEventWithGroups("Workspace Setting Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Setting Value": Belt_Option.map(settingValue, settingValueToJs),
              "Setting Name": settingNameToJs(settingName),
              "Setting Int Value": settingIntValue,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchStatusUpdated(schema, branchStatus, branchStatusBefore, numBranchApprovalsRequired, numBranchApprovals, minimumApprovalRoleRequired, associateEventWithBranch, associateEventWithWorkspace, updateGroupPropertiesForBranch, branchGroupBranchStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("A9JKf-e377", "de29d829506973cd1403e2e74515ec193a9a1984b174b2e4bb721da262be4420", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Status Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Branch Status": branchStatus,
          "Branch Status Before": branchStatusBefore,
          "Num Branch Approvals Required": numBranchApprovalsRequired,
          "Num Branch Approvals": numBranchApprovals,
          "Minimum Approval Role Required": minimumApprovalRoleRequired,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("A9JKf-e377", "Branch Status Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fWFcpfq5fO",
              name: "Branch Status",
              value: branchStatus
            },
            {
              id: "WMB2rjAKPg",
              name: "Branch Status Before",
              value: branchStatusBefore
            },
            {
              id: "O9MVK9OPv9",
              name: "Num Branch Approvals Required",
              value: numBranchApprovalsRequired
            },
            {
              id: "A6fFIqgbb",
              name: "Num Branch Approvals",
              value: numBranchApprovals
            },
            {
              id: "JbVdm6KxfI",
              name: "Minimum Approval Role Required",
              value: minimumApprovalRoleRequired
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "JZgOLWGQYL",
                  name: "Branch Status (Branch " + (updateGroupPropertiesForBranch + " group property)"),
                  value: branchGroupBranchStatus
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("A9JKf-e377", Date.now(), "Branch Status Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fWFcpfq5fO",
              name: "Branch Status",
              value: branchStatus
            },
            {
              id: "WMB2rjAKPg",
              name: "Branch Status Before",
              value: branchStatusBefore
            },
            {
              id: "O9MVK9OPv9",
              name: "Num Branch Approvals Required",
              value: numBranchApprovalsRequired
            },
            {
              id: "A6fFIqgbb",
              name: "Num Branch Approvals",
              value: numBranchApprovals
            },
            {
              id: "JbVdm6KxfI",
              name: "Minimum Approval Role Required",
              value: minimumApprovalRoleRequired
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Branch Status Before": branchStatusBefore,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Status Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Branch Status Before": branchStatusBefore,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Branch Status Before": branchStatusBefore,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Branch Status Before": branchStatusBefore,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Status": branchStatus,
        "Branch Status Before": branchStatusBefore,
        "Num Branch Approvals Required": numBranchApprovalsRequired,
        "Num Branch Approvals": numBranchApprovals,
        "Minimum Approval Role Required": minimumApprovalRoleRequired,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Branch", updateGroupPropertiesForBranch, {
        "Branch Status": branchGroupBranchStatus
      });
  return logEventWithGroups("Branch Status Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Branch Status": branchStatus,
              "Branch Status Before": branchStatusBefore,
              "Num Branch Approvals Required": numBranchApprovalsRequired,
              "Num Branch Approvals": numBranchApprovals,
              "Minimum Approval Role Required": minimumApprovalRoleRequired,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function integrationCreated(schema, integrationConfig, schemaCreatedIntegrationsCount, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertSchemaCreatedIntegrationsCount(schemaCreatedIntegrationsCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("isZdw8aXVY", "c5fc804c60a6de5df1dec5415fd098511dcc34ff9625d48ca6f44adbf146f009", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Integration Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
          "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
          "Integration Name": integrationConfig.integrationName,
          "Integration Id": integrationConfig.integrationId,
          "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
          "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("isZdw8aXVY", "Integration Created", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rQJB6hCVLg",
              name: "Integration Type",
              value: Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs)
            },
            {
              id: "ufKzJnGim",
              name: "Integration Filters",
              value: Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs)
            },
            {
              id: "II4Xahkoyw",
              name: "Integration Name",
              value: integrationConfig.integrationName
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationConfig.integrationId
            },
            {
              id: "cAS3onEsHP",
              name: "Integration Payload Format",
              value: Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs)
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("isZdw8aXVY", Date.now(), "Integration Created", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rQJB6hCVLg",
              name: "Integration Type",
              value: Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs)
            },
            {
              id: "ufKzJnGim",
              name: "Integration Filters",
              value: Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs)
            },
            {
              id: "II4Xahkoyw",
              name: "Integration Name",
              value: integrationConfig.integrationName
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationConfig.integrationId
            },
            {
              id: "cAS3onEsHP",
              name: "Integration Payload Format",
              value: Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs)
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Integration Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Integration Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Integration Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Integration Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Integration Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Integration Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Integration Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
              "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
              "Integration Name": integrationConfig.integrationName,
              "Integration Id": integrationConfig.integrationId,
              "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
              "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function integrationInitiated(schema, schemaCreatedIntegrationsCount, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertSchemaCreatedIntegrationsCount(schemaCreatedIntegrationsCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("42JcVh2A_q", "77a43148f0b453072aa9e84a76417a280f943d272aa0c86159488ef10b8965ad", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Integration Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("42JcVh2A_q", "Integration Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("42JcVh2A_q", Date.now(), "Integration Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Integration Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Integration Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Integration Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Integration Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Integration Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Integration Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Integration Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function integrationConfigured(integrationConfig, schema, integrationStage, integrationItemType, schemaCreatedIntegrationsCount, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertSchemaCreatedIntegrationsCount(schemaCreatedIntegrationsCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("wxJ2vuGdXe", "faae36ba5271a344dfcaad629960989e8369f8d81d6a8433f47734ba7dee460e", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Integration Configured", {
          "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
          "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
          "Integration Name": integrationConfig.integrationName,
          "Integration Id": integrationConfig.integrationId,
          "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Integration Stage": integrationStage,
          "Integration Item Type": integrationItemTypeToJs(integrationItemType),
          "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("wxJ2vuGdXe", "Integration Configured", messages$1, [
            {
              id: "rQJB6hCVLg",
              name: "Integration Type",
              value: Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs)
            },
            {
              id: "ufKzJnGim",
              name: "Integration Filters",
              value: Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs)
            },
            {
              id: "II4Xahkoyw",
              name: "Integration Name",
              value: integrationConfig.integrationName
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationConfig.integrationId
            },
            {
              id: "cAS3onEsHP",
              name: "Integration Payload Format",
              value: Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs)
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EGVlEZpjzG",
              name: "Integration Stage",
              value: integrationStage
            },
            {
              id: "LlJWCR5HS1",
              name: "Integration Item Type",
              value: integrationItemTypeToJs(integrationItemType)
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("wxJ2vuGdXe", Date.now(), "Integration Configured", messages$1, [
            {
              id: "rQJB6hCVLg",
              name: "Integration Type",
              value: Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs)
            },
            {
              id: "ufKzJnGim",
              name: "Integration Filters",
              value: Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs)
            },
            {
              id: "II4Xahkoyw",
              name: "Integration Name",
              value: integrationConfig.integrationName
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationConfig.integrationId
            },
            {
              id: "cAS3onEsHP",
              name: "Integration Payload Format",
              value: Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs)
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EGVlEZpjzG",
              name: "Integration Stage",
              value: integrationStage
            },
            {
              id: "LlJWCR5HS1",
              name: "Integration Item Type",
              value: integrationItemTypeToJs(integrationItemType)
            },
            {
              id: "qWYnYb-nad",
              name: "Schema Created Integrations Count",
              value: schemaCreatedIntegrationsCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Integration Configured", {
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Stage": integrationStage,
        "Integration Item Type": integrationItemTypeToJs(integrationItemType),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Integration Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Integration Configured", {
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Stage": integrationStage,
        "Integration Item Type": integrationItemTypeToJs(integrationItemType),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Integration Configured", {
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Stage": integrationStage,
        "Integration Item Type": integrationItemTypeToJs(integrationItemType),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Integration Configured", {
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Stage": integrationStage,
        "Integration Item Type": integrationItemTypeToJs(integrationItemType),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Integration Configured", {
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Stage": integrationStage,
        "Integration Item Type": integrationItemTypeToJs(integrationItemType),
        "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Integration Configured", {
              "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
              "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
              "Integration Name": integrationConfig.integrationName,
              "Integration Id": integrationConfig.integrationId,
              "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Integration Stage": integrationStage,
              "Integration Item Type": integrationItemTypeToJs(integrationItemType),
              "Schema Created Integrations Count": schemaCreatedIntegrationsCount,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function categoryInitiated(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("er_WUUDHof", "f1c164162887d2a27af146b599e0fa4ea196dcb012d9c038628cdd88b2072404", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Category Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("er_WUUDHof", "Category Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("er_WUUDHof", Date.now(), "Category Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Category Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Category Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Category Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Category Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Category Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Category Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Category Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertyConfigured(property, schema, propertyConfiguration, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Xv4LF1C28D", "0f920fcea4f2bd9eb5930a515da5a99a0d9f1d7ecb3dd0394a118266639ce6da", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Property Configured", {
          "Property Id": property.propertyId,
          "Property Name": property.propertyName,
          "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
          "Property Type": propertyTypeToJs(property.propertyType),
          "Correct Case": property.correctCase,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Property Component": propertyComponentToJs(propertyConfiguration.propertyComponent),
          "Property Configure Action": propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction),
          "Property Value Type Before": Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs),
          "Property Presence Type Before": Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs),
          "Property List Toggle After": Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs),
          "Property Presence Type After": Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs),
          "Property Value Type After": Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs),
          "Property List Toggle Before": Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs),
          "Property Value Constraint Type": Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs),
          "Property Presence When in Object Toggle Before": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs),
          "Property Presence When in Object Toggle After": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Xv4LF1C28D", "Property Configured", messages, [
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "KFqeCegASD",
              name: "Property Component",
              value: propertyComponentToJs(propertyConfiguration.propertyComponent)
            },
            {
              id: "dimYVqPdb8",
              name: "Property Configure Action",
              value: propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction)
            },
            {
              id: "dcG5BRhj7d",
              name: "Property Value Type Before",
              value: Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs)
            },
            {
              id: "yDUakkulRG",
              name: "Property Presence Type Before",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs)
            },
            {
              id: "7j4kPQb2fu",
              name: "Property List Toggle After",
              value: Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs)
            },
            {
              id: "2ztahroWVM",
              name: "Property Presence Type After",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs)
            },
            {
              id: "oCSF2FxWjD",
              name: "Property Value Type After",
              value: Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs)
            },
            {
              id: "g88vZNOxcB",
              name: "Property List Toggle Before",
              value: Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs)
            },
            {
              id: "VtECXEKU6",
              name: "Property Value Constraint Type",
              value: Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs)
            },
            {
              id: "lu6tHA6a9Q",
              name: "Property Presence When in Object Toggle Before",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs)
            },
            {
              id: "z3y6xraWIV",
              name: "Property Presence When in Object Toggle After",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Xv4LF1C28D", Date.now(), "Property Configured", messages, [
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: property.propertyId
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: property.propertyName
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(property.namingConvention, namingConventionToJs)
            },
            {
              id: "4cb9784c-ef76-411d-bd3c-7dbcb7567641",
              name: "Property Type",
              value: propertyTypeToJs(property.propertyType)
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: property.correctCase
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "KFqeCegASD",
              name: "Property Component",
              value: propertyComponentToJs(propertyConfiguration.propertyComponent)
            },
            {
              id: "dimYVqPdb8",
              name: "Property Configure Action",
              value: propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction)
            },
            {
              id: "dcG5BRhj7d",
              name: "Property Value Type Before",
              value: Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs)
            },
            {
              id: "yDUakkulRG",
              name: "Property Presence Type Before",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs)
            },
            {
              id: "7j4kPQb2fu",
              name: "Property List Toggle After",
              value: Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs)
            },
            {
              id: "2ztahroWVM",
              name: "Property Presence Type After",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs)
            },
            {
              id: "oCSF2FxWjD",
              name: "Property Value Type After",
              value: Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs)
            },
            {
              id: "g88vZNOxcB",
              name: "Property List Toggle Before",
              value: Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs)
            },
            {
              id: "VtECXEKU6",
              name: "Property Value Constraint Type",
              value: Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs)
            },
            {
              id: "lu6tHA6a9Q",
              name: "Property Presence When in Object Toggle Before",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs)
            },
            {
              id: "z3y6xraWIV",
              name: "Property Presence When in Object Toggle After",
              value: Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Property Configured", {
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Component": propertyComponentToJs(propertyConfiguration.propertyComponent),
        "Property Configure Action": propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction),
        "Property Value Type Before": Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs),
        "Property Presence Type Before": Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs),
        "Property List Toggle After": Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs),
        "Property Presence Type After": Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs),
        "Property Value Type After": Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs),
        "Property List Toggle Before": Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs),
        "Property Value Constraint Type": Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs),
        "Property Presence When in Object Toggle Before": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs),
        "Property Presence When in Object Toggle After": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Property Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Property Configured", {
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Component": propertyComponentToJs(propertyConfiguration.propertyComponent),
        "Property Configure Action": propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction),
        "Property Value Type Before": Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs),
        "Property Presence Type Before": Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs),
        "Property List Toggle After": Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs),
        "Property Presence Type After": Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs),
        "Property Value Type After": Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs),
        "Property List Toggle Before": Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs),
        "Property Value Constraint Type": Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs),
        "Property Presence When in Object Toggle Before": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs),
        "Property Presence When in Object Toggle After": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Property Configured", {
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Component": propertyComponentToJs(propertyConfiguration.propertyComponent),
        "Property Configure Action": propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction),
        "Property Value Type Before": Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs),
        "Property Presence Type Before": Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs),
        "Property List Toggle After": Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs),
        "Property Presence Type After": Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs),
        "Property Value Type After": Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs),
        "Property List Toggle Before": Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs),
        "Property Value Constraint Type": Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs),
        "Property Presence When in Object Toggle Before": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs),
        "Property Presence When in Object Toggle After": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Property Configured", {
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Component": propertyComponentToJs(propertyConfiguration.propertyComponent),
        "Property Configure Action": propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction),
        "Property Value Type Before": Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs),
        "Property Presence Type Before": Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs),
        "Property List Toggle After": Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs),
        "Property Presence Type After": Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs),
        "Property Value Type After": Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs),
        "Property List Toggle Before": Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs),
        "Property Value Constraint Type": Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs),
        "Property Presence When in Object Toggle Before": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs),
        "Property Presence When in Object Toggle After": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Property Configured", {
        "Property Id": property.propertyId,
        "Property Name": property.propertyName,
        "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
        "Property Type": propertyTypeToJs(property.propertyType),
        "Correct Case": property.correctCase,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Property Component": propertyComponentToJs(propertyConfiguration.propertyComponent),
        "Property Configure Action": propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction),
        "Property Value Type Before": Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs),
        "Property Presence Type Before": Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs),
        "Property List Toggle After": Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs),
        "Property Presence Type After": Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs),
        "Property Value Type After": Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs),
        "Property List Toggle Before": Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs),
        "Property Value Constraint Type": Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs),
        "Property Presence When in Object Toggle Before": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs),
        "Property Presence When in Object Toggle After": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Property Configured", {
              "Property Id": property.propertyId,
              "Property Name": property.propertyName,
              "Naming Convention": Belt_Option.map(property.namingConvention, namingConventionToJs),
              "Property Type": propertyTypeToJs(property.propertyType),
              "Correct Case": property.correctCase,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Property Component": propertyComponentToJs(propertyConfiguration.propertyComponent),
              "Property Configure Action": propertyConfigureActionToJs(propertyConfiguration.propertyConfigureAction),
              "Property Value Type Before": Belt_Option.map(propertyConfiguration.propertyValueTypeBefore, propertyValueTypeBeforeToJs),
              "Property Presence Type Before": Belt_Option.map(propertyConfiguration.propertyPresenceTypeBefore, propertyPresenceTypeBeforeToJs),
              "Property List Toggle After": Belt_Option.map(propertyConfiguration.propertyListToggleAfter, propertyListToggleAfterToJs),
              "Property Presence Type After": Belt_Option.map(propertyConfiguration.propertyPresenceTypeAfter, propertyPresenceTypeAfterToJs),
              "Property Value Type After": Belt_Option.map(propertyConfiguration.propertyValueTypeAfter, propertyValueTypeAfterToJs),
              "Property List Toggle Before": Belt_Option.map(propertyConfiguration.propertyListToggleBefore, propertyListToggleBeforeToJs),
              "Property Value Constraint Type": Belt_Option.map(propertyConfiguration.propertyValueConstraintType, propertyValueConstraintTypeToJs),
              "Property Presence When in Object Toggle Before": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleBeforeToJs),
              "Property Presence When in Object Toggle After": Belt_Option.map(propertyConfiguration.propertyPresenceWhenInObjectToggleAfter, propertyPresenceWhenInObjectToggleAfterToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function inspectorApiKeyGenerated(source, generatedAtUnixTime, inspectorApiKeyId, schemaId, schemaName, generatedAtIsoTime, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("iM8sPx-AAv", "60c5e5c9d1648a4890cc14773b6f47769baca348c35b1bcb900732e4c3a6d5b0", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Api Key Generated", {
          "Source Id": source.sourceId,
          "Source Name": source.sourceName,
          "Generated At Unix Time": generatedAtUnixTime,
          "Inspector Api Key Id": inspectorApiKeyId,
          "Schema Id": schemaId,
          "Schema Name": schemaName,
          "Generated At ISO Time": generatedAtIsoTime,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("iM8sPx-AAv", "Inspector Api Key Generated", messages, [
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "_fmsvlH5nD",
              name: "Generated At Unix Time",
              value: generatedAtUnixTime
            },
            {
              id: "xr0h2gN5D7",
              name: "Inspector Api Key Id",
              value: inspectorApiKeyId
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schemaName
            },
            {
              id: "PVueQa-2H",
              name: "Generated At ISO Time",
              value: generatedAtIsoTime
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("iM8sPx-AAv", Date.now(), "Inspector Api Key Generated", messages, [
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "_fmsvlH5nD",
              name: "Generated At Unix Time",
              value: generatedAtUnixTime
            },
            {
              id: "xr0h2gN5D7",
              name: "Inspector Api Key Id",
              value: inspectorApiKeyId
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schemaName
            },
            {
              id: "PVueQa-2H",
              name: "Generated At ISO Time",
              value: generatedAtIsoTime
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Api Key Generated", {
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Generated At Unix Time": generatedAtUnixTime,
        "Inspector Api Key Id": inspectorApiKeyId,
        "Schema Id": schemaId,
        "Schema Name": schemaName,
        "Generated At ISO Time": generatedAtIsoTime,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Api Key Generated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Api Key Generated", {
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Generated At Unix Time": generatedAtUnixTime,
        "Inspector Api Key Id": inspectorApiKeyId,
        "Schema Id": schemaId,
        "Schema Name": schemaName,
        "Generated At ISO Time": generatedAtIsoTime,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Api Key Generated", {
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Generated At Unix Time": generatedAtUnixTime,
        "Inspector Api Key Id": inspectorApiKeyId,
        "Schema Id": schemaId,
        "Schema Name": schemaName,
        "Generated At ISO Time": generatedAtIsoTime,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Api Key Generated", {
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Generated At Unix Time": generatedAtUnixTime,
        "Inspector Api Key Id": inspectorApiKeyId,
        "Schema Id": schemaId,
        "Schema Name": schemaName,
        "Generated At ISO Time": generatedAtIsoTime,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Api Key Generated", {
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Generated At Unix Time": generatedAtUnixTime,
        "Inspector Api Key Id": inspectorApiKeyId,
        "Schema Id": schemaId,
        "Schema Name": schemaName,
        "Generated At ISO Time": generatedAtIsoTime,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Api Key Generated", {
              "Source Id": source.sourceId,
              "Source Name": source.sourceName,
              "Generated At Unix Time": generatedAtUnixTime,
              "Inspector Api Key Id": inspectorApiKeyId,
              "Schema Id": schemaId,
              "Schema Name": schemaName,
              "Generated At ISO Time": generatedAtIsoTime,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function importCallToActionSeen(schema, numEventsInSchema, numPropertiesInSchema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumEventsInSchema(numEventsInSchema));
    var messages$2 = Belt_Array.concat(messages$1, assertNumPropertiesInSchema(numPropertiesInSchema));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Uv-SRxqMhn", "7482ae7bb0c0acabd43b958cccaa793313cd3babbca19860c8c2e823f39aa83d", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Import Call to Action Seen", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Events in Schema": numEventsInSchema,
          "Num Properties in Schema": numPropertiesInSchema,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Uv-SRxqMhn", "Import Call to Action Seen", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Uv-SRxqMhn", Date.now(), "Import Call to Action Seen", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Import Call to Action Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Import Call to Action Seen", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Import Call to Action Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Import Call to Action Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Import Call to Action Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Import Call to Action Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Import Call to Action Seen", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Events in Schema": numEventsInSchema,
              "Num Properties in Schema": numPropertiesInSchema,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function importStarted(schema, numEventsInSchema, numPropertiesInSchema, importStartedLocation, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumEventsInSchema(numEventsInSchema));
    var messages$2 = Belt_Array.concat(messages$1, assertNumPropertiesInSchema(numPropertiesInSchema));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Cz_Yfmh4A9", "3c913abf0c6328653b722c922306042fff19c7b93da58a8ab379f37cdda10c53", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Import Started", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Events in Schema": numEventsInSchema,
          "Num Properties in Schema": numPropertiesInSchema,
          "Import Started Location": importStartedLocationToJs(importStartedLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Cz_Yfmh4A9", "Import Started", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "YVjMV8IaQ",
              name: "Import Started Location",
              value: importStartedLocationToJs(importStartedLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Cz_Yfmh4A9", Date.now(), "Import Started", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "YVjMV8IaQ",
              name: "Import Started Location",
              value: importStartedLocationToJs(importStartedLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Import Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Import Started", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Import Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Import Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Import Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Import Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Import Started", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Events in Schema": numEventsInSchema,
              "Num Properties in Schema": numPropertiesInSchema,
              "Import Started Location": importStartedLocationToJs(importStartedLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function importFileSelected(schema, numEventsInSchema, numPropertiesInSchema, importParserResult, importParserFormat, numImportWarnings, numEventsInImport, numPropertiesInImport, numNewEventsInImport, numUpdatedEventsInImport, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumEventsInSchema(numEventsInSchema));
    var messages$2 = Belt_Array.concat(messages$1, assertNumPropertiesInSchema(numPropertiesInSchema));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("t6RxQcDY71", "c378fc7a3c784be14cb5d50ce08c8563991c14fb49ecec13d32c616c80e7fca1", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Import File Selected", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Events in Schema": numEventsInSchema,
          "Num Properties in Schema": numPropertiesInSchema,
          "Import Parser Result": importParserResult,
          "Import Parser Format": importParserFormat,
          "Num Import Warnings": numImportWarnings,
          "Num Events in Import": numEventsInImport,
          "Num Properties in Import": numPropertiesInImport,
          "Num New Events in Import": numNewEventsInImport,
          "Num Updated Events in Import": numUpdatedEventsInImport,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("t6RxQcDY71", "Import File Selected", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "r0S4FWXLTi",
              name: "Import Parser Result",
              value: importParserResult
            },
            {
              id: "nT1sQiFPvx",
              name: "Import Parser Format",
              value: importParserFormat
            },
            {
              id: "ywH9BtwaAH",
              name: "Num Import Warnings",
              value: numImportWarnings
            },
            {
              id: "6kwfa3GPCf",
              name: "Num Events in Import",
              value: numEventsInImport
            },
            {
              id: "jGJiCq2NYB",
              name: "Num Properties in Import",
              value: numPropertiesInImport
            },
            {
              id: "HkwMgSoFL",
              name: "Num New Events in Import",
              value: numNewEventsInImport
            },
            {
              id: "2F-HzQ3A1l",
              name: "Num Updated Events in Import",
              value: numUpdatedEventsInImport
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("t6RxQcDY71", Date.now(), "Import File Selected", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "r0S4FWXLTi",
              name: "Import Parser Result",
              value: importParserResult
            },
            {
              id: "nT1sQiFPvx",
              name: "Import Parser Format",
              value: importParserFormat
            },
            {
              id: "ywH9BtwaAH",
              name: "Num Import Warnings",
              value: numImportWarnings
            },
            {
              id: "6kwfa3GPCf",
              name: "Num Events in Import",
              value: numEventsInImport
            },
            {
              id: "jGJiCq2NYB",
              name: "Num Properties in Import",
              value: numPropertiesInImport
            },
            {
              id: "HkwMgSoFL",
              name: "Num New Events in Import",
              value: numNewEventsInImport
            },
            {
              id: "2F-HzQ3A1l",
              name: "Num Updated Events in Import",
              value: numUpdatedEventsInImport
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Import File Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Parser Result": importParserResult,
        "Import Parser Format": importParserFormat,
        "Num Import Warnings": numImportWarnings,
        "Num Events in Import": numEventsInImport,
        "Num Properties in Import": numPropertiesInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Import File Selected", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Import File Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Parser Result": importParserResult,
        "Import Parser Format": importParserFormat,
        "Num Import Warnings": numImportWarnings,
        "Num Events in Import": numEventsInImport,
        "Num Properties in Import": numPropertiesInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Import File Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Parser Result": importParserResult,
        "Import Parser Format": importParserFormat,
        "Num Import Warnings": numImportWarnings,
        "Num Events in Import": numEventsInImport,
        "Num Properties in Import": numPropertiesInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Import File Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Parser Result": importParserResult,
        "Import Parser Format": importParserFormat,
        "Num Import Warnings": numImportWarnings,
        "Num Events in Import": numEventsInImport,
        "Num Properties in Import": numPropertiesInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Import File Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Import Parser Result": importParserResult,
        "Import Parser Format": importParserFormat,
        "Num Import Warnings": numImportWarnings,
        "Num Events in Import": numEventsInImport,
        "Num Properties in Import": numPropertiesInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Import File Selected", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Events in Schema": numEventsInSchema,
              "Num Properties in Schema": numPropertiesInSchema,
              "Import Parser Result": importParserResult,
              "Import Parser Format": importParserFormat,
              "Num Import Warnings": numImportWarnings,
              "Num Events in Import": numEventsInImport,
              "Num Properties in Import": numPropertiesInImport,
              "Num New Events in Import": numNewEventsInImport,
              "Num Updated Events in Import": numUpdatedEventsInImport,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function importCompleted(schema, numEventsInSchema, numPropertiesInSchema, numPropertiesInImport, numEventsInImport, numNewEventsInImport, numUpdatedEventsInImport, importStartedLocation, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumEventsInSchema(numEventsInSchema));
    var messages$2 = Belt_Array.concat(messages$1, assertNumPropertiesInSchema(numPropertiesInSchema));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("EFpQ0Oynwp", "cebc8ba863463bc3e782c34f85e6e39fcc5b50e9e453cd307586de0a32536aa3", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Import Completed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Events in Schema": numEventsInSchema,
          "Num Properties in Schema": numPropertiesInSchema,
          "Num Properties in Import": numPropertiesInImport,
          "Num Events in Import": numEventsInImport,
          "Num New Events in Import": numNewEventsInImport,
          "Num Updated Events in Import": numUpdatedEventsInImport,
          "Import Started Location": importStartedLocationToJs(importStartedLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("EFpQ0Oynwp", "Import Completed", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "jGJiCq2NYB",
              name: "Num Properties in Import",
              value: numPropertiesInImport
            },
            {
              id: "6kwfa3GPCf",
              name: "Num Events in Import",
              value: numEventsInImport
            },
            {
              id: "HkwMgSoFL",
              name: "Num New Events in Import",
              value: numNewEventsInImport
            },
            {
              id: "2F-HzQ3A1l",
              name: "Num Updated Events in Import",
              value: numUpdatedEventsInImport
            },
            {
              id: "YVjMV8IaQ",
              name: "Import Started Location",
              value: importStartedLocationToJs(importStartedLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("EFpQ0Oynwp", Date.now(), "Import Completed", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "ZUpmS4p1y",
              name: "Num Properties in Schema",
              value: numPropertiesInSchema
            },
            {
              id: "jGJiCq2NYB",
              name: "Num Properties in Import",
              value: numPropertiesInImport
            },
            {
              id: "6kwfa3GPCf",
              name: "Num Events in Import",
              value: numEventsInImport
            },
            {
              id: "HkwMgSoFL",
              name: "Num New Events in Import",
              value: numNewEventsInImport
            },
            {
              id: "2F-HzQ3A1l",
              name: "Num Updated Events in Import",
              value: numUpdatedEventsInImport
            },
            {
              id: "YVjMV8IaQ",
              name: "Import Started Location",
              value: importStartedLocationToJs(importStartedLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Import Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Properties in Import": numPropertiesInImport,
        "Num Events in Import": numEventsInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus
  };
  logEvent("Import Completed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Import Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Properties in Import": numPropertiesInImport,
        "Num Events in Import": numEventsInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Import Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Properties in Import": numPropertiesInImport,
        "Num Events in Import": numEventsInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Import Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Properties in Import": numPropertiesInImport,
        "Num Events in Import": numEventsInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Import Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events in Schema": numEventsInSchema,
        "Num Properties in Schema": numPropertiesInSchema,
        "Num Properties in Import": numPropertiesInImport,
        "Num Events in Import": numEventsInImport,
        "Num New Events in Import": numNewEventsInImport,
        "Num Updated Events in Import": numUpdatedEventsInImport,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Import Completed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Events in Schema": numEventsInSchema,
              "Num Properties in Schema": numPropertiesInSchema,
              "Num Properties in Import": numPropertiesInImport,
              "Num Events in Import": numEventsInImport,
              "Num New Events in Import": numNewEventsInImport,
              "Num Updated Events in Import": numUpdatedEventsInImport,
              "Import Started Location": importStartedLocationToJs(importStartedLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function addSeatPrompted(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("KnD0DF1PWv", "43bb9cc06123c58c4a6c2095094ce134cc483d3fc9aede18887b4c206970c742", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Add Seat Prompted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("KnD0DF1PWv", "Add Seat Prompted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("KnD0DF1PWv", Date.now(), "Add Seat Prompted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Add Seat Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Add Seat Prompted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Add Seat Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Add Seat Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Add Seat Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Add Seat Prompted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Add Seat Prompted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function addSeatPromptInteraction(schema, addSeatPromptInteraction$1, associateEventWithWorkspace, associateEventWithBranch) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("mwmA0kzw4B", "c9a6524fc710124261bf65be4bc9c4a4b9363978dc2dc58eca5d4d641df350b9", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Add Seat Prompt Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Add Seat Prompt Interaction": addSeatPromptInteraction$1,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("mwmA0kzw4B", "Add Seat Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FDB5Kr05b4",
              name: "Add Seat Prompt Interaction",
              value: addSeatPromptInteraction$1
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("mwmA0kzw4B", Date.now(), "Add Seat Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FDB5Kr05b4",
              name: "Add Seat Prompt Interaction",
              value: addSeatPromptInteraction$1
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Add Seat Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Add Seat Prompt Interaction": addSeatPromptInteraction$1,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Add Seat Prompt Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Add Seat Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Add Seat Prompt Interaction": addSeatPromptInteraction$1,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Add Seat Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Add Seat Prompt Interaction": addSeatPromptInteraction$1,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Add Seat Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Add Seat Prompt Interaction": addSeatPromptInteraction$1,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Add Seat Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Add Seat Prompt Interaction": addSeatPromptInteraction$1,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  return logEventWithGroups("Add Seat Prompt Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Add Seat Prompt Interaction": addSeatPromptInteraction$1,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function subscriptionUpgradeStarted(schema, subscriptionPlan, subscriptionPlanSlug, subscriptionPrice, subscriptionTimeUnit, buttonCopy, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("idoVEeqJE-", "c7278c8f4427ea9c6907d5b89f39a7ade6807743e3fffe37f455a18008b25c3c", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Subscription Upgrade Started", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
          "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
          "Subscription Price": subscriptionPrice,
          "Subscription Time Unit": subscriptionTimeUnit,
          "Button Copy": buttonCopy,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("idoVEeqJE-", "Subscription Upgrade Started", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "BFzJ_-K1pQ",
              name: "Button Copy",
              value: buttonCopy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("idoVEeqJE-", Date.now(), "Subscription Upgrade Started", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "BFzJ_-K1pQ",
              name: "Button Copy",
              value: buttonCopy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Subscription Upgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Subscription Upgrade Started", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Subscription Upgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Subscription Upgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Subscription Upgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Subscription Upgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Subscription Upgrade Started", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
              "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
              "Subscription Price": subscriptionPrice,
              "Subscription Time Unit": subscriptionTimeUnit,
              "Button Copy": buttonCopy,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function subscriptionUpgradeModalInteraction(schema, subscriptionPlan, subscriptionPlanSlug, subscriptionPrice, subscriptionTimeUnit, subscriptionUpgradeModalInteraction$1, subscriptionTrackingPlanEditors, subscriptionInspectorEventVolume, subscriptionMonthlyActiveImplementers, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("SUwY6Ked0o", "6ebcace6f9a8bec36c86bec672ba718cc1c13342182d876cb8be1bed1aefb31a", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Subscription Upgrade Modal Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
          "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
          "Subscription Price": subscriptionPrice,
          "Subscription Time Unit": subscriptionTimeUnit,
          "Subscription Upgrade Modal Interaction": subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1),
          "Subscription Tracking Plan Editors": subscriptionTrackingPlanEditors,
          "Subscription Inspector Event Volume": subscriptionInspectorEventVolume,
          "Subscription Monthly Active Implementers": subscriptionMonthlyActiveImplementers,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("SUwY6Ked0o", "Subscription Upgrade Modal Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "83YBDeyRyZ",
              name: "Subscription Upgrade Modal Interaction",
              value: subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1)
            },
            {
              id: "Fj30XMMGu",
              name: "Subscription Tracking Plan Editors",
              value: subscriptionTrackingPlanEditors
            },
            {
              id: "G2cJT2iJ0",
              name: "Subscription Inspector Event Volume",
              value: subscriptionInspectorEventVolume
            },
            {
              id: "TVFyRNa72",
              name: "Subscription Monthly Active Implementers",
              value: subscriptionMonthlyActiveImplementers
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("SUwY6Ked0o", Date.now(), "Subscription Upgrade Modal Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "83YBDeyRyZ",
              name: "Subscription Upgrade Modal Interaction",
              value: subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1)
            },
            {
              id: "Fj30XMMGu",
              name: "Subscription Tracking Plan Editors",
              value: subscriptionTrackingPlanEditors
            },
            {
              id: "G2cJT2iJ0",
              name: "Subscription Inspector Event Volume",
              value: subscriptionInspectorEventVolume
            },
            {
              id: "TVFyRNa72",
              name: "Subscription Monthly Active Implementers",
              value: subscriptionMonthlyActiveImplementers
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Subscription Upgrade Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Subscription Upgrade Modal Interaction": subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1),
        "Subscription Tracking Plan Editors": subscriptionTrackingPlanEditors,
        "Subscription Inspector Event Volume": subscriptionInspectorEventVolume,
        "Subscription Monthly Active Implementers": subscriptionMonthlyActiveImplementers,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Subscription Upgrade Modal Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Subscription Upgrade Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Subscription Upgrade Modal Interaction": subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1),
        "Subscription Tracking Plan Editors": subscriptionTrackingPlanEditors,
        "Subscription Inspector Event Volume": subscriptionInspectorEventVolume,
        "Subscription Monthly Active Implementers": subscriptionMonthlyActiveImplementers,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Subscription Upgrade Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Subscription Upgrade Modal Interaction": subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1),
        "Subscription Tracking Plan Editors": subscriptionTrackingPlanEditors,
        "Subscription Inspector Event Volume": subscriptionInspectorEventVolume,
        "Subscription Monthly Active Implementers": subscriptionMonthlyActiveImplementers,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Subscription Upgrade Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Subscription Upgrade Modal Interaction": subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1),
        "Subscription Tracking Plan Editors": subscriptionTrackingPlanEditors,
        "Subscription Inspector Event Volume": subscriptionInspectorEventVolume,
        "Subscription Monthly Active Implementers": subscriptionMonthlyActiveImplementers,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Subscription Upgrade Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Subscription Upgrade Modal Interaction": subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1),
        "Subscription Tracking Plan Editors": subscriptionTrackingPlanEditors,
        "Subscription Inspector Event Volume": subscriptionInspectorEventVolume,
        "Subscription Monthly Active Implementers": subscriptionMonthlyActiveImplementers,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Subscription Upgrade Modal Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
              "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
              "Subscription Price": subscriptionPrice,
              "Subscription Time Unit": subscriptionTimeUnit,
              "Subscription Upgrade Modal Interaction": subscriptionUpgradeModalInteractionToJs(subscriptionUpgradeModalInteraction$1),
              "Subscription Tracking Plan Editors": subscriptionTrackingPlanEditors,
              "Subscription Inspector Event Volume": subscriptionInspectorEventVolume,
              "Subscription Monthly Active Implementers": subscriptionMonthlyActiveImplementers,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function subscriptionDowngradeStarted(schema, subscriptionPlan, subscriptionPlanSlug, subscriptionPrice, subscriptionTimeUnit, buttonCopy, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("xqg_qq_bAi", "716a4d6d75bda5c7596cdd0a916e93a71cc21837e5ad5e5d8044593731b94544", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Subscription Downgrade Started", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
          "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
          "Subscription Price": subscriptionPrice,
          "Subscription Time Unit": subscriptionTimeUnit,
          "Button Copy": buttonCopy,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("xqg_qq_bAi", "Subscription Downgrade Started", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "BFzJ_-K1pQ",
              name: "Button Copy",
              value: buttonCopy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("xqg_qq_bAi", Date.now(), "Subscription Downgrade Started", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "BFzJ_-K1pQ",
              name: "Button Copy",
              value: buttonCopy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Subscription Downgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Subscription Downgrade Started", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Subscription Downgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Subscription Downgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Subscription Downgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Subscription Downgrade Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        "Button Copy": buttonCopy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Subscription Downgrade Started", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
              "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
              "Subscription Price": subscriptionPrice,
              "Subscription Time Unit": subscriptionTimeUnit,
              "Button Copy": buttonCopy,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function onboardingChecklistOpened(schema, checklistType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("aRM3fByi0W", "0f7c34400bb4f0ebada24825bb9fc69d28f2a83854ddd5c947c0e510e6a1a900", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Type": checklistTypeToJs(checklistType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("aRM3fByi0W", "Onboarding Checklist Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("aRM3fByi0W", Date.now(), "Onboarding Checklist Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Onboarding Checklist Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Type": checklistTypeToJs(checklistType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function onboardingChecklistStepDetailsOpened(schema, checklistStepName, checklistStepStatus, checklistType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("bkpmbB1ydx", "7c6d90bfeab3b008fd545260779f829b7af2e1b79e1eea5092b296773eac026d", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Step Details Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Step Name": checklistStepNameToJs(checklistStepName),
          "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
          "Checklist Type": checklistTypeToJs(checklistType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("bkpmbB1ydx", "Onboarding Checklist Step Details Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5mB-XJ_FJ",
              name: "Checklist Step Name",
              value: checklistStepNameToJs(checklistStepName)
            },
            {
              id: "YJVggq7yhl",
              name: "Checklist Step Status",
              value: checklistStepStatusToJs(checklistStepStatus)
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("bkpmbB1ydx", Date.now(), "Onboarding Checklist Step Details Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5mB-XJ_FJ",
              name: "Checklist Step Name",
              value: checklistStepNameToJs(checklistStepName)
            },
            {
              id: "YJVggq7yhl",
              name: "Checklist Step Status",
              value: checklistStepStatusToJs(checklistStepStatus)
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Step Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Step Details Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Step Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Step Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Step Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Step Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Onboarding Checklist Step Details Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Step Name": checklistStepNameToJs(checklistStepName),
              "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
              "Checklist Type": checklistTypeToJs(checklistType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function onboardingChecklistStepDetailsInteraction(schema, checklistStepName, checklistStepStatus, checklistStepDetailsInteractionType, checklistStepDetailsInteractionName, checklistType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("nPN0gsCXrA", "0380af41efb3d67f1fb302792ef99ebc2bde5a66ea4542d4731424db0990dfd7", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Step Details Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Step Name": checklistStepNameToJs(checklistStepName),
          "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
          "Checklist Step Details Interaction Type": checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType),
          "Checklist Step Details Interaction Name": checklistStepDetailsInteractionName,
          "Checklist Type": checklistTypeToJs(checklistType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("nPN0gsCXrA", "Onboarding Checklist Step Details Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5mB-XJ_FJ",
              name: "Checklist Step Name",
              value: checklistStepNameToJs(checklistStepName)
            },
            {
              id: "YJVggq7yhl",
              name: "Checklist Step Status",
              value: checklistStepStatusToJs(checklistStepStatus)
            },
            {
              id: "ACdnPVgUwn",
              name: "Checklist Step Details Interaction Type",
              value: checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType)
            },
            {
              id: "ERDvQOFIgf",
              name: "Checklist Step Details Interaction Name",
              value: checklistStepDetailsInteractionName
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("nPN0gsCXrA", Date.now(), "Onboarding Checklist Step Details Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5mB-XJ_FJ",
              name: "Checklist Step Name",
              value: checklistStepNameToJs(checklistStepName)
            },
            {
              id: "YJVggq7yhl",
              name: "Checklist Step Status",
              value: checklistStepStatusToJs(checklistStepStatus)
            },
            {
              id: "ACdnPVgUwn",
              name: "Checklist Step Details Interaction Type",
              value: checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType)
            },
            {
              id: "ERDvQOFIgf",
              name: "Checklist Step Details Interaction Name",
              value: checklistStepDetailsInteractionName
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Step Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Step Details Interaction Type": checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType),
        "Checklist Step Details Interaction Name": checklistStepDetailsInteractionName,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Step Details Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Step Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Step Details Interaction Type": checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType),
        "Checklist Step Details Interaction Name": checklistStepDetailsInteractionName,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Step Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Step Details Interaction Type": checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType),
        "Checklist Step Details Interaction Name": checklistStepDetailsInteractionName,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Step Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Step Details Interaction Type": checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType),
        "Checklist Step Details Interaction Name": checklistStepDetailsInteractionName,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Step Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Step Name": checklistStepNameToJs(checklistStepName),
        "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
        "Checklist Step Details Interaction Type": checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType),
        "Checklist Step Details Interaction Name": checklistStepDetailsInteractionName,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Onboarding Checklist Step Details Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Step Name": checklistStepNameToJs(checklistStepName),
              "Checklist Step Status": checklistStepStatusToJs(checklistStepStatus),
              "Checklist Step Details Interaction Type": checklistStepDetailsInteractionTypeToJs(checklistStepDetailsInteractionType),
              "Checklist Step Details Interaction Name": checklistStepDetailsInteractionName,
              "Checklist Type": checklistTypeToJs(checklistType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function onboardingChecklistClosed(schema, checklistCloseLocation, checklistType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("1VQ9N1kGoD", "9b23e9af037b78960fda001ede16d2f69a4ed25e33dcb3ac3c389e32d2cf89c1", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Closed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Close Location": checklistCloseLocationToJs(checklistCloseLocation),
          "Checklist Type": checklistTypeToJs(checklistType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("1VQ9N1kGoD", "Onboarding Checklist Closed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "CW0i1zXZ4u",
              name: "Checklist Close Location",
              value: checklistCloseLocationToJs(checklistCloseLocation)
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("1VQ9N1kGoD", Date.now(), "Onboarding Checklist Closed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "CW0i1zXZ4u",
              name: "Checklist Close Location",
              value: checklistCloseLocationToJs(checklistCloseLocation)
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Close Location": checklistCloseLocationToJs(checklistCloseLocation),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Closed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Close Location": checklistCloseLocationToJs(checklistCloseLocation),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Close Location": checklistCloseLocationToJs(checklistCloseLocation),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Close Location": checklistCloseLocationToJs(checklistCloseLocation),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Closed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Close Location": checklistCloseLocationToJs(checklistCloseLocation),
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Onboarding Checklist Closed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Close Location": checklistCloseLocationToJs(checklistCloseLocation),
              "Checklist Type": checklistTypeToJs(checklistType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function onboardingChecklistDismissed(schema, checklistStepsLeft, numChecklistStepsLeft, checklistType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumChecklistStepsLeft(numChecklistStepsLeft));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("4qpOyujZW0", "8a769849f9812f38d41f131cbfc6a9ec8650ec014e1a93dabfdea9b6c433b461", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Dismissed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
          "Num Checklist Steps Left": numChecklistStepsLeft,
          "Checklist Type": checklistTypeToJs(checklistType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("4qpOyujZW0", "Onboarding Checklist Dismissed", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "G1a4oEFQG",
              name: "Checklist Steps Left",
              value: Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs)
            },
            {
              id: "GDZiM7GiK",
              name: "Num Checklist Steps Left",
              value: numChecklistStepsLeft
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("4qpOyujZW0", Date.now(), "Onboarding Checklist Dismissed", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "G1a4oEFQG",
              name: "Checklist Steps Left",
              value: Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs)
            },
            {
              id: "GDZiM7GiK",
              name: "Num Checklist Steps Left",
              value: numChecklistStepsLeft
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Dismissed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Dismissed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Dismissed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Dismissed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Dismissed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Dismissed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
        "Num Checklist Steps Left": numChecklistStepsLeft,
        "Checklist Type": checklistTypeToJs(checklistType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Onboarding Checklist Dismissed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Steps Left": Belt_Array.map(checklistStepsLeft, checklistStepsLeftToJs),
              "Num Checklist Steps Left": numChecklistStepsLeft,
              "Checklist Type": checklistTypeToJs(checklistType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function implementationInstructionInteraction(schema, implementationInstructionInteraction$1, implementationInstructionFormat, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("FQYCuWUO-E", "0ae6e7df8382f07ffd30d8635715aae592c18057de8a436587f0c11fcacb43c4", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Implementation Instruction Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Implementation Instruction Interaction": implementationInstructionInteractionToJs(implementationInstructionInteraction$1),
          "Implementation Instruction Format": implementationInstructionFormatToJs(implementationInstructionFormat),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("FQYCuWUO-E", "Implementation Instruction Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "JquzUUXfq",
              name: "Implementation Instruction Interaction",
              value: implementationInstructionInteractionToJs(implementationInstructionInteraction$1)
            },
            {
              id: "kJwkdEqsI",
              name: "Implementation Instruction Format",
              value: implementationInstructionFormatToJs(implementationInstructionFormat)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("FQYCuWUO-E", Date.now(), "Implementation Instruction Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "JquzUUXfq",
              name: "Implementation Instruction Interaction",
              value: implementationInstructionInteractionToJs(implementationInstructionInteraction$1)
            },
            {
              id: "kJwkdEqsI",
              name: "Implementation Instruction Format",
              value: implementationInstructionFormatToJs(implementationInstructionFormat)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Implementation Instruction Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Implementation Instruction Interaction": implementationInstructionInteractionToJs(implementationInstructionInteraction$1),
        "Implementation Instruction Format": implementationInstructionFormatToJs(implementationInstructionFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Implementation Instruction Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Implementation Instruction Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Implementation Instruction Interaction": implementationInstructionInteractionToJs(implementationInstructionInteraction$1),
        "Implementation Instruction Format": implementationInstructionFormatToJs(implementationInstructionFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Implementation Instruction Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Implementation Instruction Interaction": implementationInstructionInteractionToJs(implementationInstructionInteraction$1),
        "Implementation Instruction Format": implementationInstructionFormatToJs(implementationInstructionFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Implementation Instruction Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Implementation Instruction Interaction": implementationInstructionInteractionToJs(implementationInstructionInteraction$1),
        "Implementation Instruction Format": implementationInstructionFormatToJs(implementationInstructionFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Implementation Instruction Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Implementation Instruction Interaction": implementationInstructionInteractionToJs(implementationInstructionInteraction$1),
        "Implementation Instruction Format": implementationInstructionFormatToJs(implementationInstructionFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Implementation Instruction Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Implementation Instruction Interaction": implementationInstructionInteractionToJs(implementationInstructionInteraction$1),
              "Implementation Instruction Format": implementationInstructionFormatToJs(implementationInstructionFormat),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventsViewConfigured(schema, eventsViewConfiguredItem, eventsViewConfiguredAction, eventsViewColumnName, columnsVisibleAfterChange, numberOfColumnsVisibleAfterChange, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("R_hEDyS-63T", "e7cd796240067f8e1eb47e0d2628be8d84f82ba0fb34b501f731aa82587a1774", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Events View Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Events View Configured Item": eventsViewConfiguredItemToJs(eventsViewConfiguredItem),
          "Events View Configured Action": eventsViewConfiguredActionToJs(eventsViewConfiguredAction),
          "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
          "Columns Visible After Change": Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs),
          "Number of Columns Visible After Change": numberOfColumnsVisibleAfterChange,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("R_hEDyS-63T", "Events View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "p212qlw4Ry5",
              name: "Events View Configured Item",
              value: eventsViewConfiguredItemToJs(eventsViewConfiguredItem)
            },
            {
              id: "lCfyqG-nzmP",
              name: "Events View Configured Action",
              value: eventsViewConfiguredActionToJs(eventsViewConfiguredAction)
            },
            {
              id: "yypiPAuv8r-",
              name: "Events View Column Name",
              value: Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs)
            },
            {
              id: "ookJil-Cbro",
              name: "Columns Visible After Change",
              value: Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs)
            },
            {
              id: "a2cpnK-lqPC",
              name: "Number of Columns Visible After Change",
              value: numberOfColumnsVisibleAfterChange
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("R_hEDyS-63T", Date.now(), "Events View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "p212qlw4Ry5",
              name: "Events View Configured Item",
              value: eventsViewConfiguredItemToJs(eventsViewConfiguredItem)
            },
            {
              id: "lCfyqG-nzmP",
              name: "Events View Configured Action",
              value: eventsViewConfiguredActionToJs(eventsViewConfiguredAction)
            },
            {
              id: "yypiPAuv8r-",
              name: "Events View Column Name",
              value: Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs)
            },
            {
              id: "ookJil-Cbro",
              name: "Columns Visible After Change",
              value: Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs)
            },
            {
              id: "a2cpnK-lqPC",
              name: "Number of Columns Visible After Change",
              value: numberOfColumnsVisibleAfterChange
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Events View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Configured Item": eventsViewConfiguredItemToJs(eventsViewConfiguredItem),
        "Events View Configured Action": eventsViewConfiguredActionToJs(eventsViewConfiguredAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Columns Visible After Change": Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs),
        "Number of Columns Visible After Change": numberOfColumnsVisibleAfterChange,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Events View Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Events View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Configured Item": eventsViewConfiguredItemToJs(eventsViewConfiguredItem),
        "Events View Configured Action": eventsViewConfiguredActionToJs(eventsViewConfiguredAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Columns Visible After Change": Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs),
        "Number of Columns Visible After Change": numberOfColumnsVisibleAfterChange,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Events View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Configured Item": eventsViewConfiguredItemToJs(eventsViewConfiguredItem),
        "Events View Configured Action": eventsViewConfiguredActionToJs(eventsViewConfiguredAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Columns Visible After Change": Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs),
        "Number of Columns Visible After Change": numberOfColumnsVisibleAfterChange,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Events View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Configured Item": eventsViewConfiguredItemToJs(eventsViewConfiguredItem),
        "Events View Configured Action": eventsViewConfiguredActionToJs(eventsViewConfiguredAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Columns Visible After Change": Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs),
        "Number of Columns Visible After Change": numberOfColumnsVisibleAfterChange,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Events View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Configured Item": eventsViewConfiguredItemToJs(eventsViewConfiguredItem),
        "Events View Configured Action": eventsViewConfiguredActionToJs(eventsViewConfiguredAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Columns Visible After Change": Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs),
        "Number of Columns Visible After Change": numberOfColumnsVisibleAfterChange,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Events View Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Events View Configured Item": eventsViewConfiguredItemToJs(eventsViewConfiguredItem),
              "Events View Configured Action": eventsViewConfiguredActionToJs(eventsViewConfiguredAction),
              "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
              "Columns Visible After Change": Belt_Array.map(columnsVisibleAfterChange, columnsVisibleAfterChangeToJs),
              "Number of Columns Visible After Change": numberOfColumnsVisibleAfterChange,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function implementationStatusDetailsViewed(schema, source, eventId, eventName, highlightedImplementationStatusType, highlightedImplementationStatusState, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("NV_3HNjUnT-", "6d4d0c79d0637c0c523a683eaebc2615dfa24c7ea9212634a8e5ae65a5738a8d", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Implementation Status Details Viewed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Source Id": source.sourceId,
          "Source Name": source.sourceName,
          "Event Id": eventId,
          "Event Name": eventName,
          "Highlighted Implementation Status Type": highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType),
          "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("NV_3HNjUnT-", "Implementation Status Details Viewed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "YmxskpRG7B7",
              name: "Highlighted Implementation Status Type",
              value: highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType)
            },
            {
              id: "sWcs4zmma7x",
              name: "Highlighted Implementation Status State",
              value: highlightedImplementationStatusStateToJs(highlightedImplementationStatusState)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("NV_3HNjUnT-", Date.now(), "Implementation Status Details Viewed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "YmxskpRG7B7",
              name: "Highlighted Implementation Status Type",
              value: highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType)
            },
            {
              id: "sWcs4zmma7x",
              name: "Highlighted Implementation Status State",
              value: highlightedImplementationStatusStateToJs(highlightedImplementationStatusState)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Implementation Status Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Highlighted Implementation Status Type": highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType),
        "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {
    "Schema Billing Status": schema.schemaBillingStatus,
    "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState)
  };
  logEvent("Implementation Status Details Viewed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Implementation Status Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Highlighted Implementation Status Type": highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType),
        "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Implementation Status Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Highlighted Implementation Status Type": highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType),
        "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Implementation Status Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Highlighted Implementation Status Type": highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType),
        "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Implementation Status Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Event Id": eventId,
        "Event Name": eventName,
        "Highlighted Implementation Status Type": highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType),
        "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Implementation Status Details Viewed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Source Id": source.sourceId,
              "Source Name": source.sourceName,
              "Event Id": eventId,
              "Event Name": eventName,
              "Highlighted Implementation Status Type": highlightedImplementationStatusTypeToJs(highlightedImplementationStatusType),
              "Highlighted Implementation Status State": highlightedImplementationStatusStateToJs(highlightedImplementationStatusState),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventsViewInteraction(schema, eventsViewColumnName, eventLocation, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("uweoqk8I44o", "eb17562306badb996e0a63568d3a4612e0a525be7e56389aa015e8869f478e70", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Events View Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
          "Event Location": eventLocation,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("uweoqk8I44o", "Events View Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "yypiPAuv8r-",
              name: "Events View Column Name",
              value: Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs)
            },
            {
              id: "SxwJmVV0Z",
              name: "Event Location",
              value: eventLocation
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("uweoqk8I44o", Date.now(), "Events View Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "yypiPAuv8r-",
              name: "Events View Column Name",
              value: Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs)
            },
            {
              id: "SxwJmVV0Z",
              name: "Event Location",
              value: eventLocation
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Events View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Events View Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Events View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Events View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Events View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Events View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Events View Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
              "Event Location": eventLocation,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventsViewPopupInteraction(schema, eventsViewPopupAction, eventsViewColumnName, eventAttribute, eventLocation, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("igCaeAJCKN3", "3dd4d9109e2818658fc015c36c58d8d1d20524ebc2c9744be74a1eb0f0e3c735", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Events View Popup Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Events View Popup Action": eventsViewPopupActionToJs(eventsViewPopupAction),
          "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
          "Event Attribute": eventAttributeToJs(eventAttribute),
          "Event Location": eventLocation,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("igCaeAJCKN3", "Events View Popup Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "65RU_myMHhx",
              name: "Events View Popup Action",
              value: eventsViewPopupActionToJs(eventsViewPopupAction)
            },
            {
              id: "yypiPAuv8r-",
              name: "Events View Column Name",
              value: Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs)
            },
            {
              id: "oU66DSeY4Aa",
              name: "Event Attribute",
              value: eventAttributeToJs(eventAttribute)
            },
            {
              id: "SxwJmVV0Z",
              name: "Event Location",
              value: eventLocation
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("igCaeAJCKN3", Date.now(), "Events View Popup Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "65RU_myMHhx",
              name: "Events View Popup Action",
              value: eventsViewPopupActionToJs(eventsViewPopupAction)
            },
            {
              id: "yypiPAuv8r-",
              name: "Events View Column Name",
              value: Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs)
            },
            {
              id: "oU66DSeY4Aa",
              name: "Event Attribute",
              value: eventAttributeToJs(eventAttribute)
            },
            {
              id: "SxwJmVV0Z",
              name: "Event Location",
              value: eventLocation
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Events View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Popup Action": eventsViewPopupActionToJs(eventsViewPopupAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Attribute": eventAttributeToJs(eventAttribute),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Events View Popup Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Events View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Popup Action": eventsViewPopupActionToJs(eventsViewPopupAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Attribute": eventAttributeToJs(eventAttribute),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Events View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Popup Action": eventsViewPopupActionToJs(eventsViewPopupAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Attribute": eventAttributeToJs(eventAttribute),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Events View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Popup Action": eventsViewPopupActionToJs(eventsViewPopupAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Attribute": eventAttributeToJs(eventAttribute),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Events View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Events View Popup Action": eventsViewPopupActionToJs(eventsViewPopupAction),
        "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
        "Event Attribute": eventAttributeToJs(eventAttribute),
        "Event Location": eventLocation,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Events View Popup Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Events View Popup Action": eventsViewPopupActionToJs(eventsViewPopupAction),
              "Events View Column Name": Belt_Option.map(eventsViewColumnName, eventsViewColumnNameToJs),
              "Event Attribute": eventAttributeToJs(eventAttribute),
              "Event Location": eventLocation,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function inspectorIntegrationInteraction(schema, source, inspectorIntegrationType, inspectorEnvironment, inspectorIntegrationInteractionType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("as4qBoejFJ", "26c882b571ca8440bbc15f8eb6e55aa944da9caa9ba24d7a859e1f70ba7da889", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Integration Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Source Id": source.sourceId,
          "Source Name": source.sourceName,
          "Inspector Integration Type": inspectorIntegrationTypeToJs(inspectorIntegrationType),
          "Inspector Environment": inspectorEnvironment,
          "Inspector Integration Interaction Type": inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("as4qBoejFJ", "Inspector Integration Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "9Yc8_EG_pw",
              name: "Inspector Integration Type",
              value: inspectorIntegrationTypeToJs(inspectorIntegrationType)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEnvironment
            },
            {
              id: "LOuXl79pLdb",
              name: "Inspector Integration Interaction Type",
              value: inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("as4qBoejFJ", Date.now(), "Inspector Integration Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: source.sourceId
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: source.sourceName
            },
            {
              id: "9Yc8_EG_pw",
              name: "Inspector Integration Type",
              value: inspectorIntegrationTypeToJs(inspectorIntegrationType)
            },
            {
              id: "FWQoA4kfXO",
              name: "Inspector Environment",
              value: inspectorEnvironment
            },
            {
              id: "LOuXl79pLdb",
              name: "Inspector Integration Interaction Type",
              value: inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Integration Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Inspector Integration Type": inspectorIntegrationTypeToJs(inspectorIntegrationType),
        "Inspector Environment": inspectorEnvironment,
        "Inspector Integration Interaction Type": inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Integration Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Integration Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Inspector Integration Type": inspectorIntegrationTypeToJs(inspectorIntegrationType),
        "Inspector Environment": inspectorEnvironment,
        "Inspector Integration Interaction Type": inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Integration Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Inspector Integration Type": inspectorIntegrationTypeToJs(inspectorIntegrationType),
        "Inspector Environment": inspectorEnvironment,
        "Inspector Integration Interaction Type": inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Integration Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Inspector Integration Type": inspectorIntegrationTypeToJs(inspectorIntegrationType),
        "Inspector Environment": inspectorEnvironment,
        "Inspector Integration Interaction Type": inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Integration Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Source Id": source.sourceId,
        "Source Name": source.sourceName,
        "Inspector Integration Type": inspectorIntegrationTypeToJs(inspectorIntegrationType),
        "Inspector Environment": inspectorEnvironment,
        "Inspector Integration Interaction Type": inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Integration Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Source Id": source.sourceId,
              "Source Name": source.sourceName,
              "Inspector Integration Type": inspectorIntegrationTypeToJs(inspectorIntegrationType),
              "Inspector Environment": inspectorEnvironment,
              "Inspector Integration Interaction Type": inspectorIntegrationInteractionTypeToJs(inspectorIntegrationInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function jobFunctionPromptDisplayed(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ovG_Qsb-dp", "7d5d29b6c597d2b0794dbf1d436fd19a6eac7932946d2678b2d5e73829b2e4d9", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Job Function Prompt Displayed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ovG_Qsb-dp", "Job Function Prompt Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ovG_Qsb-dp", Date.now(), "Job Function Prompt Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Job Function Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Job Function Prompt Displayed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Job Function Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Job Function Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Job Function Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Job Function Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Job Function Prompt Displayed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function jobFunctionPromptInteraction(schema, jobFunctionPromptInteractionType, jobFunction, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("-j7zvHPcx", "b2e104670e55d2eea4fb93d8183aa15fd9f24c9d990b6da405155abbcb329ace", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Job Function Prompt Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Job Function Prompt Interaction Type": jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType),
          "Job Function": Belt_Option.map(jobFunction, jobFunctionToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("-j7zvHPcx", "Job Function Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "CeBJSIED9",
              name: "Job Function Prompt Interaction Type",
              value: jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType)
            },
            {
              id: "fwPvkvAgU",
              name: "Job Function",
              value: Belt_Option.map(jobFunction, jobFunctionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("-j7zvHPcx", Date.now(), "Job Function Prompt Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "CeBJSIED9",
              name: "Job Function Prompt Interaction Type",
              value: jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType)
            },
            {
              id: "fwPvkvAgU",
              name: "Job Function",
              value: Belt_Option.map(jobFunction, jobFunctionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Job Function Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function Prompt Interaction Type": jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType),
        "Job Function": Belt_Option.map(jobFunction, jobFunctionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Job Function Prompt Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Job Function Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function Prompt Interaction Type": jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType),
        "Job Function": Belt_Option.map(jobFunction, jobFunctionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Job Function Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function Prompt Interaction Type": jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType),
        "Job Function": Belt_Option.map(jobFunction, jobFunctionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Job Function Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function Prompt Interaction Type": jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType),
        "Job Function": Belt_Option.map(jobFunction, jobFunctionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Job Function Prompt Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function Prompt Interaction Type": jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType),
        "Job Function": Belt_Option.map(jobFunction, jobFunctionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Job Function Prompt Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Job Function Prompt Interaction Type": jobFunctionPromptInteractionTypeToJs(jobFunctionPromptInteractionType),
              "Job Function": Belt_Option.map(jobFunction, jobFunctionToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function jobFunctionPromptCompleted(schema, userId_, jobFunction, userOnboardingJobFunction, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("v01exR4VfH", "39c60d9efc5ec8f4a57f5bfa5c73fbc2d010f85946fba3696df0b4feb0fc7ed7", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Job Function Prompt Completed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Job Function": jobFunctionToJs(jobFunction),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {
          "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
        });
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("v01exR4VfH", "Job Function Prompt Completed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fwPvkvAgU",
              name: "Job Function",
              value: jobFunctionToJs(jobFunction)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([{
                  id: "79mSn_Q6No",
                  name: "User Onboarding Job Function",
                  value: Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
                }], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("v01exR4VfH", Date.now(), "Job Function Prompt Completed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fwPvkvAgU",
              name: "Job Function",
              value: jobFunctionToJs(jobFunction)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], [{
              id: "79mSn_Q6No",
              name: "User Onboarding Job Function",
              value: Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
            }]);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Job Function Prompt Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function": jobFunctionToJs(jobFunction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomUserProperties = {
    "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs).join(",")
  };
  setUserProperties(intercomUserProperties);
  var intercomProperties = {};
  logEvent("Job Function Prompt Completed", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Job Function Prompt Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function": jobFunctionToJs(jobFunction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setUserProperties$1({
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
      });
  logEvent$1("Job Function Prompt Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function": jobFunctionToJs(jobFunction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Job Function Prompt Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function": jobFunctionToJs(jobFunction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).setUserProperties, userId_, {
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Job Function Prompt Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Job Function": jobFunctionToJs(jobFunction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  setUserProperties$2({
        "User Onboarding Job Function": Belt_Array.map(userOnboardingJobFunction, userOnboardingJobFunctionToJs)
      });
  return logEventWithGroups("Job Function Prompt Completed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Job Function": jobFunctionToJs(jobFunction),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function workspaceNameUpdated(schema, updateGroupPropertiesForWorkspace, workspaceName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("OpPpXEC7ib", "eb8f809a4640c90b0b137a25309a41f8fa25a8d7009c1034e6467c97023c2f7b", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Workspace Name Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("OpPpXEC7ib", "Workspace Name Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "EHjllhnus",
                  name: "Workspace Name (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceName
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("OpPpXEC7ib", Date.now(), "Workspace Name Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Name": workspaceName
      });
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Workspace Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Workspace Name Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Name": workspaceName
      });
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Workspace Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Workspace Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Name": workspaceName
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Workspace Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Name": workspaceName
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Workspace Name Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Name": workspaceName
      });
  return logEvent$2("Workspace Name Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function exportSchemaCompleted(schema, exportFormat, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("winnvwGAL", "edc60d6a1e98dd25cb0ef9c25df784f9989928868d91d68f459e873f2999d8dc", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Export Schema Completed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Export Format": exportFormatToJs(exportFormat),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("winnvwGAL", "Export Schema Completed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "UhkPzWxup",
              name: "Export Format",
              value: exportFormatToJs(exportFormat)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("winnvwGAL", Date.now(), "Export Schema Completed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "UhkPzWxup",
              name: "Export Format",
              value: exportFormatToJs(exportFormat)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Export Schema Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Export Schema Completed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Export Schema Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Export Schema Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Export Schema Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Export Schema Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Export Schema Completed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Export Format": exportFormatToJs(exportFormat),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function exportSchemaStarted(schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ZRlC4CBrEw", "407f397cdd41fdaa658586b23c1299424b67fd0833ee4d0c2594ed7b1c8b42e1", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Export Schema Started", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ZRlC4CBrEw", "Export Schema Started", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ZRlC4CBrEw", Date.now(), "Export Schema Started", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Export Schema Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Export Schema Started", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Export Schema Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Export Schema Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Export Schema Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Export Schema Started", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Export Schema Started", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function exportSchemaFormatSelected(schema, exportFormat, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("l1Ftx9DJ_", "80d381d1b0e8c6e7f622485149557ae10b3c26949dfdd66b4ed0c1e1ddf0e3ce", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Export Schema Format Selected", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Export Format": exportFormatToJs(exportFormat),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("l1Ftx9DJ_", "Export Schema Format Selected", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "UhkPzWxup",
              name: "Export Format",
              value: exportFormatToJs(exportFormat)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("l1Ftx9DJ_", Date.now(), "Export Schema Format Selected", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "UhkPzWxup",
              name: "Export Format",
              value: exportFormatToJs(exportFormat)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Export Schema Format Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Export Schema Format Selected", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Export Schema Format Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Export Schema Format Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Export Schema Format Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Export Schema Format Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Export Format": exportFormatToJs(exportFormat),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Export Schema Format Selected", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Export Format": exportFormatToJs(exportFormat),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerInitiated(schema, eventId, eventName, numTriggersOnEvent, eventType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("X7uiE2td7", "5b700d5cc3c20e45f5d007f73a0773a17db3cbfd4f26f1404341a920a5ae5297", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Num Triggers on Event": numTriggersOnEvent,
          "Event Type": eventTypeToJs(eventType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("X7uiE2td7", "Event Trigger Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: eventTypeToJs(eventType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("X7uiE2td7", Date.now(), "Event Trigger Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: eventTypeToJs(eventType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Num Triggers on Event": numTriggersOnEvent,
              "Event Type": eventTypeToJs(eventType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerInitiatedOnEvent(schema, eventId, eventName, numTriggersOnEvent, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("X7uiE2td7.P2-Tk1quK", "f29b9e0e42734763243860987f93b522f7eb513878ce90ff63c17f2b65dc16e4", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Type": "Event",
          "Event Id": eventId,
          "Event Name": eventName,
          "Num Triggers on Event": numTriggersOnEvent,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("X7uiE2td7.P2-Tk1quK", "Event Trigger Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("X7uiE2td7.P2-Tk1quK", Date.now(), "Event Trigger Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Type": "Event",
              "Event Id": eventId,
              "Event Name": eventName,
              "Num Triggers on Event": numTriggersOnEvent,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerInitiatedOnVariant(schema, eventId, eventName, numTriggersOnEvent, eventVariantId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("X7uiE2td7.xzQ2RLuEk", "a0fee3e564af98e7474856447798398aebc17fc0f69aa97c8ca1b0d3e336d554", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Type": "Event Variant",
          "Event Id": eventId,
          "Event Name": eventName,
          "Num Triggers on Event": numTriggersOnEvent,
          "Event Variant Id": eventVariantId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("X7uiE2td7.xzQ2RLuEk", "Event Trigger Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event Variant"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("X7uiE2td7.xzQ2RLuEk", Date.now(), "Event Trigger Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event Variant"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Type": "Event Variant",
              "Event Id": eventId,
              "Event Name": eventName,
              "Num Triggers on Event": numTriggersOnEvent,
              "Event Variant Id": eventVariantId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerConfigured(schema, eventId, eventName, triggerConfigureAction, triggerConfigureItem, numTriggersOnEvent, eventType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("bzNm2n3fC", "97f5965773e0754ed46eb2972d4616ca53921616f3ad1249853f622c339651ba", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
          "Trigger Configure Item": triggerConfigureItem,
          "Num Triggers on Event": numTriggersOnEvent,
          "Event Type": eventTypeToJs(eventType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("bzNm2n3fC", "Event Trigger Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aXvDPnD7tg",
              name: "Trigger Configure Action",
              value: triggerConfigureActionToJs(triggerConfigureAction)
            },
            {
              id: "bPWzHZNlUD",
              name: "Trigger Configure Item",
              value: triggerConfigureItem
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: eventTypeToJs(eventType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("bzNm2n3fC", Date.now(), "Event Trigger Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aXvDPnD7tg",
              name: "Trigger Configure Action",
              value: triggerConfigureActionToJs(triggerConfigureAction)
            },
            {
              id: "bPWzHZNlUD",
              name: "Trigger Configure Item",
              value: triggerConfigureItem
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: eventTypeToJs(eventType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
              "Trigger Configure Item": triggerConfigureItem,
              "Num Triggers on Event": numTriggersOnEvent,
              "Event Type": eventTypeToJs(eventType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerConfiguredOnEvent(schema, eventId, eventName, triggerConfigureAction, triggerConfigureItem, numTriggersOnEvent, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("bzNm2n3fC.-DdJ03H5k", "05e524d35875121ba7fa4595f510928d5553c0e50b4c2c687ee9c0f2216ab51b", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Type": "Event",
          "Event Id": eventId,
          "Event Name": eventName,
          "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
          "Trigger Configure Item": triggerConfigureItem,
          "Num Triggers on Event": numTriggersOnEvent,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("bzNm2n3fC.-DdJ03H5k", "Event Trigger Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aXvDPnD7tg",
              name: "Trigger Configure Action",
              value: triggerConfigureActionToJs(triggerConfigureAction)
            },
            {
              id: "bPWzHZNlUD",
              name: "Trigger Configure Item",
              value: triggerConfigureItem
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("bzNm2n3fC.-DdJ03H5k", Date.now(), "Event Trigger Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aXvDPnD7tg",
              name: "Trigger Configure Action",
              value: triggerConfigureActionToJs(triggerConfigureAction)
            },
            {
              id: "bPWzHZNlUD",
              name: "Trigger Configure Item",
              value: triggerConfigureItem
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Type": "Event",
              "Event Id": eventId,
              "Event Name": eventName,
              "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
              "Trigger Configure Item": triggerConfigureItem,
              "Num Triggers on Event": numTriggersOnEvent,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerConfiguredOnVariant(schema, eventId, eventName, triggerConfigureAction, triggerConfigureItem, numTriggersOnEvent, eventVariantId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("bzNm2n3fC.X0APPWMmM", "ee13ca06d9f0706e83f126797f3440d6853a9cf40a20e9c178525f8754bc9757", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Type": "Event Variant",
          "Event Id": eventId,
          "Event Name": eventName,
          "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
          "Trigger Configure Item": triggerConfigureItem,
          "Num Triggers on Event": numTriggersOnEvent,
          "Event Variant Id": eventVariantId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("bzNm2n3fC.X0APPWMmM", "Event Trigger Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event Variant"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aXvDPnD7tg",
              name: "Trigger Configure Action",
              value: triggerConfigureActionToJs(triggerConfigureAction)
            },
            {
              id: "bPWzHZNlUD",
              name: "Trigger Configure Item",
              value: triggerConfigureItem
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("bzNm2n3fC.X0APPWMmM", Date.now(), "Event Trigger Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event Variant"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "aXvDPnD7tg",
              name: "Trigger Configure Action",
              value: triggerConfigureActionToJs(triggerConfigureAction)
            },
            {
              id: "bPWzHZNlUD",
              name: "Trigger Configure Item",
              value: triggerConfigureItem
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
        "Trigger Configure Item": triggerConfigureItem,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Type": "Event Variant",
              "Event Id": eventId,
              "Event Name": eventName,
              "Trigger Configure Action": triggerConfigureActionToJs(triggerConfigureAction),
              "Trigger Configure Item": triggerConfigureItem,
              "Num Triggers on Event": numTriggersOnEvent,
              "Event Variant Id": eventVariantId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerCreated(schema, eventId, eventName, numTriggerSources, triggerContentType, numTriggersOnEvent, eventType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggerSources(numTriggerSources));
    var messages$2 = Belt_Array.concat(messages$1, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("v0RNShrad", "e17cd345f09ae58574eb2f0d7d5d692c42d5fb14afa748a45faf2a6ca799fb31", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Num Trigger Sources": numTriggerSources,
          "Trigger Content Type": triggerContentType,
          "Num Triggers on Event": numTriggersOnEvent,
          "Event Type": eventTypeToJs(eventType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("v0RNShrad", "Event Trigger Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "UAcH9V5q9Q",
              name: "Num Trigger Sources",
              value: numTriggerSources
            },
            {
              id: "gB525MBGyV",
              name: "Trigger Content Type",
              value: triggerContentType
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: eventTypeToJs(eventType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("v0RNShrad", Date.now(), "Event Trigger Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "UAcH9V5q9Q",
              name: "Num Trigger Sources",
              value: numTriggerSources
            },
            {
              id: "gB525MBGyV",
              name: "Trigger Content Type",
              value: triggerContentType
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: eventTypeToJs(eventType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Type": eventTypeToJs(eventType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Num Trigger Sources": numTriggerSources,
              "Trigger Content Type": triggerContentType,
              "Num Triggers on Event": numTriggersOnEvent,
              "Event Type": eventTypeToJs(eventType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerCreatedOnEvent(schema, eventId, eventName, numTriggerSources, triggerContentType, numTriggersOnEvent, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggerSources(numTriggerSources));
    var messages$2 = Belt_Array.concat(messages$1, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("v0RNShrad.yNrxfVLni", "130cc5fdfa6c22158807ad1eb4a0cefab8fca4fdfd08478717f53d2caf594c08", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Type": "Event",
          "Event Id": eventId,
          "Event Name": eventName,
          "Num Trigger Sources": numTriggerSources,
          "Trigger Content Type": triggerContentType,
          "Num Triggers on Event": numTriggersOnEvent,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("v0RNShrad.yNrxfVLni", "Event Trigger Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "UAcH9V5q9Q",
              name: "Num Trigger Sources",
              value: numTriggerSources
            },
            {
              id: "gB525MBGyV",
              name: "Trigger Content Type",
              value: triggerContentType
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("v0RNShrad.yNrxfVLni", Date.now(), "Event Trigger Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "UAcH9V5q9Q",
              name: "Num Trigger Sources",
              value: numTriggerSources
            },
            {
              id: "gB525MBGyV",
              name: "Trigger Content Type",
              value: triggerContentType
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Type": "Event",
              "Event Id": eventId,
              "Event Name": eventName,
              "Num Trigger Sources": numTriggerSources,
              "Trigger Content Type": triggerContentType,
              "Num Triggers on Event": numTriggersOnEvent,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function eventTriggerCreatedOnVariant(schema, eventId, eventName, numTriggerSources, triggerContentType, numTriggersOnEvent, eventVariantId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumTriggerSources(numTriggerSources));
    var messages$2 = Belt_Array.concat(messages$1, assertNumTriggersOnEvent(numTriggersOnEvent));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("v0RNShrad.RPZIZCZ1J", "382cab377d6f2840f6d266c6888be97e1c52914dc06f493d2d8e4b080dd0e79a", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Trigger Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Type": "Event Variant",
          "Event Id": eventId,
          "Event Name": eventName,
          "Num Trigger Sources": numTriggerSources,
          "Trigger Content Type": triggerContentType,
          "Num Triggers on Event": numTriggersOnEvent,
          "Event Variant Id": eventVariantId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("v0RNShrad.RPZIZCZ1J", "Event Trigger Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event Variant"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "UAcH9V5q9Q",
              name: "Num Trigger Sources",
              value: numTriggerSources
            },
            {
              id: "gB525MBGyV",
              name: "Trigger Content Type",
              value: triggerContentType
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("v0RNShrad.RPZIZCZ1J", Date.now(), "Event Trigger Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "mVpOCBjOH",
              name: "Event Type",
              value: "Event Variant"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "UAcH9V5q9Q",
              name: "Num Trigger Sources",
              value: numTriggerSources
            },
            {
              id: "gB525MBGyV",
              name: "Trigger Content Type",
              value: triggerContentType
            },
            {
              id: "oJg68u5oqc",
              name: "Num Triggers on Event",
              value: numTriggersOnEvent
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Trigger Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Trigger Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Type": "Event Variant",
        "Event Id": eventId,
        "Event Name": eventName,
        "Num Trigger Sources": numTriggerSources,
        "Trigger Content Type": triggerContentType,
        "Num Triggers on Event": numTriggersOnEvent,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Trigger Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Type": "Event Variant",
              "Event Id": eventId,
              "Event Name": eventName,
              "Num Trigger Sources": numTriggerSources,
              "Trigger Content Type": triggerContentType,
              "Num Triggers on Event": numTriggersOnEvent,
              "Event Variant Id": eventVariantId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertiesViewConfigured(schema, propertiesViewConfiguredAction, propertiesViewSegmentationBefore, propertiesViewSegmentationAfter, propertiesViewColumnName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("9DNVd63gc", "79cf822ea9a998ea6c7a177abd35d633d4c1e798c7424b6841fd791bd41fae1f", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Properties View Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Properties View Configured Action": propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction),
          "Properties View Segmentation Before": Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs),
          "Properties View Segmentation After": Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs),
          "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("9DNVd63gc", "Properties View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "69L-oFLZl",
              name: "Properties View Configured Action",
              value: propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction)
            },
            {
              id: "ciPgyw3F5I",
              name: "Properties View Segmentation Before",
              value: Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs)
            },
            {
              id: "npxPxwikrC",
              name: "Properties View Segmentation After",
              value: Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs)
            },
            {
              id: "x9rpl6YRSm",
              name: "Properties View Column Name",
              value: Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("9DNVd63gc", Date.now(), "Properties View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "69L-oFLZl",
              name: "Properties View Configured Action",
              value: propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction)
            },
            {
              id: "ciPgyw3F5I",
              name: "Properties View Segmentation Before",
              value: Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs)
            },
            {
              id: "npxPxwikrC",
              name: "Properties View Segmentation After",
              value: Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs)
            },
            {
              id: "x9rpl6YRSm",
              name: "Properties View Column Name",
              value: Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Properties View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Configured Action": propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction),
        "Properties View Segmentation Before": Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs),
        "Properties View Segmentation After": Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Properties View Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Properties View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Configured Action": propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction),
        "Properties View Segmentation Before": Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs),
        "Properties View Segmentation After": Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Properties View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Configured Action": propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction),
        "Properties View Segmentation Before": Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs),
        "Properties View Segmentation After": Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Properties View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Configured Action": propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction),
        "Properties View Segmentation Before": Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs),
        "Properties View Segmentation After": Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Properties View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Configured Action": propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction),
        "Properties View Segmentation Before": Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs),
        "Properties View Segmentation After": Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Properties View Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Properties View Configured Action": propertiesViewConfiguredActionToJs(propertiesViewConfiguredAction),
              "Properties View Segmentation Before": Belt_Option.map(propertiesViewSegmentationBefore, propertiesViewSegmentationBeforeToJs),
              "Properties View Segmentation After": Belt_Option.map(propertiesViewSegmentationAfter, propertiesViewSegmentationAfterToJs),
              "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function issueReportConfigOpened(schema, issueReportEventCasing, issueReportPropertyCasing, numSchemaIssues, issueReportConfigsOn, numIssueReportConfigsOn, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, role, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumIssueReportConfigsOn(numIssueReportConfigsOn));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("URQ2G00rI2", "29c9e5f686e906fa005546b21436b076dc3143309cc9fecb2498e7b22846b72a", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Report Config Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
          "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
          "Num Schema Issues": numSchemaIssues,
          "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
          "Num Issue Report Configs On": numIssueReportConfigsOn,
          "Event Naming Conventions Availability": eventNamingConventionsAvailability,
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          "Advanced Event Name Component Count": advancedEventNameComponentCount,
          "Advanced Event Name Component Names": advancedEventNameComponentNames,
          "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                })),
          "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                })),
          "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
          Role: roleToJs(role),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("URQ2G00rI2", "Issue Report Config Opened", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("URQ2G00rI2", Date.now(), "Issue Report Config Opened", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Report Config Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Issue Report Config Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Report Config Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Issue Report Config Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Report Config Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Report Config Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Issue Report Config Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
              "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
              "Num Schema Issues": numSchemaIssues,
              "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
              "Num Issue Report Configs On": numIssueReportConfigsOn,
              "Event Naming Conventions Availability": eventNamingConventionsAvailability,
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              "Advanced Event Name Component Count": advancedEventNameComponentCount,
              "Advanced Event Name Component Names": advancedEventNameComponentNames,
              "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    })),
              "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    })),
              "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
              Role: roleToJs(role),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function issueReportConfigured(schema, issueReportEventCasing, issueReportPropertyCasing, numSchemaIssues, issueReportConfigsOn, numIssueReportConfigsOn, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, issueReportConfigureItem, issueReportConfigureAction, role, associateEventWithWorkspace, associateEventWithBranch, updateGroupPropertiesForWorkspace, workspaceIssueReportConfigsOn, workspaceIssueReportEventCasing, workspaceIssueReportPropertyCasing, workspaceIssueReportEventNamingConvention) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumIssueReportConfigsOn(numIssueReportConfigsOn));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("L4txjbYZHa", "118f17c135f1d135a309e38de549d77f7c1bc08eacefd76fd1128ff6c695dd9b", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Report Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
          "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
          "Num Schema Issues": numSchemaIssues,
          "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
          "Num Issue Report Configs On": numIssueReportConfigsOn,
          "Event Naming Conventions Availability": eventNamingConventionsAvailability,
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          "Advanced Event Name Component Count": advancedEventNameComponentCount,
          "Advanced Event Name Component Names": advancedEventNameComponentNames,
          "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                })),
          "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                })),
          "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
          "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
          "Issue Report Configure Action": issueReportConfigureAction,
          Role: roleToJs(role),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("L4txjbYZHa", "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: issueReportConfigureItemToJs(issueReportConfigureItem)
            },
            {
              id: "fBQpV6UtN",
              name: "Issue Report Configure Action",
              value: issueReportConfigureAction
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "6zbXIloPh3",
                  name: "Workspace Issue Report Configs On (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs)
                },
                {
                  id: "S15d59yOHV",
                  name: "Workspace Issue Report Event Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing)
                },
                {
                  id: "rFbz5q7e7N",
                  name: "Workspace Issue Report Property Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing)
                },
                {
                  id: "VBaA4-FEo",
                  name: "Workspace Issue Report Event Naming Convention (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("L4txjbYZHa", Date.now(), "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: issueReportConfigureItemToJs(issueReportConfigureItem)
            },
            {
              id: "fBQpV6UtN",
              name: "Issue Report Configure Action",
              value: issueReportConfigureAction
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Issue Report Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  return logEventWithGroups("Issue Report Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
              "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
              "Num Schema Issues": numSchemaIssues,
              "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
              "Num Issue Report Configs On": numIssueReportConfigsOn,
              "Event Naming Conventions Availability": eventNamingConventionsAvailability,
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              "Advanced Event Name Component Count": advancedEventNameComponentCount,
              "Advanced Event Name Component Names": advancedEventNameComponentNames,
              "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    })),
              "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    })),
              "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
              "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
              "Issue Report Configure Action": issueReportConfigureAction,
              Role: roleToJs(role),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function issueReportConfiguredCasing(schema, issueReportEventCasing, issueReportPropertyCasing, numSchemaIssues, issueReportConfigsOn, numIssueReportConfigsOn, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, issueReportConfigureItem, issueReportConfigureAction, role, issueReportConfigureCasing, associateEventWithWorkspace, associateEventWithBranch, updateGroupPropertiesForWorkspace, workspaceIssueReportConfigsOn, workspaceIssueReportEventCasing, workspaceIssueReportPropertyCasing, workspaceIssueReportEventNamingConvention) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumIssueReportConfigsOn(numIssueReportConfigsOn));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("L4txjbYZHa.pDLNY6kRE", "207383246fa50223f6d9e65f7ce6f8d52b79d78ba78048a49eb613662c356525", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Report Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
          "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
          "Num Schema Issues": numSchemaIssues,
          "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
          "Num Issue Report Configs On": numIssueReportConfigsOn,
          "Event Naming Conventions Availability": eventNamingConventionsAvailability,
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          "Advanced Event Name Component Count": advancedEventNameComponentCount,
          "Advanced Event Name Component Names": advancedEventNameComponentNames,
          "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                })),
          "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                })),
          "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
          "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
          "Issue Report Configure Action": issueReportConfigureAction,
          Role: roleToJs(role),
          "Issue Report Configure Casing": issueReportConfigureCasingToJs(issueReportConfigureCasing),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("L4txjbYZHa.pDLNY6kRE", "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: issueReportConfigureItemToJs(issueReportConfigureItem)
            },
            {
              id: "fBQpV6UtN",
              name: "Issue Report Configure Action",
              value: issueReportConfigureAction
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "uUq7QkuhU",
              name: "Issue Report Configure Casing",
              value: issueReportConfigureCasingToJs(issueReportConfigureCasing)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "6zbXIloPh3",
                  name: "Workspace Issue Report Configs On (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs)
                },
                {
                  id: "S15d59yOHV",
                  name: "Workspace Issue Report Event Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing)
                },
                {
                  id: "rFbz5q7e7N",
                  name: "Workspace Issue Report Property Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing)
                },
                {
                  id: "VBaA4-FEo",
                  name: "Workspace Issue Report Event Naming Convention (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("L4txjbYZHa.pDLNY6kRE", Date.now(), "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: issueReportConfigureItemToJs(issueReportConfigureItem)
            },
            {
              id: "fBQpV6UtN",
              name: "Issue Report Configure Action",
              value: issueReportConfigureAction
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "uUq7QkuhU",
              name: "Issue Report Configure Casing",
              value: issueReportConfigureCasingToJs(issueReportConfigureCasing)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        "Issue Report Configure Casing": issueReportConfigureCasingToJs(issueReportConfigureCasing),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Issue Report Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        "Issue Report Configure Casing": issueReportConfigureCasingToJs(issueReportConfigureCasing),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        "Issue Report Configure Casing": issueReportConfigureCasingToJs(issueReportConfigureCasing),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        "Issue Report Configure Casing": issueReportConfigureCasingToJs(issueReportConfigureCasing),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        "Issue Report Configure Casing": issueReportConfigureCasingToJs(issueReportConfigureCasing),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  return logEventWithGroups("Issue Report Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
              "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
              "Num Schema Issues": numSchemaIssues,
              "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
              "Num Issue Report Configs On": numIssueReportConfigsOn,
              "Event Naming Conventions Availability": eventNamingConventionsAvailability,
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              "Advanced Event Name Component Count": advancedEventNameComponentCount,
              "Advanced Event Name Component Names": advancedEventNameComponentNames,
              "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    })),
              "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    })),
              "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
              "Issue Report Configure Item": issueReportConfigureItemToJs(issueReportConfigureItem),
              "Issue Report Configure Action": issueReportConfigureAction,
              Role: roleToJs(role),
              "Issue Report Configure Casing": issueReportConfigureCasingToJs(issueReportConfigureCasing),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function issueReportConfiguredBranchBlocking(schema, issueReportEventCasing, issueReportPropertyCasing, numSchemaIssues, issueReportConfigsOn, numIssueReportConfigsOn, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, role, issueReportConfigureBranchEnforcementStatus, associateEventWithWorkspace, associateEventWithBranch, updateGroupPropertiesForWorkspace, workspaceIssueReportConfigsOn, workspaceIssueReportEventCasing, workspaceIssueReportPropertyCasing, workspaceIssueReportEventNamingConvention) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumIssueReportConfigsOn(numIssueReportConfigsOn));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("L4txjbYZHa.edwURnSTS", "eba18d50a04a671988c4c89a18acb3a129a56fcaf03951094a741868030d4923", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Report Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Issue Report Configure Item": "Branch Enforcement Status",
          "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
          "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
          "Num Schema Issues": numSchemaIssues,
          "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
          "Num Issue Report Configs On": numIssueReportConfigsOn,
          "Event Naming Conventions Availability": eventNamingConventionsAvailability,
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          "Advanced Event Name Component Count": advancedEventNameComponentCount,
          "Advanced Event Name Component Names": advancedEventNameComponentNames,
          "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                })),
          "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                  return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                })),
          "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
          Role: roleToJs(role),
          "Issue Report Configure Branch Enforcement Status": issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("L4txjbYZHa.edwURnSTS", "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: "Branch Enforcement Status"
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "Z-pVNpIuQJ",
              name: "Issue Report Configure Branch Enforcement Status",
              value: issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "6zbXIloPh3",
                  name: "Workspace Issue Report Configs On (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs)
                },
                {
                  id: "S15d59yOHV",
                  name: "Workspace Issue Report Event Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing)
                },
                {
                  id: "rFbz5q7e7N",
                  name: "Workspace Issue Report Property Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing)
                },
                {
                  id: "VBaA4-FEo",
                  name: "Workspace Issue Report Event Naming Convention (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("L4txjbYZHa.edwURnSTS", Date.now(), "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: "Branch Enforcement Status"
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    }))
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    }))
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "Z-pVNpIuQJ",
              name: "Issue Report Configure Branch Enforcement Status",
              value: issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Branch Enforcement Status",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        "Issue Report Configure Branch Enforcement Status": issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Issue Report Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Branch Enforcement Status",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        "Issue Report Configure Branch Enforcement Status": issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Branch Enforcement Status",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        "Issue Report Configure Branch Enforcement Status": issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Branch Enforcement Status",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        "Issue Report Configure Branch Enforcement Status": issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Branch Enforcement Status",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
              })),
        "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
              })),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        Role: roleToJs(role),
        "Issue Report Configure Branch Enforcement Status": issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  return logEventWithGroups("Issue Report Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Issue Report Configure Item": "Branch Enforcement Status",
              "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
              "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
              "Num Schema Issues": numSchemaIssues,
              "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
              "Num Issue Report Configs On": numIssueReportConfigsOn,
              "Event Naming Conventions Availability": eventNamingConventionsAvailability,
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              "Advanced Event Name Component Count": advancedEventNameComponentCount,
              "Advanced Event Name Component Names": advancedEventNameComponentNames,
              "Advanced Event Name Component Types": Belt_Option.map(advancedEventNameComponentTypes, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentTypesToJs);
                    })),
              "Advanced Event Name Component Casings": Belt_Option.map(advancedEventNameComponentCasings, (function (maybe) {
                      return Belt_Array.map(maybe, advancedEventNameComponentCasingsToJs);
                    })),
              "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
              Role: roleToJs(role),
              "Issue Report Configure Branch Enforcement Status": issueReportConfigureBranchEnforcementStatusToJs(issueReportConfigureBranchEnforcementStatus),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function issueReportConfiguredAdvancedEventNamingConvention(schema, issueReportEventCasing, issueReportPropertyCasing, numSchemaIssues, issueReportConfigsOn, numIssueReportConfigsOn, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, issueReportConfigureAction, role, associateEventWithWorkspace, associateEventWithBranch, updateGroupPropertiesForWorkspace, workspaceIssueReportConfigsOn, workspaceIssueReportEventCasing, workspaceIssueReportPropertyCasing, workspaceIssueReportEventNamingConvention) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertNumIssueReportConfigsOn(numIssueReportConfigsOn));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("L4txjbYZHa.19tvaBulJ", "d510f2fbeeac1547958e24aac43cf549161fe94e472819be847ad72b6294e7c4", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Issue Report Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Issue Report Configure Item": "Force event casing",
          "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
          "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
          "Num Schema Issues": numSchemaIssues,
          "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
          "Num Issue Report Configs On": numIssueReportConfigsOn,
          "Event Naming Conventions Availability": eventNamingConventionsAvailability,
          "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
          "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
          "Advanced Event Name Component Count": advancedEventNameComponentCount,
          "Advanced Event Name Component Names": advancedEventNameComponentNames,
          "Advanced Event Name Component Types": Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs),
          "Advanced Event Name Component Casings": Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs),
          "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
          "Issue Report Configure Action": issueReportConfigureAction,
          Role: roleToJs(role),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("L4txjbYZHa.19tvaBulJ", "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: "Force event casing"
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs)
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs)
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "fBQpV6UtN",
              name: "Issue Report Configure Action",
              value: issueReportConfigureAction
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "6zbXIloPh3",
                  name: "Workspace Issue Report Configs On (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs)
                },
                {
                  id: "S15d59yOHV",
                  name: "Workspace Issue Report Event Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing)
                },
                {
                  id: "rFbz5q7e7N",
                  name: "Workspace Issue Report Property Casing (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing)
                },
                {
                  id: "VBaA4-FEo",
                  name: "Workspace Issue Report Event Naming Convention (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("L4txjbYZHa.19tvaBulJ", Date.now(), "Issue Report Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "jbVOmkZMCg",
              name: "Issue Report Configure Item",
              value: "Force event casing"
            },
            {
              id: "eqUZawtQB4",
              name: "Issue Report Event Casing",
              value: issueReportEventCasingToJs(issueReportEventCasing)
            },
            {
              id: "aIn7v6G4ga",
              name: "Issue Report Property Casing",
              value: issueReportPropertyCasingToJs(issueReportPropertyCasing)
            },
            {
              id: "TWFV69p3bK",
              name: "Num Schema Issues",
              value: numSchemaIssues
            },
            {
              id: "MiC67IVEB",
              name: "Issue Report Configs On",
              value: Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs)
            },
            {
              id: "Td8i_XgFo",
              name: "Num Issue Report Configs On",
              value: numIssueReportConfigsOn
            },
            {
              id: "Tt2YkpDXD",
              name: "Event Naming Conventions Availability",
              value: eventNamingConventionsAvailability
            },
            {
              id: "MVOpjE2Ji",
              name: "Advanced Event Name Structure Component Names",
              value: advancedEventNameStructureComponentNames
            },
            {
              id: "rN20W8O0S",
              name: "Advanced Event Name Structure Component Config",
              value: advancedEventNameStructureComponentConfig
            },
            {
              id: "7nMmZLmU-",
              name: "Advanced Event Name Component Count",
              value: advancedEventNameComponentCount
            },
            {
              id: "Uxjspthml",
              name: "Advanced Event Name Component Names",
              value: advancedEventNameComponentNames
            },
            {
              id: "uDK9TLfj2",
              name: "Advanced Event Name Component Types",
              value: Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs)
            },
            {
              id: "mGES1FttK",
              name: "Advanced Event Name Component Casings",
              value: Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs)
            },
            {
              id: "dauiij9bJ",
              name: "Issue Report Event Naming Convention",
              value: issueReportEventNamingConventionToJs(issueReportEventNamingConvention)
            },
            {
              id: "fBQpV6UtN",
              name: "Issue Report Configure Action",
              value: issueReportConfigureAction
            },
            {
              id: "c5808f07-e00f-447e-b236-63b1bc5e8f8f",
              name: "Role",
              value: roleToJs(role)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Force event casing",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs),
        "Advanced Event Name Component Casings": Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Issue Report Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Force event casing",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs),
        "Advanced Event Name Component Casings": Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Force event casing",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs),
        "Advanced Event Name Component Casings": Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Force event casing",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs),
        "Advanced Event Name Component Casings": Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Issue Report Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Issue Report Configure Item": "Force event casing",
        "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
        "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
        "Num Schema Issues": numSchemaIssues,
        "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
        "Num Issue Report Configs On": numIssueReportConfigsOn,
        "Event Naming Conventions Availability": eventNamingConventionsAvailability,
        "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
        "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
        "Advanced Event Name Component Count": advancedEventNameComponentCount,
        "Advanced Event Name Component Names": advancedEventNameComponentNames,
        "Advanced Event Name Component Types": Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs),
        "Advanced Event Name Component Casings": Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs),
        "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
        "Issue Report Configure Action": issueReportConfigureAction,
        Role: roleToJs(role),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Issue Report Configs On": Belt_Array.map(workspaceIssueReportConfigsOn, workspaceIssueReportConfigsOnToJs),
        "Workspace Issue Report Event Casing": workspaceIssueReportEventCasingToJs(workspaceIssueReportEventCasing),
        "Workspace Issue Report Property Casing": workspaceIssueReportPropertyCasingToJs(workspaceIssueReportPropertyCasing),
        "Workspace Issue Report Event Naming Convention": workspaceIssueReportEventNamingConventionToJs(workspaceIssueReportEventNamingConvention)
      });
  return logEventWithGroups("Issue Report Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Issue Report Configure Item": "Force event casing",
              "Issue Report Event Casing": issueReportEventCasingToJs(issueReportEventCasing),
              "Issue Report Property Casing": issueReportPropertyCasingToJs(issueReportPropertyCasing),
              "Num Schema Issues": numSchemaIssues,
              "Issue Report Configs On": Belt_Array.map(issueReportConfigsOn, issueReportConfigsOnToJs),
              "Num Issue Report Configs On": numIssueReportConfigsOn,
              "Event Naming Conventions Availability": eventNamingConventionsAvailability,
              "Advanced Event Name Structure Component Names": advancedEventNameStructureComponentNames,
              "Advanced Event Name Structure Component Config": advancedEventNameStructureComponentConfig,
              "Advanced Event Name Component Count": advancedEventNameComponentCount,
              "Advanced Event Name Component Names": advancedEventNameComponentNames,
              "Advanced Event Name Component Types": Belt_Array.map(advancedEventNameComponentTypes, advancedEventNameComponentTypesToJs),
              "Advanced Event Name Component Casings": Belt_Array.map(advancedEventNameComponentCasings, advancedEventNameComponentCasingsToJs),
              "Issue Report Event Naming Convention": issueReportEventNamingConventionToJs(issueReportEventNamingConvention),
              "Issue Report Configure Action": issueReportConfigureAction,
              Role: roleToJs(role),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function cmdPaletteOptionSelected(schema, cmdPalette, cmdPaletteSelectionImpact, cmdPaletteActionSelected, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertCmdPalettePathDepth(cmdPalette.cmdPalettePathDepth));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("cYB4om2Egs", "6b512ab706df7663056ec26bef86b58b69967ef2eeecee0a347f5bfa3217e5dd", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Cmd Palette Option Selected", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
          "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
          "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
          "Cmd Palette Selection Impact": cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact),
          "Cmd Palette Action Selected": cmdPaletteActionSelected,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("cYB4om2Egs", "Cmd Palette Option Selected", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "AvUJvm_M_-",
              name: "Cmd Palette Active Path",
              value: cmdPalette.cmdPaletteActivePath
            },
            {
              id: "DAWbmWhXIl",
              name: "Cmd Palette Path Depth",
              value: cmdPalette.cmdPalettePathDepth
            },
            {
              id: "zBeURyx8jw",
              name: "Cmd Palette Search Term",
              value: cmdPalette.cmdPaletteSearchTerm
            },
            {
              id: "HM6iBWCsP5",
              name: "Cmd Palette Selection Impact",
              value: cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact)
            },
            {
              id: "z_Fnk9q3Q2",
              name: "Cmd Palette Action Selected",
              value: cmdPaletteActionSelected
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("cYB4om2Egs", Date.now(), "Cmd Palette Option Selected", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "AvUJvm_M_-",
              name: "Cmd Palette Active Path",
              value: cmdPalette.cmdPaletteActivePath
            },
            {
              id: "DAWbmWhXIl",
              name: "Cmd Palette Path Depth",
              value: cmdPalette.cmdPalettePathDepth
            },
            {
              id: "zBeURyx8jw",
              name: "Cmd Palette Search Term",
              value: cmdPalette.cmdPaletteSearchTerm
            },
            {
              id: "HM6iBWCsP5",
              name: "Cmd Palette Selection Impact",
              value: cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact)
            },
            {
              id: "z_Fnk9q3Q2",
              name: "Cmd Palette Action Selected",
              value: cmdPaletteActionSelected
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Cmd Palette Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Cmd Palette Selection Impact": cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact),
        "Cmd Palette Action Selected": cmdPaletteActionSelected,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Cmd Palette Option Selected", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Cmd Palette Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Cmd Palette Selection Impact": cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact),
        "Cmd Palette Action Selected": cmdPaletteActionSelected,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Cmd Palette Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Cmd Palette Selection Impact": cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact),
        "Cmd Palette Action Selected": cmdPaletteActionSelected,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Cmd Palette Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Cmd Palette Selection Impact": cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact),
        "Cmd Palette Action Selected": cmdPaletteActionSelected,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Cmd Palette Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Cmd Palette Selection Impact": cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact),
        "Cmd Palette Action Selected": cmdPaletteActionSelected,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Cmd Palette Option Selected", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
              "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
              "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
              "Cmd Palette Selection Impact": cmdPaletteSelectionImpactToJs(cmdPaletteSelectionImpact),
              "Cmd Palette Action Selected": cmdPaletteActionSelected,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function cmdPaletteOpened(schema, cmdPaletteInitialPath, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("TQ0C8XcHwF", "a84c32d71afeebd340ba584e4eb021d8157e468976002debfa49da5b899f4804", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Cmd Palette Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Cmd Palette Initial Path": cmdPaletteInitialPathToJs(cmdPaletteInitialPath),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("TQ0C8XcHwF", "Cmd Palette Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Lwmf51SY5U",
              name: "Cmd Palette Initial Path",
              value: cmdPaletteInitialPathToJs(cmdPaletteInitialPath)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("TQ0C8XcHwF", Date.now(), "Cmd Palette Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "Lwmf51SY5U",
              name: "Cmd Palette Initial Path",
              value: cmdPaletteInitialPathToJs(cmdPaletteInitialPath)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Cmd Palette Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Initial Path": cmdPaletteInitialPathToJs(cmdPaletteInitialPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Cmd Palette Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Cmd Palette Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Initial Path": cmdPaletteInitialPathToJs(cmdPaletteInitialPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Cmd Palette Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Initial Path": cmdPaletteInitialPathToJs(cmdPaletteInitialPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Cmd Palette Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Initial Path": cmdPaletteInitialPathToJs(cmdPaletteInitialPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Cmd Palette Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Cmd Palette Initial Path": cmdPaletteInitialPathToJs(cmdPaletteInitialPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Cmd Palette Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Cmd Palette Initial Path": cmdPaletteInitialPathToJs(cmdPaletteInitialPath),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function cmdPaletteExited(cmdPalette, schema, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertCmdPalettePathDepth(cmdPalette.cmdPalettePathDepth));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("T5qj6oiMw", "625e3a2caec5f4868e05c5cca339a75b304ca9cb12f3b7c42b7f9bf365537544", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Cmd Palette Exited", {
          "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
          "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
          "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("T5qj6oiMw", "Cmd Palette Exited", messages$1, [
            {
              id: "AvUJvm_M_-",
              name: "Cmd Palette Active Path",
              value: cmdPalette.cmdPaletteActivePath
            },
            {
              id: "DAWbmWhXIl",
              name: "Cmd Palette Path Depth",
              value: cmdPalette.cmdPalettePathDepth
            },
            {
              id: "zBeURyx8jw",
              name: "Cmd Palette Search Term",
              value: cmdPalette.cmdPaletteSearchTerm
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("T5qj6oiMw", Date.now(), "Cmd Palette Exited", messages$1, [
            {
              id: "AvUJvm_M_-",
              name: "Cmd Palette Active Path",
              value: cmdPalette.cmdPaletteActivePath
            },
            {
              id: "DAWbmWhXIl",
              name: "Cmd Palette Path Depth",
              value: cmdPalette.cmdPalettePathDepth
            },
            {
              id: "zBeURyx8jw",
              name: "Cmd Palette Search Term",
              value: cmdPalette.cmdPaletteSearchTerm
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Cmd Palette Exited", {
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Cmd Palette Exited", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Cmd Palette Exited", {
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Cmd Palette Exited", {
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Cmd Palette Exited", {
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Cmd Palette Exited", {
        "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
        "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
        "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Cmd Palette Exited", {
              "Cmd Palette Active Path": cmdPalette.cmdPaletteActivePath,
              "Cmd Palette Path Depth": cmdPalette.cmdPalettePathDepth,
              "Cmd Palette Search Term": cmdPalette.cmdPaletteSearchTerm,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function updateNumInspectorProductionSources(updateGroupPropertiesForWorkspace, workspaceNumInspectorProductionSources) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("RrGtLYc6Ez", "49ef1a11ed9c9c66af290b0b0ed7e79097758bf030a87cdfc2e67c270ddf1cd6", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Update Num Inspector Production Sources", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("RrGtLYc6Ez", "Update Num Inspector Production Sources", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "J5gcDUL-a",
                  name: "Workspace Num Inspector Production Sources (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumInspectorProductionSources
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("RrGtLYc6Ez", Date.now(), "Update Num Inspector Production Sources", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (!__AVO_NOOP__.contents) {
    Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Production Sources": workspaceNumInspectorProductionSources
        });
    Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Production Sources": workspaceNumInspectorProductionSources
        });
    Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Production Sources": workspaceNumInspectorProductionSources
        });
    Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Production Sources": workspaceNumInspectorProductionSources
        });
    return setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
                "Workspace Num Inspector Production Sources": workspaceNumInspectorProductionSources
              });
  }
  
}

function updateNumInspectorDevelopmentSources(updateGroupPropertiesForWorkspace, workspaceNumInspectorDevelopmentSources) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("OE1rrRRxuO", "f94072fb4d0cff4401fedac7b4e8c7ccc7e1d27003cc6fa335349c254c28a8ea", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Update Num Inspector Development Sources", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("OE1rrRRxuO", "Update Num Inspector Development Sources", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "DnoCSvB0h",
                  name: "Workspace Num Inspector Development Sources (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumInspectorDevelopmentSources
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("OE1rrRRxuO", Date.now(), "Update Num Inspector Development Sources", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (!__AVO_NOOP__.contents) {
    Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Development Sources": workspaceNumInspectorDevelopmentSources
        });
    Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Development Sources": workspaceNumInspectorDevelopmentSources
        });
    Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Development Sources": workspaceNumInspectorDevelopmentSources
        });
    Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Development Sources": workspaceNumInspectorDevelopmentSources
        });
    return setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
                "Workspace Num Inspector Development Sources": workspaceNumInspectorDevelopmentSources
              });
  }
  
}

function updateNumInspectorStagingSources(updateGroupPropertiesForWorkspace, workspaceNumInspectorStagingSources) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("D14IC13eWL", "39468b09ffdeeb36c7a1be9b0997245d86f15f7a2b024e4b50716c925a5ceb4e", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Update Num Inspector Staging Sources", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("D14IC13eWL", "Update Num Inspector Staging Sources", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "f5plhmWsaA",
                  name: "Workspace Num Inspector Staging Sources (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumInspectorStagingSources
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("D14IC13eWL", Date.now(), "Update Num Inspector Staging Sources", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (!__AVO_NOOP__.contents) {
    Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Staging Sources": workspaceNumInspectorStagingSources
        });
    Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Staging Sources": workspaceNumInspectorStagingSources
        });
    Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Staging Sources": workspaceNumInspectorStagingSources
        });
    Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Num Inspector Staging Sources": workspaceNumInspectorStagingSources
        });
    return setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
                "Workspace Num Inspector Staging Sources": workspaceNumInspectorStagingSources
              });
  }
  
}

function workspaceTrialStarted(updateGroupPropertiesForWorkspace, workspaceTrialStartedAt, workspaceName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ZDCzWWAnpX", "965e889ebc1322bb2f20afe408073151ac4ae6b49bdfd2ee373ced3eea539e97", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Workspace Trial Started", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ZDCzWWAnpX", "Workspace Trial Started", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [
                {
                  id: "xo8m0cdxe_",
                  name: "Workspace Trial Started At (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceTrialStartedAt
                },
                {
                  id: "EHjllhnus",
                  name: "Workspace Name (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceName
                }
              ]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ZDCzWWAnpX", Date.now(), "Workspace Trial Started", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (!__AVO_NOOP__.contents) {
    Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Trial Started At": workspaceTrialStartedAt,
          "Workspace Name": workspaceName
        });
    Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Trial Started At": workspaceTrialStartedAt,
          "Workspace Name": workspaceName
        });
    Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Trial Started_at": workspaceTrialStartedAt,
          "Workspace Name": workspaceName
        });
    Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
          "Workspace Trial Started At": workspaceTrialStartedAt,
          "Workspace Name": workspaceName
        });
    return setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
                "Workspace Trial Started At": workspaceTrialStartedAt,
                "Workspace Name": workspaceName
              });
  }
  
}

function sheetFiltersInteraction(schema, sheetFiltersInteractionType) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("AcwdcsaEMf", "2e3a91ef645043dd0cbeb631bebeb61186f437eef746c18b49a308320ff95584", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sheet Filters Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Sheet Filters Interaction Type": sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("AcwdcsaEMf", "Sheet Filters Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TTCri0aN9S",
              name: "Sheet Filters Interaction Type",
              value: sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("AcwdcsaEMf", Date.now(), "Sheet Filters Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TTCri0aN9S",
              name: "Sheet Filters Interaction Type",
              value: sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sheet Filters Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sheet Filters Interaction Type": sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sheet Filters Interaction", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sheet Filters Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sheet Filters Interaction Type": sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sheet Filters Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sheet Filters Interaction Type": sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sheet Filters Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sheet Filters Interaction Type": sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sheet Filters Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sheet Filters Interaction Type": sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sheet Filters Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Sheet Filters Interaction Type": sheetFiltersInteractionTypeToJs(sheetFiltersInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function inviteRemoved(schema, invitedBy, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("MVRd-AaEpG", "75c86bf471b724574c9973277d5c7ba0c3dd5382f0ab5e294b25ca99149b90b2", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Invite Removed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Invited By": invitedBy,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("MVRd-AaEpG", "Invite Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bba3fd90-d5f9-44c3-94fb-7da0395a7837",
              name: "Invited By",
              value: invitedBy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("MVRd-AaEpG", Date.now(), "Invite Removed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bba3fd90-d5f9-44c3-94fb-7da0395a7837",
              name: "Invited By",
              value: invitedBy
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Invite Removed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Invite Removed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Invited By": invitedBy,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Invite Removed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Invited By": invitedBy,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function duplicateEvent(schema, eventId, eventName, correctCase, namingConvention, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("UfbTpDgTTM", "d36f33313357be6ed0bbff1bfc1effaebee935c28391cae5837e1df963a9aa12", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Duplicate Event", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Correct Case": correctCase,
          "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("UfbTpDgTTM", "Duplicate Event", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("UfbTpDgTTM", Date.now(), "Duplicate Event", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "0Z2MogyD4",
              name: "Correct Case",
              value: correctCase
            },
            {
              id: "32e62547-30a8-4541-95a3-0ed49957ccd5",
              name: "Naming Convention",
              value: Belt_Option.map(namingConvention, namingConventionToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Duplicate Event", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Duplicate Event", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Duplicate Event", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Duplicate Event", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Duplicate Event", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Duplicate Event", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Correct Case": correctCase,
        "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Duplicate Event", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Correct Case": correctCase,
              "Naming Convention": Belt_Option.map(namingConvention, namingConventionToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function auditIssueInteracted(schema, itemId, itemName, itemType, trackingPlanAuditLocation, auditIssueInteractionType, issueType, associateEventWithWorkspace, associateEventWithBranch) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("uLeoOn0Zt", "bbcbec09e5735e89c3a5516f2f892d844740d11d464fcc9e18b3949730e976ba", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Audit Issue Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": Belt_Option.map(itemType, itemTypeToJs),
          "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
          "Audit Issue Interaction Type": auditIssueInteractionTypeToJs(auditIssueInteractionType),
          "Issue Type": issueTypeToJs(issueType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("uLeoOn0Zt", "Audit Issue Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: Belt_Option.map(itemType, itemTypeToJs)
            },
            {
              id: "ndry-ZTdL4",
              name: "Tracking Plan Audit Location",
              value: trackingPlanAuditLocationToJs(trackingPlanAuditLocation)
            },
            {
              id: "RPtSwpQLOe",
              name: "Audit Issue Interaction Type",
              value: auditIssueInteractionTypeToJs(auditIssueInteractionType)
            },
            {
              id: "zh2pil15Lo",
              name: "Issue Type",
              value: issueTypeToJs(issueType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("uLeoOn0Zt", Date.now(), "Audit Issue Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: Belt_Option.map(itemType, itemTypeToJs)
            },
            {
              id: "ndry-ZTdL4",
              name: "Tracking Plan Audit Location",
              value: trackingPlanAuditLocationToJs(trackingPlanAuditLocation)
            },
            {
              id: "RPtSwpQLOe",
              name: "Audit Issue Interaction Type",
              value: auditIssueInteractionTypeToJs(auditIssueInteractionType)
            },
            {
              id: "zh2pil15Lo",
              name: "Issue Type",
              value: issueTypeToJs(issueType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Audit Issue Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": Belt_Option.map(itemType, itemTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Audit Issue Interaction Type": auditIssueInteractionTypeToJs(auditIssueInteractionType),
        "Issue Type": issueTypeToJs(issueType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Audit Issue Interacted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Audit Issue Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": Belt_Option.map(itemType, itemTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Audit Issue Interaction Type": auditIssueInteractionTypeToJs(auditIssueInteractionType),
        "Issue Type": issueTypeToJs(issueType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Audit Issue Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": Belt_Option.map(itemType, itemTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Audit Issue Interaction Type": auditIssueInteractionTypeToJs(auditIssueInteractionType),
        "Issue Type": issueTypeToJs(issueType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Audit Issue Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": Belt_Option.map(itemType, itemTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Audit Issue Interaction Type": auditIssueInteractionTypeToJs(auditIssueInteractionType),
        "Issue Type": issueTypeToJs(issueType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Audit Issue Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": Belt_Option.map(itemType, itemTypeToJs),
        "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
        "Audit Issue Interaction Type": auditIssueInteractionTypeToJs(auditIssueInteractionType),
        "Issue Type": issueTypeToJs(issueType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  return logEventWithGroups("Audit Issue Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": Belt_Option.map(itemType, itemTypeToJs),
              "Tracking Plan Audit Location": trackingPlanAuditLocationToJs(trackingPlanAuditLocation),
              "Audit Issue Interaction Type": auditIssueInteractionTypeToJs(auditIssueInteractionType),
              "Issue Type": issueTypeToJs(issueType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function itemUnarchived(schema, itemId, itemName, itemType, unarchiveLocation, associateEventWithWorkspace, associateEventWithBranch) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("igFzGIiJZX", "09bc1b2e55e402280d1312bf397f8d4aa0e0560df4967d0de48b7888669543da", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Item Unarchived", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Unarchive Location": unarchiveLocationToJs(unarchiveLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("igFzGIiJZX", "Item Unarchived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "6jPuvsqd8",
              name: "Unarchive Location",
              value: unarchiveLocationToJs(unarchiveLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("igFzGIiJZX", Date.now(), "Item Unarchived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "6jPuvsqd8",
              name: "Unarchive Location",
              value: unarchiveLocationToJs(unarchiveLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Item Unarchived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Unarchive Location": unarchiveLocationToJs(unarchiveLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Item Unarchived", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Item Unarchived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Unarchive Location": unarchiveLocationToJs(unarchiveLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Item Unarchived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Unarchive Location": unarchiveLocationToJs(unarchiveLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Item Unarchived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Unarchive Location": unarchiveLocationToJs(unarchiveLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Item Unarchived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Unarchive Location": unarchiveLocationToJs(unarchiveLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  return logEventWithGroups("Item Unarchived", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Unarchive Location": unarchiveLocationToJs(unarchiveLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function promoBannerInteracted(bannerLocation, bannerDestinationPath, path, overlayItemInteractionType) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("bGnnm3OH6", "2d885b5b865b5faf582ede7c2a95edbd07a3b9c2c859a77749389a4dc53bbb34", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Promo Banner Interacted", {
          "Banner Location": bannerLocationToJs(bannerLocation),
          "Banner Destination Path": bannerDestinationPath,
          Path: path,
          "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("bGnnm3OH6", "Promo Banner Interacted", messages, [
            {
              id: "YoHBjZaYfU",
              name: "Banner Location",
              value: bannerLocationToJs(bannerLocation)
            },
            {
              id: "8ythdVUjh1",
              name: "Banner Destination Path",
              value: bannerDestinationPath
            },
            {
              id: "UT9ZlNsN2",
              name: "Path",
              value: path
            },
            {
              id: "mscBrhxP-0",
              name: "Overlay Item Interaction Type",
              value: overlayItemInteractionTypeToJs(overlayItemInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("bGnnm3OH6", Date.now(), "Promo Banner Interacted", messages, [
            {
              id: "YoHBjZaYfU",
              name: "Banner Location",
              value: bannerLocationToJs(bannerLocation)
            },
            {
              id: "8ythdVUjh1",
              name: "Banner Destination Path",
              value: bannerDestinationPath
            },
            {
              id: "UT9ZlNsN2",
              name: "Path",
              value: path
            },
            {
              id: "mscBrhxP-0",
              name: "Overlay Item Interaction Type",
              value: overlayItemInteractionTypeToJs(overlayItemInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Promo Banner Interacted", {
        "Banner Location": bannerLocationToJs(bannerLocation),
        "Banner Destination Path": bannerDestinationPath,
        Path: path,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Promo Banner Interacted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Promo Banner Interacted", {
        "Banner Location": bannerLocationToJs(bannerLocation),
        "Banner Destination Path": bannerDestinationPath,
        Path: path,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Promo Banner Interacted", {
        "Banner Location": bannerLocationToJs(bannerLocation),
        "Banner Destination Path": bannerDestinationPath,
        Path: path,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Promo Banner Interacted", {
        "Banner Location": bannerLocationToJs(bannerLocation),
        "Banner Destination Path": bannerDestinationPath,
        Path: path,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Promo Banner Interacted", {
        "Banner Location": bannerLocationToJs(bannerLocation),
        "Banner Destination Path": bannerDestinationPath,
        Path: path,
        "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Promo Banner Interacted", {
              "Banner Location": bannerLocationToJs(bannerLocation),
              "Banner Destination Path": bannerDestinationPath,
              Path: path,
              "Overlay Item Interaction Type": overlayItemInteractionTypeToJs(overlayItemInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function webhookSecretModalInteraction(schema, integrationConfig, webhookSecretModalInteractionType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("2J5B8lGAii", "1c9b2375f1f62026b751d757a474e5e7f3af5af92a867fea7989c6b222f6d42f", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Webhook Secret Modal Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
          "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
          "Integration Name": integrationConfig.integrationName,
          "Integration Id": integrationConfig.integrationId,
          "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
          "Webhook Secret Modal Interaction Type": webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("2J5B8lGAii", "Webhook Secret Modal Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rQJB6hCVLg",
              name: "Integration Type",
              value: Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs)
            },
            {
              id: "ufKzJnGim",
              name: "Integration Filters",
              value: Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs)
            },
            {
              id: "II4Xahkoyw",
              name: "Integration Name",
              value: integrationConfig.integrationName
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationConfig.integrationId
            },
            {
              id: "cAS3onEsHP",
              name: "Integration Payload Format",
              value: Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs)
            },
            {
              id: "IW5IZHYNSH",
              name: "Webhook Secret Modal Interaction Type",
              value: webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("2J5B8lGAii", Date.now(), "Webhook Secret Modal Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rQJB6hCVLg",
              name: "Integration Type",
              value: Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs)
            },
            {
              id: "ufKzJnGim",
              name: "Integration Filters",
              value: Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs)
            },
            {
              id: "II4Xahkoyw",
              name: "Integration Name",
              value: integrationConfig.integrationName
            },
            {
              id: "6maP1VTFIL",
              name: "Integration Id",
              value: integrationConfig.integrationId
            },
            {
              id: "cAS3onEsHP",
              name: "Integration Payload Format",
              value: Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs)
            },
            {
              id: "IW5IZHYNSH",
              name: "Webhook Secret Modal Interaction Type",
              value: webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Webhook Secret Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Webhook Secret Modal Interaction Type": webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Webhook Secret Modal Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Webhook Secret Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Webhook Secret Modal Interaction Type": webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Webhook Secret Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Webhook Secret Modal Interaction Type": webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Webhook Secret Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Webhook Secret Modal Interaction Type": webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Webhook Secret Modal Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
        "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
        "Integration Name": integrationConfig.integrationName,
        "Integration Id": integrationConfig.integrationId,
        "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
        "Webhook Secret Modal Interaction Type": webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Webhook Secret Modal Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Integration Type": Belt_Option.map(integrationConfig.integrationType, integrationTypeToJs),
              "Integration Filters": Belt_Array.map(integrationConfig.integrationFilters, integrationFiltersToJs),
              "Integration Name": integrationConfig.integrationName,
              "Integration Id": integrationConfig.integrationId,
              "Integration Payload Format": Belt_Option.map(integrationConfig.integrationPayloadFormat, integrationPayloadFormatToJs),
              "Webhook Secret Modal Interaction Type": webhookSecretModalInteractionTypeToJs(webhookSecretModalInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function logoClicked(schema, fromScreen) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("CpHam41GUY", "dbbaa9ad35f767065a3bb57f712a8c21b9e32524951db7d802aaa248de32ad0b", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Logo Clicked", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("CpHam41GUY", "Logo Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bffcc6e2-e330-4112-bce3-6f1d64373830",
              name: "From Screen",
              value: Belt_Option.map(fromScreen, fromScreenToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("CpHam41GUY", Date.now(), "Logo Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "bffcc6e2-e330-4112-bce3-6f1d64373830",
              name: "From Screen",
              value: Belt_Option.map(fromScreen, fromScreenToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Logo Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Logo Clicked", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Logo Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Logo Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Logo Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Logo Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Logo Clicked", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "From Screen": Belt_Option.map(fromScreen, fromScreenToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function branchLinkClicked(schema, toBranchId, branchLinkLocation) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("r3qoSaziQi", "c806d68565dd5caec5c0c6d50ccb1c3a66a5c671bff2ffe135d7feff4aff3a65", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Link Clicked", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "To Branch Id": toBranchId,
          "Branch Link Location": branchLinkLocationToJs(branchLinkLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("r3qoSaziQi", "Branch Link Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "1GVK1BIwiR",
              name: "Branch Link Location",
              value: branchLinkLocationToJs(branchLinkLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("r3qoSaziQi", Date.now(), "Branch Link Clicked", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "1GVK1BIwiR",
              name: "Branch Link Location",
              value: branchLinkLocationToJs(branchLinkLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Branch Link Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Branch Link Location": branchLinkLocationToJs(branchLinkLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Branch Link Clicked", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Branch Link Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Branch Link Location": branchLinkLocationToJs(branchLinkLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Branch Link Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Branch Link Location": branchLinkLocationToJs(branchLinkLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Branch Link Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Branch Link Location": branchLinkLocationToJs(branchLinkLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Branch Link Clicked", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Branch Link Location": branchLinkLocationToJs(branchLinkLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Branch Link Clicked", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "To Branch Id": toBranchId,
              "Branch Link Location": branchLinkLocationToJs(branchLinkLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function activityLogMenuInteracted(schema, toBranchId, activityLogMenuInteraction) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("NoACW_v9Qo", "6fbddc9ad5a0967d8516e461b27ae5e4a6acf54052db2b94bfe3d187b11033c2", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Activity Log Menu Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "To Branch Id": toBranchId,
          "Activity Log Menu Interaction": activityLogMenuInteractionToJs(activityLogMenuInteraction),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("NoACW_v9Qo", "Activity Log Menu Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "t55q82fDC",
              name: "Activity Log Menu Interaction",
              value: activityLogMenuInteractionToJs(activityLogMenuInteraction)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("NoACW_v9Qo", Date.now(), "Activity Log Menu Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "wb3LD0XhV",
              name: "To Branch Id",
              value: toBranchId
            },
            {
              id: "t55q82fDC",
              name: "Activity Log Menu Interaction",
              value: activityLogMenuInteractionToJs(activityLogMenuInteraction)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Activity Log Menu Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Activity Log Menu Interaction": activityLogMenuInteractionToJs(activityLogMenuInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Activity Log Menu Interacted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Activity Log Menu Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Activity Log Menu Interaction": activityLogMenuInteractionToJs(activityLogMenuInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Activity Log Menu Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Activity Log Menu Interaction": activityLogMenuInteractionToJs(activityLogMenuInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Activity Log Menu Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Activity Log Menu Interaction": activityLogMenuInteractionToJs(activityLogMenuInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Activity Log Menu Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "To Branch Id": toBranchId,
        "Activity Log Menu Interaction": activityLogMenuInteractionToJs(activityLogMenuInteraction),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Activity Log Menu Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "To Branch Id": toBranchId,
              "Activity Log Menu Interaction": activityLogMenuInteractionToJs(activityLogMenuInteraction),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function demoBranchCreated(schema, numEvents, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("zJzBhF6TAv", "7ef10258571baa143a36ac35fb77219cffa1a676a33413e6c6cb6bf13f5300d1", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Demo Branch Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Events": numEvents,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("zJzBhF6TAv", "Demo Branch Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("zJzBhF6TAv", Date.now(), "Demo Branch Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Demo Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Demo Branch Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Demo Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Demo Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Demo Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Demo Branch Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Demo Branch Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Events": numEvents,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchesViewConfigured(schema, branchViewConfigureToState, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("MgIilwPT7e", "f3babb3d914cc9574630c112b5b5e64032bf04afc76cc2caf397c008cd16540e", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branches View Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Branch View Configure to State": branchViewConfigureToStateToJs(branchViewConfigureToState),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("MgIilwPT7e", "Branches View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "L1otqf2N4y",
              name: "Branch View Configure to State",
              value: branchViewConfigureToStateToJs(branchViewConfigureToState)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("MgIilwPT7e", Date.now(), "Branches View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "L1otqf2N4y",
              name: "Branch View Configure to State",
              value: branchViewConfigureToStateToJs(branchViewConfigureToState)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branches View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch View Configure to State": branchViewConfigureToStateToJs(branchViewConfigureToState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branches View Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branches View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch View Configure to State": branchViewConfigureToStateToJs(branchViewConfigureToState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branches View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch View Configure to State": branchViewConfigureToStateToJs(branchViewConfigureToState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branches View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch View Configure to State": branchViewConfigureToStateToJs(branchViewConfigureToState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branches View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch View Configure to State": branchViewConfigureToStateToJs(branchViewConfigureToState),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branches View Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Branch View Configure to State": branchViewConfigureToStateToJs(branchViewConfigureToState),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function serviceAccountCreated(schema, serviceAccountName, associateUserWithWorkspace, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceNumServiceAccounts) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("TFPbBPsCji", "8ad728c530a14ada118410ffac3db52297a80178bd4209f131ca0eb0f56f2e34", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Service Account Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Service Account Name": serviceAccountName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("TFPbBPsCji", "Service Account Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "OW46wwbtnr",
              name: "Service Account Name",
              value: serviceAccountName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "e4CDCAZsBo",
                  name: "Workspace Num Service Accounts (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumServiceAccounts
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("TFPbBPsCji", Date.now(), "Service Account Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "OW46wwbtnr",
              name: "Service Account Name",
              value: serviceAccountName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Service Account Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Service Account Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Service Account Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Service Account Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Service Account Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Service Account Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  addCurrentUserToGroup("Workspace", associateUserWithWorkspace);
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  return logEventWithGroups("Service Account Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Service Account Name": serviceAccountName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function serviceAccountDeleted(schema, serviceAccountName, associateUserWithWorkspace, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceNumServiceAccounts) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Xnk_q_zGo", "16764c5cefa213b01aae32246f5c9eef38d1ce17fffc970b315968c8e99c0f34", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Service Account Deleted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Service Account Name": serviceAccountName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Xnk_q_zGo", "Service Account Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "OW46wwbtnr",
              name: "Service Account Name",
              value: serviceAccountName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "e4CDCAZsBo",
                  name: "Workspace Num Service Accounts (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceNumServiceAccounts
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Xnk_q_zGo", Date.now(), "Service Account Deleted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "OW46wwbtnr",
              name: "Service Account Name",
              value: serviceAccountName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Service Account Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Service Account Deleted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Service Account Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Service Account Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Service Account Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Service Account Deleted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Service Account Name": serviceAccountName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  addCurrentUserToGroup("Workspace", associateUserWithWorkspace);
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Num Service Accounts": workspaceNumServiceAccounts
      });
  return logEventWithGroups("Service Account Deleted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Service Account Name": serviceAccountName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function branchNotFound(schema, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("01YgnvYeV", "38f36a2c3719fde31e739c3f5f51b9fe028de87d822a41671a23d92ae88cfdb1", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Not Found", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("01YgnvYeV", "Branch Not Found", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("01YgnvYeV", Date.now(), "Branch Not Found", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Not Found", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Not Found", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Not Found", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Not Found", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Not Found", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Not Found", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branch Not Found", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function namePromptDisplayed(schema, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("g2y0au1MHd", "ce56c1ba0a34d68ebece971e3830680865fb2f6e61a4dc1db954d859aac17e67", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Name Prompt Displayed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("g2y0au1MHd", "Name Prompt Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("g2y0au1MHd", Date.now(), "Name Prompt Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Name Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Name Prompt Displayed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Name Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Name Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Name Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Name Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Name Prompt Displayed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function namePromptInteracted(schema, namePromptInteractionType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("-uKexP1Z3", "937b3e63c384bd7a64fc50e97aee72636e59015b94a935ae5dbeb2e82c09ffec", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Name Prompt Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Name Prompt Interaction Type": namePromptInteractionType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("-uKexP1Z3", "Name Prompt Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "3nVqKmVuge",
              name: "Name Prompt Interaction Type",
              value: namePromptInteractionType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("-uKexP1Z3", Date.now(), "Name Prompt Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "3nVqKmVuge",
              name: "Name Prompt Interaction Type",
              value: namePromptInteractionType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Name Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Name Prompt Interaction Type": namePromptInteractionType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Name Prompt Interacted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Name Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Name Prompt Interaction Type": namePromptInteractionType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Name Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Name Prompt Interaction Type": namePromptInteractionType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Name Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Name Prompt Interaction Type": namePromptInteractionType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Name Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Name Prompt Interaction Type": namePromptInteractionType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Name Prompt Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Name Prompt Interaction Type": namePromptInteractionType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function emptyStateDisplayed(schema, emptyStateLocation, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("7fMydDEKbv", "70ce75eec1f347b37b0d885fd2f7c06a3618728dd5d1acf90d36752e6449053f", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Empty State Displayed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("7fMydDEKbv", "Empty State Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rSYm8UI6R6",
              name: "Empty State Location",
              value: emptyStateLocationToJs(emptyStateLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("7fMydDEKbv", Date.now(), "Empty State Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rSYm8UI6R6",
              name: "Empty State Location",
              value: emptyStateLocationToJs(emptyStateLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Empty State Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Empty State Displayed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Empty State Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Empty State Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Empty State Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Empty State Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Empty State Displayed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function emptyStateInteracted(schema, emptyStateLocation, emptyStateInteraction, emptyStateInteractionPath) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("pKJrfHQO00", "7b5487bf579999ddaf2505603ea906439e407b4aa4e1e235698ae2329b080196", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Empty State Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
          "Empty State Interaction": emptyStateInteractionToJs(emptyStateInteraction),
          "Empty State Interaction Path": emptyStateInteractionPath,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("pKJrfHQO00", "Empty State Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rSYm8UI6R6",
              name: "Empty State Location",
              value: emptyStateLocationToJs(emptyStateLocation)
            },
            {
              id: "b3hE1MBu8b",
              name: "Empty State Interaction",
              value: emptyStateInteractionToJs(emptyStateInteraction)
            },
            {
              id: "iedOwO5e70",
              name: "Empty State Interaction Path",
              value: emptyStateInteractionPath
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("pKJrfHQO00", Date.now(), "Empty State Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rSYm8UI6R6",
              name: "Empty State Location",
              value: emptyStateLocationToJs(emptyStateLocation)
            },
            {
              id: "b3hE1MBu8b",
              name: "Empty State Interaction",
              value: emptyStateInteractionToJs(emptyStateInteraction)
            },
            {
              id: "iedOwO5e70",
              name: "Empty State Interaction Path",
              value: emptyStateInteractionPath
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Empty State Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        "Empty State Interaction": emptyStateInteractionToJs(emptyStateInteraction),
        "Empty State Interaction Path": emptyStateInteractionPath,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Empty State Interacted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Empty State Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        "Empty State Interaction": emptyStateInteractionToJs(emptyStateInteraction),
        "Empty State Interaction Path": emptyStateInteractionPath,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Empty State Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        "Empty State Interaction": emptyStateInteractionToJs(emptyStateInteraction),
        "Empty State Interaction Path": emptyStateInteractionPath,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Empty State Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        "Empty State Interaction": emptyStateInteractionToJs(emptyStateInteraction),
        "Empty State Interaction Path": emptyStateInteractionPath,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Empty State Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
        "Empty State Interaction": emptyStateInteractionToJs(emptyStateInteraction),
        "Empty State Interaction Path": emptyStateInteractionPath,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Empty State Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Empty State Location": emptyStateLocationToJs(emptyStateLocation),
              "Empty State Interaction": emptyStateInteractionToJs(emptyStateInteraction),
              "Empty State Interaction Path": emptyStateInteractionPath,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function collapsibleItemInteracted(schema, collapseState, collapsibleItemType, pageName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("QYxvAbXkB5", "154fe92808ae289a73977352124bd36c7a50b3329c1a73a1543c00d01a34d66a", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Collapsible Item Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Collapse State": collapseState,
          "Collapsible Item Type": collapsibleItemTypeToJs(collapsibleItemType),
          "Page Name": pageNameToJs(pageName),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("QYxvAbXkB5", "Collapsible Item Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rVZs7I4CY",
              name: "Collapse State",
              value: collapseState
            },
            {
              id: "hpI3Q4J8KR",
              name: "Collapsible Item Type",
              value: collapsibleItemTypeToJs(collapsibleItemType)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("QYxvAbXkB5", Date.now(), "Collapsible Item Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "rVZs7I4CY",
              name: "Collapse State",
              value: collapseState
            },
            {
              id: "hpI3Q4J8KR",
              name: "Collapsible Item Type",
              value: collapsibleItemTypeToJs(collapsibleItemType)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Collapsible Item Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Collapse State": collapseState,
        "Collapsible Item Type": collapsibleItemTypeToJs(collapsibleItemType),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Collapsible Item Interacted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Collapsible Item Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Collapse State": collapseState,
        "Collapsible Item Type": collapsibleItemTypeToJs(collapsibleItemType),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Collapsible Item Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Collapse State": collapseState,
        "Collapsible Item Type": collapsibleItemTypeToJs(collapsibleItemType),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Collapsible Item Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Collapse State": collapseState,
        "Collapsible Item Type": collapsibleItemTypeToJs(collapsibleItemType),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Collapsible Item Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Collapse State": collapseState,
        "Collapsible Item Type": collapsibleItemTypeToJs(collapsibleItemType),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Collapsible Item Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Collapse State": collapseState,
              "Collapsible Item Type": collapsibleItemTypeToJs(collapsibleItemType),
              "Page Name": pageNameToJs(pageName),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function warningPromptDisplayed(schema, warningType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("1BcU5Auh1i", "ef098d3b043b517220ecf2ace120f6074a266992ab343401d2c270b47fdc63db", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Warning Prompt Displayed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Warning Type": warningTypeToJs(warningType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("1BcU5Auh1i", "Warning Prompt Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EjAcV_qaQ",
              name: "Warning Type",
              value: warningTypeToJs(warningType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("1BcU5Auh1i", Date.now(), "Warning Prompt Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EjAcV_qaQ",
              name: "Warning Type",
              value: warningTypeToJs(warningType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Warning Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Warning Prompt Displayed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Warning Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Warning Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Warning Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Warning Prompt Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Warning Prompt Displayed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Warning Type": warningTypeToJs(warningType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function warningPromptInteracted(schema, warningPromptInteraction, warningType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("_FRFeBhZz", "ba6b23d036b885c32a1a2be2ee5b9a289d7a20147f10bc14a0db3c54889dae47", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Warning Prompt Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Warning Prompt Interaction": warningPromptInteraction,
          "Warning Type": warningTypeToJs(warningType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("_FRFeBhZz", "Warning Prompt Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FojdqDuzZ",
              name: "Warning Prompt Interaction",
              value: warningPromptInteraction
            },
            {
              id: "EjAcV_qaQ",
              name: "Warning Type",
              value: warningTypeToJs(warningType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("_FRFeBhZz", Date.now(), "Warning Prompt Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FojdqDuzZ",
              name: "Warning Prompt Interaction",
              value: warningPromptInteraction
            },
            {
              id: "EjAcV_qaQ",
              name: "Warning Type",
              value: warningTypeToJs(warningType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Warning Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Prompt Interaction": warningPromptInteraction,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Warning Prompt Interacted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Warning Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Prompt Interaction": warningPromptInteraction,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Warning Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Prompt Interaction": warningPromptInteraction,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Warning Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Prompt Interaction": warningPromptInteraction,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Warning Prompt Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Warning Prompt Interaction": warningPromptInteraction,
        "Warning Type": warningTypeToJs(warningType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Warning Prompt Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Warning Prompt Interaction": warningPromptInteraction,
              "Warning Type": warningTypeToJs(warningType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function tooltipDisplayed(schema, tooltipInfo, itemId, itemName, itemType, branchImplementationViewAccessType, userAuthenticationStatus, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("r5oj_GJw9", "2f32069578fb219bcd76921461c5993bb1cf12a2df7602b3d2e49cdff7cbfc44", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Tooltip Displayed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Tooltip Name": tooltipInfo.tooltipName,
          "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
          "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Branch Implementation View Access Type": branchImplementationViewAccessType,
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("r5oj_GJw9", "Tooltip Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TpjPw4KLM",
              name: "Tooltip Name",
              value: tooltipInfo.tooltipName
            },
            {
              id: "vRRG3MNnO",
              name: "Tooltip Purpose",
              value: tooltipPurposeToJs(tooltipInfo.tooltipPurpose)
            },
            {
              id: "yxB06abom",
              name: "Tooltip Display Method",
              value: tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod)
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("r5oj_GJw9", Date.now(), "Tooltip Displayed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TpjPw4KLM",
              name: "Tooltip Name",
              value: tooltipInfo.tooltipName
            },
            {
              id: "vRRG3MNnO",
              name: "Tooltip Purpose",
              value: tooltipPurposeToJs(tooltipInfo.tooltipPurpose)
            },
            {
              id: "yxB06abom",
              name: "Tooltip Display Method",
              value: tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod)
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Tooltip Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Tooltip Displayed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Tooltip Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Tooltip Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Tooltip Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Tooltip Displayed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Tooltip Displayed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Tooltip Name": tooltipInfo.tooltipName,
              "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
              "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Branch Implementation View Access Type": branchImplementationViewAccessType,
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function tooltipInteracted(schema, tooltipInfo, itemId, itemName, itemType, tooltipInteractionType, branchImplementationViewAccessType, userAuthenticationStatus, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("J14thHYwg", "290175e3e620904915462e3434a008b8280075070eb53ed17d910f5723291d4b", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Tooltip Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Tooltip Name": tooltipInfo.tooltipName,
          "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
          "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Tooltip Interaction Type": tooltipInteractionTypeToJs(tooltipInteractionType),
          "Branch Implementation View Access Type": branchImplementationViewAccessType,
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("J14thHYwg", "Tooltip Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TpjPw4KLM",
              name: "Tooltip Name",
              value: tooltipInfo.tooltipName
            },
            {
              id: "vRRG3MNnO",
              name: "Tooltip Purpose",
              value: tooltipPurposeToJs(tooltipInfo.tooltipPurpose)
            },
            {
              id: "yxB06abom",
              name: "Tooltip Display Method",
              value: tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod)
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "9h1LxW9Ko",
              name: "Tooltip Interaction Type",
              value: tooltipInteractionTypeToJs(tooltipInteractionType)
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("J14thHYwg", Date.now(), "Tooltip Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TpjPw4KLM",
              name: "Tooltip Name",
              value: tooltipInfo.tooltipName
            },
            {
              id: "vRRG3MNnO",
              name: "Tooltip Purpose",
              value: tooltipPurposeToJs(tooltipInfo.tooltipPurpose)
            },
            {
              id: "yxB06abom",
              name: "Tooltip Display Method",
              value: tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod)
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "9h1LxW9Ko",
              name: "Tooltip Interaction Type",
              value: tooltipInteractionTypeToJs(tooltipInteractionType)
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Tooltip Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Tooltip Interaction Type": tooltipInteractionTypeToJs(tooltipInteractionType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Tooltip Interacted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Tooltip Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Tooltip Interaction Type": tooltipInteractionTypeToJs(tooltipInteractionType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Tooltip Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Tooltip Interaction Type": tooltipInteractionTypeToJs(tooltipInteractionType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Tooltip Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Tooltip Interaction Type": tooltipInteractionTypeToJs(tooltipInteractionType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Tooltip Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Tooltip Name": tooltipInfo.tooltipName,
        "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
        "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Tooltip Interaction Type": tooltipInteractionTypeToJs(tooltipInteractionType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Tooltip Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Tooltip Name": tooltipInfo.tooltipName,
              "Tooltip Purpose": tooltipPurposeToJs(tooltipInfo.tooltipPurpose),
              "Tooltip Display Method": tooltipDisplayMethodToJs(tooltipInfo.tooltipDisplayMethod),
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Tooltip Interaction Type": tooltipInteractionTypeToJs(tooltipInteractionType),
              "Branch Implementation View Access Type": branchImplementationViewAccessType,
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function itemDetailsOpened(schema, itemId, itemName, itemType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("r4hY2hYvF", "d69f14e3509fe541f470eaecc269eda4396e98d1af667965996304cd494a02fd", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Item Details Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("r4hY2hYvF", "Item Details Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("r4hY2hYvF", Date.now(), "Item Details Opened", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Item Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Item Details Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Item Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Item Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Item Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Item Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Item Details Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function eventImplementationSnippetInteraction(schema, eventId, eventName, eventImplementationSnippetInteractionType, pageName, userAuthenticationStatus, branchImplementationViewAccessType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("4SGU7XgjJ7", "472c2cee7125f58f426ebc4961817fa317b5829c0aba21bafbe9d60f7cca5af9", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Implementation Snippet Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Implementation Snippet Interaction Type": eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType),
          "Page Name": pageNameToJs(pageName),
          "User Authentication Status": userAuthenticationStatus,
          "Branch Implementation View Access Type": branchImplementationViewAccessType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("4SGU7XgjJ7", "Event Implementation Snippet Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "f0pizDYBZ",
              name: "Event Implementation Snippet Interaction Type",
              value: eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("4SGU7XgjJ7", Date.now(), "Event Implementation Snippet Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "f0pizDYBZ",
              name: "Event Implementation Snippet Interaction Type",
              value: eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Implementation Snippet Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Implementation Snippet Interaction Type": eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType),
        "Page Name": pageNameToJs(pageName),
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Event Implementation Snippet Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Implementation Snippet Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Implementation Snippet Interaction Type": eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType),
        "Page Name": pageNameToJs(pageName),
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Event Implementation Snippet Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Implementation Snippet Interaction Type": eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType),
        "Page Name": pageNameToJs(pageName),
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Implementation Snippet Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Implementation Snippet Interaction Type": eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType),
        "Page Name": pageNameToJs(pageName),
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Implementation Snippet Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Implementation Snippet Interaction Type": eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType),
        "Page Name": pageNameToJs(pageName),
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Event Implementation Snippet Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Implementation Snippet Interaction Type": eventImplementationSnippetInteractionTypeToJs(eventImplementationSnippetInteractionType),
              "Page Name": pageNameToJs(pageName),
              "User Authentication Status": userAuthenticationStatus,
              "Branch Implementation View Access Type": branchImplementationViewAccessType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchImplementationInteraction(schema, branchImplementationInteractionType, diffType, branchImplementationViewAccessType, userAuthenticationStatus, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("flIYMOymYU", "ae86defee6c16e81d062382498af7eb320aec5864a88a000182b01fc1ff157c7", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Implementation Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Branch Implementation Interaction Type": branchImplementationInteractionTypeToJs(branchImplementationInteractionType),
          "Diff Type": diffTypeToJs(diffType),
          "Branch Implementation View Access Type": branchImplementationViewAccessType,
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("flIYMOymYU", "Branch Implementation Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "J5lrGerOC",
              name: "Branch Implementation Interaction Type",
              value: branchImplementationInteractionTypeToJs(branchImplementationInteractionType)
            },
            {
              id: "5QK5a3n47",
              name: "Diff Type",
              value: diffTypeToJs(diffType)
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("flIYMOymYU", Date.now(), "Branch Implementation Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "J5lrGerOC",
              name: "Branch Implementation Interaction Type",
              value: branchImplementationInteractionTypeToJs(branchImplementationInteractionType)
            },
            {
              id: "5QK5a3n47",
              name: "Diff Type",
              value: diffTypeToJs(diffType)
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Implementation Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Implementation Interaction Type": branchImplementationInteractionTypeToJs(branchImplementationInteractionType),
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Implementation Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Implementation Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Implementation Interaction Type": branchImplementationInteractionTypeToJs(branchImplementationInteractionType),
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Implementation Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Implementation Interaction Type": branchImplementationInteractionTypeToJs(branchImplementationInteractionType),
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Implementation Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Implementation Interaction Type": branchImplementationInteractionTypeToJs(branchImplementationInteractionType),
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Implementation Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Branch Implementation Interaction Type": branchImplementationInteractionTypeToJs(branchImplementationInteractionType),
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branch Implementation Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Branch Implementation Interaction Type": branchImplementationInteractionTypeToJs(branchImplementationInteractionType),
              "Diff Type": diffTypeToJs(diffType),
              "Branch Implementation View Access Type": branchImplementationViewAccessType,
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchImplementViewConfigured(schema, diffType, branchImplementationConfiguredAction, fromDiffType, sourceName, fromSourceName, userAuthenticationStatus, branchImplementationViewAccessType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("ZeR_zQ1KkY", "4def3fd29dec8491ac00d95b749033f098f203d5308772e686476914f35f2670", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Implement View Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Diff Type": diffTypeToJs(diffType),
          "Branch Implementation Configured Action": branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction),
          "From Diff Type": fromDiffTypeToJs(fromDiffType),
          "Source Name": sourceName,
          "From Source Name": fromSourceName,
          "User Authentication Status": userAuthenticationStatus,
          "Branch Implementation View Access Type": branchImplementationViewAccessType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("ZeR_zQ1KkY", "Branch Implement View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5QK5a3n47",
              name: "Diff Type",
              value: diffTypeToJs(diffType)
            },
            {
              id: "bapWlXYJA",
              name: "Branch Implementation Configured Action",
              value: branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction)
            },
            {
              id: "zQhFLXykl",
              name: "From Diff Type",
              value: fromDiffTypeToJs(fromDiffType)
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "59840dcb-fe7a-4e4c-9606-4df9c09b0326",
              name: "From Source Name",
              value: fromSourceName
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("ZeR_zQ1KkY", Date.now(), "Branch Implement View Configured", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5QK5a3n47",
              name: "Diff Type",
              value: diffTypeToJs(diffType)
            },
            {
              id: "bapWlXYJA",
              name: "Branch Implementation Configured Action",
              value: branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction)
            },
            {
              id: "zQhFLXykl",
              name: "From Diff Type",
              value: fromDiffTypeToJs(fromDiffType)
            },
            {
              id: "1c84842f-471d-4195-a6c8-d69a40ef31d7",
              name: "Source Name",
              value: sourceName
            },
            {
              id: "59840dcb-fe7a-4e4c-9606-4df9c09b0326",
              name: "From Source Name",
              value: fromSourceName
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "fie6u3OOm",
              name: "Branch Implementation View Access Type",
              value: branchImplementationViewAccessType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Implement View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation Configured Action": branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction),
        "From Diff Type": fromDiffTypeToJs(fromDiffType),
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Implement View Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Implement View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation Configured Action": branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction),
        "From Diff Type": fromDiffTypeToJs(fromDiffType),
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Implement View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation Configured Action": branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction),
        "From Diff Type": fromDiffTypeToJs(fromDiffType),
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Implement View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation Configured Action": branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction),
        "From Diff Type": fromDiffTypeToJs(fromDiffType),
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Implement View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Diff Type": diffTypeToJs(diffType),
        "Branch Implementation Configured Action": branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction),
        "From Diff Type": fromDiffTypeToJs(fromDiffType),
        "Source Name": sourceName,
        "From Source Name": fromSourceName,
        "User Authentication Status": userAuthenticationStatus,
        "Branch Implementation View Access Type": branchImplementationViewAccessType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branch Implement View Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Diff Type": diffTypeToJs(diffType),
              "Branch Implementation Configured Action": branchImplementationConfiguredActionToJs(branchImplementationConfiguredAction),
              "From Diff Type": fromDiffTypeToJs(fromDiffType),
              "Source Name": sourceName,
              "From Source Name": fromSourceName,
              "User Authentication Status": userAuthenticationStatus,
              "Branch Implementation View Access Type": branchImplementationViewAccessType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function branchImplementationShareModalInteraction(branchImplementationShareModalInteractionType, branchImplementationShareId, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("GwYMU2nKK", "12ca4bce403ac17ac3e61753291d21c5b47edc3b619ef4b4dae21f8e6e4e31d8", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Branch Implementation Share Modal Interaction", {
          "Branch Implementation Share Modal Interaction Type": branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType),
          "Branch Implementation Share Id": branchImplementationShareId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("GwYMU2nKK", "Branch Implementation Share Modal Interaction", messages, [
            {
              id: "65ZemwVmw",
              name: "Branch Implementation Share Modal Interaction Type",
              value: branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType)
            },
            {
              id: "5zm6zbZTY",
              name: "Branch Implementation Share Id",
              value: branchImplementationShareId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("GwYMU2nKK", Date.now(), "Branch Implementation Share Modal Interaction", messages, [
            {
              id: "65ZemwVmw",
              name: "Branch Implementation Share Modal Interaction Type",
              value: branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType)
            },
            {
              id: "5zm6zbZTY",
              name: "Branch Implementation Share Id",
              value: branchImplementationShareId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Branch Implementation Share Modal Interaction", {
        "Branch Implementation Share Modal Interaction Type": branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType),
        "Branch Implementation Share Id": branchImplementationShareId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Branch Implementation Share Modal Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Branch Implementation Share Modal Interaction", {
        "Branch Implementation Share Modal Interaction Type": branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType),
        "Branch Implementation Share Id": branchImplementationShareId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Branch Implementation Share Modal Interaction", {
        "Branch Implementation Share Modal Interaction Type": branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType),
        "Branch Implementation Share Id": branchImplementationShareId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Branch Implementation Share Modal Interaction", {
        "Branch Implementation Share Modal Interaction Type": branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType),
        "Branch Implementation Share Id": branchImplementationShareId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Branch Implementation Share Modal Interaction", {
        "Branch Implementation Share Modal Interaction Type": branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType),
        "Branch Implementation Share Id": branchImplementationShareId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Branch Implementation Share Modal Interaction", {
              "Branch Implementation Share Modal Interaction Type": branchImplementationShareModalInteractionTypeToJs(branchImplementationShareModalInteractionType),
              "Branch Implementation Share Id": branchImplementationShareId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function codeChangesAuditInteracted(schema, numEvents, codeChangesAuditInteractionType, associateEventWithWorkspace, associateEventWithBranch) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("qoS3l2OVes", "544718aed32c85a2e62066f0d2f899368001c65564838397d832423aa7e7670e", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Code Changes Audit Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Num Events": numEvents,
          "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("qoS3l2OVes", "Code Changes Audit Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "vLS0ER8x6",
              name: "Code Changes Audit Interaction Type",
              value: codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("qoS3l2OVes", Date.now(), "Code Changes Audit Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "vLS0ER8x6",
              name: "Code Changes Audit Interaction Type",
              value: codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Code Changes Audit Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Code Changes Audit Interacted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Code Changes Audit Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Code Changes Audit Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Code Changes Audit Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Code Changes Audit Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Num Events": numEvents,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  return logEventWithGroups("Code Changes Audit Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Num Events": numEvents,
              "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function codeChangesAuditSourceInteracted(schema, itemId, itemName, itemType, numEvents, implementationMethod, codeChangesAuditInteractionType, associateEventWithWorkspace, associateEventWithBranch) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Nn330E76-P", "23f0805019c7a6c968d1bf3243c382c93c5f6f02c806674c65b21517e79ae3dd", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Code Changes Audit Source Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Item Id": itemId,
          "Item Name": itemName,
          "Item Type": itemTypeToJs(itemType),
          "Num Events": numEvents,
          "Implementation Method": implementationMethod,
          "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Nn330E76-P", "Code Changes Audit Source Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "lOArnFzBn",
              name: "Implementation Method",
              value: implementationMethod
            },
            {
              id: "vLS0ER8x6",
              name: "Code Changes Audit Interaction Type",
              value: codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Nn330E76-P", Date.now(), "Code Changes Audit Source Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "sMN1gEoKl3",
              name: "Item Id",
              value: itemId
            },
            {
              id: "5deedc63-d86d-4bd6-b96b-6b97301b299f",
              name: "Item Name",
              value: itemName
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "aedf3dca-91f5-4ae9-9975-acf20de3bc9a",
              name: "Num Events",
              value: numEvents
            },
            {
              id: "lOArnFzBn",
              name: "Implementation Method",
              value: implementationMethod
            },
            {
              id: "vLS0ER8x6",
              name: "Code Changes Audit Interaction Type",
              value: codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Code Changes Audit Source Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Num Events": numEvents,
        "Implementation Method": implementationMethod,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Code Changes Audit Source Interacted", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Code Changes Audit Source Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Num Events": numEvents,
        "Implementation Method": implementationMethod,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Code Changes Audit Source Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Num Events": numEvents,
        "Implementation Method": implementationMethod,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Code Changes Audit Source Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Num Events": numEvents,
        "Implementation Method": implementationMethod,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Code Changes Audit Source Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Id": itemId,
        "Item Name": itemName,
        "Item Type": itemTypeToJs(itemType),
        "Num Events": numEvents,
        "Implementation Method": implementationMethod,
        "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  return logEventWithGroups("Code Changes Audit Source Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Item Id": itemId,
              "Item Name": itemName,
              "Item Type": itemTypeToJs(itemType),
              "Num Events": numEvents,
              "Implementation Method": implementationMethod,
              "Code Changes Audit Interaction Type": codeChangesAuditInteractionTypeToJs(codeChangesAuditInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function intelligenceNamingFeedbackInitiated(schema, itemType, namingActionType, estimatedNameSpaceTokenCount, avoIntelligenceAccessType, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("aG9s_30eYp", "11c32ae30c7969434f80f6b9d5498db004da54056abb3a4e190f63df77828876", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Intelligence Naming Feedback Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Item Type": itemTypeToJs(itemType),
          "Naming Action Type": namingActionType,
          "Estimated Name Space Token Count": estimatedNameSpaceTokenCount,
          "Avo Intelligence Access Type": avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("aG9s_30eYp", "Intelligence Naming Feedback Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "L707cT8Ue",
              name: "Estimated Name Space Token Count",
              value: estimatedNameSpaceTokenCount
            },
            {
              id: "ie8z0Ix2E",
              name: "Avo Intelligence Access Type",
              value: avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("aG9s_30eYp", Date.now(), "Intelligence Naming Feedback Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "L707cT8Ue",
              name: "Estimated Name Space Token Count",
              value: estimatedNameSpaceTokenCount
            },
            {
              id: "ie8z0Ix2E",
              name: "Avo Intelligence Access Type",
              value: avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Intelligence Naming Feedback Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Estimated Name Space Token Count": estimatedNameSpaceTokenCount,
        "Avo Intelligence Access Type": avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Intelligence Naming Feedback Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Intelligence Naming Feedback Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Estimated Name Space Token Count": estimatedNameSpaceTokenCount,
        "Avo Intelligence Access Type": avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Intelligence Naming Feedback Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Estimated Name Space Token Count": estimatedNameSpaceTokenCount,
        "Avo Intelligence Access Type": avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Intelligence Naming Feedback Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Estimated Name Space Token Count": estimatedNameSpaceTokenCount,
        "Avo Intelligence Access Type": avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Intelligence Naming Feedback Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Estimated Name Space Token Count": estimatedNameSpaceTokenCount,
        "Avo Intelligence Access Type": avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Intelligence Naming Feedback Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Item Type": itemTypeToJs(itemType),
              "Naming Action Type": namingActionType,
              "Estimated Name Space Token Count": estimatedNameSpaceTokenCount,
              "Avo Intelligence Access Type": avoIntelligenceAccessTypeToJs(avoIntelligenceAccessType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function namingFeedbackApplied(schema, feedbackType, itemType, namingActionType, feedbackDetails, namingFeedbackSuggestion, namingFeedbackOriginalName, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("yBoWc2GLi_", "9ac5d97a5db34166deb43f44a95dc10aa3243ddc6199d546f451cc9adb9f28bc", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Naming Feedback Applied", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Feedback Type": feedbackType,
          "Item Type": itemTypeToJs(itemType),
          "Naming Action Type": namingActionType,
          "Feedback Details": feedbackDetailsToJs(feedbackDetails),
          "Naming Feedback Suggestion": namingFeedbackSuggestion,
          "Naming Feedback Original Name": namingFeedbackOriginalName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("yBoWc2GLi_", "Naming Feedback Applied", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "tj0kZn82I",
              name: "Feedback Type",
              value: feedbackType
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "RWG7_ZCDs",
              name: "Feedback Details",
              value: feedbackDetailsToJs(feedbackDetails)
            },
            {
              id: "B4htjwt44",
              name: "Naming Feedback Suggestion",
              value: namingFeedbackSuggestion
            },
            {
              id: "29YrBIq3b",
              name: "Naming Feedback Original Name",
              value: namingFeedbackOriginalName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("yBoWc2GLi_", Date.now(), "Naming Feedback Applied", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "tj0kZn82I",
              name: "Feedback Type",
              value: feedbackType
            },
            {
              id: "459b896b-1372-4567-ac7a-7ccb535dee7f",
              name: "Item Type",
              value: itemTypeToJs(itemType)
            },
            {
              id: "OpXKlka34",
              name: "Naming Action Type",
              value: namingActionType
            },
            {
              id: "RWG7_ZCDs",
              name: "Feedback Details",
              value: feedbackDetailsToJs(feedbackDetails)
            },
            {
              id: "B4htjwt44",
              name: "Naming Feedback Suggestion",
              value: namingFeedbackSuggestion
            },
            {
              id: "29YrBIq3b",
              name: "Naming Feedback Original Name",
              value: namingFeedbackOriginalName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Naming Feedback Applied", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        "Naming Feedback Suggestion": namingFeedbackSuggestion,
        "Naming Feedback Original Name": namingFeedbackOriginalName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Naming Feedback Applied", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Naming Feedback Applied", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        "Naming Feedback Suggestion": namingFeedbackSuggestion,
        "Naming Feedback Original Name": namingFeedbackOriginalName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Naming Feedback Applied", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        "Naming Feedback Suggestion": namingFeedbackSuggestion,
        "Naming Feedback Original Name": namingFeedbackOriginalName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Naming Feedback Applied", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        "Naming Feedback Suggestion": namingFeedbackSuggestion,
        "Naming Feedback Original Name": namingFeedbackOriginalName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Naming Feedback Applied", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Feedback Type": feedbackType,
        "Item Type": itemTypeToJs(itemType),
        "Naming Action Type": namingActionType,
        "Feedback Details": feedbackDetailsToJs(feedbackDetails),
        "Naming Feedback Suggestion": namingFeedbackSuggestion,
        "Naming Feedback Original Name": namingFeedbackOriginalName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Naming Feedback Applied", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Feedback Type": feedbackType,
              "Item Type": itemTypeToJs(itemType),
              "Naming Action Type": namingActionType,
              "Feedback Details": feedbackDetailsToJs(feedbackDetails),
              "Naming Feedback Suggestion": namingFeedbackSuggestion,
              "Naming Feedback Original Name": namingFeedbackOriginalName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function inspectorIssueDetailsInteraction(schema, inspectorIssueDetails, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorIssueProportion(inspectorIssueDetails.inspectorIssueProportion));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorIssueVolume(inspectorIssueDetails.inspectorIssueVolume));
    var messages$3 = Belt_Array.concat(messages$2, assertInspectorEventVolume(inspectorIssueDetails.inspectorEventVolume));
    var messages$4 = Belt_Array.concat(messages$3, assertInspectorIssueSourceCount(inspectorIssueDetails.inspectorIssueSourceCount));
    var messages$5 = Belt_Array.concat(messages$4, assertInspectorIssueFirstSeenHoursAgo(inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo));
    var messages$6 = Belt_Array.concat(messages$5, assertInspectorIssueLastSeenHoursAgo(inspectorIssueDetails.inspectorIssueLastSeenHoursAgo));
    var messages$7 = Belt_Array.concat(messages$6, assertInspectorDisplayedIssueCountOptional(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("5v2WPwG57", "5eb6068f2712e61f3e174327796d06c85aea53f4cebd1e6695ddd8577eb41185", Belt_Array.map(messages$7, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Issue Details Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
          "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
          "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
          "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
          "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
          "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
          "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
          "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
          "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
          "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
          "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
          "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
          "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
          "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                  return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                })),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("5v2WPwG57", "Inspector Issue Details Interaction", messages$7, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FhxqbTTFK",
              name: "Inspector Issue Proportion",
              value: inspectorIssueDetails.inspectorIssueProportion
            },
            {
              id: "JioDYuCXb",
              name: "Inspector Issue Volume",
              value: inspectorIssueDetails.inspectorIssueVolume
            },
            {
              id: "Jq3Fw3nm1",
              name: "Inspector Event Volume",
              value: inspectorIssueDetails.inspectorEventVolume
            },
            {
              id: "c6YWp34Db",
              name: "Inspector Issue Source Count",
              value: inspectorIssueDetails.inspectorIssueSourceCount
            },
            {
              id: "WWIKORM2D",
              name: "Inspector Issue First Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo
            },
            {
              id: "VqRh4QggM",
              name: "Inspector Issue Last Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueLastSeenHoursAgo
            },
            {
              id: "60fnXoikqp",
              name: "Inspector Issue Type",
              value: inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType)
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueDetails.inspectorIssueId
            },
            {
              id: "QLwkicycu",
              name: "Inspector Issue Shared Id",
              value: inspectorIssueDetails.inspectorIssueSharedId
            },
            {
              id: "qMiLLbXzE",
              name: "Inspector Issue Details Interaction Type",
              value: inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType)
            },
            {
              id: "uMCjNP6Vc",
              name: "Inspector Issue Status",
              value: inspectorIssueDetails.inspectorIssueStatus
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs)
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    }))
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("5v2WPwG57", Date.now(), "Inspector Issue Details Interaction", messages$7, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FhxqbTTFK",
              name: "Inspector Issue Proportion",
              value: inspectorIssueDetails.inspectorIssueProportion
            },
            {
              id: "JioDYuCXb",
              name: "Inspector Issue Volume",
              value: inspectorIssueDetails.inspectorIssueVolume
            },
            {
              id: "Jq3Fw3nm1",
              name: "Inspector Event Volume",
              value: inspectorIssueDetails.inspectorEventVolume
            },
            {
              id: "c6YWp34Db",
              name: "Inspector Issue Source Count",
              value: inspectorIssueDetails.inspectorIssueSourceCount
            },
            {
              id: "WWIKORM2D",
              name: "Inspector Issue First Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo
            },
            {
              id: "VqRh4QggM",
              name: "Inspector Issue Last Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueLastSeenHoursAgo
            },
            {
              id: "60fnXoikqp",
              name: "Inspector Issue Type",
              value: inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType)
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueDetails.inspectorIssueId
            },
            {
              id: "QLwkicycu",
              name: "Inspector Issue Shared Id",
              value: inspectorIssueDetails.inspectorIssueSharedId
            },
            {
              id: "qMiLLbXzE",
              name: "Inspector Issue Details Interaction Type",
              value: inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType)
            },
            {
              id: "uMCjNP6Vc",
              name: "Inspector Issue Status",
              value: inspectorIssueDetails.inspectorIssueStatus
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs)
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    }))
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Issue Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Issue Details Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Issue Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Issue Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Issue Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Issue Details Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Issue Details Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
              "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
              "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
              "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
              "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
              "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
              "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
              "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
              "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
              "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
              "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
              "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
              "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
              "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    })),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorIssuesViewed(schema, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, inspectorSavedViewCount, eventUnexpectedByTrackingPlanInspectorIssueCount, numEventsInSchema, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceInspectorInstallStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorSavedViewCount(inspectorSavedViewCount));
    var messages$3 = Belt_Array.concat(messages$2, assertNumEventsInSchema(numEventsInSchema));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("wT9YClRuA", "846072a06429dc176666848cc40014cd74c9405f389e75f753cd06fe07bbe16b", Belt_Array.map(messages$3, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Issues Viewed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          "Inspector Saved View Count": inspectorSavedViewCount,
          "Event Unexpected by Tracking Plan Inspector Issue Count": eventUnexpectedByTrackingPlanInspectorIssueCount,
          "Num Events in Schema": numEventsInSchema,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("wT9YClRuA", "Inspector Issues Viewed", messages$3, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViewCount
            },
            {
              id: "LVdjpfwRn",
              name: "Event Unexpected by Tracking Plan Inspector Issue Count",
              value: eventUnexpectedByTrackingPlanInspectorIssueCount
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "N9zV1896K9",
                  name: "Workspace Inspector Install Status (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("wT9YClRuA", Date.now(), "Inspector Issues Viewed", messages$3, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViewCount
            },
            {
              id: "LVdjpfwRn",
              name: "Event Unexpected by Tracking Plan Inspector Issue Count",
              value: eventUnexpectedByTrackingPlanInspectorIssueCount
            },
            {
              id: "Cyfje9QqIM",
              name: "Num Events in Schema",
              value: numEventsInSchema
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Issues Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Count": inspectorSavedViewCount,
        "Event Unexpected by Tracking Plan Inspector Issue Count": eventUnexpectedByTrackingPlanInspectorIssueCount,
        "Num Events in Schema": numEventsInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Issues Viewed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Issues Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Count": inspectorSavedViewCount,
        "Event Unexpected by Tracking Plan Inspector Issue Count": eventUnexpectedByTrackingPlanInspectorIssueCount,
        "Num Events in Schema": numEventsInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Issues Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Count": inspectorSavedViewCount,
        "Event Unexpected by Tracking Plan Inspector Issue Count": eventUnexpectedByTrackingPlanInspectorIssueCount,
        "Num Events in Schema": numEventsInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Issues Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Count": inspectorSavedViewCount,
        "Event Unexpected by Tracking Plan Inspector Issue Count": eventUnexpectedByTrackingPlanInspectorIssueCount,
        "Num Events in Schema": numEventsInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Issues Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Count": inspectorSavedViewCount,
        "Event Unexpected by Tracking Plan Inspector Issue Count": eventUnexpectedByTrackingPlanInspectorIssueCount,
        "Num Events in Schema": numEventsInSchema,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Install Status": Belt_Option.map(workspaceInspectorInstallStatus, workspaceInspectorInstallStatusToJs)
      });
  return logEventWithGroups("Inspector Issues Viewed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              "Inspector Saved View Count": inspectorSavedViewCount,
              "Event Unexpected by Tracking Plan Inspector Issue Count": eventUnexpectedByTrackingPlanInspectorIssueCount,
              "Num Events in Schema": numEventsInSchema,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorIssueDetailsOpened(schema, inspectorIssueDetails, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorIssueProportion(inspectorIssueDetails.inspectorIssueProportion));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorIssueVolume(inspectorIssueDetails.inspectorIssueVolume));
    var messages$3 = Belt_Array.concat(messages$2, assertInspectorEventVolume(inspectorIssueDetails.inspectorEventVolume));
    var messages$4 = Belt_Array.concat(messages$3, assertInspectorIssueSourceCount(inspectorIssueDetails.inspectorIssueSourceCount));
    var messages$5 = Belt_Array.concat(messages$4, assertInspectorIssueFirstSeenHoursAgo(inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo));
    var messages$6 = Belt_Array.concat(messages$5, assertInspectorIssueLastSeenHoursAgo(inspectorIssueDetails.inspectorIssueLastSeenHoursAgo));
    var messages$7 = Belt_Array.concat(messages$6, assertInspectorDisplayedIssueCountOptional(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("uD129FmIh", "52efe21b3c16f6f2bf6e5ab2367ebbf47d5fe0f44724e07f557a35c8236336d2", Belt_Array.map(messages$7, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Issue Details Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
          "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
          "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
          "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
          "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
          "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
          "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
          "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
          "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
          "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
          "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
          "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
          "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
          "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                  return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                })),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("uD129FmIh", "Inspector Issue Details Opened", messages$7, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FhxqbTTFK",
              name: "Inspector Issue Proportion",
              value: inspectorIssueDetails.inspectorIssueProportion
            },
            {
              id: "JioDYuCXb",
              name: "Inspector Issue Volume",
              value: inspectorIssueDetails.inspectorIssueVolume
            },
            {
              id: "Jq3Fw3nm1",
              name: "Inspector Event Volume",
              value: inspectorIssueDetails.inspectorEventVolume
            },
            {
              id: "c6YWp34Db",
              name: "Inspector Issue Source Count",
              value: inspectorIssueDetails.inspectorIssueSourceCount
            },
            {
              id: "WWIKORM2D",
              name: "Inspector Issue First Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo
            },
            {
              id: "VqRh4QggM",
              name: "Inspector Issue Last Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueLastSeenHoursAgo
            },
            {
              id: "60fnXoikqp",
              name: "Inspector Issue Type",
              value: inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType)
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueDetails.inspectorIssueId
            },
            {
              id: "QLwkicycu",
              name: "Inspector Issue Shared Id",
              value: inspectorIssueDetails.inspectorIssueSharedId
            },
            {
              id: "qMiLLbXzE",
              name: "Inspector Issue Details Interaction Type",
              value: inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType)
            },
            {
              id: "uMCjNP6Vc",
              name: "Inspector Issue Status",
              value: inspectorIssueDetails.inspectorIssueStatus
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs)
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    }))
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("uD129FmIh", Date.now(), "Inspector Issue Details Opened", messages$7, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FhxqbTTFK",
              name: "Inspector Issue Proportion",
              value: inspectorIssueDetails.inspectorIssueProportion
            },
            {
              id: "JioDYuCXb",
              name: "Inspector Issue Volume",
              value: inspectorIssueDetails.inspectorIssueVolume
            },
            {
              id: "Jq3Fw3nm1",
              name: "Inspector Event Volume",
              value: inspectorIssueDetails.inspectorEventVolume
            },
            {
              id: "c6YWp34Db",
              name: "Inspector Issue Source Count",
              value: inspectorIssueDetails.inspectorIssueSourceCount
            },
            {
              id: "WWIKORM2D",
              name: "Inspector Issue First Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo
            },
            {
              id: "VqRh4QggM",
              name: "Inspector Issue Last Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueLastSeenHoursAgo
            },
            {
              id: "60fnXoikqp",
              name: "Inspector Issue Type",
              value: inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType)
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueDetails.inspectorIssueId
            },
            {
              id: "QLwkicycu",
              name: "Inspector Issue Shared Id",
              value: inspectorIssueDetails.inspectorIssueSharedId
            },
            {
              id: "qMiLLbXzE",
              name: "Inspector Issue Details Interaction Type",
              value: inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType)
            },
            {
              id: "uMCjNP6Vc",
              name: "Inspector Issue Status",
              value: inspectorIssueDetails.inspectorIssueStatus
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs)
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    }))
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Issue Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Issue Details Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Issue Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Issue Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Issue Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Issue Details Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Issue Details Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
              "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
              "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
              "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
              "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
              "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
              "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
              "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
              "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
              "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
              "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
              "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
              "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
              "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    })),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorIssuesViewConfigured(schema, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, inspectorIssuesConfigureAction, inspectorIssuesConfigureItem, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("jXla7u_j6", "27a63ab966187276b8e92155064a921d883a2b1da67aba1e40e77007b4fe4b0c", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Issues View Configured", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          "Inspector Issues Configure Action": inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction),
          "Inspector Issues Configure Item": inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("jXla7u_j6", "Inspector Issues View Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "k48GUJ3_Z",
              name: "Inspector Issues Configure Action",
              value: inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction)
            },
            {
              id: "DfqwnaoyU",
              name: "Inspector Issues Configure Item",
              value: inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("jXla7u_j6", Date.now(), "Inspector Issues View Configured", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "k48GUJ3_Z",
              name: "Inspector Issues Configure Action",
              value: inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction)
            },
            {
              id: "DfqwnaoyU",
              name: "Inspector Issues Configure Item",
              value: inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Issues View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Issues Configure Action": inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction),
        "Inspector Issues Configure Item": inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Issues View Configured", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Issues View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Issues Configure Action": inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction),
        "Inspector Issues Configure Item": inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Issues View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Issues Configure Action": inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction),
        "Inspector Issues Configure Item": inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Issues View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Issues Configure Action": inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction),
        "Inspector Issues Configure Item": inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Issues View Configured", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Issues Configure Action": inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction),
        "Inspector Issues Configure Item": inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Issues View Configured", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              "Inspector Issues Configure Action": inspectorIssuesConfigureActionToJs(inspectorIssuesConfigureAction),
              "Inspector Issues Configure Item": inspectorIssuesConfigureItemToJs(inspectorIssuesConfigureItem),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorEventsSidebarInteraction(schema, inspectorEventSidebarInteractionType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("UnBLuUTtI7", "a711a7cd2233b00eb2f82b3fba5a595a9851802f9824652f455d60cbec4c7cd0", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Events Sidebar Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Event Sidebar Interaction Type": inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("UnBLuUTtI7", "Inspector Events Sidebar Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "JOJRUm4is",
              name: "Inspector Event Sidebar Interaction Type",
              value: inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("UnBLuUTtI7", Date.now(), "Inspector Events Sidebar Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "JOJRUm4is",
              name: "Inspector Event Sidebar Interaction Type",
              value: inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Events Sidebar Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Event Sidebar Interaction Type": inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Events Sidebar Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Events Sidebar Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Event Sidebar Interaction Type": inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Events Sidebar Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Event Sidebar Interaction Type": inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Events Sidebar Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Event Sidebar Interaction Type": inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Events Sidebar Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Event Sidebar Interaction Type": inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Events Sidebar Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Event Sidebar Interaction Type": inspectorEventSidebarInteractionTypeToJs(inspectorEventSidebarInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorSavedViewCreated(schema, inspectorSavedViews, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, associateUserWithWorkspace, associateEventWithWorkspace, updateGroupPropertiesForWorkspace, workspaceInspectorSavedViewCount) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorSavedViewCount(inspectorSavedViews.inspectorSavedViewCount));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("V9GNif03Y", "6307155f4b4255ad4a2361657ce12201137828d5333cdc4baf47f3bf5d5b4a16", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Saved View Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
          "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
          "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
          "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("V9GNif03Y", "Inspector Saved View Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], [{
                  id: "lucHSiddd",
                  name: "Workspace Inspector Saved View Count  (Workspace " + (updateGroupPropertiesForWorkspace + " group property)"),
                  value: workspaceInspectorSavedViewCount
                }]));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("V9GNif03Y", Date.now(), "Inspector Saved View Created", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(postHog.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Saved View Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Saved View Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(segment.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Saved View Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Saved View Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Saved View Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).addCurrentUserToGroup, "Workspace", associateUserWithWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).setGroupProperties, "Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Saved View Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  addCurrentUserToGroup("Workspace", associateUserWithWorkspace);
  setGroupProperties("Workspace", updateGroupPropertiesForWorkspace, {
        "Workspace Inspector Saved View Count ": workspaceInspectorSavedViewCount
      });
  return logEventWithGroups("Inspector Saved View Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
              "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
              "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
              "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorSavedViewInteraction(schema, inspectorSavedViews, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, inspectorSavedViewInteractionType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorSavedViewCount(inspectorSavedViews.inspectorSavedViewCount));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("t-3EOdOUF", "7069274eb59897a8a7d443782af5bef6c57d98dae102b63e59e48e84626a0148", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Saved View Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
          "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
          "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
          "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          "Inspector Saved View Interaction Type": inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("t-3EOdOUF", "Inspector Saved View Interaction", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "MnZyF6NPK",
              name: "Inspector Saved View Interaction Type",
              value: inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("t-3EOdOUF", Date.now(), "Inspector Saved View Interaction", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "MnZyF6NPK",
              name: "Inspector Saved View Interaction Type",
              value: inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Saved View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Interaction Type": inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Saved View Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Saved View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Interaction Type": inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Saved View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Interaction Type": inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Saved View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Interaction Type": inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Saved View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Saved View Interaction Type": inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Saved View Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
              "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
              "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
              "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              "Inspector Saved View Interaction Type": inspectorSavedViewInteractionTypeToJs(inspectorSavedViewInteractionType),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorSavedViewOpened(schema, inspectorSavedViews, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorSavedViewCount(inspectorSavedViews.inspectorSavedViewCount));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("uDKww60Zn", "37f2fe324fed539b7b4094f5b2e2f0820378ad39817830a1dca7a2eb1efdf9ee", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Saved View Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
          "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
          "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
          "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("uDKww60Zn", "Inspector Saved View Opened", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("uDKww60Zn", Date.now(), "Inspector Saved View Opened", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Saved View Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Saved View Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Saved View Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Saved View Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Saved View Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Saved View Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Saved View Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
              "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
              "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
              "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorSavedViewInitiated(schema, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("JrRQsVq8P", "a62b0180833f13097076b167965e3405a07352ff84913a771fa4a1d864835a93", Belt_Array.map(messages$1, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Saved View Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("JrRQsVq8P", "Inspector Saved View Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("JrRQsVq8P", Date.now(), "Inspector Saved View Initiated", messages$1, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Saved View Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Saved View Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Saved View Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Saved View Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Saved View Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Saved View Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Saved View Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorSavedViewDraftInitiated(schema, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("zYxEaAxlY", "323141d409b3110ac5d5db574cce7f2e0df108c76ea98e0b0f705254bd740999", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Saved View Draft Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("zYxEaAxlY", "Inspector Saved View Draft Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("zYxEaAxlY", Date.now(), "Inspector Saved View Draft Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Saved View Draft Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Saved View Draft Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Saved View Draft Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Saved View Draft Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Saved View Draft Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Saved View Draft Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Saved View Draft Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function productFlowStarted(productFlows, schema, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("tzcLL4JZn_", "a45c9140bebf2430b63e2249517a0997c50dbc30353127268484ac58ebf4e2ad", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Product Flow Started", {
          "Product Flow Name": productFlows.productFlowName,
          "Product Flow ID": productFlows.productFlowId,
          "Product Flow Version": productFlows.productFlowVersion,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("tzcLL4JZn_", "Product Flow Started", messages, [
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("tzcLL4JZn_", Date.now(), "Product Flow Started", messages, [
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Product Flow Started", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Product Flow Started", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Product Flow Started", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Product Flow Started", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Product Flow Started", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Product Flow Started", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Product Flow Started", {
              "Product Flow Name": productFlows.productFlowName,
              "Product Flow ID": productFlows.productFlowId,
              "Product Flow Version": productFlows.productFlowVersion,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function productFlowStepCompleted(productFlows, schema, productFlowStepId, productFlowStepNumber, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("hZtQfpjcdG", "1b4d5b503efdb7182b06c5c70827c46e7812f3dc096af768f27944ae88b935b5", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Product Flow Step Completed", {
          "Product Flow Name": productFlows.productFlowName,
          "Product Flow ID": productFlows.productFlowId,
          "Product Flow Version": productFlows.productFlowVersion,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Product Flow Step Id": productFlowStepId,
          "Product Flow Step Number": productFlowStepNumber,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("hZtQfpjcdG", "Product Flow Step Completed", messages, [
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "xFjRw65P8",
              name: "Product Flow Step Id",
              value: productFlowStepId
            },
            {
              id: "-xnC-s2kW",
              name: "Product Flow Step Number",
              value: productFlowStepNumber
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("hZtQfpjcdG", Date.now(), "Product Flow Step Completed", messages, [
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "xFjRw65P8",
              name: "Product Flow Step Id",
              value: productFlowStepId
            },
            {
              id: "-xnC-s2kW",
              name: "Product Flow Step Number",
              value: productFlowStepNumber
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Product Flow Step Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Product Flow Step Completed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Product Flow Step Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Product Flow Step Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Product Flow Step Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Product Flow Step Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Product Flow Step Completed", {
              "Product Flow Name": productFlows.productFlowName,
              "Product Flow ID": productFlows.productFlowId,
              "Product Flow Version": productFlows.productFlowVersion,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Product Flow Step Id": productFlowStepId,
              "Product Flow Step Number": productFlowStepNumber,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function productFlowCompleted(productFlows, schema, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("WiadmrBVVY", "5c95ebf791345ec0e41674ee471e9c2be367636bc3a125d7ddfdd180e8599440", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Product Flow Completed", {
          "Product Flow Name": productFlows.productFlowName,
          "Product Flow ID": productFlows.productFlowId,
          "Product Flow Version": productFlows.productFlowVersion,
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("WiadmrBVVY", "Product Flow Completed", messages, [
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("WiadmrBVVY", Date.now(), "Product Flow Completed", messages, [
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Product Flow Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Product Flow Completed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Product Flow Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Product Flow Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Product Flow Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Product Flow Completed", {
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Product Flow Completed", {
              "Product Flow Name": productFlows.productFlowName,
              "Product Flow ID": productFlows.productFlowId,
              "Product Flow Version": productFlows.productFlowVersion,
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function productFlowExited(schema, productFlows, productFlowStepId, productFlowStepNumber, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("yFLSD0fsJG", "836aaf88145fdb678919058505a97481e30c316926ae0ded97ab09636b355c1c", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Product Flow Exited", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Product Flow Name": productFlows.productFlowName,
          "Product Flow ID": productFlows.productFlowId,
          "Product Flow Version": productFlows.productFlowVersion,
          "Product Flow Step Id": productFlowStepId,
          "Product Flow Step Number": productFlowStepNumber,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("yFLSD0fsJG", "Product Flow Exited", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "xFjRw65P8",
              name: "Product Flow Step Id",
              value: productFlowStepId
            },
            {
              id: "-xnC-s2kW",
              name: "Product Flow Step Number",
              value: productFlowStepNumber
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("yFLSD0fsJG", Date.now(), "Product Flow Exited", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "nfzzo8LT4",
              name: "Product Flow Name",
              value: productFlows.productFlowName
            },
            {
              id: "CYvqHOvn5",
              name: "Product Flow ID",
              value: productFlows.productFlowId
            },
            {
              id: "vlNfZ9rd4",
              name: "Product Flow Version",
              value: productFlows.productFlowVersion
            },
            {
              id: "xFjRw65P8",
              name: "Product Flow Step Id",
              value: productFlowStepId
            },
            {
              id: "-xnC-s2kW",
              name: "Product Flow Step Number",
              value: productFlowStepNumber
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Product Flow Exited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Product Flow Exited", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Product Flow Exited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Product Flow Exited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Product Flow Exited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Product Flow Exited", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Product Flow Name": productFlows.productFlowName,
        "Product Flow ID": productFlows.productFlowId,
        "Product Flow Version": productFlows.productFlowVersion,
        "Product Flow Step Id": productFlowStepId,
        "Product Flow Step Number": productFlowStepNumber,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Product Flow Exited", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Product Flow Name": productFlows.productFlowName,
              "Product Flow ID": productFlows.productFlowId,
              "Product Flow Version": productFlows.productFlowVersion,
              "Product Flow Step Id": productFlowStepId,
              "Product Flow Step Number": productFlowStepNumber,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function releaseVersionConfigurationPromptSeen(schema, inspectorBreakingChangeInfo, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertBreakingChangeImpactedEventCount(inspectorBreakingChangeInfo.breakingChangeImpactedEventCount));
    var messages$2 = Belt_Array.concat(messages$1, assertBreakingChangeImpactedSourceCount(inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("wPdn2NIqu", "eab9e99252f871698988a6c7e136305f5473c8faed27fbefc4bb1c18272d0c1f", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Release Version Configuration Prompt Seen", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
          "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
          "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
          "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("wPdn2NIqu", "Release Version Configuration Prompt Seen", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("wPdn2NIqu", Date.now(), "Release Version Configuration Prompt Seen", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Release Version Configuration Prompt Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Release Version Configuration Prompt Seen", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Release Version Configuration Prompt Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Release Version Configuration Prompt Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Release Version Configuration Prompt Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Release Version Configuration Prompt Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Release Version Configuration Prompt Seen", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
              "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
              "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
              "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function releaseVersionConfigurationModalOpened(schema, inspectorBreakingChangeInfo, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertBreakingChangeImpactedEventCount(inspectorBreakingChangeInfo.breakingChangeImpactedEventCount));
    var messages$2 = Belt_Array.concat(messages$1, assertBreakingChangeImpactedSourceCount(inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("Fw7XyVEUW", "59814948693fd41f62778f8e427d8dd1723a66bd307bbac02ee5914eb07ef87a", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Release Version Configuration Modal Opened", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
          "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
          "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
          "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("Fw7XyVEUW", "Release Version Configuration Modal Opened", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("Fw7XyVEUW", Date.now(), "Release Version Configuration Modal Opened", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Release Version Configuration Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Release Version Configuration Modal Opened", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Release Version Configuration Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Release Version Configuration Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Release Version Configuration Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Release Version Configuration Modal Opened", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Release Version Configuration Modal Opened", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
              "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
              "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
              "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function releaseVersionConfigurationCompleted(schema, inspectorBreakingChangeInfo, releaseVersionConfiguredSourceCount, releaseVersionConfiguredSourceIds, releaseVersionConfiguredOptions, releaseVersionAppliedOptions, saveAsDefaultStatus, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertBreakingChangeImpactedEventCount(inspectorBreakingChangeInfo.breakingChangeImpactedEventCount));
    var messages$2 = Belt_Array.concat(messages$1, assertBreakingChangeImpactedSourceCount(inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount));
    var messages$3 = Belt_Array.concat(messages$2, assertReleaseVersionConfiguredSourceCount(releaseVersionConfiguredSourceCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("S_Tr0JN1V", "52e4234b1be83cb9f6f983f45181dbff94660bd40bbc1ec43efe01c9067726bf", Belt_Array.map(messages$3, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Release Version Configuration Completed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
          "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
          "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
          "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
          "Release Version Configured Source Count": releaseVersionConfiguredSourceCount,
          "Release Version Configured Source Ids": releaseVersionConfiguredSourceIds,
          "Release Version Configured Options": Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs),
          "Release Version Applied Options": Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs),
          "Save as Default Status": saveAsDefaultStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("S_Tr0JN1V", "Release Version Configuration Completed", messages$3, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "sxOJ8K-0U",
              name: "Release Version Configured Source Count",
              value: releaseVersionConfiguredSourceCount
            },
            {
              id: "rwiojdM0y",
              name: "Release Version Configured Source Ids",
              value: releaseVersionConfiguredSourceIds
            },
            {
              id: "RFSaQjI2o",
              name: "Release Version Configured Options",
              value: Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs)
            },
            {
              id: "2vDar0gGi",
              name: "Release Version Applied Options",
              value: Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs)
            },
            {
              id: "LB2rvAl-l",
              name: "Save as Default Status",
              value: saveAsDefaultStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("S_Tr0JN1V", Date.now(), "Release Version Configuration Completed", messages$3, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "sxOJ8K-0U",
              name: "Release Version Configured Source Count",
              value: releaseVersionConfiguredSourceCount
            },
            {
              id: "rwiojdM0y",
              name: "Release Version Configured Source Ids",
              value: releaseVersionConfiguredSourceIds
            },
            {
              id: "RFSaQjI2o",
              name: "Release Version Configured Options",
              value: Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs)
            },
            {
              id: "2vDar0gGi",
              name: "Release Version Applied Options",
              value: Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs)
            },
            {
              id: "LB2rvAl-l",
              name: "Save as Default Status",
              value: saveAsDefaultStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Release Version Configuration Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Configured Source Count": releaseVersionConfiguredSourceCount,
        "Release Version Configured Source Ids": releaseVersionConfiguredSourceIds,
        "Release Version Configured Options": Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs),
        "Release Version Applied Options": Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs),
        "Save as Default Status": saveAsDefaultStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Release Version Configuration Completed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Release Version Configuration Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Configured Source Count": releaseVersionConfiguredSourceCount,
        "Release Version Configured Source Ids": releaseVersionConfiguredSourceIds,
        "Release Version Configured Options": Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs),
        "Release Version Applied Options": Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs),
        "Save as Default Status": saveAsDefaultStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Release Version Configuration Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Configured Source Count": releaseVersionConfiguredSourceCount,
        "Release Version Configured Source Ids": releaseVersionConfiguredSourceIds,
        "Release Version Configured Options": Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs),
        "Release Version Applied Options": Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs),
        "Save as Default Status": saveAsDefaultStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Release Version Configuration Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Configured Source Count": releaseVersionConfiguredSourceCount,
        "Release Version Configured Source Ids": releaseVersionConfiguredSourceIds,
        "Release Version Configured Options": Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs),
        "Release Version Applied Options": Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs),
        "Save as Default Status": saveAsDefaultStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Release Version Configuration Completed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Configured Source Count": releaseVersionConfiguredSourceCount,
        "Release Version Configured Source Ids": releaseVersionConfiguredSourceIds,
        "Release Version Configured Options": Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs),
        "Release Version Applied Options": Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs),
        "Save as Default Status": saveAsDefaultStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Release Version Configuration Completed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
              "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
              "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
              "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
              "Release Version Configured Source Count": releaseVersionConfiguredSourceCount,
              "Release Version Configured Source Ids": releaseVersionConfiguredSourceIds,
              "Release Version Configured Options": Belt_Array.map(releaseVersionConfiguredOptions, releaseVersionConfiguredOptionsToJs),
              "Release Version Applied Options": Belt_Array.map(releaseVersionAppliedOptions, releaseVersionAppliedOptionsToJs),
              "Save as Default Status": saveAsDefaultStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function releaseVersionSourceOptionSelected(schema, inspectorBreakingChangeInfo, releaseVersionSourceOptionSelected$1, sourceType, sourceCategory, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertBreakingChangeImpactedEventCount(inspectorBreakingChangeInfo.breakingChangeImpactedEventCount));
    var messages$2 = Belt_Array.concat(messages$1, assertBreakingChangeImpactedSourceCount(inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("HgCJDb4uY", "bfdc56a3bd8d02f4dec2f09030593921a5dd2570efd447d7b86c9a819d474c2a", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Release Version Source Option Selected", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
          "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
          "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
          "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
          "Release Version Source Option Selected": releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1),
          "Source Type": sourceTypeToJs(sourceType),
          "Source Category": sourceCategoryToJs(sourceCategory),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("HgCJDb4uY", "Release Version Source Option Selected", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "2bKqlQuiU",
              name: "Release Version Source Option Selected",
              value: releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1)
            },
            {
              id: "yXfHGF-ag",
              name: "Source Type",
              value: sourceTypeToJs(sourceType)
            },
            {
              id: "iMwrkoUmt",
              name: "Source Category",
              value: sourceCategoryToJs(sourceCategory)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("HgCJDb4uY", Date.now(), "Release Version Source Option Selected", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "1PcfiRNBI",
              name: "Breaking Change Impacted Event Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventCount
            },
            {
              id: "QmsdjFnSm",
              name: "Breaking Change Impacted Event Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedEventIds
            },
            {
              id: "n28yD3xQ-",
              name: "Breaking Change Impacted Source Ids",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds
            },
            {
              id: "J6DF_puXQ",
              name: "Breaking Change Impacted Source Count",
              value: inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount
            },
            {
              id: "2bKqlQuiU",
              name: "Release Version Source Option Selected",
              value: releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1)
            },
            {
              id: "yXfHGF-ag",
              name: "Source Type",
              value: sourceTypeToJs(sourceType)
            },
            {
              id: "iMwrkoUmt",
              name: "Source Category",
              value: sourceCategoryToJs(sourceCategory)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Release Version Source Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Source Option Selected": releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1),
        "Source Type": sourceTypeToJs(sourceType),
        "Source Category": sourceCategoryToJs(sourceCategory),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Release Version Source Option Selected", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Release Version Source Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Source Option Selected": releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1),
        "Source Type": sourceTypeToJs(sourceType),
        "Source Category": sourceCategoryToJs(sourceCategory),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Release Version Source Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Source Option Selected": releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1),
        "Source Type": sourceTypeToJs(sourceType),
        "Source Category": sourceCategoryToJs(sourceCategory),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Release Version Source Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Source Option Selected": releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1),
        "Source Type": sourceTypeToJs(sourceType),
        "Source Category": sourceCategoryToJs(sourceCategory),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Release Version Source Option Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
        "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
        "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
        "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
        "Release Version Source Option Selected": releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1),
        "Source Type": sourceTypeToJs(sourceType),
        "Source Category": sourceCategoryToJs(sourceCategory),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Release Version Source Option Selected", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Breaking Change Impacted Event Count": inspectorBreakingChangeInfo.breakingChangeImpactedEventCount,
              "Breaking Change Impacted Event Ids": inspectorBreakingChangeInfo.breakingChangeImpactedEventIds,
              "Breaking Change Impacted Source Ids": inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds,
              "Breaking Change Impacted Source Count": inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount,
              "Release Version Source Option Selected": releaseVersionSourceOptionSelectedToJs(releaseVersionSourceOptionSelected$1),
              "Source Type": sourceTypeToJs(sourceType),
              "Source Category": sourceCategoryToJs(sourceCategory),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function inspectorAlertsInitiated(schema, inspectorSavedViews, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorSavedViewCount(inspectorSavedViews.inspectorSavedViewCount));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("mtM8B01Je", "49bdea06733ba9dd576acccef50cce5394b036d96437913dee4846dce79c87df", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Alerts Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
          "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
          "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
          "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("mtM8B01Je", "Inspector Alerts Initiated", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("mtM8B01Je", Date.now(), "Inspector Alerts Initiated", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Alerts Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Alerts Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Alerts Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Alerts Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Alerts Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Alerts Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Alerts Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
              "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
              "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
              "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorAlertsTypeSelected(schema, inspectorSavedViews, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, inspectorAlertType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorSavedViewCount(inspectorSavedViews.inspectorSavedViewCount));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("EN-O8C2zW", "136b474dbcc5a7946cd4c97409fe5b4039d66bd94e82580df5e18b14a13dd2b2", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Alerts Type Selected", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
          "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
          "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
          "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          "Inspector Alert Type": inspectorAlertType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("EN-O8C2zW", "Inspector Alerts Type Selected", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "kwrFJubq1",
              name: "Inspector Alert Type",
              value: inspectorAlertType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("EN-O8C2zW", Date.now(), "Inspector Alerts Type Selected", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "kwrFJubq1",
              name: "Inspector Alert Type",
              value: inspectorAlertType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Alerts Type Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Alerts Type Selected", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Alerts Type Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Alerts Type Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Alerts Type Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Alerts Type Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Alerts Type Selected", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
              "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
              "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
              "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              "Inspector Alert Type": inspectorAlertType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function inspectorAlertsDisabled(schema, inspectorSavedViews, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, inspectorAlertType, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorSavedViewCount(inspectorSavedViews.inspectorSavedViewCount));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorDisplayedIssueCount(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("noFPDCg1-", "06cf54d4125af9b843d5f766d40d6e817448775cf172cfec1c6ea710dec533e4", Belt_Array.map(messages$2, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Alerts Disabled", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
          "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
          "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
          "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
          "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
          "Inspector Issues Ordering": inspectorIssuesOrdering,
          "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
          "Inspector Issues Status View": inspectorIssuesStatusView,
          "Inspector Alert Type": inspectorAlertType,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("noFPDCg1-", "Inspector Alerts Disabled", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "kwrFJubq1",
              name: "Inspector Alert Type",
              value: inspectorAlertType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("noFPDCg1-", Date.now(), "Inspector Alerts Disabled", messages$2, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "oQAXjNQ_4",
              name: "Inspector Saved View Name",
              value: inspectorSavedViews.inspectorSavedViewName
            },
            {
              id: "U4bufcuh_",
              name: "Inspector Saved View Id",
              value: inspectorSavedViews.inspectorSavedViewId
            },
            {
              id: "n7olY2NJD",
              name: "Inspector Saved View Relationship",
              value: inspectorSavedViews.inspectorSavedViewRelationship
            },
            {
              id: "optDeUTmv",
              name: "Inspector Saved View Count",
              value: inspectorSavedViews.inspectorSavedViewCount
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: inspectorIssuesOrdering
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs)
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: inspectorIssueViewTypeToJs(inspectorIssueViewType)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: inspectorIssuesStatusView
            },
            {
              id: "kwrFJubq1",
              name: "Inspector Alert Type",
              value: inspectorAlertType
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Alerts Disabled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Alerts Disabled", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Alerts Disabled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Alerts Disabled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Alerts Disabled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Alerts Disabled", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
        "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
        "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
        "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
        "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
        "Inspector Issues Ordering": inspectorIssuesOrdering,
        "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
        "Inspector Issues Status View": inspectorIssuesStatusView,
        "Inspector Alert Type": inspectorAlertType,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Alerts Disabled", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Saved View Name": inspectorSavedViews.inspectorSavedViewName,
              "Inspector Saved View Id": inspectorSavedViews.inspectorSavedViewId,
              "Inspector Saved View Relationship": inspectorSavedViews.inspectorSavedViewRelationship,
              "Inspector Saved View Count": inspectorSavedViews.inspectorSavedViewCount,
              "Inspector Issues Column Ordered By": inspectorIssuesColumnOrderedByToJs(inspectorIssuesColumnOrderedBy),
              "Inspector Issues Ordering": inspectorIssuesOrdering,
              "Inspector Issues Active Filters": Belt_Array.map(inspectorIssuesActiveFilters, inspectorIssuesActiveFiltersToJs),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": inspectorIssueViewTypeToJs(inspectorIssueViewType),
              "Inspector Issues Status View": inspectorIssuesStatusView,
              "Inspector Alert Type": inspectorAlertType,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function propertiesViewInteraction(schema, propertiesViewColumnName, propertyLocation, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("yP2HQ1trf", "3bd1460e89d4885b18b0d4feed8074e533dd85d146ba232517da39d319982908", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Properties View Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
          "Property Location": propertyLocationToJs(propertyLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("yP2HQ1trf", "Properties View Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "x9rpl6YRSm",
              name: "Properties View Column Name",
              value: Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs)
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("yP2HQ1trf", Date.now(), "Properties View Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "x9rpl6YRSm",
              name: "Properties View Column Name",
              value: Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs)
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Properties View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Properties View Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Properties View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Properties View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Properties View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Properties View Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Properties View Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
              "Property Location": propertyLocationToJs(propertyLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function propertiesViewPopupInteraction(schema, propertiesViewPopupAction, propertiesViewColumnName, propertyLocation, propertyAttribute, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("KjjZFnNx4", "cb62bd3cf223be3a010fa1832f1c675fc093628c525bd685f1a7c5659755a6c6", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Properties View Popup Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Properties View Popup Action": propertiesViewPopupActionToJs(propertiesViewPopupAction),
          "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
          "Property Location": propertyLocationToJs(propertyLocation),
          "Property Attribute": propertyAttribute,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("KjjZFnNx4", "Properties View Popup Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "0RbZc7-V1",
              name: "Properties View Popup Action",
              value: propertiesViewPopupActionToJs(propertiesViewPopupAction)
            },
            {
              id: "x9rpl6YRSm",
              name: "Properties View Column Name",
              value: Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs)
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "jYISCQ-wK",
              name: "Property Attribute",
              value: propertyAttribute
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("KjjZFnNx4", Date.now(), "Properties View Popup Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "0RbZc7-V1",
              name: "Properties View Popup Action",
              value: propertiesViewPopupActionToJs(propertiesViewPopupAction)
            },
            {
              id: "x9rpl6YRSm",
              name: "Properties View Column Name",
              value: Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs)
            },
            {
              id: "aoNO8RpMdN",
              name: "Property Location",
              value: propertyLocationToJs(propertyLocation)
            },
            {
              id: "jYISCQ-wK",
              name: "Property Attribute",
              value: propertyAttribute
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Properties View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Popup Action": propertiesViewPopupActionToJs(propertiesViewPopupAction),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Attribute": propertyAttribute,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Properties View Popup Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Properties View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Popup Action": propertiesViewPopupActionToJs(propertiesViewPopupAction),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Attribute": propertyAttribute,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Properties View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Popup Action": propertiesViewPopupActionToJs(propertiesViewPopupAction),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Attribute": propertyAttribute,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Properties View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Popup Action": propertiesViewPopupActionToJs(propertiesViewPopupAction),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Attribute": propertyAttribute,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Properties View Popup Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Properties View Popup Action": propertiesViewPopupActionToJs(propertiesViewPopupAction),
        "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
        "Property Location": propertyLocationToJs(propertyLocation),
        "Property Attribute": propertyAttribute,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Properties View Popup Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Properties View Popup Action": propertiesViewPopupActionToJs(propertiesViewPopupAction),
              "Properties View Column Name": Belt_Option.map(propertiesViewColumnName, propertiesViewColumnNameToJs),
              "Property Location": propertyLocationToJs(propertyLocation),
              "Property Attribute": propertyAttribute,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function inspectorIssueStatusUpdated(schema, inspectorIssueDetails, inspectorIssuesColumnOrderedBy, inspectorIssuesOrdering, inspectorIssuesActiveFilters, inspectorDisplayedIssueCount, inspectorIssueViewType, inspectorIssuesStatusView, inspectorIssueResolveIn, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    var messages$1 = Belt_Array.concat(messages, assertInspectorIssueProportion(inspectorIssueDetails.inspectorIssueProportion));
    var messages$2 = Belt_Array.concat(messages$1, assertInspectorIssueVolume(inspectorIssueDetails.inspectorIssueVolume));
    var messages$3 = Belt_Array.concat(messages$2, assertInspectorEventVolume(inspectorIssueDetails.inspectorEventVolume));
    var messages$4 = Belt_Array.concat(messages$3, assertInspectorIssueSourceCount(inspectorIssueDetails.inspectorIssueSourceCount));
    var messages$5 = Belt_Array.concat(messages$4, assertInspectorIssueFirstSeenHoursAgo(inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo));
    var messages$6 = Belt_Array.concat(messages$5, assertInspectorIssueLastSeenHoursAgo(inspectorIssueDetails.inspectorIssueLastSeenHoursAgo));
    var messages$7 = Belt_Array.concat(messages$6, assertInspectorDisplayedIssueCountOptional(inspectorDisplayedIssueCount));
    if (!__AVO_NOOP__.contents) {
      avo_invoke("3mWXMB0Hd", "6f6cc09134ba180307b4e01c2860ad6caadb5b4f24cf6f4ea1879eb8879c319b", Belt_Array.map(messages$7, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Inspector Issue Status Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
          "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
          "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
          "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
          "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
          "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
          "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
          "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
          "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
          "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
          "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
          "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
          "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
          "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                  return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                })),
          "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
          "Inspector Issue View Type": Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs),
          "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
          "Inspector Issue Resolve In": Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("3mWXMB0Hd", "Inspector Issue Status Updated", messages$7, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FhxqbTTFK",
              name: "Inspector Issue Proportion",
              value: inspectorIssueDetails.inspectorIssueProportion
            },
            {
              id: "JioDYuCXb",
              name: "Inspector Issue Volume",
              value: inspectorIssueDetails.inspectorIssueVolume
            },
            {
              id: "Jq3Fw3nm1",
              name: "Inspector Event Volume",
              value: inspectorIssueDetails.inspectorEventVolume
            },
            {
              id: "c6YWp34Db",
              name: "Inspector Issue Source Count",
              value: inspectorIssueDetails.inspectorIssueSourceCount
            },
            {
              id: "WWIKORM2D",
              name: "Inspector Issue First Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo
            },
            {
              id: "VqRh4QggM",
              name: "Inspector Issue Last Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueLastSeenHoursAgo
            },
            {
              id: "60fnXoikqp",
              name: "Inspector Issue Type",
              value: inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType)
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueDetails.inspectorIssueId
            },
            {
              id: "QLwkicycu",
              name: "Inspector Issue Shared Id",
              value: inspectorIssueDetails.inspectorIssueSharedId
            },
            {
              id: "qMiLLbXzE",
              name: "Inspector Issue Details Interaction Type",
              value: inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType)
            },
            {
              id: "uMCjNP6Vc",
              name: "Inspector Issue Status",
              value: inspectorIssueDetails.inspectorIssueStatus
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs)
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    }))
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs)
            },
            {
              id: "HP2ZQH7E6",
              name: "Inspector Issue Resolve In",
              value: Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("3mWXMB0Hd", Date.now(), "Inspector Issue Status Updated", messages$7, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "FhxqbTTFK",
              name: "Inspector Issue Proportion",
              value: inspectorIssueDetails.inspectorIssueProportion
            },
            {
              id: "JioDYuCXb",
              name: "Inspector Issue Volume",
              value: inspectorIssueDetails.inspectorIssueVolume
            },
            {
              id: "Jq3Fw3nm1",
              name: "Inspector Event Volume",
              value: inspectorIssueDetails.inspectorEventVolume
            },
            {
              id: "c6YWp34Db",
              name: "Inspector Issue Source Count",
              value: inspectorIssueDetails.inspectorIssueSourceCount
            },
            {
              id: "WWIKORM2D",
              name: "Inspector Issue First Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo
            },
            {
              id: "VqRh4QggM",
              name: "Inspector Issue Last Seen Hours Ago",
              value: inspectorIssueDetails.inspectorIssueLastSeenHoursAgo
            },
            {
              id: "60fnXoikqp",
              name: "Inspector Issue Type",
              value: inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType)
            },
            {
              id: "Xyq1tp6zw",
              name: "Inspector Issue Id",
              value: inspectorIssueDetails.inspectorIssueId
            },
            {
              id: "QLwkicycu",
              name: "Inspector Issue Shared Id",
              value: inspectorIssueDetails.inspectorIssueSharedId
            },
            {
              id: "qMiLLbXzE",
              name: "Inspector Issue Details Interaction Type",
              value: inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType)
            },
            {
              id: "uMCjNP6Vc",
              name: "Inspector Issue Status",
              value: inspectorIssueDetails.inspectorIssueStatus
            },
            {
              id: "TJ-9fs92B",
              name: "Inspector Issues Column Ordered By",
              value: Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs)
            },
            {
              id: "WifAf-xXv",
              name: "Inspector Issues Ordering",
              value: Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs)
            },
            {
              id: "eBPocvGMr",
              name: "Inspector Issues Active Filters",
              value: Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    }))
            },
            {
              id: "BRTP-mzzf",
              name: "Inspector Displayed Issue Count",
              value: inspectorDisplayedIssueCount
            },
            {
              id: "SaceF3yMC",
              name: "Inspector Issue View Type",
              value: Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs)
            },
            {
              id: "Z89naLFg9",
              name: "Inspector Issues Status View",
              value: Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs)
            },
            {
              id: "HP2ZQH7E6",
              name: "Inspector Issue Resolve In",
              value: Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Inspector Issue Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        "Inspector Issue Resolve In": Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Inspector Issue Status Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Inspector Issue Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        "Inspector Issue Resolve In": Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Inspector Issue Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        "Inspector Issue Resolve In": Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Inspector Issue Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        "Inspector Issue Resolve In": Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Inspector Issue Status Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
        "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
        "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
        "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
        "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
        "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
        "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
        "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
        "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
        "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
        "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
        "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
        "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
        "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
              })),
        "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
        "Inspector Issue View Type": Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs),
        "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
        "Inspector Issue Resolve In": Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Inspector Issue Status Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Inspector Issue Proportion": inspectorIssueDetails.inspectorIssueProportion,
              "Inspector Issue Volume": inspectorIssueDetails.inspectorIssueVolume,
              "Inspector Event Volume": inspectorIssueDetails.inspectorEventVolume,
              "Inspector Issue Source Count": inspectorIssueDetails.inspectorIssueSourceCount,
              "Inspector Issue First Seen Hours Ago": inspectorIssueDetails.inspectorIssueFirstSeenHoursAgo,
              "Inspector Issue Last Seen Hours Ago": inspectorIssueDetails.inspectorIssueLastSeenHoursAgo,
              "Inspector Issue Type": inspectorIssueTypeToJs(inspectorIssueDetails.inspectorIssueType),
              "Inspector Issue Id": inspectorIssueDetails.inspectorIssueId,
              "Inspector Issue Shared Id": inspectorIssueDetails.inspectorIssueSharedId,
              "Inspector Issue Details Interaction Type": inspectorIssueDetailsInteractionTypeToJs(inspectorIssueDetails.inspectorIssueDetailsInteractionType),
              "Inspector Issue Status": inspectorIssueDetails.inspectorIssueStatus,
              "Inspector Issues Column Ordered By": Belt_Option.map(inspectorIssuesColumnOrderedBy, inspectorIssuesColumnOrderedByToJs),
              "Inspector Issues Ordering": Belt_Option.map(inspectorIssuesOrdering, inspectorIssuesOrderingToJs),
              "Inspector Issues Active Filters": Belt_Option.map(inspectorIssuesActiveFilters, (function (maybe) {
                      return Belt_Array.map(maybe, inspectorIssuesActiveFiltersToJs);
                    })),
              "Inspector Displayed Issue Count": inspectorDisplayedIssueCount,
              "Inspector Issue View Type": Belt_Option.map(inspectorIssueViewType, inspectorIssueViewTypeToJs),
              "Inspector Issues Status View": Belt_Option.map(inspectorIssuesStatusView, inspectorIssuesStatusViewToJs),
              "Inspector Issue Resolve In": Belt_Option.map(inspectorIssueResolveIn, inspectorIssueResolveInToJs),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function importChanged(schema, importChange, eventName, propertyName, importStartedLocation, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("050wEDpMY", "47376f83c3000cdc2abffc83c24f05ee109e52e10c636a83a95c5a1846df0087", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Import Changed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Import Change": importChangeToJs(importChange),
          "Event Name": eventName,
          "Property Name": propertyName,
          "Import Started Location": importStartedLocationToJs(importStartedLocation),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("050wEDpMY", "Import Changed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "4Z1zt3EKU",
              name: "Import Change",
              value: importChangeToJs(importChange)
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "YVjMV8IaQ",
              name: "Import Started Location",
              value: importStartedLocationToJs(importStartedLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("050wEDpMY", Date.now(), "Import Changed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "4Z1zt3EKU",
              name: "Import Change",
              value: importChangeToJs(importChange)
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "ff2ec394-097b-47cf-9747-3495a2181272",
              name: "Property Name",
              value: propertyName
            },
            {
              id: "YVjMV8IaQ",
              name: "Import Started Location",
              value: importStartedLocationToJs(importStartedLocation)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Import Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Import Change": importChangeToJs(importChange),
        "Event Name": eventName,
        "Property Name": propertyName,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Import Changed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Import Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Import Change": importChangeToJs(importChange),
        "Event Name": eventName,
        "Property Name": propertyName,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Import Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Import Change": importChangeToJs(importChange),
        "Event Name": eventName,
        "Property Name": propertyName,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Import Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Import Change": importChangeToJs(importChange),
        "Event Name": eventName,
        "Property Name": propertyName,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Import Changed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Import Change": importChangeToJs(importChange),
        "Event Name": eventName,
        "Property Name": propertyName,
        "Import Started Location": importStartedLocationToJs(importStartedLocation),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Import Changed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Import Change": importChangeToJs(importChange),
              "Event Name": eventName,
              "Property Name": propertyName,
              "Import Started Location": importStartedLocationToJs(importStartedLocation),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function subscriptionDetailsViewed(schema, subscriptionPlan, subscriptionPlanSlug, subscriptionPrice, subscriptionTimeUnit, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("1ouOcdatQ", "6ca1ca918ac0b9b4319036bb071ab537d6430ecb5154f414ba4c66d5d729b80c", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Subscription Details Viewed", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
          "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
          "Subscription Price": subscriptionPrice,
          "Subscription Time Unit": subscriptionTimeUnit,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("1ouOcdatQ", "Subscription Details Viewed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("1ouOcdatQ", Date.now(), "Subscription Details Viewed", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "919a9c2d-9408-40c1-8c1c-b218eb748f59",
              name: "Subscription Plan",
              value: Belt_Option.map(subscriptionPlan, subscriptionPlanToJs)
            },
            {
              id: "eRTibzpkyf",
              name: "Subscription Plan Slug",
              value: subscriptionPlanSlugToJs(subscriptionPlanSlug)
            },
            {
              id: "h9-srJWwlo",
              name: "Subscription Price",
              value: subscriptionPrice
            },
            {
              id: "B6itY8VWZy",
              name: "Subscription Time Unit",
              value: subscriptionTimeUnit
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Subscription Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Subscription Details Viewed", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Subscription Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Subscription Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Subscription Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Subscription Details Viewed", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
        "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
        "Subscription Price": subscriptionPrice,
        "Subscription Time Unit": subscriptionTimeUnit,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Subscription Details Viewed", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Subscription Plan": Belt_Option.map(subscriptionPlan, subscriptionPlanToJs),
              "Subscription Plan Slug": subscriptionPlanSlugToJs(subscriptionPlanSlug),
              "Subscription Price": subscriptionPrice,
              "Subscription Time Unit": subscriptionTimeUnit,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function onboardingChecklistPathSelected(schema, checklistType, checklistPath, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("yiS0V5I6U", "c6c86fd9205a120ae96d299c52203a0701b40218f7b54760ce48d8d1ea9295ab", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Path Selected", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Type": checklistTypeToJs(checklistType),
          "Checklist Path": checklistPathToJs(checklistPath),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("yiS0V5I6U", "Onboarding Checklist Path Selected", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "mrmUbf7B0",
              name: "Checklist Path",
              value: checklistPathToJs(checklistPath)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("yiS0V5I6U", Date.now(), "Onboarding Checklist Path Selected", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "mrmUbf7B0",
              name: "Checklist Path",
              value: checklistPathToJs(checklistPath)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Path Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Checklist Path": checklistPathToJs(checklistPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Path Selected", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Path Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Checklist Path": checklistPathToJs(checklistPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Path Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Checklist Path": checklistPathToJs(checklistPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Path Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Checklist Path": checklistPathToJs(checklistPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Path Selected", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Checklist Path": checklistPathToJs(checklistPath),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Onboarding Checklist Path Selected", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Type": checklistTypeToJs(checklistType),
              "Checklist Path": checklistPathToJs(checklistPath),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function sandboxInteraction(schema, sandboxInteraction$1, sandboxElement, userAuthenticationStatus, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("388NJtN2nN", "1895e77b3cc05fb0747566e6d8750157f4d029048812b39aed4550c1256883e6", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sandbox Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction$1),
          "Sandbox Element": sandboxElementToJs(sandboxElement),
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("388NJtN2nN", "Sandbox Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fDJr3ZdGb",
              name: "Sandbox Interaction",
              value: sandboxInteractionToJs(sandboxInteraction$1)
            },
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("388NJtN2nN", Date.now(), "Sandbox Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "fDJr3ZdGb",
              name: "Sandbox Interaction",
              value: sandboxInteractionToJs(sandboxInteraction$1)
            },
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Sandbox Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction$1),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Sandbox Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Sandbox Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction$1),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Sandbox Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction$1),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Sandbox Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction$1),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Sandbox Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction$1),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Sandbox Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction$1),
              "Sandbox Element": sandboxElementToJs(sandboxElement),
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace
            });
}

function sandboxModalSeen(schema, sandboxElement, userAuthenticationStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("CfkY66l-nb", "90eef6246056870fc5a7b735499492aade14d5bb652fc1387c33f2edf1c2da63", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sandbox Modal Seen", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Sandbox Element": sandboxElementToJs(sandboxElement),
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("CfkY66l-nb", "Sandbox Modal Seen", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("CfkY66l-nb", Date.now(), "Sandbox Modal Seen", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sandbox Modal Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sandbox Modal Seen", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sandbox Modal Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sandbox Modal Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sandbox Modal Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sandbox Modal Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sandbox Modal Seen", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Sandbox Element": sandboxElementToJs(sandboxElement),
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function onboardingChecklistInteraction(schema, checklistType, onboardingChecklistInteraction$1, associateEventWithBranch, associateEventWithWorkspace) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("CYhm403RR", "7d4c24db104bf39d82cefd49f10d2ef22b3a28b8454a3951cb56ca00adc0e6cf", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Onboarding Checklist Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Checklist Type": checklistTypeToJs(checklistType),
          "Onboarding Checklist Interaction": onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("CYhm403RR", "Onboarding Checklist Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "w69QMNbkS",
              name: "Onboarding Checklist Interaction",
              value: onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("CYhm403RR", Date.now(), "Onboarding Checklist Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "iMeqv_XZno",
              name: "Checklist Type",
              value: checklistTypeToJs(checklistType)
            },
            {
              id: "w69QMNbkS",
              name: "Onboarding Checklist Interaction",
              value: onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Onboarding Checklist Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Onboarding Checklist Interaction": onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  var intercomProperties = {};
  logEvent("Onboarding Checklist Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Onboarding Checklist Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Onboarding Checklist Interaction": onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  logEvent$1("Onboarding Checklist Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Onboarding Checklist Interaction": onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Onboarding Checklist Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Onboarding Checklist Interaction": onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Onboarding Checklist Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Checklist Type": checklistTypeToJs(checklistType),
        "Onboarding Checklist Interaction": onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Branch: associateEventWithBranch,
        Workspace: associateEventWithWorkspace
      });
  return logEventWithGroups("Onboarding Checklist Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Checklist Type": checklistTypeToJs(checklistType),
              "Onboarding Checklist Interaction": onboardingChecklistInteractionToJs(onboardingChecklistInteraction$1),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Branch: associateEventWithBranch,
              Workspace: associateEventWithWorkspace
            });
}

function anonymousUserCreated(userId_) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("8IgPgmVZ5N", "07b137f24cb2ed579b299ac9108d08e7169c96883a51121454bfb162e835d1d4", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Anonymous User Created", {
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("8IgPgmVZ5N", "Anonymous User Created", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("8IgPgmVZ5N", Date.now(), "Anonymous User Created", messages, [
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._1(Belt_Option.getExn(postHog.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Anonymous User Created", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify(userId_);
  var intercomProperties = {};
  logEvent("Anonymous User Created", intercomProperties);
  Curry._1(Belt_Option.getExn(segment.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Anonymous User Created", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify$1(userId_);
  logEvent$1("Anonymous User Created", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(intercomDestinationInterface.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Anonymous User Created", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._1(Belt_Option.getExn(inspectorCustomDestination.contents).identify, userId_);
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Anonymous User Created", {
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  identify$2(userId_);
  return logEvent$2("Anonymous User Created", {
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function sandboxInteractionWithoutWorkspace(sandboxInteraction, sandboxElement, userAuthenticationStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("hY829Quc1", "e1b5290c7f1ce2bc192d52167c84d5858b6e6e05db4acd73df2788f1a243e0af", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sandbox Interaction (Without Workspace)", {
          "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction),
          "Sandbox Element": sandboxElementToJs(sandboxElement),
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("hY829Quc1", "Sandbox Interaction (Without Workspace)", messages, [
            {
              id: "fDJr3ZdGb",
              name: "Sandbox Interaction",
              value: sandboxInteractionToJs(sandboxInteraction)
            },
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("hY829Quc1", Date.now(), "Sandbox Interaction (Without Workspace)", messages, [
            {
              id: "fDJr3ZdGb",
              name: "Sandbox Interaction",
              value: sandboxInteractionToJs(sandboxInteraction)
            },
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sandbox Interaction", {
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sandbox Interaction", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sandbox Interaction", {
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sandbox Interaction", {
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sandbox Interaction", {
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sandbox Interaction (Without Workspace)", {
        "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction),
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sandbox Interaction", {
              "Sandbox Interaction": sandboxInteractionToJs(sandboxInteraction),
              "Sandbox Element": sandboxElementToJs(sandboxElement),
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function sandboxModalSeenWithoutWorkspace(sandboxElement, userAuthenticationStatus) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("W7gskBjZa", "c10fcbd0010a250ad74f98a6fcf3985c9ba09164ee6d44479304d8c0627f7b43", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Sandbox Modal Seen (Without Workspace)", {
          "Sandbox Element": sandboxElementToJs(sandboxElement),
          "User Authentication Status": userAuthenticationStatus,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("W7gskBjZa", "Sandbox Modal Seen (Without Workspace)", messages, [
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("W7gskBjZa", Date.now(), "Sandbox Modal Seen (Without Workspace)", messages, [
            {
              id: "xEJw8068P",
              name: "Sandbox Element",
              value: sandboxElementToJs(sandboxElement)
            },
            {
              id: "nPHYS8ehr",
              name: "User Authentication Status",
              value: userAuthenticationStatus
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Sandbox Modal Seen", {
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Sandbox Modal Seen", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Sandbox Modal Seen", {
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Sandbox Modal Seen", {
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Sandbox Modal Seen", {
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Sandbox Modal Seen (Without Workspace)", {
        "Sandbox Element": sandboxElementToJs(sandboxElement),
        "User Authentication Status": userAuthenticationStatus,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Sandbox Modal Seen", {
              "Sandbox Element": sandboxElementToJs(sandboxElement),
              "User Authentication Status": userAuthenticationStatus,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function learnHubSeen(schema, learnHubState, learnHubContentTitle, pageName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("0E9Wq0ovV-", "c23f282a4d71cd74b4ff63602306015033ba5b8f16417dadeb87d372b827c142", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Learn Hub Seen", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Learn Hub State": learnHubState,
          "Learn Hub Content Title": learnHubContentTitle,
          "Page Name": pageNameToJs(pageName),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("0E9Wq0ovV-", "Learn Hub Seen", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EecaWUB5F",
              name: "Learn Hub State",
              value: learnHubState
            },
            {
              id: "ha1o1q-SN",
              name: "Learn Hub Content Title",
              value: learnHubContentTitle
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("0E9Wq0ovV-", Date.now(), "Learn Hub Seen", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EecaWUB5F",
              name: "Learn Hub State",
              value: learnHubState
            },
            {
              id: "ha1o1q-SN",
              name: "Learn Hub Content Title",
              value: learnHubContentTitle
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Learn Hub Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Learn Hub Seen", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Learn Hub Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Learn Hub Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Learn Hub Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Learn Hub Seen", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Learn Hub Seen", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Learn Hub State": learnHubState,
              "Learn Hub Content Title": learnHubContentTitle,
              "Page Name": pageNameToJs(pageName),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function learnHubInteracted(schema, learnHubState, learnHubContentTitle, learnHubInteraction, learnHubContentCallToActions, pageName) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("T-7yuWYf-l", "e83d24021fe98b31fd0f34dc3213927a33f44f9a4f3c80a62623060f4ba41f82", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Learn Hub Interacted", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Learn Hub State": learnHubState,
          "Learn Hub Content Title": learnHubContentTitle,
          "Learn Hub Interaction": learnHubInteractionToJs(learnHubInteraction),
          "Learn Hub Content Call to Actions": Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs),
          "Page Name": pageNameToJs(pageName),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("T-7yuWYf-l", "Learn Hub Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EecaWUB5F",
              name: "Learn Hub State",
              value: learnHubState
            },
            {
              id: "ha1o1q-SN",
              name: "Learn Hub Content Title",
              value: learnHubContentTitle
            },
            {
              id: "3WDPyhZor",
              name: "Learn Hub Interaction",
              value: learnHubInteractionToJs(learnHubInteraction)
            },
            {
              id: "wGiGQwlsK",
              name: "Learn Hub Content Call to Actions",
              value: Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("T-7yuWYf-l", Date.now(), "Learn Hub Interacted", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "EecaWUB5F",
              name: "Learn Hub State",
              value: learnHubState
            },
            {
              id: "ha1o1q-SN",
              name: "Learn Hub Content Title",
              value: learnHubContentTitle
            },
            {
              id: "3WDPyhZor",
              name: "Learn Hub Interaction",
              value: learnHubInteractionToJs(learnHubInteraction)
            },
            {
              id: "wGiGQwlsK",
              name: "Learn Hub Content Call to Actions",
              value: Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs)
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: pageNameToJs(pageName)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._2(Belt_Option.getExn(postHog.contents).logEvent, "Learn Hub Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Learn Hub Interaction": learnHubInteractionToJs(learnHubInteraction),
        "Learn Hub Content Call to Actions": Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  var intercomProperties = {};
  logEvent("Learn Hub Interacted", intercomProperties);
  Curry._2(Belt_Option.getExn(segment.contents).logEvent, "Learn Hub Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Learn Hub Interaction": learnHubInteractionToJs(learnHubInteraction),
        "Learn Hub Content Call to Actions": Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  logEvent$1("Learn Hub Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Learn Hub Interaction": learnHubInteractionToJs(learnHubInteraction),
        "Learn Hub Content Call to Actions": Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(intercomDestinationInterface.contents).logEvent, "Learn Hub Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Learn Hub Interaction": learnHubInteractionToJs(learnHubInteraction),
        "Learn Hub Content Call to Actions": Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._2(Belt_Option.getExn(inspectorCustomDestination.contents).logEvent, "Learn Hub Interacted", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Learn Hub State": learnHubState,
        "Learn Hub Content Title": learnHubContentTitle,
        "Learn Hub Interaction": learnHubInteractionToJs(learnHubInteraction),
        "Learn Hub Content Call to Actions": Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs),
        "Page Name": pageNameToJs(pageName),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  return logEvent$2("Learn Hub Interacted", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Learn Hub State": learnHubState,
              "Learn Hub Content Title": learnHubContentTitle,
              "Learn Hub Interaction": learnHubInteractionToJs(learnHubInteraction),
              "Learn Hub Content Call to Actions": Belt_Array.map(learnHubContentCallToActions, learnHubContentCallToActionsToJs),
              "Page Name": pageNameToJs(pageName),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            });
}

function eventVariantCreated(schema, eventId, eventName, eventVariantId, associateEventWithWorkspace, associateEventWithBranch, associateEventWithEvent) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("tlIcgVy4ho", "8fb274d96bf41fd8af19a21064bd45533fbe83383c6cc150d21eb470e3acc3e3", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Variant Created", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Variant Id": eventVariantId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("tlIcgVy4ho", "Event Variant Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("tlIcgVy4ho", Date.now(), "Event Variant Created", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Variant Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  var intercomProperties = {};
  logEvent("Event Variant Created", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Variant Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  logEvent$1("Event Variant Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Variant Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Variant Created", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  return logEventWithGroups("Event Variant Created", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Variant Id": eventVariantId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch,
              Event: associateEventWithEvent
            });
}

function eventVariantInitiated(schema, eventId, eventName, associateEventWithWorkspace, associateEventWithBranch, associateEventWithEvent) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("DIqnQyHR-9", "b1214be802c6a99c24d2ed66e36b0368ae020f17978f412a40c390ba33003fad", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Variant Initiated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("DIqnQyHR-9", "Event Variant Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("DIqnQyHR-9", Date.now(), "Event Variant Initiated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Variant Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  var intercomProperties = {};
  logEvent("Event Variant Initiated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Variant Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  logEvent$1("Event Variant Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Variant Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Variant Initiated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  return logEventWithGroups("Event Variant Initiated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch,
              Event: associateEventWithEvent
            });
}

function eventVariantArchived(schema, eventId, eventName, eventVariantId, associateEventWithWorkspace, associateEventWithBranch, associateEventWithEvent) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("eAYIyaTsS5", "78f71e4991ab8886ac14a79523b252385cd940d67fd830aa443bacba1a5124d3", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Variant Archived", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Variant Id": eventVariantId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("eAYIyaTsS5", "Event Variant Archived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("eAYIyaTsS5", Date.now(), "Event Variant Archived", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Variant Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  var intercomProperties = {};
  logEvent("Event Variant Archived", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Variant Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  logEvent$1("Event Variant Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Variant Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Variant Archived", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  return logEventWithGroups("Event Variant Archived", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Variant Id": eventVariantId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch,
              Event: associateEventWithEvent
            });
}

function eventVariantUpdated(schema, eventId, eventName, eventVariantUpdateType, eventVariantId, associateEventWithWorkspace, associateEventWithBranch, associateEventWithEvent) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("PhbY4jhajl", "151306556d7fbfa093298ac084071575fe1d1291e0582063030f3c076d688e62", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Variant Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
          "Event Variant Id": eventVariantId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("PhbY4jhajl", "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: eventVariantUpdateTypeToJs(eventVariantUpdateType)
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("PhbY4jhajl", Date.now(), "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: eventVariantUpdateTypeToJs(eventVariantUpdateType)
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  var intercomProperties = {};
  logEvent("Event Variant Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  logEvent$1("Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  return logEventWithGroups("Event Variant Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
              "Event Variant Id": eventVariantId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch,
              Event: associateEventWithEvent
            });
}

function eventVariantUpdatedProperty(schema, eventId, eventName, eventVariantUpdateType, eventVariantId, propertyId, associateEventWithWorkspace, associateEventWithBranch, associateEventWithEvent) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("PhbY4jhajl.rs86z2Cbx", "2831756dabaf44cef0f9e2d7e666ff979352e8de420ffe64518c822da60be52a", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Variant Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
          "Event Variant Id": eventVariantId,
          "Property Id": propertyId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("PhbY4jhajl.rs86z2Cbx", "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: eventVariantUpdateTypeToJs(eventVariantUpdateType)
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("PhbY4jhajl.rs86z2Cbx", Date.now(), "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: eventVariantUpdateTypeToJs(eventVariantUpdateType)
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  var intercomProperties = {};
  logEvent("Event Variant Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  logEvent$1("Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  return logEventWithGroups("Event Variant Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
              "Event Variant Id": eventVariantId,
              "Property Id": propertyId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch,
              Event: associateEventWithEvent
            });
}

function eventVariantUpdatedPropertyPresenceOverride(schema, eventId, eventName, eventVariantId, propertyId, eventVariantPropertyPresenceOverrideTypeBefore, eventVariantPropertyPresenceOverrideTypeAfter, associateEventWithWorkspace, associateEventWithBranch, associateEventWithEvent) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("PhbY4jhajl.A1e8cYMbl", "53cc483aa9503ba1797a1c802860f345e6f381c94fd927a1ed3eb81f7cfbf681", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Variant Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Variant Update Type": "Change presence override",
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Variant Id": eventVariantId,
          "Property Id": propertyId,
          "Event Variant Property Presence Override Type Before": eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore),
          "Event Variant Property Presence Override Type After": eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter),
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("PhbY4jhajl.A1e8cYMbl", "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: "Change presence override"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "AcS48IzBR",
              name: "Event Variant Property Presence Override Type Before",
              value: eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore)
            },
            {
              id: "aEKywv2QFU",
              name: "Event Variant Property Presence Override Type After",
              value: eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("PhbY4jhajl.A1e8cYMbl", Date.now(), "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: "Change presence override"
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "AcS48IzBR",
              name: "Event Variant Property Presence Override Type Before",
              value: eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore)
            },
            {
              id: "aEKywv2QFU",
              name: "Event Variant Property Presence Override Type After",
              value: eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter)
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Variant Update Type": "Change presence override",
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        "Event Variant Property Presence Override Type Before": eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore),
        "Event Variant Property Presence Override Type After": eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  var intercomProperties = {};
  logEvent("Event Variant Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Variant Update Type": "Change presence override",
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        "Event Variant Property Presence Override Type Before": eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore),
        "Event Variant Property Presence Override Type After": eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  logEvent$1("Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Variant Update Type": "Change presence override",
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        "Event Variant Property Presence Override Type Before": eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore),
        "Event Variant Property Presence Override Type After": eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Variant Update Type": "Change presence override",
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        "Event Variant Property Presence Override Type Before": eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore),
        "Event Variant Property Presence Override Type After": eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Variant Update Type": "Change presence override",
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Id": eventVariantId,
        "Property Id": propertyId,
        "Event Variant Property Presence Override Type Before": eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore),
        "Event Variant Property Presence Override Type After": eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter),
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  return logEventWithGroups("Event Variant Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Variant Update Type": "Change presence override",
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Variant Id": eventVariantId,
              "Property Id": propertyId,
              "Event Variant Property Presence Override Type Before": eventVariantPropertyPresenceOverrideTypeBeforeToJs(eventVariantPropertyPresenceOverrideTypeBefore),
              "Event Variant Property Presence Override Type After": eventVariantPropertyPresenceOverrideTypeAfterToJs(eventVariantPropertyPresenceOverrideTypeAfter),
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch,
              Event: associateEventWithEvent
            });
}

function eventVariantUpdatedSource(schema, eventId, eventName, eventVariantUpdateType, eventVariantId, sourceId, associateEventWithWorkspace, associateEventWithBranch, associateEventWithEvent) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("PhbY4jhajl.OL7u7uxVE", "e205ed79017a5974ba79bd0ab15a780ca2c6ec9cdfb93ec47cf60c2c383676f5", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Event Variant Updated", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Event Id": eventId,
          "Event Name": eventName,
          "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
          "Event Variant Id": eventVariantId,
          "Source Id": sourceId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("PhbY4jhajl.OL7u7uxVE", "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: eventVariantUpdateTypeToJs(eventVariantUpdateType)
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: sourceId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("PhbY4jhajl.OL7u7uxVE", Date.now(), "Event Variant Updated", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "6802001a-dd3d-449c-86c9-3dd1e67e48bf",
              name: "Event Name",
              value: eventName
            },
            {
              id: "_Eotc_uir",
              name: "Event Variant Update Type",
              value: eventVariantUpdateTypeToJs(eventVariantUpdateType)
            },
            {
              id: "HwvoGGVTw",
              name: "Event Variant Id",
              value: eventVariantId
            },
            {
              id: "7d9b094e-46bb-43bb-9915-2b7c5b1342d0",
              name: "Source Id",
              value: sourceId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  var intercomProperties = {};
  logEvent("Event Variant Updated", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  logEvent$1("Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Event Variant Updated", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Event Id": eventId,
        "Event Name": eventName,
        "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
        "Event Variant Id": eventVariantId,
        "Source Id": sourceId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch,
        Event: associateEventWithEvent
      });
  return logEventWithGroups("Event Variant Updated", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Event Id": eventId,
              "Event Name": eventName,
              "Event Variant Update Type": eventVariantUpdateTypeToJs(eventVariantUpdateType),
              "Event Variant Id": eventVariantId,
              "Source Id": sourceId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch,
              Event: associateEventWithEvent
            });
}

function regexInteraction(schema, regexInteractionType, propertyId, associateEventWithWorkspace, associateEventWithBranch) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("b67jaAncqi", "4289e5ca09d98037dafbc823d928016098d38b88cb3db4259279dd93c3819ae0", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Regex Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Page Name": "Property",
          "Regex Interaction Type": regexInteractionType,
          "Property Id": propertyId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("b67jaAncqi", "Regex Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: "Property"
            },
            {
              id: "JPzMubCmt",
              name: "Regex Interaction Type",
              value: regexInteractionType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("b67jaAncqi", Date.now(), "Regex Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: "Property"
            },
            {
              id: "JPzMubCmt",
              name: "Regex Interaction Type",
              value: regexInteractionType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Property",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Regex Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Property",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Property",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Property",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Property",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  return logEventWithGroups("Regex Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Page Name": "Property",
              "Regex Interaction Type": regexInteractionType,
              "Property Id": propertyId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

function regexInteractionEventSpecific(schema, regexInteractionType, propertyId, eventId, associateEventWithWorkspace, associateEventWithBranch) {
  if (__ENV__.contents !== "prod" || __WEB_DEBUGGER__.contents) {
    var messages = [];
    if (!__AVO_NOOP__.contents) {
      avo_invoke("b67jaAncqi.LeQeaDO_S", "63daaae2104daef038907c03680467d1312c444d96e263336b956cc89d2cc8df", Belt_Array.map(messages, (function (message) {
                  return {
                          tag: message.tag,
                          propertyId: message.propertyId
                        };
                })));
    }
    logEventSent("Regex Interaction", {
          "Schema Id": schema.schemaId,
          "Schema Name": schema.schemaName,
          "Schema Billing Status": schema.schemaBillingStatus,
          "Branch Id": schema.branchId,
          "Branch Name": schema.branchName,
          "Schema Subscription Plan": schema.schemaSubscriptionPlan,
          "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
          "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
          "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
          "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
          "Schema Type": schema.schemaType,
          "Page Name": "Event",
          "Regex Interaction Type": regexInteractionType,
          "Property Id": propertyId,
          "Event Id": eventId,
          Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
          Version: Belt_Option.getExn(sysVersion.contents)
        }, {});
    if (__WEB_DEBUGGER__.contents) {
      _avo_debugger_log("b67jaAncqi.LeQeaDO_S", "Regex Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: "Event"
            },
            {
              id: "JPzMubCmt",
              name: "Regex Interaction Type",
              value: regexInteractionType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], Belt_Array.concat([], []));
    }
    if (__ENV__.contents !== "prod" && __MOBILE_DEBUGGER__.contents !== undefined || __ENV__.contents === "prod" && _mobile_debugger_enabled(undefined)) {
      _mobile_debugger_post_event("b67jaAncqi.LeQeaDO_S", Date.now(), "Regex Interaction", messages, [
            {
              id: "40958e87-d69a-4d5a-98f8-b36922466787",
              name: "Schema Id",
              value: schema.schemaId
            },
            {
              id: "a49af1dc-3166-45db-a906-86179135854b",
              name: "Schema Name",
              value: schema.schemaName
            },
            {
              id: "bf9e6b00-ce68-4379-9172-134a5ab4d495",
              name: "Schema Billing Status",
              value: schema.schemaBillingStatus
            },
            {
              id: "tlrUtYcti",
              name: "Branch Id",
              value: schema.branchId
            },
            {
              id: "9xcXzuN-3",
              name: "Branch Name",
              value: schema.branchName
            },
            {
              id: "KZ9nbzWseP",
              name: "Schema Subscription Plan",
              value: schema.schemaSubscriptionPlan
            },
            {
              id: "hoYGdti-jm",
              name: "Schema Trial Plan",
              value: Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs)
            },
            {
              id: "FlvR9BrYsX",
              name: "Authentication Config",
              value: authenticationConfigToJs(schema.authenticationConfig)
            },
            {
              id: "VbCcDZsBB",
              name: "Schema Subscription Plan Slug",
              value: schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug)
            },
            {
              id: "wJEm0-Jtu3",
              name: "Schema Trial Plan Slug",
              value: Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs)
            },
            {
              id: "BNJvykjYU",
              name: "Schema Type",
              value: schema.schemaType
            },
            {
              id: "36cb5782-efae-4557-9528-331fcf4faf1d",
              name: "Page Name",
              value: "Event"
            },
            {
              id: "JPzMubCmt",
              name: "Regex Interaction Type",
              value: regexInteractionType
            },
            {
              id: "1d7831cf-44df-45ca-87f9-e4f273fdc057",
              name: "Property Id",
              value: propertyId
            },
            {
              id: "5feae078-eb50-4a01-b374-ca703fe298a9",
              name: "Event Id",
              value: eventId
            },
            {
              id: "9e5c4ff5-d5f6-4e82-b061-d5fa02755aae",
              name: "Client",
              value: clientToJs(Belt_Option.getExn(sysClient.contents))
            },
            {
              id: "2fad5bf3-7782-49a2-acc2-825daf823095",
              name: "Version",
              value: Belt_Option.getExn(sysVersion.contents)
            }
          ], []);
    }
    
  }
  if (__AVO_NOOP__.contents) {
    return ;
  }
  Curry._3(Belt_Option.getExn(postHog.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Event",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  var intercomProperties = {};
  logEvent("Regex Interaction", intercomProperties);
  Curry._3(Belt_Option.getExn(segment.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Event",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  logEvent$1("Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Event",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      });
  Curry._3(Belt_Option.getExn(intercomDestinationInterface.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Event",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  Curry._3(Belt_Option.getExn(inspectorCustomDestination.contents).logEventWithGroups, "Regex Interaction", {
        "Schema Id": schema.schemaId,
        "Schema Name": schema.schemaName,
        "Schema Billing Status": schema.schemaBillingStatus,
        "Branch Id": schema.branchId,
        "Branch Name": schema.branchName,
        "Schema Subscription Plan": schema.schemaSubscriptionPlan,
        "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
        "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
        "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
        "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
        "Schema Type": schema.schemaType,
        "Page Name": "Event",
        "Regex Interaction Type": regexInteractionType,
        "Property Id": propertyId,
        "Event Id": eventId,
        Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
        Version: Belt_Option.getExn(sysVersion.contents)
      }, {
        Workspace: associateEventWithWorkspace,
        Branch: associateEventWithBranch
      });
  return logEventWithGroups("Regex Interaction", {
              "Schema Id": schema.schemaId,
              "Schema Name": schema.schemaName,
              "Schema Billing Status": schema.schemaBillingStatus,
              "Branch Id": schema.branchId,
              "Branch Name": schema.branchName,
              "Schema Subscription Plan": schema.schemaSubscriptionPlan,
              "Schema Trial Plan": Belt_Option.map(schema.schemaTrialPlan, schemaTrialPlanToJs),
              "Authentication Config": authenticationConfigToJs(schema.authenticationConfig),
              "Schema Subscription Plan Slug": schemaSubscriptionPlanSlugToJs(schema.schemaSubscriptionPlanSlug),
              "Schema Trial Plan Slug": Belt_Option.map(schema.schemaTrialPlanSlug, schemaTrialPlanSlugToJs),
              "Schema Type": schema.schemaType,
              "Page Name": "Event",
              "Regex Interaction Type": regexInteractionType,
              "Property Id": propertyId,
              "Event Id": eventId,
              Client: clientToJs(Belt_Option.getExn(sysClient.contents)),
              Version: Belt_Option.getExn(sysVersion.contents)
            }, {
              Workspace: associateEventWithWorkspace,
              Branch: associateEventWithBranch
            });
}

export {
  authenticationStatusToJs ,
  authenticationStatusFromJs ,
  propertiesViewPopupActionToJs ,
  propertiesViewPopupActionFromJs ,
  authenticationErrorToJs ,
  authenticationErrorFromJs ,
  toRoleToJs ,
  toRoleFromJs ,
  fromRoleToJs ,
  fromRoleFromJs ,
  branchLinkLocationToJs ,
  branchLinkLocationFromJs ,
  currentSortingToJs ,
  currentSortingFromJs ,
  toScreenToJs ,
  toScreenFromJs ,
  eventTypesSelectedAfterToJs ,
  eventTypesSelectedAfterFromJs ,
  dataDestinationToJs ,
  dataDestinationFromJs ,
  userRoleToJs ,
  userRoleFromJs ,
  releaseVersionSourceOptionSelectedToJs ,
  releaseVersionSourceOptionSelectedFromJs ,
  workspaceSubscriptionPlanToJs ,
  workspaceSubscriptionPlanFromJs ,
  releaseVersionAppliedOptionsToJs ,
  releaseVersionAppliedOptionsFromJs ,
  propertyPresenceTypeAfterToJs ,
  propertyPresenceTypeAfterFromJs ,
  changedToToJs ,
  changedToFromJs ,
  namingConventionToJs ,
  namingConventionFromJs ,
  pageNameToJs ,
  pageNameFromJs ,
  learnHubInteractionToJs ,
  learnHubInteractionFromJs ,
  namePromptInteractionTypeToJs ,
  namePromptInteractionTypeFromJs ,
  developmentPlatformToJs ,
  developmentPlatformFromJs ,
  itemTypeToJs ,
  itemTypeFromJs ,
  importChangeToJs ,
  importChangeFromJs ,
  propertyTypeToJs ,
  propertyTypeFromJs ,
  fromDevelopmentPlatformToJs ,
  fromDevelopmentPlatformFromJs ,
  diffTypeToJs ,
  diffTypeFromJs ,
  onboardingDestinationsToJs ,
  onboardingDestinationsFromJs ,
  checklistStepNameToJs ,
  checklistStepNameFromJs ,
  inspectorIssueTypeToJs ,
  inspectorIssueTypeFromJs ,
  eventsViewPopupActionToJs ,
  eventsViewPopupActionFromJs ,
  branchImplementationShareModalInteractionTypeToJs ,
  branchImplementationShareModalInteractionTypeFromJs ,
  propertiesViewConfiguredActionToJs ,
  propertiesViewConfiguredActionFromJs ,
  sortAfterToJs ,
  sortAfterFromJs ,
  unarchiveLocationToJs ,
  unarchiveLocationFromJs ,
  workspaceIssueReportConfigsOnToJs ,
  workspaceIssueReportConfigsOnFromJs ,
  sortBeforeToJs ,
  sortBeforeFromJs ,
  eventTypesSelectedBeforeToJs ,
  eventTypesSelectedBeforeFromJs ,
  workspaceTrialPlanSlugToJs ,
  workspaceTrialPlanSlugFromJs ,
  userOnboardingJobFunctionToJs ,
  userOnboardingJobFunctionFromJs ,
  destinationModeToJs ,
  destinationModeFromJs ,
  profileUpdateToJs ,
  profileUpdateFromJs ,
  pathUpdateToJs ,
  pathUpdateFromJs ,
  propertyListToggleAfterToJs ,
  propertyListToggleAfterFromJs ,
  commentLocationToJs ,
  commentLocationFromJs ,
  to_ToJs ,
  to_FromJs ,
  subscriptionUpgradeModalInteractionToJs ,
  subscriptionUpgradeModalInteractionFromJs ,
  collaboratorAdderRelationshipToJs ,
  collaboratorAdderRelationshipFromJs ,
  propertyStateToJs ,
  propertyStateFromJs ,
  overlayItemTypeToJs ,
  overlayItemTypeFromJs ,
  subscriptionPlanToJs ,
  subscriptionPlanFromJs ,
  filterActionToJs ,
  filterActionFromJs ,
  inspectorIntegrationTypeToJs ,
  inspectorIntegrationTypeFromJs ,
  describedObjectToJs ,
  describedObjectFromJs ,
  clientToJs ,
  clientFromJs ,
  tooltipInteractionTypeToJs ,
  tooltipInteractionTypeFromJs ,
  checklistStepDetailsInteractionTypeToJs ,
  checklistStepDetailsInteractionTypeFromJs ,
  eventVariantPropertyPresenceOverrideTypeBeforeToJs ,
  eventVariantPropertyPresenceOverrideTypeBeforeFromJs ,
  subscriptionTimeUnitToJs ,
  subscriptionTimeUnitFromJs ,
  schemaTypeToJs ,
  schemaTypeFromJs ,
  checklistCloseLocationToJs ,
  checklistCloseLocationFromJs ,
  jobFunctionPromptInteractionTypeToJs ,
  jobFunctionPromptInteractionTypeFromJs ,
  workspaceSubscriptionPlanSlugToJs ,
  workspaceSubscriptionPlanSlugFromJs ,
  inspectorIssuesConfigureItemToJs ,
  inspectorIssuesConfigureItemFromJs ,
  inspectorColumnOrderedByToJs ,
  inspectorColumnOrderedByFromJs ,
  integrationStageToJs ,
  integrationStageFromJs ,
  learnHubStateToJs ,
  learnHubStateFromJs ,
  onboardingActionTypeToJs ,
  onboardingActionTypeFromJs ,
  warningTypeToJs ,
  warningTypeFromJs ,
  exampleTypeToJs ,
  exampleTypeFromJs ,
  addSeatPromptInteractionToJs ,
  addSeatPromptInteractionFromJs ,
  inspectorEnvironmentToJs ,
  inspectorEnvironmentFromJs ,
  eventActionToJs ,
  eventActionFromJs ,
  authenticationConfigToJs ,
  authenticationConfigFromJs ,
  warningPromptInteractionToJs ,
  warningPromptInteractionFromJs ,
  checklistStepsLeftToJs ,
  checklistStepsLeftFromJs ,
  companyRoleToJs ,
  companyRoleFromJs ,
  branchPromptInteractionToJs ,
  branchPromptInteractionFromJs ,
  cmdPaletteSelectionImpactToJs ,
  cmdPaletteSelectionImpactFromJs ,
  inspectorIssueResolveInToJs ,
  inspectorIssueResolveInFromJs ,
  webhookSecretModalInteractionTypeToJs ,
  webhookSecretModalInteractionTypeFromJs ,
  branchImplementationInteractionTypeToJs ,
  branchImplementationInteractionTypeFromJs ,
  workspaceBillingStatusToJs ,
  workspaceBillingStatusFromJs ,
  inspectorEventSidebarInteractionTypeToJs ,
  inspectorEventSidebarInteractionTypeFromJs ,
  regexInteractionTypeToJs ,
  regexInteractionTypeFromJs ,
  branchGroupBranchStatusToJs ,
  branchGroupBranchStatusFromJs ,
  minimumApprovalRoleRequiredToJs ,
  minimumApprovalRoleRequiredFromJs ,
  implementationInstructionInteractionToJs ,
  implementationInstructionInteractionFromJs ,
  propertyComponentToJs ,
  propertyComponentFromJs ,
  upgradePromptTypeToJs ,
  upgradePromptTypeFromJs ,
  schemaSubscriptionPlanToJs ,
  schemaSubscriptionPlanFromJs ,
  overlayItemNameToJs ,
  overlayItemNameFromJs ,
  branchViewConfigureToStateToJs ,
  branchViewConfigureToStateFromJs ,
  branchMergePermissionsBypassedToJs ,
  branchMergePermissionsBypassedFromJs ,
  saveAsDefaultStatusToJs ,
  saveAsDefaultStatusFromJs ,
  collaboratorTypeToJs ,
  collaboratorTypeFromJs ,
  inspectorIntegrationInteractionTypeToJs ,
  inspectorIntegrationInteractionTypeFromJs ,
  exampleInteractionToJs ,
  exampleInteractionFromJs ,
  inspectorColumnSelectedToJs ,
  inspectorColumnSelectedFromJs ,
  integrationItemTypeToJs ,
  integrationItemTypeFromJs ,
  cmdPaletteInitialPathToJs ,
  cmdPaletteInitialPathFromJs ,
  dotNewDomainRedirectToJs ,
  dotNewDomainRedirectFromJs ,
  issueReportConfigsOnToJs ,
  issueReportConfigsOnFromJs ,
  inspectorSavedViewInteractionTypeToJs ,
  inspectorSavedViewInteractionTypeFromJs ,
  workspaceInspectorInstallStatusToJs ,
  workspaceInspectorInstallStatusFromJs ,
  fromDestinationModeToJs ,
  fromDestinationModeFromJs ,
  namingActionTypeToJs ,
  namingActionTypeFromJs ,
  workspaceMinimumApprovalRoleRequiredToJs ,
  workspaceMinimumApprovalRoleRequiredFromJs ,
  authenticationMethodToJs ,
  authenticationMethodFromJs ,
  releaseVersionConfiguredOptionsToJs ,
  releaseVersionConfiguredOptionsFromJs ,
  auditIssueInteractionTypeToJs ,
  auditIssueInteractionTypeFromJs ,
  feedbackDetailsToJs ,
  feedbackDetailsFromJs ,
  sourceConfigToJs ,
  sourceConfigFromJs ,
  workspaceIssueReportEventCasingToJs ,
  workspaceIssueReportEventCasingFromJs ,
  inspectorIssueViewTypeToJs ,
  inspectorIssueViewTypeFromJs ,
  eventLocationToJs ,
  eventLocationFromJs ,
  categoryUpdateActionToJs ,
  categoryUpdateActionFromJs ,
  propertyOriginToJs ,
  propertyOriginFromJs ,
  integrationTypesPublishedToJs ,
  integrationTypesPublishedFromJs ,
  inspectorIssuesColumnOrderedByToJs ,
  inspectorIssuesColumnOrderedByFromJs ,
  sheetFiltersInteractionTypeToJs ,
  sheetFiltersInteractionTypeFromJs ,
  ruleTypeToJs ,
  ruleTypeFromJs ,
  eventNamingConventionsAvailabilityToJs ,
  eventNamingConventionsAvailabilityFromJs ,
  onboardingTrackingContributionsToJs ,
  onboardingTrackingContributionsFromJs ,
  propertyGroupActionToJs ,
  propertyGroupActionFromJs ,
  overlayItemPurposeToJs ,
  overlayItemPurposeFromJs ,
  exportFormatToJs ,
  exportFormatFromJs ,
  workspaceIssueReportEventNamingConventionToJs ,
  workspaceIssueReportEventNamingConventionFromJs ,
  onboardingCompanyRoleToJs ,
  onboardingCompanyRoleFromJs ,
  onboardingJobFunctionToJs ,
  onboardingJobFunctionFromJs ,
  schemaSubscriptionPlanSlugToJs ,
  schemaSubscriptionPlanSlugFromJs ,
  propertyValueConstraintTypeToJs ,
  propertyValueConstraintTypeFromJs ,
  branchStatusBeforeToJs ,
  branchStatusBeforeFromJs ,
  interactionTypeToJs ,
  interactionTypeFromJs ,
  fromSourceConfigToJs ,
  fromSourceConfigFromJs ,
  inspectorIssuesOrderingToJs ,
  inspectorIssuesOrderingFromJs ,
  inspectorTimeWindowToJs ,
  inspectorTimeWindowFromJs ,
  checklistStepStatusToJs ,
  checklistStepStatusFromJs ,
  importStartedLocationToJs ,
  importStartedLocationFromJs ,
  highlightedImplementationStatusTypeToJs ,
  highlightedImplementationStatusTypeFromJs ,
  bannerLocationToJs ,
  bannerLocationFromJs ,
  issueReportConfigureBranchEnforcementStatusToJs ,
  issueReportConfigureBranchEnforcementStatusFromJs ,
  inspectorIssuesStatusViewToJs ,
  inspectorIssuesStatusViewFromJs ,
  collaboratorAdderActionToJs ,
  collaboratorAdderActionFromJs ,
  eventVariantUpdateTypeToJs ,
  eventVariantUpdateTypeFromJs ,
  codeSnippetInteractionToJs ,
  codeSnippetInteractionFromJs ,
  sourceChangeToJs ,
  sourceChangeFromJs ,
  workspaceSlackIntegrationsEnabledToJs ,
  workspaceSlackIntegrationsEnabledFromJs ,
  eventVariantPropertyPresenceOverrideTypeAfterToJs ,
  eventVariantPropertyPresenceOverrideTypeAfterFromJs ,
  issueReportPropertyCasingToJs ,
  issueReportPropertyCasingFromJs ,
  triggerConfigureActionToJs ,
  triggerConfigureActionFromJs ,
  toConstraintTypeToJs ,
  toConstraintTypeFromJs ,
  propertyLocationToJs ,
  propertyLocationFromJs ,
  emptyStateInteractionToJs ,
  emptyStateInteractionFromJs ,
  destinationChangeToJs ,
  destinationChangeFromJs ,
  triggerConfigureItemToJs ,
  triggerConfigureItemFromJs ,
  branchImplementationConfiguredActionToJs ,
  branchImplementationConfiguredActionFromJs ,
  tagActionToJs ,
  tagActionFromJs ,
  schemaBillingStatusToJs ,
  schemaBillingStatusFromJs ,
  fromScreenToJs ,
  fromScreenFromJs ,
  roleToJs ,
  roleFromJs ,
  integrationPayloadFormatToJs ,
  integrationPayloadFormatFromJs ,
  settingNameToJs ,
  settingNameFromJs ,
  constraintTypeToJs ,
  constraintTypeFromJs ,
  propertiesViewSegmentationBeforeToJs ,
  propertiesViewSegmentationBeforeFromJs ,
  fromDataDestinationToJs ,
  fromDataDestinationFromJs ,
  fromProgrammingLanguageToJs ,
  fromProgrammingLanguageFromJs ,
  matchesActionToJs ,
  matchesActionFromJs ,
  issueReportEventNamingConventionToJs ,
  issueReportEventNamingConventionFromJs ,
  constraintActionToJs ,
  constraintActionFromJs ,
  propertyValueTypeBeforeToJs ,
  propertyValueTypeBeforeFromJs ,
  propertyConfigureActionToJs ,
  propertyConfigureActionFromJs ,
  dataSourceToJs ,
  dataSourceFromJs ,
  fromConstraintTypeToJs ,
  fromConstraintTypeFromJs ,
  inspectorIssuesActiveFiltersToJs ,
  inspectorIssuesActiveFiltersFromJs ,
  subscriptionPlanSlugToJs ,
  subscriptionPlanSlugFromJs ,
  metricTypeToJs ,
  metricTypeFromJs ,
  inspectorOrderToJs ,
  inspectorOrderFromJs ,
  issueReportEventCasingToJs ,
  issueReportEventCasingFromJs ,
  eventImplementationSnippetInteractionTypeToJs ,
  eventImplementationSnippetInteractionTypeFromJs ,
  fromToJs ,
  fromFromJs ,
  issueReportConfigureActionToJs ,
  issueReportConfigureActionFromJs ,
  sandboxInteractionToJs ,
  sandboxInteractionFromJs ,
  branchStatusToJs ,
  branchStatusFromJs ,
  programmingLanguageToJs ,
  programmingLanguageFromJs ,
  branchImplementationViewAccessTypeToJs ,
  branchImplementationViewAccessTypeFromJs ,
  jobFunctionToJs ,
  jobFunctionFromJs ,
  propertyListToggleBeforeToJs ,
  propertyListToggleBeforeFromJs ,
  triggerContentTypeToJs ,
  triggerContentTypeFromJs ,
  trackingContributionToJs ,
  trackingContributionFromJs ,
  linkNameToJs ,
  linkNameFromJs ,
  schemaTrialPlanToJs ,
  schemaTrialPlanFromJs ,
  collapsibleItemTypeToJs ,
  collapsibleItemTypeFromJs ,
  workspaceCompanySizeToJs ,
  workspaceCompanySizeFromJs ,
  checklistTypeToJs ,
  checklistTypeFromJs ,
  sourceCategoryToJs ,
  sourceCategoryFromJs ,
  avoIntelligenceAccessTypeToJs ,
  avoIntelligenceAccessTypeFromJs ,
  workspaceTrialPlanToJs ,
  workspaceTrialPlanFromJs ,
  propertyAttributeToJs ,
  propertyAttributeFromJs ,
  issueReportConfigureItemToJs ,
  issueReportConfigureItemFromJs ,
  planToTrialToJs ,
  planToTrialFromJs ,
  inspectorIssuesConfigureActionToJs ,
  inspectorIssuesConfigureActionFromJs ,
  implementationInstructionFormatToJs ,
  implementationInstructionFormatFromJs ,
  inspectorAlertTypeToJs ,
  inspectorAlertTypeFromJs ,
  eventsViewConfiguredActionToJs ,
  eventsViewConfiguredActionFromJs ,
  implementationMethodToJs ,
  implementationMethodFromJs ,
  propertyPresenceWhenInObjectToggleBeforeToJs ,
  propertyPresenceWhenInObjectToggleBeforeFromJs ,
  advancedEventNameComponentCasingsToJs ,
  advancedEventNameComponentCasingsFromJs ,
  eventTypeToJs ,
  eventTypeFromJs ,
  checklistPathToJs ,
  checklistPathFromJs ,
  overlayItemInteractionTypeToJs ,
  overlayItemInteractionTypeFromJs ,
  inspectorSavedViewRelationshipToJs ,
  inspectorSavedViewRelationshipFromJs ,
  userAuthenticationStatusToJs ,
  userAuthenticationStatusFromJs ,
  paywallNameToJs ,
  paywallNameFromJs ,
  propertyUpdateActionToJs ,
  propertyUpdateActionFromJs ,
  trackingPlanAuditLocationToJs ,
  trackingPlanAuditLocationFromJs ,
  propertiesViewSegmentationAfterToJs ,
  propertiesViewSegmentationAfterFromJs ,
  propertyValueTypeAfterToJs ,
  propertyValueTypeAfterFromJs ,
  eventAttributeToJs ,
  eventAttributeFromJs ,
  columnsVisibleAfterChangeToJs ,
  columnsVisibleAfterChangeFromJs ,
  eventsViewConfiguredItemToJs ,
  eventsViewConfiguredItemFromJs ,
  eventActionsIncludedToJs ,
  eventActionsIncludedFromJs ,
  inspectorIssueDetailsInteractionTypeToJs ,
  inspectorIssueDetailsInteractionTypeFromJs ,
  settingValueToJs ,
  settingValueFromJs ,
  importParserResultToJs ,
  importParserResultFromJs ,
  workspaceIssueReportPropertyCasingToJs ,
  workspaceIssueReportPropertyCasingFromJs ,
  integrationTypeToJs ,
  integrationTypeFromJs ,
  emptyStateLocationToJs ,
  emptyStateLocationFromJs ,
  eventOriginToJs ,
  eventOriginFromJs ,
  collapseStateToJs ,
  collapseStateFromJs ,
  onboardingCompanySizeToJs ,
  onboardingCompanySizeFromJs ,
  highlightedImplementationStatusStateToJs ,
  highlightedImplementationStatusStateFromJs ,
  activityLogMenuInteractionToJs ,
  activityLogMenuInteractionFromJs ,
  upgradePromptReactionToJs ,
  upgradePromptReactionFromJs ,
  metricUpdateActionToJs ,
  metricUpdateActionFromJs ,
  feedbackTypeToJs ,
  feedbackTypeFromJs ,
  advancedEventNameComponentTypesToJs ,
  advancedEventNameComponentTypesFromJs ,
  inspectorIssueStatusToJs ,
  inspectorIssueStatusFromJs ,
  issueReportConfigureCasingToJs ,
  issueReportConfigureCasingFromJs ,
  integrationFiltersToJs ,
  integrationFiltersFromJs ,
  inspectorViewToJs ,
  inspectorViewFromJs ,
  codeChangesAuditInteractionTypeToJs ,
  codeChangesAuditInteractionTypeFromJs ,
  tooltipPurposeToJs ,
  tooltipPurposeFromJs ,
  closingMethodToJs ,
  closingMethodFromJs ,
  onboardingChecklistInteractionToJs ,
  onboardingChecklistInteractionFromJs ,
  learnHubContentCallToActionsToJs ,
  learnHubContentCallToActionsFromJs ,
  schemaTrialPlanSlugToJs ,
  schemaTrialPlanSlugFromJs ,
  branchOriginToJs ,
  branchOriginFromJs ,
  propertiesViewColumnNameToJs ,
  propertiesViewColumnNameFromJs ,
  sandboxElementToJs ,
  sandboxElementFromJs ,
  propertyPresenceTypeBeforeToJs ,
  propertyPresenceTypeBeforeFromJs ,
  sourceTypeToJs ,
  sourceTypeFromJs ,
  inspectorFilterTypeUpdatedToJs ,
  inspectorFilterTypeUpdatedFromJs ,
  tooltipDisplayMethodToJs ,
  tooltipDisplayMethodFromJs ,
  eventsViewColumnNameToJs ,
  eventsViewColumnNameFromJs ,
  propertyPresenceWhenInObjectToggleAfterToJs ,
  propertyPresenceWhenInObjectToggleAfterFromJs ,
  fromDiffTypeToJs ,
  fromDiffTypeFromJs ,
  workspaceBranchPermissionsEnabledToJs ,
  workspaceBranchPermissionsEnabledFromJs ,
  issueTypeToJs ,
  issueTypeFromJs ,
  Bundle ,
  DestinationOptions ,
  AvoSystemProperties ,
  __WEB_DEBUGGER__ ,
  AvoAssertMessage ,
  AvoAssertMessageBody ,
  __MOBILE_DEBUGGER__ ,
  _mobile_debugger_set_schema_id ,
  _mobile_debugger_given ,
  _mobile_debugger_enabled ,
  _mobile_debugger_post_event ,
  avoEnvTypeToJs ,
  avoEnvTypeFromJs ,
  __ENV__ ,
  __AVO_NOOP__ ,
  AvoAssert ,
  AvoLogger ,
  avo_invoke ,
  avo_invoke_meta ,
  rawDebuggerPositionToJs ,
  rawDebuggerPositionFromJs ,
  Intercom ,
  Fullstory ,
  Mixpanel ,
  assertNumSourcesUpdatedOnBranchWithTriggers ,
  assertBreakingChangeImpactedEventCount ,
  assertNumGloballyOptionalPropertiesInSchema ,
  assertDaysSinceTrialStart ,
  assertInspectorDisplayedIssueCountOptional ,
  assertInspectorDisplayedIssueCount ,
  assertNumEventsInSchema ,
  assertCmdPalettePathDepth ,
  assertNumNewEventsWithTriggers ,
  assertInspectorIssueProportion ,
  assertNumChecklistStepsLeft ,
  assertNumIntegrationsPublished ,
  assertNumUpdatedEventsWithTriggers ,
  assertBreakingChangeImpactedSourceCount ,
  assertNumComments ,
  assertInspectorIssueVolume ,
  assertInspectorEventVolume ,
  assertProportionOfInspectorEventsNotFoundInTrackingPlan ,
  assertNumNewProperties ,
  assertNumNewGloballyOptionalProperties ,
  assertNumIssueReportConfigsOn ,
  assertNumTriggerSources ,
  assertInspectorIssueLastSeenHoursAgo ,
  assertInspectorIssueFirstSeenHoursAgo ,
  assertProportionOfUpdatedEventInstancesImplementedOnBranchOptional ,
  assertNumPropertiesInSchema ,
  assertChecklistProgressRatio ,
  assertInspectorIssueSourceCount ,
  assertProportionOfUpdatedSourcesImplementedOnBranchOptional ,
  assertNumProperties ,
  assertNumberOfNamingSuggestions ,
  assertNumTriggersOnEvent ,
  assertInspectorSavedViewCount ,
  assertSchemaCreatedIntegrationsCount ,
  assertReleaseVersionConfiguredSourceCount ,
  assertNumUpdatedProperties ,
  sysClient ,
  sysVersion ,
  setSystemProperties ,
  setSystemProperties_ ,
  postHog ,
  segment ,
  clearbit ,
  intercomDestinationInterface ,
  inspectorCustomDestination ,
  initAvo ,
  constraintValueUpdated ,
  matchUpdated ,
  matchUpdatedForEvent ,
  matchUpdatedForSource ,
  matchUpdatedForEventSource ,
  membersModalClosed ,
  membersModalOpened ,
  propertyAdded ,
  navigated ,
  signedIn ,
  membersClicked ,
  upgradePlanPrompted ,
  cancelSubscriptionButtonClicked ,
  eventInitiated ,
  signInFailed ,
  typeChanged ,
  requiredToggled ,
  memberInvited ,
  eventRemoved ,
  signUpFailed ,
  subscriptionInterestIndicated ,
  signedOut ,
  eventNameUpdated ,
  eventNameUpdatedAdvancedStructure ,
  signedUp ,
  propertyRemoved ,
  descriptionUpdated ,
  constraintTypeUpdated ,
  appOpened ,
  inviteAccepted ,
  schemaNotFound ,
  signUpVerifyRequested ,
  signUpVerifyCompleted ,
  listToggled ,
  dataSourceUpdated ,
  dataDestinationUpdated ,
  dataPathUpdated ,
  eventTypeUpdated ,
  authenticated ,
  passwordResetRequested ,
  versionUpdatePrompted ,
  versionUpdateAccepted ,
  versionUpdated ,
  propertyDeletePrompted ,
  propertyDeleted ,
  eventsFilterUpdated ,
  eventListSorted ,
  commentAdded ,
  commentSeen ,
  commentDeleted ,
  profileUpdated ,
  profileViewed ,
  memberRemoved ,
  memberRoleChanged ,
  upgradeBannerClicked ,
  eventTagsUpdated ,
  filterLinkOpened ,
  categoryCreated ,
  categoryUpdated ,
  categoryArchived ,
  metricInitiated ,
  metricArchived ,
  metricUpdated ,
  branchCreated ,
  branchMerged ,
  branchDeleted ,
  eventCreated ,
  eventCreatedAdvancedNamingConvention ,
  propertyUpdated ,
  propertyInitiated ,
  propertyCreated ,
  namingFeedbackReceived ,
  nameEditorClosed ,
  eventUpdated ,
  propertyGroupCreated ,
  propertyGroupUpdated ,
  propertyGroupArchived ,
  propertyGroupInitiated ,
  codeSnippetInteracted ,
  branchPromptInteraction ,
  upgradePromptInteraction ,
  onboardingStarted ,
  onboardingAction ,
  onboardingCompleted ,
  schemaCreated ,
  onboardingChecklistProgressed ,
  exampleInteraction ,
  searchInputActivated ,
  memberInviteRemoved ,
  memberInviteResent ,
  issueReportOpened ,
  issueExpanded ,
  issuePreviewDisplayed ,
  signedUpIntercom ,
  inspectorEventsViewed ,
  branchOpened ,
  lockedBranchEditAttempted ,
  collaboratorAdded ,
  collaboratorRemoved ,
  overlayItemDisplayed ,
  overlayItemInteraction ,
  inspectorFilterUpdated ,
  inspectorEventsNavigated ,
  workspaceSettingUpdated ,
  branchStatusUpdated ,
  integrationCreated ,
  integrationInitiated ,
  integrationConfigured ,
  categoryInitiated ,
  propertyConfigured ,
  inspectorApiKeyGenerated ,
  importCallToActionSeen ,
  importStarted ,
  importFileSelected ,
  importCompleted ,
  addSeatPrompted ,
  addSeatPromptInteraction ,
  subscriptionUpgradeStarted ,
  subscriptionUpgradeModalInteraction ,
  subscriptionDowngradeStarted ,
  onboardingChecklistOpened ,
  onboardingChecklistStepDetailsOpened ,
  onboardingChecklistStepDetailsInteraction ,
  onboardingChecklistClosed ,
  onboardingChecklistDismissed ,
  implementationInstructionInteraction ,
  eventsViewConfigured ,
  implementationStatusDetailsViewed ,
  eventsViewInteraction ,
  eventsViewPopupInteraction ,
  inspectorIntegrationInteraction ,
  jobFunctionPromptDisplayed ,
  jobFunctionPromptInteraction ,
  jobFunctionPromptCompleted ,
  workspaceNameUpdated ,
  exportSchemaCompleted ,
  exportSchemaStarted ,
  exportSchemaFormatSelected ,
  eventTriggerInitiated ,
  eventTriggerInitiatedOnEvent ,
  eventTriggerInitiatedOnVariant ,
  eventTriggerConfigured ,
  eventTriggerConfiguredOnEvent ,
  eventTriggerConfiguredOnVariant ,
  eventTriggerCreated ,
  eventTriggerCreatedOnEvent ,
  eventTriggerCreatedOnVariant ,
  propertiesViewConfigured ,
  issueReportConfigOpened ,
  issueReportConfigured ,
  issueReportConfiguredCasing ,
  issueReportConfiguredBranchBlocking ,
  issueReportConfiguredAdvancedEventNamingConvention ,
  cmdPaletteOptionSelected ,
  cmdPaletteOpened ,
  cmdPaletteExited ,
  updateNumInspectorProductionSources ,
  updateNumInspectorDevelopmentSources ,
  updateNumInspectorStagingSources ,
  workspaceTrialStarted ,
  sheetFiltersInteraction ,
  inviteRemoved ,
  duplicateEvent ,
  auditIssueInteracted ,
  itemUnarchived ,
  promoBannerInteracted ,
  webhookSecretModalInteraction ,
  logoClicked ,
  branchLinkClicked ,
  activityLogMenuInteracted ,
  demoBranchCreated ,
  branchesViewConfigured ,
  serviceAccountCreated ,
  serviceAccountDeleted ,
  branchNotFound ,
  namePromptDisplayed ,
  namePromptInteracted ,
  emptyStateDisplayed ,
  emptyStateInteracted ,
  collapsibleItemInteracted ,
  warningPromptDisplayed ,
  warningPromptInteracted ,
  tooltipDisplayed ,
  tooltipInteracted ,
  itemDetailsOpened ,
  eventImplementationSnippetInteraction ,
  branchImplementationInteraction ,
  branchImplementViewConfigured ,
  branchImplementationShareModalInteraction ,
  codeChangesAuditInteracted ,
  codeChangesAuditSourceInteracted ,
  intelligenceNamingFeedbackInitiated ,
  namingFeedbackApplied ,
  inspectorIssueDetailsInteraction ,
  inspectorIssuesViewed ,
  inspectorIssueDetailsOpened ,
  inspectorIssuesViewConfigured ,
  inspectorEventsSidebarInteraction ,
  inspectorSavedViewCreated ,
  inspectorSavedViewInteraction ,
  inspectorSavedViewOpened ,
  inspectorSavedViewInitiated ,
  inspectorSavedViewDraftInitiated ,
  productFlowStarted ,
  productFlowStepCompleted ,
  productFlowCompleted ,
  productFlowExited ,
  releaseVersionConfigurationPromptSeen ,
  releaseVersionConfigurationModalOpened ,
  releaseVersionConfigurationCompleted ,
  releaseVersionSourceOptionSelected ,
  inspectorAlertsInitiated ,
  inspectorAlertsTypeSelected ,
  inspectorAlertsDisabled ,
  propertiesViewInteraction ,
  propertiesViewPopupInteraction ,
  inspectorIssueStatusUpdated ,
  importChanged ,
  subscriptionDetailsViewed ,
  onboardingChecklistPathSelected ,
  sandboxInteraction ,
  sandboxModalSeen ,
  onboardingChecklistInteraction ,
  anonymousUserCreated ,
  sandboxInteractionWithoutWorkspace ,
  sandboxModalSeenWithoutWorkspace ,
  learnHubSeen ,
  learnHubInteracted ,
  eventVariantCreated ,
  eventVariantInitiated ,
  eventVariantArchived ,
  eventVariantUpdated ,
  eventVariantUpdatedProperty ,
  eventVariantUpdatedPropertyPresenceOverride ,
  eventVariantUpdatedSource ,
  regexInteraction ,
  regexInteractionEventSpecific ,
  
}
/*  Not a pure module */
